import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";

function AddDivision({
  type = "add",
  show,
  setShow,
  selectedDivision,
  divisions,
  setDivisions,
  selectedRivalry,
  getDivisions,
  getAllDivisions,
}) {
  const [initialValues, setInitialValues] = useState({
    divisionName: "",
    // subDivisionNo: 1,
  });
  const [rivalries, setRivalries] = useState([]);
  const [nights, setNights] = useState([]);

  const getAllRivalry = async () => {
    const response = await api.get("rivelary/api/v1/getRivelaries", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setRivalries(response.data);
  };

  useEffect(() => {
    getAllRivalry();
  }, []);
  const handlecompetitionChange = (id) => {
    const selectedRivelary = rivalries.filter((item) => item._id === id);
    setNights(selectedRivelary?.[0]?.days);
  };
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    // setInitialValues({
    //   divisionName: "",
    //   night: "",
    //   competition: "",
    //   subDivisionNo: 1,
    // });
    setShow(false);
  }

  useEffect(() => {
    // console.log(selectedDivision);
    if (selectedDivision && type !=='add') {
      setNights(selectedDivision.competition?.days);
      setInitialValues({
        ...initialValues,
        competition: selectedDivision.competition?._id,
        divisionName: selectedDivision.divisionName,
        night: selectedDivision.night,
        type: selectedDivision?.type,
      });
    } else {
      setInitialValues({
        divisionName: "",
        night: "",
        competition: "",
        type: "Senior",
      });
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedDivision,type]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      competition: Yup.string().required("Required"),
      divisionName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      night: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      // subDivisionNo: Yup.number()
      //   .min(1, "Please insert a valid subDivisionNo")
      //   .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values);
      // console.log(divisions);
      // console.log(type);
      try {
        if (type === "edit") {
          const newdiv = await api.put(
            "/crud/api/v1/division",
            { ...values, _id: selectedDivision._id },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("Division Edited Successfully");
          getAllDivisions();
          closeModal();
        } else {
          const newdiv = await api.post("/crud/api/v1/division", values, {
            headers: { token: cookies.token },
          });
          successNotification("Division Added Successfully");
          getAllDivisions();
          closeModal();
          // if (selectedDiv) {
          //   // console.log("edit");
          //   // console.log(selectedDiv);
          //   let matcharr = divisions.filter(
          //     (item) => item.divisionName !== selectedDiv.divisionName
          //   );
          //   setDivisions([...matcharr, values]);
          //   closeModal()

          // } else {
          //   // console.log("add new to existing rivarly");
          //   // if (divisions.length === 0) {
          //   //   setDivisions([...divisions, values]);
          //   //   closeModal()

          //   // } else {
          //   //   let matcharr = divisions.filter(
          //   //     (item) => item.divisionName === values.divisionName
          //   //   );
          //   //   if (matcharr.length > 0) {
          //   //     failNotification("division is already saved");
          //   //   } else {
          //   //     setDivisions([...divisions, values]);
          //   //     closeModal()

          //   //   }
          //   // }
          // }
        }

        // if (type == "add") {
        //   const newUser = await api.post("/crud/api/v1/", values, {
        //     headers: { token: cookies.token },
        //   });
        //   successNotification("Field Add Successfully");
        //   getAllFields();
        //   setShow(false);
        //   formik.resetForm();
        // } else {
        //   const newUser = await api.put("/crud/api/v1/editField", values, {
        //     headers: { token: cookies.token },
        //   });
        //   successNotification("Player Edited Successfully");
        //   getAllFields();
        //   setShow(false);
        //   formik.resetForm();
        // }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error.response.data.message);
        failNotification(error.response.data.message);
      }
    },
  });
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add Division" : "Edit Division"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="competition"
                      >
                        Rivalry
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="competition"
                        name="competition"
                        onChange={(e) => {
                          handlecompetitionChange(e.target.value);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.competition}
                      >
                        <option value={""} hidden>
                          Please Select rivelary
                        </option>
                        {rivalries?.map((rivalry) => {
                          return (
                            <option key={rivalry._id} value={rivalry._id}>
                              {rivalry.rivelaryName} / {rivalry.area?.areaName}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.competition &&
                      formik.errors.competition ? (
                        <div className="text-red-500">
                          {formik.errors.competition}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="night"
                      >
                        Night
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="night"
                        name="night"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.night}
                      >
                        <option value={""} hidden>
                          Please Select Night
                        </option>
                        {nights?.map((night, i) => {
                          return (
                            <option key={i} value={night}>
                              {night}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.night && formik.errors.night ? (
                        <div className="text-red-500">
                          {formik.errors.night}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="type"
                      >
                        Type
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="type"
                        name="type"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                      >
                        <option value={""} hidden>
                          Please Select type
                        </option>
                        <option value={"Senior"}>Senior</option>
                        <option value={"Junior"}>Junior</option>
                      </select>
                      {formik.touched.type && formik.errors.type ? (
                        <div className="text-red-500">
                          {formik.errors.type}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="divisionName"
                      >
                        Division Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="divisionName"
                        name="divisionName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.divisionName}
                      />
                      {formik.touched.divisionName &&
                      formik.errors.divisionName ? (
                        <div className="text-red-500">
                          {formik.errors.divisionName}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="subDivisionNo"
                      >
                        Sub Division Number
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="subDivisionNo"
                        name="subDivisionNo"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subDivisionNo}
                      />
                      {formik.touched.subDivisionNo &&
                      formik.errors.subDivisionNo ? (
                        <div className="text-red-500">
                          {formik.errors.subDivisionNo}
                        </div>
                      ) : null}
                    </div> */}

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        {type == "add" ? "Add Division" : "Edit Division"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddDivision;
