import React, { useEffect, useMemo, useState } from "react";
import api from "../api";

function WhereToPlay() {
  const [areas, setAreas] = useState([]);

  const getAreas = async () => {
    const areas = await api.get("/crud/api/v1/getAreasActive");
    setAreas(areas.data);
  };

  useEffect(() => {
    if (areas.length === 0) {
      getAreas();
    }
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="container flex flex-col scale-90 border ">
      {/* <div className="flex gap-3 justify-between">
        <input
          type="text"
          placeholder="Search by suburb or postcode"
          className="input w-1/2"
        />
        <select className="input w-1/4">
          <option value="" selected>
            Filters
          </option>
        </select>
        <button className="input bg-[#0FA9E8] text-white">Register Now</button>
      </div> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        {areas.map((area) => {
          return (
            <div className=" flex flex-col h-fit w-full rounded-lg shadow-sm">
              <img
                src={
                  area.image
                    ? `${process.env.REACT_APP_SERVER_URL + area?.image}`
                    : "mapscreen.png"
                }
                alt="./mapscreen.png"
                loading="lazy"
              />
              <div className="flex flex-col p-5">
                <h3 className="font-semibold">{area?.areaName}</h3>
                <h5>Address: {area?.address}</h5>
                {/* <h5>Phone: 0418 469 203</h5> */}
                <h5>Email: {area?.email}</h5>
                {/* <h5>Age Group: Senior</h5>
                <div className="flex flex-col md:flex-row justify-between items-center mt-3">
                  <h5>Learn more about privacy</h5>
                  <button className="input bg-[#0FA9E8] text-white">
                    Register Now
                  </button>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default WhereToPlay;
