import { Dialog, Transition } from '@headlessui/react'
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { BsEye, BsPerson, BsPhone } from 'react-icons/bs'
import { CgCalendar, CgLock } from 'react-icons/cg'
import { HiArrowRight } from 'react-icons/hi'
import { MdEmail, MdLocationPin } from 'react-icons/md'
import { RiTeamLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import api from '../api'
import * as Yup from 'yup';
import { successNotification } from '../components/Notifications'
import ChangePasswordModal from '../components/auth/ChangePasswordModal'
import ChangeUserInfo from './auth/ChangeUserInfo'

function AdminSiteSettings() {

  const [changePassword, setChangePassword] = useState(false)

  const [userData, setUserData] = useState()
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    dateOfBirth: '',
    email: '',
  })
  const [codeVisible, setCodeVisible] = useState(false)
  const [cookies, setCookie] = useCookies(['token']);
  let [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const navigate = useNavigate()
  const getPlayerInfo = async () => {
    try {
      const user = await api.get('/auth/api/v1/getPlayerData', { headers: { token: cookies.token } })
      setUserData(user.data)
      // console.log(user)
    } catch {
      console.log('error')
      navigate('/')
    }
  }
  useEffect(() => {
    getPlayerInfo()
  }, [])

  useEffect(() => {
    if (userData) {

      // console.log(userData.dateOfBirth)
      setInitialValues({ ...initialValues, ...userData, dateOfBirth: (userData.dateOfBirth) ? new Date(userData.dateOfBirth).toISOString().slice(0, 10) : '' })
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [userData])
  return (
    <>
      <ChangePasswordModal changePassword={changePassword} setChangePassword={setChangePassword} />

      <ChangeUserInfo isOpen={isOpen} closeModal={closeModal} initialValues={initialValues} getPlayerInfo={getPlayerInfo} />
      <div className='w-4/5 bg  h-fit flex flex-col gap-5 scale-90 '>
        <h1 className='text-center font-bold text-3xl'>Site Setting</h1>
        <div>

          <h2 className='font-bold text-2xl'>Admin Information</h2>
          <p className='text-sm font-normal text-[#828D9E]'>Update your admin information</p>
        </div>
        <div className='w-full items-center flex flex-col'>

          <div className='grid grid-cols-2 w-1/2 gap-3'>
            <div className='flex gap-2 items-center'>
              <BsPerson className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Full Name</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{userData?.firstName} {userData?.lastName}</span>
            </div>
            <div className='flex gap-2 items-center'>
              <MdEmail className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Email</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{userData?.email}</span>
            </div>
            <div className='flex gap-2 items-center'>
              <BsPhone className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Phone Number</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{userData?.phoneNumber}</span>
            </div>
            <div className='flex gap-2 items-center'>
              <MdLocationPin className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Address</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{userData?.address}</span>
            </div>
            <div className='flex gap-2 items-center'>
              <CgCalendar className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Date of Birth</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{new Date(userData?.dateOfBirth).toLocaleDateString()}</span>
            </div>
            {/* <div className='flex gap-2 items-center'>
              <RiTeamLine className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Team Name</span>
            </div>
            <div className='flex gap-2 flex-row-reverse'>
              <span>{userData?.teamId?.teamName}</span>
            </div> */}
            <div className='flex gap-2 items-center'>
              <CgLock className='text-[#828D9E]' />
              <span className='text-[#828D9E]'>Player Code</span>
            </div>
            <div className='flex gap-2 flex-row-reverse items-center'>
              <BsEye onClick={() => setCodeVisible(!codeVisible)} className='cursor-pointer' />
              <span >{(codeVisible) ? userData?.playerCode : '**********'}</span>
            </div>

          </div>
          <div className='w-full flex flex-col col-span-2 items-center  my-5'>
            <button className='input w-fit py-2' onClick={() => setChangePassword(true)}>changePassword</button>
          </div>
          <div className='flex w-full justify-center'>
            <button className='px-10 py-3 bg-[#00B5FF] text-white rounded-lg' onClick={openModal}>Edit Profile</button>

          </div>
        </div>
        {/* <h1 className='text-center font-bold text-3xl'>Site Setting</h1>
      <div>

        <h2 className='font-bold text-2xl'>Admin Information</h2>
        <p className='text-sm font-normal text-[#828D9E]'>Update your admin information</p>
      </div>
      <h2 className='font-bold text-lg'>Personal Information</h2>
      <div className='grid grid-cols-2 gap-x-5'>

        <div className='flex flex-col gap-4  mb-4'>
          <label htmlFor="FirstName" className='font-medium text-base'>First Name</label>
          <input id='FirstName' type="text" placeholder='First Name' className='input'></input>

        </div>
        <div className='flex flex-col gap-4  mb-4 pl-2'>
          <label htmlFor="LastName" className='font-medium text-base'>Last Name</label>
          <input id='LastName' type="text" placeholder='Last Name' className='input'></input>
        </div>
        <div className='flex flex-col gap-4  mb-4 pr-2'>
          <label htmlFor="DateOfBirth " className='font-medium text-base'>Date Of Birth </label>
          <input id='DateOfBirth' type="date" placeholder='Date Of Birth' className='input'></input>

        </div>


        <div className='flex flex-col gap-4  mb-4 pl-2'>
          <label htmlFor="PhoneNumber" className='font-medium text-base'>Phone Number</label>
          <input id='PhoneNumber' type="phone" placeholder='Phone Number' className='input'></input>

        </div>
        <div className='flex flex-col gap-4  mb-4 pr-2 col-span-2'>
          <label htmlFor="E-mail" className='font-medium text-base'>E-mail</label>
          <input id='E-mail' type="email" placeholder='E-mail' className='input'></input>
        </div>
        <div className='w-full flex justify-center col-span-2'>
          <button className='py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-1/2'>Apply Changes</button>
        </div>
      </div> */}
      </div>
    </>
  )
}

export default AdminSiteSettings