import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AdminAddNewTeamModal from "./AdminAddNewTeamModal";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import ReactLoading from "react-loading";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminEditTeamsModal from "./adminPanel/AdminEditTeamsModal";
import ConfirmRemoveTeam from "./adminPanel/ConfirmRemoveTeam";

function AdminTeams() {
  const [teams, setTeams] = useState([]);
  const [cookies] = useCookies(["token"]);

  const getAllTeams = async () => {
    const response = await api.get("auth/api/v1/getAllTeams", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setTeams(response.data);
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  let columns = [
    {
      dataField: "teamCode",
      text: "Team Code",
      style: { fontSize: "small", width: "400px" },
      hidden: false,
    },
    {
      dataField: "teamName",
      text: "Team Name",
      style: { fontSize: "small", width: "110px" },
    },
    {
      dataField: "area.areaName",
      text: "Area",
      style: { fontSize: "small", width: "50px" },
    },
    {
      dataField: "rivelary.rivelaryName",
      text: "Rivelary",
      style: { fontSize: "small" },
      formatter: (cell, row) => {
        return (
          <p
            className={`text-center ${
              !row?.rivelary?.rivelaryName && "bg-red-400 font-bold"
            }`}
          >
            {row?.rivelary?.rivelaryName
              ? row?.rivelary?.rivelaryName
              : "No rivelary"}
          </p>
        );
      },
    },
    {
      dataField: "division.divisionName",
      text: "Division",
      style: { fontSize: "small" },
      formatter: (cell, row) => {
        return (
          <p
            className={`text-center ${
              !row?.division?.divisionName && "bg-red-400 font-bold"
            }`}
          >
            {row?.division?.divisionName
              ? row?.division?.divisionName
              : "No division"}
          </p>
        );
      },
    },
    {
      dataField: "subDiv.subDivName",
      text: "Sub Division",
      style: { fontSize: "small" },
      formatter: (cell, row) => {
        return (
          <p
            className={`text-center ${
              !row?.subDiv?.subDivName && "bg-red-400 font-bold"
            }`}
          >
            {row?.subDiv?.subDivName
              ? row?.subDiv?.subDivName
              : "No sub division"}
          </p>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      style: { fontSize: "small" },
      formatter: (cell, row) => {
        return (
          <p
            className={`text-center font-bold ${
              row?.status === "active" ? "bg-green-400" : "bg-red-400"
            }`}
          >
            {row?.status}
          </p>
        );
      },
    },
    // { dataField: 'email', text: 'E-mail', style: { fontSize: 'small' } },
    // { dataField: 'phoneNumber', text: 'Phone Number', style: { fontSize: 'small' } },
    // { dataField: 'address', text: 'Address', style: { fontSize: 'small' } },
    // { dataField: 'dateOfBirth', text: 'Date Of Birth', style: { fontSize: 'small' } },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleOpenEdit(row._id)}
            />
            {row.status === "active" ? (
              <TiDeleteOutline
                size={30}
                className="cursor-pointer hover:text-red-500 text-xl"
                onClick={() => handleRemoveBtn(row)}
              />
            ) : (
              <IoArrowUndoCircleOutline
                size={30}
                className="cursor-pointer hover:text-red-500 text-xl"
                onClick={() => handleRemoveBtn(row)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => {
    setVisible(true);
  };

  const handleOpenEdit = (teamId) => {
    // console.log(teamId)
    setSelectedTeam(teamId);
    setEditModal(true);
  };

  const handleRemoveBtn = (team) => {
    console.log(team);
    setSelectedTeam(team);
    setDeleteModal(true);
  };

  return (
    <>
      <AdminAddNewTeamModal show={visible} setShow={setVisible} />
      <AdminEditTeamsModal
        show={editModal}
        setShow={setEditModal}
        teamId={selectedTeam}
      />
      <ConfirmRemoveTeam
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        team={selectedTeam}
        getAllTeams={getAllTeams}
      />
      {teams.length > 0 ? (
        <div className="flex flex-col w-4/5 gap-5 scale-90 -mx-20 min-h-screen">
          <ToolkitProvider
            keyField="teamName"
            data={teams}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="flex w-full justify-between">
                  <SearchBar {...props.searchProps} className="input " />
                  <button
                    className="normalButton py-2 text-sm"
                    onClick={() => handleOpenAdd()}
                  >
                    Add Team
                  </button>
                </div>
                <hr />

                <BootstrapTable
                  loading={true}
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  condensed
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <div className="w-4/5 flex justify-center items-center">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </>
  );
}

export default AdminTeams;
