import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { IoMdAddCircle } from "react-icons/io";
import { BiMinusCircle } from "react-icons/bi";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import {
  failNotification,
  infoNotification,
  successNotification,
} from "../Notifications";
import AddDivision from "./AddDivisionModal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Loader from "../loader";

function AdminAddRivelary({
  type = "add",
  show,
  setShow,
  getAllRivelries,
  selectedRivalry,
}) {
  // console.log(selectedRivalry, "selectedRivalry");
  const animatedComponents = makeAnimated();
  const generalInfoInitialValue = {
    socialInfo: [
      {
        platform: "LinkedIn",
        link: "",
      },
      {
        platform: "Facebook",
        link: "",
      },
      {
        platform: "Instagram",
        link: "",
      },
      {
        platform: "Twitter",
        link: "",
      },
      {
        platform: "Telegram",
        link: "",
      },
    ],
    contactInfo: {
      email: "",
      phone: "",
    },
    otherInfo: [{ key: "", value: "" }],
  };
  let [loading, setLoading] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    rivelaryName: "",
    area: "",
    priceBefore: "",
    priceAfter: "",
    priceLimitDate: "",
    rivelaryType: "",
    startDate: "",
    coordinatorName: "",
    coordinatorPhone: "",
    coordinatorEmail: "",
    numberOfMatches: "",
    venues: [],
    days: [],
    ...generalInfoInitialValue,
    // email: "",
  });
  const [addDivison, setAddDivision] = useState(false);
  const [selectedVenues, setSelectedVenues] = useState();
  const [selectedDays, setSelectedDays] = useState();
  const [selectedSocialPlatform, setSelectedSocialPlatform] = useState("");

  const days = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];
  const socialPlatform = [
    { label: "Facebook", value: "Facebook" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: "Instagram", value: "Instagram" },
    { label: "Twitter", value: "Twitter" },
    { label: "Telegram", value: "Telegram" },
  ];
  const [cookies, setCookie] = useCookies(["token"]);
  let [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function closeModal() {
    formik.resetForm();
    // setInitialValues({
    //   rivelaryName: "",
    //   area: "",
    //   priceBefore: "",
    //   priceAfter: "",
    //   priceLimitDate: "",
    //   rivelaryType: "",
    //   startDate: "",
    //   coordinatorName: "",
    //   coordinatorPhone: "",
    //   coordinatorEmail: "",
    //   numberOfMatches: "",
    //   venues: [],
    //   days: [],
    //   ...generalInfoInitialValue,
    // });
    setDivisions([]);
    setShow(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  let mydivisions = [];
  const getDivisions = async () => {
    setLoading(true);
    mydivisions = await api.get("/rivelary/api/v1/getRivelaryDivisons", {
      headers: { token: cookies.token },
      params: { _id: selectedRivalry._id },
    });
    // console.log( mydivisions.data);
    setDivisions(mydivisions.data);
    setLoading(false);
  };
  useEffect(() => {
    if (selectedRivalry) {
      let dayss = selectedRivalry.days.map((day) => {
        return { value: day, label: day };
      });
      let venuess = selectedRivalry.venues.map((venue) => {
        return { value: venue._id, label: venue.venueName };
      });
      setSelectedDays(dayss);
      setSelectedVenues(venuess);
      setInitialValues({
        ...initialValues,
        ...selectedRivalry,
        area: selectedRivalry.area?._id,
        startDate: selectedRivalry.startDate
          ? new Date(selectedRivalry.startDate).toISOString().slice(0, 10)
          : "",
        priceLimitDate: selectedRivalry.priceLimitDate
          ? new Date(selectedRivalry.priceLimitDate).toISOString().slice(0, 10)
          : "",
      });
      setDivType("edit");
      getDivisions();
    } else {
      setInitialValues({
        rivelaryName: "",
        area: "",
        priceBefore: "",
        priceAfter: "",
        priceLimitDate: "",
        rivelaryType: "",
        startDate: "",
        coordinatorName: "",
        coordinatorPhone: "",
        coordinatorEmail: "",
        numberOfMatches: 0,

        ...generalInfoInitialValue,
      });
      setSelectedDays([]);
      setSelectedVenues([]);
      setDivType("add");
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedRivalry]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      rivelaryName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      area: Yup.string().required("Required"),
      startDate: Yup.date().required("Required"),
      priceBefore: Yup.number().required("Required"),
      priceAfter: Yup.number().required("Required"),
      priceLimitDate: Yup.date().required("Required"),
      rivelaryType: Yup.string().required("Required"),
      coordinatorName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      coordinatorPhone: Yup.string()
        .length(10, "please enter a valid phone number")
        .required("Required"),
      coordinatorEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      setIsLoading(true);
      let daysArray = selectedDays.map((day) => day.label);
      let venuesArray = selectedVenues.map((venue) => venue.value);
      const formData = new FormData();

      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        if (key === "socialInfo") {
          value = JSON.stringify(value);
        }
        if (key === "contactInfo") {
          value = JSON.stringify(value);
        }
        if (key === "otherInfo") {
          value = JSON.stringify(value);
        }
        if (
          key !== "venues" &&
          key !== "divisions" &&
          key !== "days" &&
          key !== "Seasons"
        ) {
          formData.append(key, value);
        }
      });
      daysArray.forEach((day, i) => {
        formData.append(`days[${i}]`, day);
      });
      venuesArray.forEach((venue, i) => {
        formData.append(`venues[${i}]`, venue);
      });
      try {
        if (type === "add") {
          const newRivalry = await api.post(
            "/rivelary/api/v1/addRivelary",
            formData,
            // {...values,days:daysArray,venues:venuesArray},
            {
              headers: { token: cookies.token },
            }
          );
          // let divisionarray = divisions.map((division) => {
          //   return {
          //     ...division,
          //     competition: newRivalry.data.newRivelary._id,
          //   };
          // });
          // // console.log(divisionarray);
          // if (divisionarray.length > 0) {
          //   await api.post("/rivelary/api/v1/addDivisons", divisionarray, {
          //     headers: { token: cookies.token },
          //   });
          // }
          setIsLoading(false);
          successNotification("Rivalry Added Successfully");
          getAllRivelries();
          setShow(false);
          formik.resetForm();
        } else {
          let socialInfo = "",
            contactInfo = "",
            otherInfo = "";
          if (values?.socialInfo) {
            socialInfo = JSON.stringify(values.socialInfo);
          }
          if (values.contactInfo) {
            contactInfo = JSON.stringify(values.contactInfo);
          }
          if (values.otherInfo) {
            otherInfo = JSON.stringify(values.otherInfo);
          }
          const newUser = await api.post(
            "/rivelary/api/v1/editRivalry",

            formData,
            // ...values,
            // socialInfo,
            // contactInfo,
            // otherInfo,
            // _id: selectedRivalry._id,
            // days: daysArray,
            // venues: venuesArray,
            {
              headers: { token: cookies.token },
            }
          );
          setIsLoading(false);
          successNotification("Rivalry Edited Successfully");
          getAllRivelries();
          setShow(false);
          formik.resetForm();
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error.response.data.message);
        failNotification(error.response.data.message);
      }
    },
  });
  const [areas, setAreas] = useState([]);
  const [venues, setVenues] = useState([]);
  useEffect(() => {
    const getAreas = async () => {
      const areas = await api.get("/crud/api/v1/getAreasActive");
      setAreas(areas.data);
    };
    getAreas();
    const getVenues = async () => {
      const venues = await api.get("/crud/api/v1/getVenuesActive");
      let refact = venues?.data?.map((venue) => {
        return { value: venue._id, label: venue.venueName };
      });
      setVenues(refact);
    };
    getVenues();
  }, []);
  const handleDivisonRemove = async (divisionName) => {
    if (type === "edit") {
      // already saved on database
      try {
        const newdiv = await api.post(
          "/rivelary/api/v1/removeDivision",
          { _id: divisionName },
          {
            headers: { token: cookies.token },
          }
        );
        infoNotification("division removed");
        getDivisions();
      } catch (error) {
        failNotification("error occured");
        getDivisions();
      }
    } else {
      let divisionFilter = divisions.filter(
        (division) => division.divisionName != divisionName
      );
      setDivisions(divisionFilter);
    }
    // console.log(divisionFilter)
  };
  const [selectedDiv, setSelectedDiv] = useState();
  const [divType, setDivType] = useState("add");
  const handleEditDivision = (division) => {
    // setDivType("edit");
    setSelectedDiv(division);
    setAddDivision(true);
  };

  const handleAddNewDiv = () => {
    // setDivType("add");
    setSelectedDiv();
    setAddDivision(true);
  };

  const otherInfoHandleTextKeyOnChange = (indexTextField, newKey) => {
    formik.setFieldValue(`otherInfo[${indexTextField}].key`, newKey);
  };
  const otherInfoHandleTextValueOnChange = (indexTextField, newValue) => {
    formik.setFieldValue(`otherInfo[${indexTextField}].value`, newValue);
  };
  const otherInfoHandleAddNewTextField = () => {
    const otherInfo = formik.values.otherInfo;
    formik.setFieldValue("otherInfo", [...otherInfo, { key: "", value: "" }]);
  };
  const otherInfoHandleDeleteNewTextField = () => {
    if (formik.values.otherInfo.length === 1) {
      return;
    }
    formik.values.otherInfo.pop();
    formik.setFieldValue("otherInfo", formik.values.otherInfo);
  };
  return (
    <>
      <AddDivision
        show={addDivison}
        setShow={setAddDivision}
        divisions={divisions}
        setDivisions={setDivisions}
        selectedDiv={selectedDiv}
        type={divType}
        selectedRivalry={selectedRivalry}
        getDivisions={getDivisions}
      />
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {type === "add" ? "Add Rivelry" : "Edit Rivelry"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="rivelaryName"
                        >
                          Rivalry Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="rivelaryName"
                          name="rivelaryName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rivelaryName}
                        />
                        {formik.touched.rivelaryName &&
                        formik.errors.rivelaryName ? (
                          <div className="text-red-500">
                            {formik.errors.rivelaryName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="area"
                        >
                          Area
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="area"
                          name="area"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.area}
                        >
                          <option value={""} hidden>
                            Please Select Area
                          </option>
                          {areas?.map((area) => {
                            return (
                              <option value={area._id}>{area.areaName}</option>
                            );
                          })}
                        </select>
                        {formik.touched.area && formik.errors.area ? (
                          <div className="text-red-500">
                            {formik.errors.area}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="priceBefore"
                        >
                          Pricing Before
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="priceBefore"
                          name="priceBefore"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.priceBefore}
                        />
                        {formik.touched.priceBefore &&
                        formik.errors.priceBefore ? (
                          <div className="text-red-500">
                            {formik.errors.priceBefore}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="priceLimitDate"
                        >
                          Price Limit Date
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="priceLimitDate"
                          name="priceLimitDate"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.priceLimitDate}
                        />
                        {formik.touched.priceLimitDate &&
                        formik.errors.priceLimitDate ? (
                          <div className="text-red-500">
                            {formik.errors.priceLimitDate}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="priceAfter"
                        >
                          Pricing After
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="priceAfter"
                          name="priceAfter"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.priceAfter}
                        />
                        {formik.touched.priceAfter &&
                        formik.errors.priceAfter ? (
                          <div className="text-red-500">
                            {formik.errors.priceAfter}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="rivelaryType"
                        >
                          Type
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="rivelaryType"
                          name="rivelaryType"
                          onChange={(e) => {
                            //   handlerivelaryTypeChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.rivelaryType}
                        >
                          <option value={""} hidden>
                            Please Select rivelary Type
                          </option>
                          <option value="Competition">Competition</option>
                          <option value="Cup">Cup</option>
                          <option value="Tournament">Tournament</option>
                        </select>
                        {formik.touched.rivelaryType &&
                        formik.errors.rivelaryType ? (
                          <div className="text-red-500">
                            {formik.errors.rivelaryType}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="startDate"
                        >
                          Start Date
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="startDate"
                          name="startDate"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.startDate}
                        />
                        {formik.touched.startDate && formik.errors.startDate ? (
                          <div className="text-red-500">
                            {formik.errors.startDate}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="numberOfMatches"
                        >
                          Number of rounds
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="numberOfMatches"
                          name="numberOfMatches"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.numberOfMatches}
                        />
                        {formik.touched.numberOfMatches &&
                        formik.errors.numberOfMatches ? (
                          <div className="text-red-500">
                            {formik.errors.numberOfMatches}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="numberOfMatches"
                        >
                          Venues
                        </label>

                        <Select
                          styles={{
                            control: (css) => ({
                              ...css,
                              maxWidth:"100%",
                              height: "max-content",
                              minWidth: "100%",
                            }),
                          }}
                          autosize={true}
                          options={venues}
                          isMulti
                          className="w-full"
                          components={animatedComponents}
                          onChange={setSelectedVenues}
                          defaultValue={selectedVenues}
                        />
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="numberOfMatches"
                        >
                          Days
                        </label>
                        <Select
                          styles={{
                            control: (css) => ({
                              ...css,
                              maxWidth: "100%",
                              height: "max-content",
                              minWidth: "100%",
                            }),
                          }}
                          autosize={true}
                          options={days}
                          isMulti
                          className="w-full"
                          components={animatedComponents}
                          onChange={setSelectedDays}
                          defaultValue={selectedDays}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Image
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="image"
                          name="image"
                          type="file"
                          onChange={(e) => {
                            formik.setFieldValue("image", e.target.files[0]);
                          }}
                          onBlur={formik.handleBlur}
                          value={undefined}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className="text-red-500">
                            {formik.errors.image}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="coordinatorName"
                        >
                          Coordinator Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="coordinatorName"
                          name="coordinatorName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.coordinatorName}
                        />
                        {formik.touched.coordinatorName &&
                        formik.errors.coordinatorName ? (
                          <div className="text-red-500">
                            {formik.errors.coordinatorName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="coordinatorPhone"
                        >
                          Coordinator Phone
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="coordinatorPhone"
                          name="coordinatorPhone"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.coordinatorPhone}
                        />
                        {formik.touched.coordinatorPhone &&
                        formik.errors.coordinatorPhone ? (
                          <div className="text-red-500">
                            {formik.errors.coordinatorPhone}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="coordinatorEmail"
                        >
                          Coordinator Email
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="coordinatorEmail"
                          name="coordinatorEmail"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.coordinatorEmail}
                        />
                        {formik.touched.coordinatorEmail &&
                        formik.errors.coordinatorEmail ? (
                          <div className="text-red-500">
                            {formik.errors.coordinatorEmail}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col col-span-2 space-y-3">
                        <h6 className="py-2 text-lg text-left text-gray-500 border-b border-dashed border-gray-300">
                          General Info
                        </h6>
                      </div>
                      {/* social platform */}

                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="platformSocialInfo"
                        >
                          Social Platform
                        </label>
                        <Select
                          styles={{
                            control: (css) => ({
                              ...css,
                              width: "max-content",
                              minWidth: "100%",
                            }),
                          }}
                          autosize={true}
                          options={socialPlatform}
                          className="w-full"
                          components={animatedComponents}
                          onChange={(e) => {
                            setSelectedSocialPlatform(e.value);
                            const platformIndex =
                              formik.values?.socialInfo?.findIndex(
                                (elem) => elem.platform === e.value
                              );
                            formik.setFieldValue(
                              `socialInfo[${platformIndex}].link`,
                              formik.values?.socialInfo[platformIndex]?.link
                            );
                          }}
                        />
                      </div>
                      {/* social link */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="linkSocialInfo"
                        >
                          Social Link
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="linkSocialInfo"
                          type="url"
                          onChange={(e) => {
                            const platformIndex =
                              formik.values?.socialInfo?.findIndex(
                                (elem) =>
                                  elem.platform === selectedSocialPlatform
                              );
                            if (platformIndex === -1) {
                              formik.values.socialInfo.push({
                                platform: selectedSocialPlatform,
                                link: e.target.value,
                              });
                              formik.setFieldValue(
                                `socialInfo`,
                                formik.values.socialInfo
                              );
                              return;
                            }
                            formik.setFieldValue(
                              `socialInfo[${platformIndex}].link`,
                              e.target.value
                            );
                          }}
                          value={(() => {
                            const platformIndex =
                              formik.values?.socialInfo?.findIndex(
                                (elem) =>
                                  elem.platform === selectedSocialPlatform
                              );
                            if (platformIndex === -1) {
                              return "";
                            }
                            return (
                              formik.values.socialInfo[platformIndex]?.link ||
                              ""
                            );
                          })()}
                        />
                      </div>
                      {/* email Address */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="emailContactInfo"
                        >
                          Email Address
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="emailContactInfo"
                          name="contactInfo.email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contactInfo?.email}
                        />
                      </div>
                      {/* Mobile Phone */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phoneContactInfo"
                        >
                          Mobile Phone
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="phoneContactInfo"
                          name="contactInfo.phone"
                          type="tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contactInfo?.phone}
                        />
                      </div>
                      {/* Other Info */}
                      <div className="w-full flex flex-col col-span-2 space-y-3">
                        <h6 className="py-2 text-lg text-left text-gray-500 border-b border-dashed border-gray-300">
                          Other Info
                        </h6>
                      </div>
                      {formik.values.otherInfo.map(
                        (textField, indexTextField) => (
                          <>
                            <div className="w-full flex flex-col">
                              <label
                                className="font-medium text-base mt-3"
                                htmlFor="otherInfo.key"
                              >
                                Key
                              </label>
                              <input
                                className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                id="phoneContactInfo"
                                name="otherInfo.key"
                                type="text"
                                onChange={(e) =>
                                  otherInfoHandleTextKeyOnChange(
                                    indexTextField,
                                    e.target.value
                                  )
                                }
                                value={textField.key}
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <label
                                className="font-medium text-base mt-3"
                                htmlFor="otherInfo.value"
                              >
                                Value
                              </label>
                              <input
                                className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                id="phoneContactInfo"
                                name="otherInfo.value"
                                type="text"
                                onChange={(e) =>
                                  otherInfoHandleTextValueOnChange(
                                    indexTextField,
                                    e.target.value
                                  )
                                }
                                value={textField.value}
                              />
                            </div>
                          </>
                        )
                      )}
                      <div className="w-full flex flex-col col-span-2 items-end">
                        <div className="w-1/6 flex justify-around items-center">
                          <button
                            type="button"
                            onClick={otherInfoHandleDeleteNewTextField}
                            className="text-gray-400 hover:text-[#00B5FF]"
                          >
                            <BiMinusCircle className="w-[30px] h-[30px]" />
                          </button>
                          <button
                            type="button"
                            onClick={otherInfoHandleAddNewTextField}
                            className="text-gray-400 hover:text-[#00B5FF]"
                          >
                            <IoMdAddCircle className="w-[30px] h-[30px]" />
                          </button>
                        </div>
                      </div>
                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Loader />
                          ) : type === "add" ? (
                            "Add Rivalry"
                          ) : (
                            "Edit Rivalry"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AdminAddRivelary;
