import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import api from "../api";
import { MdRemoveCircle } from "react-icons/md";
import ReactLoading from "react-loading";
import { failNotification } from "./Notifications";
import AdminEditProductQuantity from "./AdminEditProductQuantity";

function AdminAddNewItemModal({
  show,
  setShow,
  itemType = "item",
  edit,
  selectedProduct,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [newItem, setNewItem] = useState("");
  const [newSize, setNewSize] = useState({
    size: "",
    quantity: 0,
  });
  const [packageItems, setPackageItems] = useState([]);
  const [size, setSize] = useState([]);
  const cancelButtonRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    // team_name : "",
    price: "",
    availability: true,
    product_Gender: "Men",
    product_breif: "",
    product_description: "",
    image: null,
    // product_model: "",
    // product_type: "",
    // quantity:0,
    product_category: "",
  });

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    // team_name: Yup.string().required("required"),
    price: Yup.string().required("required"),
    // quantity:Yup.number().required("required").min(0,'quantity cannot be a negative value'),
    product_Gender: Yup.string().required("required"),
    // product_breif: Yup.string().required("required"),
    // product_description: Yup.string().required("required"),
    image: Yup.mixed().required("Please select a file"),
    // product_model: Yup.string().required("required"),
    // product_type: Yup.string().required("required"),
    product_category: Yup.string().required("required"),
    availability: Yup.boolean().required("required"),
  });

  const handlesubmit = async (values) => {
    const formData = new FormData();
    // formData.append("image", image);
    formData.append("size", JSON.stringify(size));

    packageItems?.forEach((item, i) => {
      formData.append(`content[${i}]`, item);
    });
    formData.append("itemType", itemType);
    Object.entries(values).forEach((item) => {
      let [key, value] = item;
      if (key !== "size" && key !== "content" && key !== "itemType") {
        formData.append(key, value);
      }
    });
    try {
      setIsLoading(true);
      if (edit) {
        await api.post(
          `ecommerce/api/v1/updateProduct/${selectedProduct._id}`,
          formData
        );
        setShow(false);
      } else {
        await api.post("ecommerce/api/v1/addProduct", formData);
        setShow(false);
      }
    } catch (error) {
      failNotification(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const [shopCategory, setShopCategory] = useState("");

  useEffect(() => {
    api.get(`/crud/api/v1/shopCategory`).then((res) => {
      setShopCategory(res.data);
    });
    if (selectedProduct && edit) {
      // console.log(selectedProduct)
      setInitialValues({
        ...initialValues,
        ...selectedProduct,
        product_category: selectedProduct?.product_category?._id,
      });
      const sizes = JSON.parse(selectedProduct?.size);
      setSize(sizes);
      setImage(selectedProduct?.image);
      setPackageItems(selectedProduct?.content);
    } else {
      setInitialValues({
        name: "",
        // team_name : "",
        price: "",
        availability: true,
        product_Gender: "Men",
        product_breif: "",
        product_description: "",
        image: null,
        // product_model: "",
        // product_type: "",
        // quantity:0,
        product_category: "",
      });
      setSize([]);
      setPackageItems([]);
      setImage(null);
    }
  }, [selectedProduct, edit]);

  const handlePackageItem = () => {
    setPackageItems([...packageItems, newItem]);
    setNewItem("");
  };

  const handleRemoveItem = (index) => {
    const tempArr = [...packageItems];
    tempArr.splice(index, 1);
    setPackageItems([...tempArr]);
  };

  const handleSize = () => {
    if (newSize.size.length === 0 || newSize.quantity === 0) {
      return failNotification(
        "please insert a size and a quantity bigger than zero"
      );
    }

    setSize([...size, newSize]);
    setNewSize({
      size: "",
      quantity: 0,
    });
  };

  const handleRemoveSize = (index) => {
    const tempArr = [...size];
    tempArr.splice(index, 1);
    setSize([...tempArr]);
  };

  const handleEditSize = (newSize) => {
    const newArr = size.map((size) => {
      if (newSize.size === size.size)
        return { ...size, quantity: newSize.quantity };
      return size;
    });

    setSize(newArr);
  };
  const product_gender = [
    { value: "Men", label: "Men" },
    { value: "Women", label: "Women" },
    { value: "Unisex", label: "Unisex" },
  ];
  const availability = [
    { value: true, label: "in-stock" },
    { value: false, label: "out-stock" },
  ];
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-75"
              enterTo="opacity-100 translate-y-0 "
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 "
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-75"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* body start */}
                  <Formik
                    validationSchema={SignupSchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      handlesubmit(values);
                    }}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form className="w-full px-32">
                        <h3 className="text-[32px] font-semibold mb-4 text-center">
                          {edit ? "Edit Item" : "Add New Item"}
                        </h3>

                        <div className="flex w-full justify-center my-3">
                          {image ? (
                            <img
                              loading="lazy"
                              className="w-20 h-20 rounded-full"
                              src={`${process.env.REACT_APP_SERVER_URL}${image}`}
                              alt="Rounded avatar"
                            ></img>
                          ) : (
                            <div className="relative w-32 h-32 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                              <svg
                                className="absolute w-36 h-36 text-gray-400 -left-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          )}
                        </div>

                        <div className={"grid grid-cols-2 gap-x-5"}>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>Item Name</label>
                            <Field
                              type="text"
                              name="name"
                              className={`input ${
                                errors.name && touched.name
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Item Name"
                            />
                          </div>
                          {/* <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>
                              Product Model
                            </label>
                            <Field
                              type="string"
                              name="product_model"
                              className={`input ${
                                errors.product_model && touched.product_model
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Product Model"
                            />
                          </div> */}
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Item Price
                            </label>
                            <Field
                              type="text"
                              name="price"
                              className={`input ${
                                errors.price && touched.price
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Item Price"
                            />
                          </div>
                          {/* <div className="flex flex-col  gap-3 w-full mt-5">
                                                        <label className={'font-semibold '}>Quantity</label>
                                                        <Field
                                                            type="number"
                                                            name="quantity"
                                                            className={`input ${errors.quantity && touched.quantity ? 'border-red-600' : ""}`}
                                                            placeholder="quantity"
                                                        >
                                                        </Field>
                                                    </div> */}
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>
                              Availability
                            </label>
                            <Field
                              as={"select"}
                              type="text"
                              name="availability"
                              className={`input ${
                                errors.availability && touched.availability
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Availability"
                            >
                              {availability.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Product Gender
                            </label>
                            <Field
                              as={"select"}
                              type="text"
                              name="product_Gender"
                              className={`input ${
                                errors.product_Gender && touched.product_Gender
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Product Gender"
                            >
                              {product_gender.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Product Category
                            </label>
                            <Field
                              as={"select"}
                              type="text"
                              name="product_category"
                              className={`input ${
                                errors.product_category &&
                                touched.product_category
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Product Gender"
                            >
                              <option value={""}>select category</option>
                              {shopCategory.map((option) => (
                                <option key={option._id} value={option._id}>
                                  {option.categoryName}
                                </option>
                              ))}
                            </Field>
                          </div>
                          {/* <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>
                              Product Type
                            </label>
                            <Field
                              type="string"
                              name="product_type"
                              className={`input ${
                                errors.product_type && touched.product_type
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Product type"
                            />
                          </div> */}
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>
                              Product Image
                            </label>
                            <Field
                              type="file"
                              name="image"
                              style={{ height: "50px" }}
                              value={undefined}
                              onChange={(event) => {
                                setFieldValue("image", event.target.files[0]);
                                setImage(
                                  URL.createObjectURL(event.target.files[0])
                                );
                              }}
                              className={`input py-1 ${
                                errors.image && touched.image
                                  ? "border-red-600"
                                  : ""
                              }`}
                            />
                          </div>
                          <div className="flex flex-col col-span-2 gap-3 w-full mt-5">
                            <label className={"font-semibold "}>Brief</label>
                            <Field
                              as={"textarea"}
                              type="string"
                              name="product_breif"
                              className={`input  ${
                                errors.product_breif && touched.product_breif
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Brief"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col  gap-3 w-full mt-5">
                          <label className={"font-semibold "}>
                            Description
                          </label>
                          <Field
                            as={"textarea"}
                            type="string"
                            name="product_description"
                            className={`input ${
                              errors.product_description &&
                              touched.product_description
                                ? "border-red-600"
                                : ""
                            }`}
                            placeholder="Description"
                          />
                        </div>
                        <div className="flex flex-col  gap-y-3 w-full mt-5  ">
                          {/* <label className={"font-semibold "}>Size</label> */}
                          <div className="border-t-2 border-dashed pb-4"></div>
                          <div className="w-full flex justify-between items-end ">
                            <div className="flex flex-col">
                              <label
                                className={"font-semibold text-center mb-1"}
                              >
                                size
                              </label>
                              <input
                                type="text"
                                className="input w-20 "
                                value={newSize.size}
                                onChange={(e) =>
                                  setNewSize({
                                    ...newSize,
                                    size: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="flex flex-col">
                              <label
                                className={"font-semibold mb-1 text-center"}
                              >
                                quantity
                              </label>
                              <input
                                type="number"
                                className="input w-20"
                                value={newSize.quantity}
                                onChange={(e) =>
                                  setNewSize({
                                    ...newSize,
                                    quantity: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <button
                              onClick={handleSize}
                              className="py-2 px-8 text-center text-lg  bg-sky-500 text-white rounded-lg  cursor-pointer"
                            >
                              Add size
                            </button>
                          </div>
                          <div className="my-4  w-full">
                            <table className="table w-full ">
                              <thead className="flex gap-x-6 border w-full ">
                                <th>#</th>
                                <th>Size</th>
                                <th>Quantity</th>
                                <th> Quantify </th>
                                <th>remove</th>
                              </thead>
                              <tbody>
                                {size?.map((size, i) => {
                                  return (
                                    <AdminEditProductQuantity
                                      size={size}
                                      index={i}
                                      onRemove={handleRemoveSize}
                                      onEdit={handleEditSize}
                                    />
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {itemType !== "item" ? (
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>
                              package Content
                            </label>
                            <div className="flex justify-around">
                              <div className="flex gap-2 items-center">
                                <label className={"font-semibold "}>
                                  product name
                                </label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newItem}
                                  onChange={(e) => setNewItem(e.target.value)}
                                />
                              </div>
                              <div
                                className="normalButton w-fit"
                                onClick={handlePackageItem}
                              >
                                Add Product
                              </div>
                            </div>
                            <div>
                              <table className="table">
                                <thead>
                                  <th>#</th>
                                  <th>Product Name</th>
                                  <th>remove</th>
                                </thead>
                                <tbody>
                                  {packageItems?.map((item, i) => {
                                    return (
                                      <tr onClick={() => handleRemoveItem(i)}>
                                        <td>{i + 1}</td>
                                        <td>{item}</td>
                                        <td>
                                          <MdRemoveCircle className="cursor-pointer" />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : null}

                        <div className="flex justify-center">
                          <button
                            type="submit"
                            className="text-center py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-full"
                            disabled={isLoading}
                          >
                            {isLoading && (
                              <ReactLoading
                                type="spin"
                                color="#FFFFFF"
                                width={24}
                                height={24}
                              />
                            )}
                            Save Item
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminAddNewItemModal;
