import { useFormik } from "formik";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as Yup from "yup";
import api from "../api";
import { PhoneRegExp } from "./TeamRegForm3";

function TeamRegForm1({ currentStep = 0, setCurrentStep, setTeamLeader }) {
  const [cookies, setCookie] = useCookies(["token"]);
  const [readTerms, setReadTerms] = useState(false);
  const notifyinfo = (msg) =>
    toast.info(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: "",
      email: "",
      password: "",
      confirmPassword: "",
      image: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Must be 6 characters or more")
        .required("Required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
      image: Yup.mixed().required("File is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      const formData = new FormData();

      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        formData.append(key, value);
      });

      // console.log(readTerms)
      if (readTerms) {
        try {
          const email = await api.get("/auth/api/v1/checkEmail", {
            params: { email: values.email },
          });
          setTeamLeader(values);
          setCurrentStep(currentStep + 1);
        } catch (error) {
          // console.log(error.response.data)
          notifyerror(error.response.data.msg);
        }
      } else {
        notifyinfo("Please check the terms of Insurance and  Regulations");
      }
      // console.log(values);

      // const newUser = await api.post('/auth/api/v1/SignUp', values)
      // if (newUser.data.errors) {
      //     // notifyerror(newUser.data.errors[0].msg)
      // } else {
      //     // notifysuccess('Register Complete Successfully')
      //     // setCookie('token', newUser.data.token);
      //     // console.log(cookies.token)
      // }
    },
  });
  const type = [{ name: "senior" }, { name: "junior" }];
  return (
    <div className={currentStep !== 1 ? "hidden" : ""}>
      <h1 className="font-semibold text-4xl my-5">Delegate Details</h1>
      <form
        className="grid grid-cols-2 gap-3 my-10"
        onSubmit={formik.handleSubmit}
      >
        {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="firstName">
            First Name
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="text-red-500">{formik.errors.firstName}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="lastName">
            Last Name
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="text-red-500">{formik.errors.lastName}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="email">
            E-mail
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="address">
            Address
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="address"
            name="address"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="text-red-500">{formik.errors.address}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="dateOfBirth">
            Date Of Birth
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="dateOfBirth"
            name="dateOfBirth"
            type="date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
          />
          {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
            <div className="text-red-500">{formik.errors.dateOfBirth}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="phoneNumber">
            Phone Number
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="text-red-500">{formik.errors.phoneNumber}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="password">
            Password
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label
            className="font-medium text-base mt-3"
            htmlFor="confirmPassword"
          >
            Confirm Password
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-red-500">{formik.errors.confirmPassword}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label
            className="font-medium text-base mt-3"
            htmlFor="confirmPassword"
          >
            Image
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="image"
            name="image"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              formik.setFieldValue("image", e.target.files[0]);
            }}
            onBlur={formik.handleBlur}
            value={undefined}
          />
          <div>
            Please upload a clear image of the player’s face to avoid your
            account being declined
          </div>
          {formik.touched.image && formik.errors.image ? (
            <div className="text-red-500">{formik.errors.image}</div>
          ) : null}
        </div>
        {/* <div className="w-full flex flex-col">
          <label
            className="font-medium text-base mt-3"
            htmlFor="confirmPassword"
          >
            Type
          </label>

          <select>
            {type.map((item) => {
              return <option value={item.name}>{item.name}</option>;
            })}
          </select>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-red-500">{formik.errors.confirmPassword}</div>
          ) : null}
        </div> */}

        <div className="w-full flex flex-col col-span-2">
          <div className="flex flex-row my-5 items-center">
            <input
              checked={readTerms}
              onChange={() => setReadTerms(!readTerms)}
              type="checkbox"
              className="w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
            />
            <label className="font-medium text-base ml-3 ">
              I confirming that the team has read & agreed to the insurance
              Condititions of Play & the Competition Regulations
            </label>
          </div>
        </div>
        <div className="w-full flex flex-col col-span-2 items-center">
          <button type="submit" className="normalButton w-fit">
            Next
          </button>
        </div>
        {/* </form> */}
        {/* <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>First Name</p>
                    <input type="text" placeholder='First Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Last Name</p>
                    <input type="text" placeholder='Last Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>E-mail</p>
                    <input type="email" placeholder='E-mail' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Password</p>
                    <input type="password" placeholder='Password' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div> */}
      </form>
    </div>
  );
}

export default TeamRegForm1;
