import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DownloadApp from "../components/DownloadApp";
import api from "../api";

function Tournments() {
  const [comptetions, setComptetions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getComptetions = async () => {
    setLoading(true);
    try {
      const compt = await api.get(
        "/rivelary/api/v1/getRivelaries",
        { params: { rivelaryType: "Tournament" } }
        // { headers: { token: cookies.token } }
      );
      setComptetions(compt.data);
      // console.log(user)
    } catch {
      console.log("error");
      // navigate('/')
    }
  };
  useEffect(() => {
    getComptetions();
    window.scrollTo(0, 0);
  }, []);
  //   const cities = [
  //     "Sydney City",
  //     "Inner West",
  //     "Canterbury",
  //     "Homebush",
  //     "Hornsby",
  //     "Kuringai",
  //     "Dural",
  //     "Ryde Eastwood",
  //   ];
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 container  my-20">
        {comptetions.map((city) => (
          <Link
            className="no-underline text-[#1E354E]"
            to={"/competitionInner"}
            state={city}
          >
            <div className="w-full bg-[#FBFCFD] rounded-lg h-[400px]  cursor-pointer flex flex-col justify-center items-center">
              <img
                loading="lazy"
                src={city.image ? city.image : ""}
                alt=""
                className="rounded-full w-36 h-36"
              />
              <h2 className="md:h1Semibold text-center">{city.rivelaryName}</h2>
            </div>
          </Link>
        ))}
        {comptetions.length === 0 && (
          <p className="text-lg font-semibold text-center">No Tournament Found</p>
        )}
      </div>
      <DownloadApp />
    </div>
  );
}

export default Tournments;
