import React from "react";

export default function PlayersSus() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Players Suspensions</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          Below is a list of players who are currently suspended from the game
          of Oztag.
        </p>
        <table className="table">
          <tr>
            <td className="border-2 border-black">Name</td>
            <td className="border-2 border-black">Length</td>
            <td className="border-2 border-black">Date</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Jahi Pritchard</td>
            <td className="border-2 border-black">5 Year Ban</td>
            <td className="border-2 border-black">05/11/17 - 15/11/22</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Netane Olive</td>
            <td className="border-2 border-black">1 Year Ban</td>
            <td className="border-2 border-black">15/11/17 - 15/11/18</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Norone Olive</td>
            <td className="border-2 border-black">5 Year Ban</td>
            <td className="border-2 border-black">15/11/17 - 15/11/22</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Brodie Pitts</td>
            <td className="border-2 border-black">
              <p>1 Year Ban (Domestic)</p>
              <p>2 Year Ban (Representative)</p>
            </td>
            <td className="border-2 border-black">04/11/17 - 04/11/19</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Matt Viane</td>
            <td className="border-2 border-black">2 Year Ban</td>
            <td className="border-2 border-black">18/08/17 - 18/08/19</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Omar Bani Mohammed</td>
            <td className="border-2 border-black">2 Year Ban</td>
            <td className="border-2 border-black">21/08/17 - 21/08 /19</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Keisha Waite</td>
            <td className="border-2 border-black">2018 Ban Representative</td>
            <td className="border-2 border-black"></td>
          </tr>
        </table>
        <h3 className="text-center">Life Bans</h3>
        <p className="text-center">
          Below is a list of players who are banned from the game of Oztag for
          life
        </p>
        <p className="text-center">⦁ Jane Asiata</p>
        <p className="text-center">⦁ Terry Riley</p>
        <h3 className="text-center">Coach / Manager Suspensions</h3>
        <p className="text-center">
          Below is a list of coaches and managers who are currently banned from
          the game of Oztag at a Representative Level
        </p>
        <table className="table">
          <tr>
            <td className="border-2 border-black">Name</td>
            <td className="border-2 border-black">Length</td>
            <td className="border-2 border-black">Date</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Marty Hammoud</td>
            <td className="border-2 border-black">
              <p>2 Complete Ban (Representative) </p>
              <p>3 Year Spectatory Only</p>
            </td>
            <td className="border-2 border-black">
              <p>09/05/19 - 09/05/21 </p>
              <p>09/05/21 - 09/05/24 </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
