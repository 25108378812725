import React, { useEffect } from "react";

function Insurance() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className=" scale-75 flex flex-col gap-5 justify-center -my-40">
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">Insurance</h1>
      </div>
      <p className="text-center font-semibold text-2xl text-[#828D9E]">
        Your Registration fee covers your Injury Insurance premium. Australian
        Oztag Insurance is limited (see summary below) and Australian Oztag
        recommends that all players have their own private health insurance
      </p>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full gap-3 flex flex-col">
          <h3 className="text-2xl font-semibold">To be insured</h3>
          <p className="text-base font-medium text-[#828D9E]">
            Upon registering into a team for a competition, you must read the
            ‘conditions of play’ on the back of the ‘Team Registration Form’
            before signing, to ensure you fully understand them.
          </p>
          <p className="text-base font-medium text-[#828D9E]">
            At each game, you must sign on a ‘Team Sign on Sheet’ & notify your
            ‘Individual Registration Number’ to be insured
          </p>
          <p className="text-base font-medium text-[#828D9E]">
            The injured player must report any injury sustained whilst playing
            the game of Oztag to the Competition Convener game officials
            immediately.
          </p>
          <ul className="list-disc">
            <li className="text-base font-medium text-[#828D9E]">
              {" "}
              If the injured player cannot, a representative from the team must
              do so.
            </li>
            <li className="text-base font-medium text-[#828D9E]">
              If this is not done on the night/day of the injury, you will not
              be covered by Insurance.
            </li>
            <li className="text-base font-medium text-[#828D9E]">
              The Competition Convener will record the injury on a ‘Nightly
              Insurance Report’ which will be kept by the Convener and a copy
              sent to the Australian Oztag Head office.
            </li>
          </ul>
        </div>
        <div className="w-full">
          <img src="/images/policies2.png" alt="rules image" />
        </div>
      </div>

      <h2 className="text-3xl font-semibold">Summary of insurance cover</h2>
      <div className="flex flex-col">
        <p className="text-2xl font-medium text-[#828D9E]">
          An excess of $200 applies to each claim Loss of income insurance is
          not included Private Health.
        </p>
        <p className="text-2xl font-medium text-[#828D9E]">
          Insurance MUST be claimed, prior to submitting a claim to Sportscover.
        </p>
      </div>
      <h2 className="text-2xl font-semibold">
        Medicare Costs for the Gap cannot be claimed through Sportscover.
      </h2>

      <ul className="list-disc flex flex-col gap-3">
        <li className="text-2xl font-medium text-[#828D9E]">
          Policy Limit for Medical is $2500 per injury per annum.
        </li>
        <li className="text-2xl font-medium text-[#828D9E]">
          {" "}
          Medical benefits Covers 50% of the invoice.
        </li>
        <li className="text-2xl font-medium text-[#828D9E]">
          Physiotherapy benefit is 75% of the invoice.
        </li>
      </ul>
    </div>
  );
}

export default Insurance;
