import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as Yup from "yup";
import api from "../api";

function TeamRegForm2({ currentStep = 0, setCurrentStep, setTeamData }) {
  const [competitions, setCompetitions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [nights,setNights] = useState([]);

  useEffect(() => {
    const getAreas = async () => {
      const areas = await api.get("/crud/api/v1/getAreas");
      setAreas(areas.data);
    };
    getAreas();
  }, []);

 

  const [cookies, setCookie] = useCookies(["token"]);
  const [readTerms, setReadTerms] = useState(false);
  const [teamType, setTeamType] = useState('Junior');
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });
  const formik = useFormik({
    initialValues: {
      teamName: "",
      area: "",
      rivelary: "",
      division: "",
      // subDivPreference: "",
      night: "",
      type:"Junior"
    },
    validationSchema: Yup.object({
      teamName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      area: Yup.string().required("Required"),
      rivelary: Yup.string().required("Required"),
      division: Yup.string().required("Required"),
      // subDivPreference: Yup.string().required("Required"),
      night: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values)
      try {
        const email = await api.get("/auth/api/v1/checkTeamName", {
          params: { teamName: values.teamName },
        });
        setTeamData({...values});
        setCurrentStep(currentStep + 1);
      } catch (error) {
        console.log(error.response.data.msg);

        notifyerror(error.response.data.msg);
      }
      // setCurrentStep(currentStep + 1)
    },
  });

  const handleAreaChange = async (areaId) => {
    const response = await api.get("/rivelary/api/v1/getAreaRivelaries", {
      params: { _id: areaId },
    });
    // console.log(response)
    setCompetitions(response.data);
  };
  const handleCompetitionChange = async (rivelaryId,type) => {
    const response = await api.get("/rivelary/api/v1/getRivelaryDivisons", {
      params: { _id: rivelaryId ,type:type},
    });
    const selectedComptetion = competitions?.filter((comp)=>comp?._id===rivelaryId)
    setNights(selectedComptetion[0]?.days)
    // console.log(response)
    setDivisions(response.data);
  };

  useEffect(() => {
    if(teamType && formik.values.rivelary){
      handleCompetitionChange(formik.values.rivelary,teamType)
    }
  }, [teamType]);

  const [subDivs, setSubDivs] = useState([]);

  const handleDivisionChange = async (divisionId) => {
    // let selectedDivision = divisions.filter((division)=>division._id==divisionId)
    // let myArray = new Array(selectedDivision[0].subDivisionNo).fill(0)
    //  myArray.forEach((item,index)=>myArray[index]=index+1)
    // setSubDivs(myArray)

    const response = await api.get("/rivelary/api/v1/getDivisionSubsActive", {
      params: { _id: divisionId },
    });
    setSubDivs(response.data);
  };
  return (
    <div className={currentStep !== 2 ? "hidden" : ""}>
      <h1 className="font-semibold text-4xl my-5">Team Details</h1>
      <form
        className="grid grid-cols-2 gap-3 my-10"
        onSubmit={formik.handleSubmit}
      >
        {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="teamName">
            Team Name
          </label>
          <input
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="teamName"
            name="teamName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.teamName}
          />
          {formik.touched.teamName && formik.errors.teamName ? (
            <div className="text-red-500">{formik.errors.teamName}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="area">
            Area
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="area"
            name="area"
            onChange={(e) => {
              handleAreaChange(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.area}
          >
            <option value={""} hidden>
              Please Select Area
            </option>
            {areas?.map((area) => {
              return <option value={area._id}>{area.areaName}</option>;
            })}
          </select>
          {formik.touched.area && formik.errors.area ? (
            <div className="text-red-500">{formik.errors.area}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="type">
            Type
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="type"
            name="type"
            onChange={(e)=>{
              formik.handleChange(e);
              setTeamType(e.target.value)
              handleCompetitionChange(formik.values.rivelary,e.target.value)
            }}
            onBlur={formik.handleBlur}
            value={formik.values.type}
          >
            <option value={"Junior"}>Junior</option>
            <option value={"Senior"}>Senior</option>
          </select>
          {formik.touched.type && formik.errors.type ? (
            <div className="text-red-500">{formik.errors.type}</div>
          ) : null}
        </div>
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="rivelary">
            Competition
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="rivelary"
            name="rivelary"
            onChange={(e) => {
              handleCompetitionChange(e.target.value,formik.values.type);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.rivelary}
          >
            <option value={""} hidden>
              Please Select Competition
            </option>

            {competitions?.map((competition) => {
              return (
                <option value={competition._id}>
                  {competition.rivelaryName}
                </option>
              );
            })}
          </select>
          {formik.touched.rivelary && formik.errors.rivelary ? (
            <div className="text-red-500">{formik.errors.rivelary}</div>
          ) : null}
        </div>
        
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="division">
            Division
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="division"
            name="division"
            onChange={(e) => {
              handleDivisionChange(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.division}
          >
            <option value={""} hidden>
              Please Select Division
            </option>

            {divisions?.map((division) => {
              return (
                <option value={division._id}>{division.divisionName} / {division.night}</option>
              );
            })}
          </select>
          {formik.touched.division && formik.errors.division ? (
            <div className="text-red-500">{formik.errors.division}</div>
          ) : null}
        </div>
        {/* <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="subDivPreference">
            Sub Division Preference
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="subDivPreference"
            name="subDivPreference"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subDivPreference}
          >
            <option value={""} hidden>
              Please Select sub Division
            </option>

            {subDivs?.map((subDiv) => {
              return (
                <option value={subDiv._id}>
                  {subDiv.subDivName}
                </option>
              );
            })}
          </select>
          {formik.touched.subDivPreference && formik.errors.subDivPreference ? (
            <div className="text-red-500">{formik.errors.subDivPreference}</div>
          ) : null}
        </div> */}
        <div className="w-full flex flex-col">
          <label className="font-medium text-base mt-3" htmlFor="night">
            Night
          </label>
          <select
            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
            id="night"
            name="night"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.night}
          >
            <option value={""} hidden>
              Please Select Night
            </option>
            {nights?.map((night)=>{
              return <option value={night}>{night}</option>
            })}
            {/* <option value={"Sunday"}>Sunday</option>
            <option value={"Monday"}>Monday</option>
            <option value={"Tuesday"}>Tuesday</option>
            <option value={"Wednesday"}>Wednesday</option>
            <option value={"Thursday"}>Thursday</option>
            <option value={"Friday"}>Friday</option>
            <option value={"Saturday"}>Saturday</option> */}
          </select>
          {formik.touched.night && formik.errors.night ? (
            <div className="text-red-500">{formik.errors.night}</div>
          ) : null}
        </div>
        

        <div className="w-full flex flex-col col-span-2">
          <div className="flex flex-row my-5 items-center">
            <label className="font-medium text-base mr-5 font-inter">
              Has This Team Registered For OzTag Before?
            </label>

            <input
              type="radio"
              name="regBefore"
              className="w-4 h-4 mx-3 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
            />
            <label className="font-medium text-base mr-5 ">Yes</label>

            <input
              type="radio"
              name="regBefore"
              className="w-4 h-4 mx-3 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
            />
            <label className="font-medium text-base mr-5 ">No</label>
          </div>
        </div>
        <div className="w-full flex gap-5 justify-center col-span-2 items-center">
          <button
            className="normalButton w-fit"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Back
          </button>
          <button type="submit" className="normalButton w-fit">
            Next
          </button>
        </div>
      </form>
      {/* <div className='grid grid-cols-2 gap-3 my-10'>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Team Name</p>
                    <input type="text" placeholder='Team Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Night</p>
                    <select className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'>
                        <option value="" hidden selected>Please Select</option>

                    </select>
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Division</p>
                    <select className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'>
                        <option value="" hidden selected>Please Select</option>

                    </select>
                </div> 
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Area</p>
                    <select className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'>
                        <option value="" hidden selected>Please Select</option>
                    </select>
                </div>
            </div> */}
      {/* <div className='flex flex-row my-5 items-center'>
                <label className='font-medium text-base mr-5 font-inter'>Has This Team Registered For OzTag Before?</label>


                <input type="radio" name='regBefore' className='w-4 h-4 mx-3 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                <label className='font-medium text-base mr-5 '>Yes</label>

                <input type="radio" name='regBefore' className='w-4 h-4 mx-3 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                <label className='font-medium text-base mr-5 '>No</label>

            </div> */}
    </div>
  );
}

export default TeamRegForm2;
