import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";

function AddSeason({
  type = "add",
  show,
  setShow,
  getAllRivelries,
  selectedField,
}) {
  //   console.log(selectedField);
  const [initialValues, setInitialValues] = useState({
    seasonName: "",
    rivalry: "",
  });
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    setInitialValues({
      seasonName: "",
      rivalry: "",
    });
    setShow(false);
  }
  const [competitions, setCompetitions] = useState([])

  const getComp =async ()=>{
    const response = await api.get('/rivelary/api/v1/getRivelaries')
        // console.log(response)
        setCompetitions(response.data)
  }
  useEffect(() => {
    getComp()
    // console.log(selectedField);
    if (selectedField) {
      setInitialValues({
        ...initialValues,
        ...selectedField,
      });
    } else {
      setInitialValues({
        seasonName: "",
        rivalry: "",
      });
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedField]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      //   email: Yup.string().email("Invalid email rivalry").required("Required"),
      seasonName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),

      rivalry: Yup.string()
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        if (type == "add") {
          const newUser = await api.post("/crud/api/v1/addSeason", values, {
            headers: { token: cookies.token },
          });
          successNotification("Season Added Successfully");
          getAllRivelries();
          setShow(false);
          formik.resetForm();
        } else {
        //   const newUser = await api.put("/crud/api/v1/editField", values, {
        //     headers: { token: cookies.token },
        //   });
        //   successNotification("Player Edited Successfully");
        //   getAllRivelries();
        //   setShow(false);
        //   formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error.response.data.message);
        failNotification(error.response.data.message);
      }
    },
  });
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add Season" : "Edit Field"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="seasonName"
                      >
                        Season Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="seasonName"
                        name="seasonName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.seasonName}
                      />
                      {formik.touched.seasonName && formik.errors.seasonName ? (
                        <div className="text-red-500">
                          {formik.errors.seasonName}
                        </div>
                      ) : null}
                    </div>

                    
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="rivalry"
                      >
                        rivalry
                      </label>
                      <select
                        className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'
                        id="rivalry"
                        name="rivalry"
                        onChange={(e) => { formik.handleChange(e) }}
                        onBlur={formik.handleBlur}
                        value={formik.values.rivalry}
                    >
                        <option value={''} hidden>Please Select Competition</option>

                        {competitions?.map((competition) => {
                            return <option value={competition._id}>{competition.rivelaryName}</option>
                        })}
                    </select>
                      {formik.touched.rivalry && formik.errors.rivalry ? (
                        <div className="text-red-500">
                          {formik.errors.rivalry}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        {type == "add" ? "Add Season" : "Edit Field"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddSeason;
