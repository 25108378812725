import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import api from "../../../api";
import { failNotification, successNotification } from "../../Notifications";

function AddShopCategory({ type = "add", show, setShow, getAllCategories, selectedCategory }) {
  //   console.log(selectedCategory);
  const [initialValues, setInitialValues] = useState({
    categoryName: "",
  });
  const [cookies] = useCookies(["token"]);

  function closeModal() {
    setShow(false);
  }

  useEffect(() => {
    if (selectedCategory && type!=='add') {
      setInitialValues({
        ...initialValues,
        ...selectedCategory,
      });
    } else {
      setInitialValues({
        categoryName: "",

      });
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedCategory,type]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      categoryName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values)
      try {
        if (type == "add") {
          const newUser = await api.post("/crud/api/v1/shopCategory", values, {
            headers: { token: cookies.token },
          });
          successNotification("Category Added Successfully");
          getAllCategories();
          setShow(false);
          formik.resetForm();
        } else {
          const newUser = await api.patch("/crud/api/v1/shopCategory", values, {
            headers: { token: cookies.token },
          });
          successNotification("Category Edited Successfully");
          getAllCategories();
          setShow(false);
          formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error.response.data.message);
        failNotification(error.response.data.message);
      }
    },
  });
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add Category" : "Edit Category"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="categoryName"
                      >
                        Category Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="categoryName"
                        name="categoryName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.categoryName}
                      />
                      {formik.touched.categoryName && formik.errors.categoryName ? (
                        <div className="text-red-500">
                          {formik.errors.categoryName}
                        </div>
                      ) : null}
                    </div>


                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        {type == "add" ? "Add Category" : "Edit Category"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddShopCategory;
