import {createSlice} from "@reduxjs/toolkit";

export const cart = createSlice({
    name: "cart",
    initialState: [],
    reducers: {
        addCart: (state, action) => {
            return action.payload
        },
    },
});
export const {
    addCart,

} = cart.actions;

