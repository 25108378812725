import React, { useEffect, useState } from "react";
import PodcastCard from "../components/PodcastCard";
import api from "../api";
// import img from '../../public/images/podcastimg.png'

function Podcast() {
  const [poadCastData, setPadcast] = useState();
  useEffect(() => {
    api.get("/ecommerce/api/v1/allVoices").then((res) => {
      setPadcast(res.data.PoadCasts);
      // setPadcast(res.data.data)
    });
  }, []);
  return (
    <div className="flex flex-col gap-5 justify-center  relative px-5 md:px-40 lg:px-40">
      <div
        className="h-[520px] w-full px-5 pt-40 flex flex-col gap-5"
        style={{
          backgroundImage: `url('/podcastimg.png')`,
          backgroundSize: "cover",
        }}
      >
        <h3 className="text-white font-bold text-2xl">
          Sydney Oztag brings you the best of Oztag!
        </h3>
        <p className="text-white font-semibold text-lg">
          Welcome to All About Tag - The Unspoken Truth where we bring you the
          latest and hottest topics that are all about tag! We don’t just love
          Oztag. We live for it.
        </p>
        <button className="bg-[#00B5FF] text-white px-20 py-3 rounded-lg w-fit">
          Open Spotify
        </button>
      </div>
      <h2 className="font-semibold text-3xl">
        Listen and Subscribe to All About Oztag and enjoy a new
      </h2>
      <div className="flex gap-5 items-center my-5">
        <span className="text-2xl font-semibold">Episodes</span>
        <span>{poadCastData?.length} Episodes</span>
      </div>
      <div className="grid gap-5">
        {poadCastData?.map((item) => {
          return <PodcastCard item={item} />;
        })}
        {/* <PodcastCard /> */}
        {/*<PodcastCard />*/}
        {/*<PodcastCard />*/}
        {/*<PodcastCard />*/}
        {/*<PodcastCard />*/}
      </div>
    </div>
  );
}

export default Podcast;
