import React, { useEffect, useState } from "react";
import api from "../../api";
import AdminGameScoreCard from "../AdminGameScoreCard";
import ClipLoader from "react-spinners/ClipLoader";

const ScoresToBeUpdated = ({ games, loading, getGames }) => {
  const [visible, setVisible] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [modalType, setModalType] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const getAllGames = async () => {
  //   // setLoading(true);
  //   try {
  //     const areas = await api.get(
  //       "/games/api/v1/getAllGames?adminFinish=false "
  //     );
  //     // setGames(areas.data);
  //     // setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     // setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   getAllGames();
  // }, []);
  // console.log(games);
  console.log(games, loading, getGames);
  const handleEdit = (selectedGame) => {
    setSelectedGame(selectedGame);
    setModalType(true);
    setVisible(true);
  };
  if (loading) {
    return (
      <div className="flex w-full justify-center my-10">
        <ClipLoader
          color="#00B5FF"
          loading={loading}
          size={50}
          speedMultiplier={1}
        />
      </div>
    );
  }
  return (
    <div className="grid grid-cols-2 gap-5 mt-5">
      {games.length > 0 && (
        <>
          {games.map((game) => (
            <AdminGameScoreCard
              key={game._id}
              gameData={game}
              getAllGames={getGames}
              handleEdit={handleEdit}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ScoresToBeUpdated;
