
import { ActionTypes } from '../action-types'

const authReducer = (state = {}, action) => {


    switch (action.type) {
        case ActionTypes.LOGIN:
            return action.payload
        
        default:
            return state
    }

}

export default authReducer