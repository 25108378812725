import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Grid, Autoplay } from "swiper";
import "swiper/css/grid";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import api from "../api";
const SponsorSlider = () => {
  const [sliders, setSlider] = useState([]);
  useEffect(() => {
    api
      .get(`crud/api/v1/slider?position=sponsor`)
      .then((res) => {
        setSlider(res.data.sliders);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="md:bigDiv md:w-[1280px] flex flex-col mx-2 my-16">
      {sliders.length > 0 && (
        <>
          <p className="headerTextFeatured">Proudly Sponsored By</p>

          <Swiper
            // navigation
            // slidesPerView={4}
            spaceBetween={50}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 576px
              200: {
                slidesPerView: 2,
                // navigation: false,
                pagination: { clickable: true },
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
                pagination: { clickable: true },
              },
              // when window width is >= 1024px
              1024: {
                spaceBetween: 20,
                slidesPerView: 4,
                pagination: { clickable: true },
              },
              1280: {
                spaceBetween: 20,
                slidesPerView: 6,
                pagination: { clickable: true },
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="md:bigDiv md:w-[1280px] mx-2 my-5"
            style={{ scale: "0.8" }}
            wrapperClass="flex justify-between"
          >
            {sliders.map((slider) => (
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={process.env.REACT_APP_SERVER_URL + slider.image}
                    alt=""
                    width={100}
                    height={100}
                    className=""
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default SponsorSlider;
