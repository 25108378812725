import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminEditTeamsModal from "./adminPanel/AdminEditTeamsModal";
import ConfirmRemoveTeam from "./adminPanel/ConfirmRemoveTeam";
import AdminEditOrderModal from "./AdminEditOrderModal";
import OrderDetailsModal from "./orderDetailsModal";
import ConfirmRemoveGeneral from "./adminPanel/ConfirmRemoveGeneral";
import Loader from "./loader";
function AdminOrders() {
  const [teams, setTeams] = useState([]);
  const [cookies] = useCookies(["token"]);

  const getAllTeams = async () => {
    setLoading(true);
    const response = await api.get("ecommerce/api/v1/orders", {
      headers: { token: cookies.token },
    });
    setLoading(false);
    setTeams(response.data.orders);
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [loading, setLoading] = useState(false);

  let columns = [
    // { dataField: '_id', text: 'Order No', style: { fontSize: 'small', width: '210px' } },
    {
      dataField: "user.firstName",
      text: "Username",
      style: { fontSize: "small" },
    },
    {
      dataField: "paymentLogId.amountPaid.amountBefore",
      text: "Total",
      style: { fontSize: "small" },
      formatter: (cell, row) => (
        <p className="font-bold bg-white text-center">
          $ {row.paymentLogId.amountPaid.amountBefore / 100}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <p
            className={`font-bold bg-white text-center rounded-md ${
              row.status === "received" ? "text-green-600" : ""
            }`}
          >
            {row.status}
          </p>
        );
      },
      style: { fontSize: "small" },
    },
    { dataField: "phone", text: "Phone Number", style: { fontSize: "small" } },
    { dataField: "address", text: "Address", style: { fontSize: "small" } },
    {
      dataField: "shippingNumber",
      text: "shipping No.",
      style: { fontSize: "small" },
    },

    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleOpenEdit(row)}
            />
            {/* <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemoveBtn(row._id)}
            /> */}
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => {
    setVisible(true);
  };

  const handleOpenEdit = (orderData) => {
    // console.log(orderData)
    const obj = {
      _id: orderData._id,
      paymentLogId: orderData.paymentLogId._id,
      user: orderData.user,
      status: orderData.status,
      shippingNumber: orderData.shippingNumber,
      address: orderData.address,
      phone: orderData.phone,
      totalPrice: `$ ${orderData.paymentLogId.amountPaid.amountBefore / 100}`,
    };
    setSelectedTeam(obj);
    setEditModal(true);
  };

  const handleRemoveBtn = (teamId) => {
    // console.log(teamId)
    setSelectedTeam(teamId);
    setDeleteModal(true);
  };

  return (
    <>
      <OrderDetailsModal
        show={editModal}
        setShow={setEditModal}
        orderData={selectedTeam}
        getAllOrders={getAllTeams}
      />
      {/* <ConfirmRemoveGeneral
        confirmRemove={deleteModal}
        apiUrl="auth/api/v1/"
        setConfirmRemove={setDeleteModal}
        selected={selectedTeam}
        resetFc={getAllTeams}
      /> */}
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="teamName"
          data={teams}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                {/* <button className='normalButton py-2 text-sm' onClick={()=>handleOpenAdd()}>Add Team</button> */}
              </div>
              <hr />
              {loading ? (
                <div className="flex justify-center items-center w-full">
                  <Loader />
                </div>
              ) : (
                <BootstrapTable
                  loading={true}
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminOrders;
