import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../api";
import ComingGameCard from "../components/ComingGameCard";
import DownloadApp from "../components/DownloadApp";
import GameScoreCard from "../components/GameScoreCard";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((game) => <ComingGameCard gameData={game} />)}
    </>
  );
}

function UpcomingGames({ itemsPerPage = 8 }) {
  const [games, setGames] = useState([]);

  const getAllGames = async () => {
    const areas = await api.get("/games/api/v1/getAllGames?finished=false");

    setGames(areas.data);
    console.log(areas.data);
  };

  useEffect(() => {
    getAllGames();
  }, []);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = games.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(games.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % games.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <div className="container scale-90">
      <div className="flex justify-start items-center flex-col pb-20">
        <h1 className="text-6xl font-semibold text-[#1E354E]">Upcoming Game</h1>
        {/* <h5 className="h4Regular">Use below filters for easier navigation</h5> */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-4 w-full">
        <Items currentItems={currentItems} />
      </div>
      <ReactPaginate
        containerClassName="pagination"
        activeLinkClassName="paginationButtonsActive"
        pageLinkClassName="paginationButtons"
        previousLinkClassName="previousButtons"
        nextLinkClassName="nextButtons"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />

      <DownloadApp />
    </div>
  );
}

export default UpcomingGames;
