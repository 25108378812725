import React, { useState } from "react";
import "./Stepper.css";
// import { TiTick } from "react-icons/ti";
const Stepper = ({ currentStep }) => {
  const steps = ["Delegate", "Team", "Players", "Payment"];
  //   const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  return (
    <>
      <div className="flex justify-center w-[95%] md:w-full">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 && "active"} ${
              (i + 1 < currentStep || complete) && "complete"
            } `}
          >
            <div className="step">
              {i + 1 < currentStep || complete ? i + 1 : i + 1}
            </div>
            <p className={`${currentStep === i + 1 && "text-[#00B5FF]"} $`}>
              {step}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
