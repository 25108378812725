import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

export default function PaymentLogDetailsModal({
  show,
  setShow,
  log,
  setSelectedLog,
}) {
  console.log(log);

  const [type, setType] = useState(log?.metadata?.object);

  useEffect(() => {
    setType(log?.metadata?.object);
  }, [log]);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-80"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-80"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Payment Log
                </Dialog.Title>
                {log && (
                  <div className="mt-4 grid grid-cols-2 ">
                    <div className="flex flex-col gap-2">
                      <h5>Payment Details</h5>
                      <div className="flex flex-col items-start">
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Operation :
                          </span>
                          {log.metadata.object === "ADD_PLAYER"
                            ? "Player Registration"
                            : log.metadata.object === "ADD_TEAM"
                            ? "Team Registration"
                            : log.metadata.object === "SUBSCRIBE_TO_TEAM"
                            ? "Player Subscription"
                            : "Shop Purchase"}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Status :
                          </span>
                          {log.status === "requires_payment_method"
                            ? "Incomplete"
                            : log.status}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Customer :
                          </span>
                          {log.customer.customerId}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Email :
                          </span>
                          {log.customer.email}
                        </p>
                        {log.customer.fullName && (
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Name :
                            </span>
                            {log.customer?.fullName}
                          </p>
                        )}

                        <p>
                          <span className="text-lg font-semibold px-2">
                            Amount Before :
                          </span>
                          $ {log.amountPaid.amountBefore / 100}.
                          {log.amountPaid.amountAfter.toString().slice(-2)}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Amount After :
                          </span>
                          $ {log.amountPaid.amountAfter / 100}.
                          {log.amountPaid.amountAfter.toString().slice(-2)}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Discount :
                          </span>
                          {log?.amountPaid?.discount?.value || 0}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Currency :
                          </span>
                          {log?.currency}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Created at:
                          </span>
                          {new Date(log?.createdAt).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <h5>User Details</h5>
                      <div className="flex flex-col items-start">
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Name :
                          </span>
                          {type === "E_COMMERCE"
                            ? log.shopOrder?.user?.firstName +
                              " " +
                              log.shopOrder?.user?.lastName
                            : type === "ADD_TEAM"
                            ? log.teamleader?.firstName +
                              " " +
                              log.teamleader?.lastName
                            : log.player?.firstName +
                              " " +
                              log.player?.lastName}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Email :
                          </span>
                          {type === "E_COMMERCE"
                            ? log.shopOrder?.user?.email
                            : type === "ADD_TEAM"
                            ? log.teamleader?.email
                            : log.player?.email}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Phone :
                          </span>
                          {type === "E_COMMERCE"
                            ? log.shopOrder?.user?.phoneNumber
                            : type === "ADD_TEAM"
                            ? log.teamleader?.phoneNumber
                            : log.player?.phoneNumber}
                        </p>
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Address :
                          </span>
                          {type === "E_COMMERCE"
                            ? log.shopOrder?.user?.address
                            : type === "ADD_TEAM"
                            ? log.teamleader?.address
                            : log.player?.address}
                        </p>
                        {type === "ADD_PLAYER" && (
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Player Code :
                            </span>
                            {log.player?.playerCode}
                          </p>
                        )}
                        <p>
                          <span className="text-lg font-semibold px-2">
                            Created at:
                          </span>
                          {new Date(log?.createdAt).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    {type === "ADD_PLAYER" && (
                      <div className="flex flex-col gap-2">
                        <h5>Subscriptions</h5>
                        {log.subscriptions.map((sub) => {
                          <div className="flex flex-col items-start border rounded p-2 mb-1 w-full">
                            <p>
                              <span className="text-lg font-semibold px-2">
                                Team Code :
                              </span>
                              {sub.team?.teamCode}
                            </p>
                            <p>
                              <span className="text-lg font-semibold px-2">
                                Team Name :
                              </span>
                              {sub.team?.teamName}
                            </p>
                            <p>
                              <span className="text-lg font-semibold px-2">
                                Area :
                              </span>
                              {sub.team.area?.areaName}
                            </p>
                            <p>
                              <span className="text-lg font-semibold px-2">
                                Competition :
                              </span>
                              {sub.team.rivelary?.rivelaryName}
                            </p>
                            <p>
                              <span className="text-lg font-semibold px-2">
                                Division :
                              </span>
                              {sub.team.division?.divisionName}
                            </p>
                          </div>;
                        })}
                      </div>
                    )}
                    <div className="flex flex-col gap-2">
                      <h5>
                        {type === "E_COMMERCE"
                          ? "Order Details"
                          : type === "ADD_TEAM"
                          ? "Team Details"
                          : null}
                      </h5>
                      {type === "E_COMMERCE" ? (
                        <div className="flex flex-col items-start">
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Address :
                            </span>
                            {type === "E_COMMERCE"
                              ? log.shopOrder?.address
                              : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Phone :
                            </span>
                            {type === "E_COMMERCE" ? log.shopOrder?.phone : ""}
                          </p>
                          {type === "E_COMMERCE"
                            ? log.shopOrder.products.map((product) => {
                                return (
                                  <div className="flex flex-col items-start border rounded p-2 mb-1 w-full">
                                    <p>
                                      <span className="text-lg font-semibold px-2">
                                        Name :
                                      </span>
                                      {type === "E_COMMERCE"
                                        ? product.item.name
                                        : ""}
                                    </p>
                                    <p>
                                      <span className="text-lg font-semibold px-2">
                                        Quantitiy :
                                      </span>
                                      {type === "E_COMMERCE"
                                        ? product.quantity
                                        : ""}
                                    </p>
                                    <p>
                                      <span className="text-lg font-semibold px-2">
                                        Siza :
                                      </span>
                                      {type === "E_COMMERCE"
                                        ? product.size
                                        : ""}
                                    </p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      ) : type === "ADD_TEAM" ? (
                        <div className="flex flex-col items-start">
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Team Code :
                            </span>
                            {type === "ADD_TEAM" ? log.team?.teamCode : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Team Name :
                            </span>
                            {type === "ADD_TEAM" ? log.team?.teamName : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Area :
                            </span>
                            {type === "ADD_TEAM" ? log.team.area?.areaName : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Competition :
                            </span>
                            {type === "ADD_TEAM"
                              ? log.team.rivelary?.rivelaryName
                              : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Division :
                            </span>
                            {type === "ADD_TEAM"
                              ? log.team.division?.divisionName
                              : ""}
                          </p>
                          <p>
                            <span className="text-lg font-semibold px-2">
                              Division Category :
                            </span>
                            {type === "ADD_TEAM" ? log.team.division?.type : ""}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col gap-2">
                      <h5>{type === "ADD_TEAM" ? "Players Details" : ""}</h5>
                      {type === "ADD_TEAM" ? (
                        <div className="flex flex-col items-start">
                          {log.players.map((player) => {
                            return (
                              <div className="flex flex-col items-start border rounded p-2 mb-1 w-full">
                                <p>
                                  <span className="text-lg font-semibold px-2">
                                    Name :
                                  </span>
                                  {player?.firstName + " " + player?.lastName}
                                </p>
                                <p>
                                  <span className="text-lg font-semibold px-2">
                                    Email :
                                  </span>
                                  {player?.email}
                                </p>
                                <p>
                                  <span className="text-lg font-semibold px-2">
                                    Address :
                                  </span>
                                  {player?.address}
                                </p>
                                <p>
                                  <span className="text-lg font-semibold px-2">
                                    Phone Number :
                                  </span>
                                  {player?.phoneNumber}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
