const MenuButton = ({ active, className, onClick }) => {
  return (
    <div
      className={`relative w-10 h-10 cursor-pointer flex flex-col justify-center items-start overflow-hidden   ${className}`}
      //   onClick={onClick}
    >
      <span
        className={`absolute  h-1 bg-black top-2  transition-all transform duration-500 ${
          active ? "rotate-45 translate-y-3 w-" : "w-8"
        }`}
      />
      <span
        className={`absolute w-6 h-1  bg-black transition-all transform ${
          active ? "translate-x-12" : "w-8"
        } `}
      />
      <span
        className={`absolute h-1   bg-black bottom-2 transition-all transform duration-500 ${
          active ? "-rotate-45 -translate-y-2 w-6" : "w-8 "
        }`}
      />
    </div>
  );
};

export default MenuButton;
