import React, { useState } from "react";
import "./ComingGameCard.scss";
import Countdown from "react-countdown";
import GameDetailModal from "./GameDetailModal";

function ComingGameCard({ gameData, showField, setSelectedField }) {
  const currentTime = new Date();
  // console.log(currentTime);
  const handleShowField = () => {
    showField(true);
    setSelectedField(gameData?.field);
  };

  // Random component
  const Completionist = () => <span>Game Started!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="flex items-center gap-3">
          <span className="border rounded-lg p-3">{days}</span>:
          <span className="border rounded-lg p-3">{hours}</span>:
          <span className="border rounded-lg p-3">{minutes}</span>:
          <span className="border rounded-lg p-3">{seconds}</span>
        </span>
      );
    }
  };
  // console.log(gameData)
  const getGameTime = () => {
    const [hours, minutes] = gameData.gameTime.split(":");
    return new Date(gameData.gameDate).setHours(hours, minutes, 0, 0);
    // if(gameData.gameTime===1){

    //     return (new Date(gameData.gameDate).setHours(18,0,0,0))
    // }
    // if(gameData.gameTime===2){

    //     return (new Date(gameData.gameDate).setHours(18,45,0,0))
    // }
    // if(gameData.gameTime===3){

    //     return (new Date(gameData.gameDate).setHours(19,30,0,0))
    // }
    // if(gameData.gameTime===4){

    //     return (new Date(gameData.gameDate).setHours(20,15,0,0))
    // }
  };
  const [show, setShow] = useState(false);

  return (
    <>
      <GameDetailModal show={show} setShow={setShow} gameData={gameData} />

      <div className="comingGameSmallDiv">
        <p className="heading4">{gameData?.area.areaName}</p>
        <p className="heading5">
          <p>
            {gameData?.firstTeam?.teamName}{" "}
            {gameData.firstTeam.status === "inactive" && (
              <span className="text-yellow-300 text-xs">
                {" "}
                {`<`} Team isn't Active {`>`}
              </span>
            )}{" "}
          </p>

          <p> vs</p>
          <p>
            {gameData?.secondTeam?.teamName}
            {gameData.secondTeam.status === "inactive" && (
              <span className="text-yellow-300 text-xs ">
                {" "}
                {`<`} Team isn't Active {`>`}
              </span>
            )}
          </p>
        </p>
        <p
          className="heading5 border rounded-lg p-2 cursor-pointer"
          onClick={() => {
            handleShowField();
          }}
        >
          {gameData?.field?.fieldName}
        </p>
        <p className="heading5">Game Starts In:</p>
        <Countdown date={getGameTime()} renderer={renderer} />,
        {/* <div className="smallTimeDiv">
        <div className="timeField">
          <div className="innerTimeField">
            <span className="timeText">03</span>
          </div>
          <span className="timeTypeText">Hours</span>
        </div>
        <span className="mt1">:</span>

        <div className="timeField">
          <div className="innerTimeField">
            <span className="timeText">29</span>
          </div>
          <span className="timeTypeText">Minutes</span>
        </div>
        <span className="mt1">:</span>

        <div className="timeField">
          <div className="innerTimeField">
            <span className="timeText">25</span>
          </div>
          <span className="timeTypeText">Seconds</span>
        </div>
      </div> */}
        <button className="normalButton mt20" onClick={() => setShow(true)}>
          Game Details
        </button>
      </div>
    </>
  );
}

export default ComingGameCard;
