import React from 'react'
import { RiTeamLine } from 'react-icons/ri'
import { BsArrowRight, BsCalendarEvent } from 'react-icons/bs'
import { BiTimeFive } from 'react-icons/bi'
import { GiSoccerField, GiFist, GiStairsGoal, GiFemale } from 'react-icons/gi'
import { FcIdea } from 'react-icons/fc'
import { Link } from 'react-router-dom'
function TheGameInfo() {
    return (
        <div className='container scale-75  flex flex-col gap-5 justify-center -my-96'>
            <div className='flex flex-col justify-center items-center gap-3 w-full'>
                <h1 className='text-3xl font-semibold'>The Game</h1>
                <p className='text-lg font-normal text-[#828D9E] text-center w-4/5'>
                    Oztag was created in 1992 by former NRL Player and Coach Perry Haddock. Oztag is a non-contact version of Rugby League. It is the new sport craze in Australia.
                </p>
            </div>

            <div className='w-full flex items-center gap-20'>
                <div className='w-1/2 gap-3 flex flex-col'>
                    <h3 className='text-2xl font-semibold'>Fun</h3>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Oztag is a FUN, fast-paced and energetic sport!
                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        It's a sport that is great to watch, easy to play and safe for everyone regardless of age or ability.The attraction of Oztag is that there is no training, no volunteer duties and team coaches aren’t required.
                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Oztag competitions are currently run in midweek after school/work in both summer and winter
                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        It's a sport that is great to watch, easy to play and safe for everyone regardless of age or ability. Many friends, family and work colleagues form teams primarily for fun and fitness.
                    </p>
                    <h3 className='text-lg font-semibold'>Ready to have some fun?</h3>

                </div>
                <div className='w-1/2'>
                    <img src="/images/about1.png" alt="about image" />
                </div>
            </div>
            <div className='w-full flex items-center gap-20'>
                <div className='w-1/2'>
                    <img src="/images/about2.png" alt="about image" />
                </div>
                <div className='w-1/2 gap-3 flex flex-col'>
                    <h3 className='text-2xl font-semibold'>Get Fit</h3>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Oztag is designed to allow players to fully participate in a non-contact version of rugby league. Oztag is a non-contact sport which is team oriented.
                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Each game can be as challenging as you want. Chase someone to make a Tag OR chase the ball off a kick off.                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Go for a run with the ball & score a try. The great thing is you can work Oztag into whatever workouts because it focuses on Passing, catching, tagging, evasive and kicking .                    </p>

                    <h3 className='text-lg font-semibold'>Start Oztag today to get that base level of fitness you have always been chasing.</h3>

                </div>

            </div>
            <div className='w-full flex items-center gap-20'>
                <div className='w-1/2 gap-3 flex flex-col'>
                    <h3 className='text-2xl font-semibold'>Safe</h3>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Oztag is a limited contact sport that has no tackling. The aim is to grab the ‘TAG’ from the side of your opponent's shorts to stop them from scoring a try.
                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Strict rules regulate the game in regards to contact so you can be assured safety is one of Oztags priorities                    </p>
                    <p className='text-base font-normal text-[#828D9E]'>
                        Oztag aims to reduce gender discrimination, encourage fitness, and be a sport which is inclusive and enjoyable for all levels                    </p>


                </div>
                <div className='w-1/2'>
                    <img src="/images/about3.png" alt="about image" />
                </div>
            </div>
            <div className='flex flex-col items-center w-full'>
                <h1 className='text-[40px] font-medium text-[#1E354E]'>
                    Contact Us Now
                </h1>
                <div className='flex flex-row w-full items-center justify-center mt-5 gap-5'>
                    <div className='flex flex-col w-1/5 gap-2'>
                        <label className='text-base font-semibold' htmlFor="YourName">Your Name</label>
                        <input type="text" name="YourName" id="YourName" className='input' placeholder='Name' />
                        <label className='text-base font-semibold' htmlFor="EmailAddress">Email Address</label>
                        <input type="email" name="EmailAddress" id="EmailAddress" className='input' placeholder='Email' />
                    </div>
                    <div className='flex flex-col w-2/5 gap-2'>
                        <label className='text-base font-semibold' htmlFor="YourMessage">Your Message</label>
                        <textarea name="YourMessage" id="YourMessage" rows={6} className='input' placeholder='Email' />
                    </div>
                </div>
                <button className='input bg-[#00B5FF] text-white my-4'>Send Message</button>
            </div>
            <div className='w-full flex items-center gap-20'>
                <div className='w-1/2 gap-3 flex flex-col'>
                    <h3 className='text-2xl font-semibold'>Oztag Information</h3>
                    <table className='w-full  gap-3'>
                        <tr >
                            <td ><RiTeamLine className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'> Team Limits</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>Minimum 11 players /team. 8 players on the field</td>
                        </tr>
                        <tr>
                            <td ><BiTimeFive className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Match Duration</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>40 min - 20 min*</td>
                        </tr>
                        <tr>
                            <td ><GiSoccerField className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Oztag Field</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>70m x 50m (half a soccer/Rugby field)</td>
                        </tr>
                        <tr>
                            <td ><GiFist className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Skills</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>Passing, Catching, Tagging, Evasive and Kicking</td>
                        </tr>
                        <tr>
                            <td ><GiStairsGoal className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Fitness Level</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>All fitness levels</td>
                        </tr>
                        <tr>
                            <td ><GiFemale className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Team’s Sex</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>Males ,Females , Mixed Team</td>
                        </tr>
                        <tr>
                            <td ><BsCalendarEvent className='text-[#828D9E] w-5 h-5 my-3 mr-2' /></td>
                            <td className='text-lg text-[#828D9E]'>Ages</td>
                            <td><BsArrowRight className='text-[#828D9E]' /></td>
                            <td className='text-lg'>From 6 To 60 years</td>
                        </tr>
                        <tr>
                            <td ><FcIdea className='w-5 h-5 mr-2 my-3' /></td>
                            <td colSpan={3} className='text-lg'>Game time may be varied to suit age groups and conditions.</td>
                        </tr>
                    </table>


                </div>
                <div className='w-1/2'>
                    <img src="/images/about4.png" alt="about image" />
                </div>
            </div>
            <div className='flex items-center gap-10 w-full justify-center'>
                <span className='text-2xl font-semibold'>Find out more</span>
                <Link to={'/howtoplay'}>
                    <button className='input bg-[#00B5FF] text-white my-4 px-10'>How to Play</button>
                </Link>

            </div>
        </div>
    )
}

export default TheGameInfo