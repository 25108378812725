import React, { useState, useEffect } from "react";
import ShopCard from "../components/ShopCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, fetchShopCategories } from "./shopSlice";
import { Pagination } from "flowbite-react";
import { ClipLoader } from "react-spinners";
import { ShopFilter } from "./ShopFilter";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems?.map((item) => (
          <ShopCard
            name={item.name}
            price={item.price}
            id={item._id}
            image={item.image}
            teamName={item.team_name}
          />
        ))}
    </>
  );
}

function Shop() {
  const [currentPage, setCurrentPage] = useState(1);
  const shopCategories = useSelector(
    (state) => state.shop.shopCategories.categories
  );

  const products =
    useSelector((state) => state.shop.products.data?.data) || null;
  const numOfPages =
    useSelector((state) => state.shop.products.data?.numberOfPages) || 0;
  const loadingProducts = useSelector((state) => state.shop.loading);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    product_Gender: null,
    product_category: null,
    availability: null,
    itemType: null,
  });

  useEffect(() => {
    dispatch(fetchProducts({ pageNumber: currentPage - 1, ...filter }));
    if (shopCategories.length === 0) {
      dispatch(fetchShopCategories());
    }
  }, [filter, currentPage]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container flex flex-col justify-center relative md:flex-row">
      <ShopFilter
        filter={filter}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        shopCategories={shopCategories}
      />
      <div className="w-full flex flex-col justify-center items-center">
        {loadingProducts ? (
          <div className="flex w-full justify-center my-10">
            <ClipLoader
              color="#00B5FF"
              loading={loadingProducts}
              size={50}
              speedMultiplier={1}
            />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-4 w-full">
              <Items currentItems={products} />
            </div>
            <div className="flex justify-center">
              <Pagination
              
                currentPage={currentPage}
                onPageChange={(page) => {
                  setCurrentPage(page);
                }}
                totalPages={numOfPages}
              />
            </div>
          </>
        )}
      </div>
      <br />
    </div>
  );
}

export default Shop;
