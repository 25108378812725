import { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

function AdminScoreFilter({ getAllGames }) {
  const filterInitalValue = {
    gameDate: "",
    rivelaryName: "",
    firstTeam: "",
    firstTeamScore: "",
    secondTeam: "",
    secondTeamScore: "",
    finished: "",
  };
  const ref = useRef(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [filterValue, setFilterValue] = useState(filterInitalValue);
  const handleFilterValueChange = (value, key) => {
    console.log(key, value);
    setFilterValue((prev) => {
      return { ...prev, [key]: value };
    });
  };
  const handleToggleDropDown = () => {
    setToggleDropdown((prev) => !prev);
  };

  const handleSubmitFilter = () => {
    const query =
      "?" +
      Object.keys(filterValue)
        .map((key) => {
          if (filterValue[key]) {
            return `${key}=${encodeURIComponent(filterValue[key])}`;
          }
        })
        .join("&");
    getAllGames(query);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  console.log(filterValue, "filterValue");
  return (
    <div className="relative" ref={ref}>
      <div>
        <button
          className={`input bg-[#0FA9E8] text-white`}
          onClick={handleToggleDropDown}
        >
          Filter
        </button>
      </div>
      <div
        className={`w-[300px] z-50 absolute space-y-4 px-4 py-3 shadow-lg bg-white ${
          toggleDropdown ? "block" : "hidden"
        }`}
      >
        <div className="form-group">
          <label for="gameDate">Game Date</label>
          <input
            type="date"
            className="form-control"
            id="gameDate"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "gameDate")
            }
            value={filterValue.gameDate}
          />
        </div>
        <div className="form-group">
          <label for="rivelaryName">Rivalry Name</label>
          <input
            type="text"
            className="form-control"
            id="rivelaryName"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "rivelaryName")
            }
            value={filterValue.rivelaryName}
          />
        </div>
        <div className="form-group">
          <label for="firstTeam">First Team Name</label>
          <input
            type="text"
            className="form-control"
            id="firstTeam"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "firstTeam")
            }
            value={filterValue.firstTeam}
          />
        </div>
        <div className="form-group">
          <label for="firstTeamScore">First Team Score</label>
          <input
            type="number"
            className="form-control"
            id="firstTeamScore"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "firstTeamScore")
            }
            value={filterValue.firstTeamScore}
          />
        </div>

        <div className="form-group">
          <label for="secondTeam">Second Team Name</label>
          <input
            type="text"
            className="form-control"
            id="secondTeam"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "secondTeam")
            }
            value={filterValue.teamName}
          />
        </div>
        <div className="form-group">
          <label for="secondTeamScore">Second Team Score</label>
          <input
            type="number"
            className="form-control"
            id="secondTeamScore"
            onChange={(e) =>
              handleFilterValueChange(e.target.value, "secondTeamScore")
            }
            value={filterValue.secondTeamScore}
          />
        </div>
        <div className="form-group">
          <label className="form-check-label" for="finshed">
            Finshed Game
          </label>
          <select
            className="w-full border border-gray-500 rounded-lg"
            id="finshed"
            onChange={(e) => handleFilterValueChange(e.target.value, "finished")}
            value={filterValue.finished}
          >
            <option value="">All</option>
            <option value="true">Finished</option>
            <option value="false">Not Finished</option>
          </select>
        </div>

        <div className="flex justify-center space-x-4">
          <button
            type="submit"
            onClick={handleSubmitFilter}
            className="input bg-[#0FA9E8] text-white"
          >
            Search
          </button>
          <button
            type="reset"
            onClick={() => {
              getAllGames();
              setFilterValue(filterInitalValue);
            }}
            className="input border-2 border-[#0FA9E8] text-black"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminScoreFilter;
