import React from "react";
import { useState } from "react";
import AdminAddEditScoreModal from "./AdminAddEditScoreModal";
import { GiCancel } from "react-icons/gi";
import api from "../api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
function AdminGameScoreCard({ gameData, getAllGames, handleEdit }) {
  const [show, setShow] = useState(false);
  const [cookies] = useCookies(["token"]);
  const handleDelete = (id) => {
    api
      .put(
        "/games/api/v1/cancelGame",
        { _id: id },
        {
          headers: { token: cookies.token },
        }
      )
      .then(() => {
        toast.success("game deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
        getAllGames();
      })
      .catch(() => {});
  };
  return (
    <div className="w-full flex-col flex p-3 border rounded-lg py-5 items-center relative">
      <div className="flex justify-between w-full items-center">
        <p className="absolute left-4 top-4">
          <div className="flex gap-3">
            <GiCancel
              size={25}
              className="cursor-pointer"
              onClick={() => {
                handleDelete(gameData._id);
              }}
            />
            <MdEdit
              size={25}
              className="cursor-pointer"
              onClick={() => {
                handleEdit(gameData);
              }}
            />
          </div>
        </p>{" "}
        {gameData.finished === false ? (
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-4 top-4"
          >
            <circle cx="12.5" cy="12.5" r="12.5" fill="#FF1F1F" />
          </svg>
        ) : (
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-4 top-4"
          >
            <circle cx="12.5" cy="12.5" r="12.5" fill="#25EF51" />
          </svg>
        )}
      </div>

      <AdminAddEditScoreModal
        show={show}
        setShow={setShow}
        gameData={gameData}
        getAllGames={getAllGames}
      />
      <p className="heading4">{gameData.gameDate.slice(0, 10)}</p>
      <p className="heading3 mb-5">{gameData.rivelary?.rivelaryName}</p>
      <p className="heading5">Final Score</p>
      <div className="scoreDiv items-center">
        <span className="mt1 heading3">{gameData.firstTeam?.teamName}</span>
        <div className="timeField">
          <div className="innerTimeField">
            <span className="timeText heading3">{gameData?.firstTeamScore}</span>
          </div>
        </div>
        <span className="mt1 heading3">VS</span>

        <div className="timeField">
          <div className="innerTimeField">
            <span className="timeText heading3">
              {gameData?.secondTeamScore}
            </span>
          </div>
        </div>
        <span className="mt1 heading3">{gameData.secondTeam?.teamName}</span>
      </div>
      <button className="normalButton mt20 w-fit" onClick={() => setShow(true)}>
        {gameData.finished === false ? "Add Score" : "Edit Score"}
      </button>
    </div>
  );
}

export default AdminGameScoreCard;
