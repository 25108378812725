import React from "react";
import { Link } from "react-router-dom";

const RegSuccess = () => {
  return (
    <>
      <div className="text-center flex justify-center mt-7 mb-7 ">
        <svg
          className="w-32 h-32  text-green-400 dark:text-white text-center "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>

      <h2 className=" text-center h-full ">
        {" "}
        <span className="text-green-400">Congratulations</span> <br /> You've
        registered Successfully{" "}
      </h2>
      <div className="text-center ">
        <Link className="hover:no-underline" to={"/"}>
          go to login
        </Link>
      </div>
    </>
  );
};

export default RegSuccess;
