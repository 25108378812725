import { Select } from "flowbite-react";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { BsEye, BsPerson, BsPhone } from "react-icons/bs";
import { CgCalendar, CgLock } from "react-icons/cg";
import { CiEdit, CiMedal } from "react-icons/ci";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { TbSoccerField } from "react-icons/tb";
import { IoMedalOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import EditPlayerModal from "../../components/EditPlayerDataModal";
import ClipLoader from "react-spinners/ClipLoader";
import { useFormik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import * as Yup from "yup";
import {
  failNotification,
  successNotification,
} from "../../components/Notifications";
import ChangePasswordModal from "../../components/auth/ChangePasswordModal";
import EditTeamDataModal from "./teams/EditTeamDataModal";
import AdminEditPlayerData from "./AdminEditPlayerData";
import AdminAssignDelegate from "./AdminAssignDelegate";
import { PhoneRegExp } from "../TeamRegForm3";

function AdminEditTeamsModal({ show, setShow, teamId }) {
  let [loading, setLoading] = useState(true);
  const [adminType, setAdminType] = useState(3);

  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    dateOfBirth: "",
    email: "",
  });
  const [editData, setEditData] = useState(false);
  const [userData, setUserData] = useState();
  const [editTeamModal, setEditTeamModal] = useState(false);
  const [teamData, setTeamData] = useState();
  const [players, setPlayers] = useState();
  const [codeVisible, setCodeVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  const [changePassword, setChangePassword] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // console.log(adminType);
  const navigate = useNavigate();
  const getPlayerInfo = async () => {
    setLoading(true);

    try {
      const user = await api.post(
        "/auth/api/v1/getTeamLeader",
        { subscriptions: { team: teamId } },
        {
          headers: { token: cookies.token },
        }
      );
      // console.log(user.data[0]);

      setUserData(() => user.data?.[0]);

      const team = await api.post(
        "/auth/api/v1/getTeamData",
        { _id: teamId },
        { headers: { token: cookies.token } }
      );
      setTeamData(() => team.data?.[0]);

      // console.log(user.data[0]);
      // console.log(teamData);
      const players = await api.post(
        "/auth/api/v1/getTeamPlayers",
        { _id: teamId },
        { headers: { token: cookies.token } }
      );
      setPlayers(players.data);

      setLoading(false);

      // console.log(players)
    } catch {
      // console.log("error");
      setLoading(false);
      setShow(false);
      //   navigate("/");
    }
  };
  useEffect(() => {
    const handleProfile = async () => {
      const newUser = await api.get("/auth/api/v1/validateToken", {
        headers: { token: cookies.token },
      });
      setAdminType(newUser.data.roleId);
    };
    handleProfile();
    if (teamId) {
      getPlayerInfo();
    }
  }, [teamId]);
  // useEffect(() => {
  //     if (userData) {

  //         // console.log(userData.dateOfBirth)
  //         setInitialValues({ ...initialValues, ...userData, dateOfBirth: new Date(userData.dateOfBirth).toISOString().slice(0, 10) })
  //     }
  //     // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  // }, [userData])
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(9, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      if (editData) {
        try {
          const newUser = await api.post(
            "/auth/api/v1/updatePlayerDataFromTeam",
            values,
            { headers: { token: cookies.token } }
          );
          getPlayerInfo();
          successNotification("Player Data Updated Successfully");
          closeModal();
          // console.log(newUser)
        } catch (error) {
          failNotification(error.response.data.message);

          // console.log("error");
        }
      } else {
        try {
          const email = await api.get("/auth/api/v1/checkEmail", {
            params: { email: values.email },
          });
          const newUser = await api.post("/auth/api/v1/addTeamPlayers", [
            { ...values, teamId, regPlatform: "Web" },
          ]);
          resetForm();
          closeModal();
          getPlayerInfo();
          successNotification("Player Add Successfully");
          // console.log(newUser)
        } catch (error) {
          console.log(error.response.data, "======,,");
          failNotification(error.response.data.msg);
        }
      }
    },
  });
  const activeAllPlayer = async () => {
    const playersId = players?.map((player) => {
      return player._id;
    });

    await api.put(
      "/auth/api/v1/updateStatusPlayers",
      { ids: playersId, status: true },
      {
        headers: { token: cookies.token },
      }
    );

    setPlayers((prev) => prev.map((player) => ({ ...player, paid: true })));
  };
  const handleEdit = (player) => {
    setEditData(true);
    setInitialValues({
      ...player,
      dateOfBirth: new Date(player.dateOfBirth).toISOString().slice(0, 10),
    });
    openModal();
  };
  const handleAdd = () => {
    setEditData(false);
    setInitialValues({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: "",
      email: "",
    });
    openModal();
  };

  const handleEditTeam = () => {
    setEditTeamModal(true);
  };
  const getPlayerStatus = (player) => {
    // const state = player.subscriptions.map((sub) => {
    //   if (sub.team === teamId) {
    //     return sub.paidStatus ? "Active" : "Pending";
    //   }
    // });
    // return state;
    return player.paid ? "Active" : "Pending";
  };
  return (
    <>
      <EditTeamDataModal
        show={editTeamModal}
        setShow={setEditTeamModal}
        selectedTeam={teamId}
        resetFunc={getPlayerInfo}
        teamData={teamData}
      />
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          // initialFocus={cancelButtonRef}
          onClose={setShow}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
                enterTo="opacity-100 translate-y-0 sm:scale-75"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-75"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-2/3 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    {/* body start */}
                    <>
                      <ChangePasswordModal
                        changePassword={changePassword}
                        setChangePassword={setChangePassword}
                      />
                      <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={closeModal}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                          </Transition.Child>

                          <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                  <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                  >
                                    Edit Player
                                  </Dialog.Title>
                                  <div className="mt-2">
                                    <form
                                      className="grid grid-cols-2 gap-3 my-10"
                                      onSubmit={formik.handleSubmit}
                                    >
                                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="firstName"
                                        >
                                          First Name
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="firstName"
                                          name="firstName"
                                          type="text"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.firstName}
                                        />
                                        {formik.touched.firstName &&
                                        formik.errors.firstName ? (
                                          <div className="text-red-500">
                                            {formik.errors.firstName}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="lastName"
                                        >
                                          Last Name
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="lastName"
                                          name="lastName"
                                          type="text"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.lastName}
                                        />
                                        {formik.touched.lastName &&
                                        formik.errors.lastName ? (
                                          <div className="text-red-500">
                                            {formik.errors.lastName}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="email"
                                        >
                                          E-mail
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="email"
                                          name="email"
                                          type="email"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.email}
                                        />
                                        {formik.touched.email &&
                                        formik.errors.email ? (
                                          <div className="text-red-500">
                                            {formik.errors.email}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="address"
                                        >
                                          Address
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="address"
                                          name="address"
                                          type="text"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.address}
                                        />
                                        {formik.touched.address &&
                                        formik.errors.address ? (
                                          <div className="text-red-500">
                                            {formik.errors.address}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="dateOfBirth"
                                        >
                                          Date Of Birth
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="dateOfBirth"
                                          name="dateOfBirth"
                                          type="date"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.dateOfBirth}
                                        />
                                        {formik.touched.dateOfBirth &&
                                        formik.errors.dateOfBirth ? (
                                          <div className="text-red-500">
                                            {formik.errors.dateOfBirth}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="w-full flex flex-col">
                                        <label
                                          className="font-medium text-base mt-3"
                                          htmlFor="phoneNumber"
                                        >
                                          Phone Number
                                        </label>
                                        <input
                                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                                          id="phoneNumber"
                                          name="phoneNumber"
                                          type="tel"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.phoneNumber}
                                        />
                                        {formik.touched.phoneNumber &&
                                        formik.errors.phoneNumber ? (
                                          <div className="text-red-500">
                                            {formik.errors.phoneNumber}
                                          </div>
                                        ) : null}
                                      </div>

                                      {/* <div className='w-full flex flex-col col-span-2'>
                                                <div className='flex flex-row my-5 items-center'>
                                                     <input checked={readTerms} onChange={() => setReadTerms(!readTerms)} type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                                                    <label className='font-medium text-base ml-3 '>This player has read & agreed to the insurance Condititions of Play & the Competition Regulations</label>
                                                </div>

                                            </div> */}
                                      <div className="w-full flex flex-col col-span-2 items-center">
                                        <button
                                          type="submit"
                                          className="normalButton w-fit py-2"
                                        >
                                          Apply Changes
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition>

                      <EditPlayerModal
                        show={showModal}
                        setShow={setShowModal}
                      />
                      {loading ? (
                        <div className="flex w-full justify-center my-10">
                          <ClipLoader
                            color="#00B5FF"
                            loading={loading}
                            size={50}
                            speedMultiplier={1}
                          />
                        </div>
                      ) : (
                        <div className="container scale-90  flex flex-col gap-5 justify-center ">
                          <div className="flex justify-between">
                            <h2 className="font-semibold text-2xl">
                              Delegate Details
                            </h2>
                            {!userData?._id && (
                              <AdminAssignDelegate
                                teamId={teamId}
                                getPlayerInfo={getPlayerInfo}
                                players={players}
                              />
                            )}
                          </div>
                          <div className="grid grid-cols-4 gap-x-20 gap-y-5">
                            <div className="flex gap-2 items-center">
                              <BsPerson className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Full Name</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>
                                {userData?.firstName} {userData?.lastName}
                              </span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <MdEmail className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Email</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{userData?.email}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <BsPhone className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Phone Number
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{userData?.phoneNumber}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <MdLocationPin className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Address</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{userData?.address}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <CgCalendar className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Date of Birth
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>
                                {new Date(
                                  userData?.dateOfBirth
                                ).toLocaleDateString()}
                              </span>
                            </div>
                            {/* <div className="flex gap-2 items-center">
                              <RiTeamLine className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Team Name</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{userData?.teamId?.teamName}</span>
                            </div> */}
                            <div className="flex gap-2 items-center">
                              <CgLock className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Player Code
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse items-center">
                              <BsEye
                                onClick={() => setCodeVisible(!codeVisible)}
                                className="cursor-pointer"
                              />
                              <span>
                                {codeVisible
                                  ? userData?.playerCode
                                  : "**********"}
                              </span>
                            </div>
                            {/* <button
                            className="input"
                            onClick={() => setChangePassword(true)}
                          >
                            Change Password
                          </button> */}
                          </div>
                          <h2 className="font-semibold text-2xl">
                            Team Details
                          </h2>
                          <div className="grid grid-cols-4 gap-x-20 gap-y-5">
                            <div className="flex gap-2 items-center">
                              <BsPerson className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Team Code</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.teamCode}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <BsPerson className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Team Name</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.teamName}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <TbSoccerField className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Division</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.division?.divisionName}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <MdLocationPin className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Area</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.area?.areaName}</span>
                            </div>
                            {/* <div className="flex gap-2 items-center">
                              <RiTeamLine className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Sub Division Preferance
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>
                                {teamData?.subDivPreference?.subDivName}
                              </span>
                            </div> */}
                            <div className="flex gap-2 items-center">
                              <CiMedal className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Competition
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.rivelary?.rivelaryName}</span>
                            </div>

                            <div className="flex gap-2 items-center">
                              <RiTeamLine className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">
                                Sub Division
                              </span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.subDiv?.subDivName}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <RiTeamLine className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Night</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span>{teamData?.division?.night}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <RiTeamLine className="text-[#828D9E]" />
                              <span className="text-[#828D9E]">Status</span>
                            </div>
                            <div className="flex gap-2 flex-row-reverse">
                              <span
                                className={`font-bold ${
                                  teamData?.status === "active"
                                    ? "text-green-600"
                                    : "text-red-500"
                                }`}
                              >
                                {teamData?.status}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <h2 className="font-semibold text-lg">
                              Players Information
                            </h2>
                            <button
                              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
                              onClick={() => activeAllPlayer()}
                            >
                              Active All
                            </button>
                          </div>
                          <div className="w-full grid grid-cols-2 gap-x-20 gap-y-2">
                            {players?.map((player) => {
                              return (
                                <div
                                  className="flex items-center justify-between"
                                  key={player._id}
                                >
                                  <span className="text-base font-semibold text-[#828D9E]">
                                    {player.firstName} {player.lastName}
                                  </span>
                                  <span className="text-base font-semibold text-[#828D9E]">
                                    {player.playerCode}
                                  </span>
                                  <span
                                    className={`text-base font-semibold p-2 ${
                                      getPlayerStatus(player) === "Active"
                                        ? "bg-green-100 text-green-800"
                                        : "bg-yellow-100 text-yellow-800"
                                    }`}
                                  >
                                    {getPlayerStatus(player)}
                                  </span>
                                  <CiEdit
                                    className="text-2xl font-semibold text-[#828D9E] cursor-pointer"
                                    onClick={() => handleEdit(player)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex w-full flex-row-reverse">
                            <button
                              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
                              onClick={() => handleAdd()}
                            >
                              Add Player
                            </button>
                          </div>
                          <div className="flex justify-center">
                            <button
                              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
                              onClick={() => handleEditTeam()}
                            >
                              Edit Team
                            </button>
                          </div>
                        </div>
                      )}
                    </>

                    {/* body end */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default AdminEditTeamsModal;
