import React, { Fragment } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DownloadApp from "../components/DownloadApp";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import api from "../api";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  failNotification,
  successNotification,
} from "../components/Notifications";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { checkDiscount } from "../utils/CheckDiscount";
import PaymentForm from "../components/paymentForm";
import { PhoneRegExp } from "../components/TeamRegForm3";

function SignUp() {
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });

  const notifysuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });

  const [cookies, setCookie] = useCookies(["token"]);
  const [areas, setAreas] = useState([]);
  const [UIErrorMsgs, setUIErrorMsgs] = useState({
    discount: "",
    playerCode: "",
    teamCode: "",
  });
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState("");

  // const [selectedArea, setSelectedArea] = useState({});
  // const [area, setArea] = useState("");
  const options = {
    clientSecret: clientSecret,
  };
  const [playerPayment, setPlayerPayment] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

  useEffect(() => {
    if (cookies.token) {
      setCookie("token", "");
    }
    const fetchAreas = async () => {
      const response = await api
        .get("/crud/api/v1/getAreas")
        .then((res) => setAreas(res.data));
      return response;
    };
    fetchAreas();
  }, []);
  // console.log(areas);
  // const [codemsg, setcodemsg] = useState({
  //   type: 0,
  //   msg: "",
  // });
  // const [editPlayerData, setEditPlayerData] = useState(false);
  // const [playerData, setPlayerData] = useState();
  // const [payNow, setPayNow] = useState(true);
  // const [playerCode, setPlayerCode] = useState(false);
  // const [playerPaid, setPlayerPaid] = useState(false);
  // const [discountMsg, setDiscountMsg] = useState("");
  // const [teamId, setTeamId] = useState("");
  // const [compPrice, setCompPrice] = useState(undefined);
  // const [getTeamByCode, setTeamByCode] = useState();
  // const [teamCode, setTeamCode] = useState("");
  let navigate = useNavigate();
  const [myValues, setValues] = useState();
  const [playerCodeValidation, setPlayerCodeValiadtion] = useState(false);
  const [teamId, setTeamId] = useState(" ");
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: "",
      email: "",
      password: "",
      confirmPassword: "",
      area: "",
      discount: "",
      alreadyReg: false,
      payOnPremises: false,
      playerCode: "",
      teamCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      password: Yup.string()
        .min(6, "Must be 6 characters or more")
        .required("Required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      alreadyReg: Yup.boolean().required(),
      playerCode: Yup.number()
        .optional()
        .when("alreadyReg", {
          is: true,
          then: Yup.number().required("player code is required"),
        }),
      teamCode: Yup.number().optional(),
      area: Yup.string().optional().when("alreadyReg", {
        is: false,
        then: Yup.string().required(),
      }),
      payOnPremises: Yup.boolean().required(),
      discount: Yup.object().optional(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (playerCodeValidation === false && values.alreadyReg) {
        return failNotification("please add a valid player code");
      }

      if (
        !values.teamCode &&
        !values.payOnPremises &&
        !playerCodeValidation &&
        !values.alreadyReg
      ) {
        return failNotification(
          " you don't have team information to pay,  please insert a valid data "
        );
      }
      const formData = new FormData();

      areas.forEach((area) => {
        if (area.areaName === values.area) {
          values = { ...values, area: area._id };
        }
        // setValues(newValues);
      });

      values.discount = JSON.stringify(values.discount);
      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        if (key === "teamCode") return;
        if (key === "area") {
          if (value === "") return;
        }
        if (key === "playerCode") {
          if (!playerCodeValidation) return;
        }
        // console.log(key, value);
        formData.append(key, value);
      });
      if (!formik.values.alreadyReg && !formik.values.payOnPremises) {
        formData.append("teamId", teamId);
      }
      formData.append("regPlatform", "Web");
      // formData.append("amount", amount);
      // console.log(formData);
      // formik.values.entries().foreach((item) => {
      //   let [key, value] = item;
      //   formData.append(key, value);
      // });

      try {
        const newPlayer = await api.post("auth/api/v1/SignUp", formData);

        if (newPlayer.data.client_secret) {
          setClientSecret(newPlayer.data.client_secret);
        }
        if (!newPlayer.data.client_secret && newPlayer.status === 200) {
          navigate("/regSuccess");
        }
      } catch (error) {
        notifyerror(error.response.data.message);
      }

      // setSubmitting(false);
      // console.log(values);
      // if (!alreadyReg) {
      //   try {
      //     const email = await api.get("/auth/api/v1/checkEmail", {
      //       params: { email: values.email.toLowerCase() },
      //     });
      //     if (email.status === 200) {
      //       setPlayerData(values);
      //       console.log(payNow, teamCodeValidation);
      //       if (payNow && teamCodeValidation) {
      //         setPayModal(true);
      //       } else {
      //         handlePaySubmit();
      //       }
      //     }
      //   } catch (error) {
      //     notifyerror("Invalid Email");
      //   }
      // } else {
      //   try {
      //     const formData = new FormData();
      //     Object.entries(values).forEach((item) => {
      //       let [key, value] = item;
      //       value = key === "email" ? value.toLowerCase() : value;
      //       formData.append(key, value);
      //     });
      //     formData.append("paid", true);
      //     formData.append("playerCode", playerCode);
      //     const newUser = await api.patch(
      //       "/auth/api/v1/updatePlayerDataByCode",
      //       formData
      //     );
      //     notifysuccess("Register Complete Successfully");
      //     navigate("/login");
      //   } catch (error) {
      //     notifyerror("invalid player code");
      //   }
      // }
    },
  });
  // const handleAreaChange = (area) => {
  //   setSelectedArea(area);
  //   const choosenArea = areas.find((myArea) => myArea.areaName === area);
  //   // console.log(choosenArea);
  //   setArea(() => choosenArea);
  // };
  // const handlePaySubmit = async () => {
  //   // if (!alreadyReg && !teamCodeValidation) return;
  //   const formData = new FormData();
  //   if (!alreadyReg && teamId?.length >= 1) formData.append("teamId", teamId);
  //   // console.log(playerData);
  //   Object.entries(playerData).forEach((item) => {
  //     let [key, value] = item;
  //     formData.append(key, value);
  //   });
  //   formData.append("paid", payNow);
  //   formData.append("regPlatform", "Web");

  //   if (area) {
  //     formData.append("area", area._id);
  //   }

  //   const newUser = await api.post("/auth/api/v1/SignUp", formData);
  //   // console.log(newUser);
  //   if (newUser.data.errors) {
  //     notifyerror(newUser.data.errors[0].msg);
  //   } else {
  //     notifysuccess("Register Complete Successfully");
  //     setPayModal(false);
  //     navigate("/regSuccess");
  //   }
  // };

  const handleAlreadyRegister = (checked) => {
    formik.setFieldValue("alreadyReg", checked);
    if (checked && playerCodeValidation) {
      formik.setFieldValue("playerCode", undefined);
    } else {
      formik.setFieldValue("teamCode", undefined);
      formik.setFieldValue("area", undefined);
    }
  };
  const verifyPlayerCode = async (playercode) => {
    formik.setFieldTouched("playerCode", true);
    formik.setFieldValue("playerCode", playercode);

    // if (playercode.length === 0) {
    //   setUIErrorMsgs((prev) => (prev.discount = "Player code is required"));
    //   // formik.setFieldError("playerCode", "Player code is required");
    //   return;
    // }
    try {
      const response = await api.post("/auth/api/v1/verifyPlayerCode", {
        playerCode: playercode,
      });
      setPlayerCodeValiadtion(true);
      setPlayerPayment(response.data.user.subscriptions[0].paidStatus);
      formik.setFieldValue("playerCode", playercode);
    } catch (error) {
      setPlayerCodeValiadtion(false);
      // formik.setFieldValue("playerCode", "", { shouldValidate: true });
      if (error.response.status === 404) {
        formik.setFieldError("playerCode", "code isn't valid");
      }
      if (error.response.status === 401) {
        formik.setFieldError("playerCode", "already registered");
      }
      // formik.setFieldValue("playerCode", undefined, false);
    }
  };

  const handleTeamCode = async (teamCode) => {
    try {
      if (teamCode.length !== 10) {
        return;
      }
      const response = await api.post("/auth/api/v1/checkTeamCode", {
        teamCode,
      });
      if (response.status === 200) {
        setTeamId(response.data.team._id);
        formik.setFieldValue("teamCode", response.data.team.teamCode);
        console.log(response.data.team.rivelary.priceBefore);
        console.log(response.data.team.rivelary.priceAfter);
        if (new Date(response.data.team.rivelary.priceLimitDate) > new Date()) {
          formik.setFieldValue(
            "amount",
            100 * response.data.team.rivelary.priceBefore
          );
          setAmount(response.data.team.rivelary.priceBefore);
        } else {
          formik.setFieldValue(
            "amount",
            100 * response.data.team.rivelary.priceAfter
          );
          setAmount(response.data.team.rivelary.priceAfter);
        }
      }
    } catch (error) {
      formik.setFieldError("teamCode", "code isn't valid");
    }
  };
  const handleDiscountChange = async (code) => {
    try {
      if (code.length === 6) {
        const dicount = await api.get(
          `/crud/api/v1/getDiscountByCode?code=${code}`
        );
        formik.setFieldValue("discount", {
          _id: dicount.data._id,
          value: dicount.data.value,
        });
      }
    } catch (error) {
      formik.setFieldValue("discount", undefined);
      notifyerror(error.response.data.msg);
    }
  };
  // console.log(formik.errors);
  // console.log(formik.values);
  // console.log(formik.touched);

  const [alreadyReg, setAlreadyReg] = useState(false);
  const [payModal, setPayModal] = useState(false);
  // console.log(process.env.REACT_APP_STRIPE_PUBLIC)
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  // const stripePromise = loadStripe(
  //   "pk_live_51MsY0jFsHqSwSqLjYPFDMHtQRWrRYz6rWwvYJgdzvdPvy86yKjKVFHSUUBj7sIxkxC6M4iW4qclI7oMOyNSDe6bM00pFIRdEkY"
  // );
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

  return (
    <>
      {/* {payModal && teamCodeValidation ? (
        <Elements stripe={stripePromise}>
          <PaymentForm
            payModal={payModal}
            setPayModal={setPayModal}
            playerData={playerData}
            price={compPrice}
            handlePaySubmit={handlePaySubmit}
            discount={dicount.percentage}
          />
        </Elements>
      ) : (
        ""
      )} */}
      <div className="flex justify-center items-center flex-col">
        <div className="flex justify-center items-center">
          <div className="flex flex-col ml-5  w-full lg:w-1/4    md:w-1/2 md:px-5 sm:px-5 scale-90  ">
            <h2 className="text-3xl font-semibold my-4">Player Registration</h2>
            <Link
              to="/login"
              className="no-underline font-medium text-base text-[#828D9E]"
            >
              Already a player? Log in
            </Link>
            <div className="flex flex-col">
              <form className="flex flex-col" onSubmit={formik.handleSubmit}>
                <label
                  className="font-medium text-base mt-3"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-500">{formik.errors.firstName}</div>
                ) : null}

                <label
                  className="font-medium text-base mt-3"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-500">{formik.errors.lastName}</div>
                ) : null}

                <label className="font-medium text-base mt-3" htmlFor="email">
                  E-mail
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}

                <label className="font-medium text-base mt-3" htmlFor="address">
                  Address
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="address"
                  name="address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-red-500">{formik.errors.address}</div>
                ) : null}

                <label
                  className="font-medium text-base mt-3"
                  htmlFor="dateOfBirth"
                >
                  Date Of Birth
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfBirth}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                  <div className="text-red-500">
                    {formik.errors.dateOfBirth}
                  </div>
                ) : null}

                <label
                  className="font-medium text-base mt-3"
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="text-red-500">
                    {formik.errors.phoneNumber}
                  </div>
                ) : null}
                <label
                  className="font-medium text-base mt-3"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500">{formik.errors.password}</div>
                ) : null}

                <label
                  className="font-medium text-base mt-3"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="text-red-500">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}

                <label
                  className="font-medium text-base mt-3"
                  htmlFor="confirmPassword"
                >
                  Image
                </label>
                <input
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  id="image"
                  name="image"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("image", e.target.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                  value={undefined}
                />
                <div>
                  Please upload a clear image of the player’s face to avoid your
                  account being declined
                </div>
                {formik.touched.image && formik.errors.image ? (
                  <div className="text-red-500">{formik.errors.image}</div>
                ) : null}

                <div className="flex flex-row my-3 items-center">
                  <input
                    name="alreadyReg"
                    type="checkbox"
                    {...formik.getFieldProps("alreadyReg")}
                    onChange={(e) => {
                      handleAlreadyRegister(e.target.checked);
                    }}
                    className="w-4 h-4 text-[#00B5FF] bg-[#fff] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
                  />
                  <label className="font-medium text-base ml-3 ">
                    This player is already registered by team
                  </label>
                </div>
                {formik.values.alreadyReg ? (
                  <>
                    <p className="font-medium text-base mt-3">Player Code</p>
                    <input
                      maxLength={10}
                      type="number"
                      placeholder="Player Code"
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      // {...formik.getFieldProps("playerCode")}
                      onChange={(e) => verifyPlayerCode(e.target.value)}
                    />
                    {formik.touched.playerCode && formik.errors.playerCode && (
                      <div className="text-red-500">
                        {formik.errors.playerCode}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="">
                    <p className="font-medium text-base mt-3">Team Code</p>
                    <input
                      maxLength={10}
                      type="number"
                      placeholder="Team Code (Optional) "
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      {...formik.getFieldProps("teamCode")}
                      onChange={(e) => {
                        handleTeamCode(e.target.value);
                        // setTeamCode(e.target.value);
                      }}
                    />
                    {formik.touched.teamCode && formik.errors.teamCode && (
                      <div className="text-red-500">
                        {formik.errors.teamCode}
                      </div>
                    )}

                    <div>
                      <label className="font-medium text-base mt-3">
                        Player Area
                      </label>
                      <br />
                      <select name="area" {...formik.getFieldProps("area")}>
                        <option value="">No Selection</option>
                        {areas?.map((area) => (
                          <option value={area.areaName} key={area._id}>
                            {area.areaName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                {(!playerCodeValidation || !playerPayment) && (
                  <div className="flex flex-row my-3 items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-[#00b5ff] bg-[#fff] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
                      {...formik.getFieldProps("payOnPremises")}
                    />
                    <label className="font-medium text-base ml-3 ">
                      On premises payment
                    </label>
                  </div>
                )}
                {!formik.values.payOnPremises &&
                  (!playerCodeValidation || !playerPayment) && (
                    <>
                      <p className="font-medium text-base mt-3">
                        Discount Code
                      </p>
                      <input
                        maxLength={10}
                        placeholder="Discount Code"
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        onChange={(e) => handleDiscountChange(e.target.value)}
                      />
                      {formik.values?.discount?.value && (
                        <p className={`text-green-500`}>
                          Code Success Discount value is{" "}
                          {formik.values.discount.value}{" "}
                        </p>
                      )}
                      {amount && <p>amount to be paid is {amount} $ </p>}
                    </>
                  )}

                <button
                  type="submit"
                  className="py-3 bg-[#00B5FF] rounded-lg mb-3 text-white my-5"
                >
                  {formik.values.payOnPremises ||
                  (playerCodeValidation && playerPayment)
                    ? "Sign Up"
                    : "Proceed To Payment"}
                </button>
              </form>
              <span className="font-medium text-sm text-[#828D9E] ">
                This site is protected by reCAPTCHA and the Google
              </span>
              <span className="font-medium text-sm text-[#828D9E] ">
                Privacy Policy and Terms of Service apply
              </span>
            </div>
            <div>
              {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                  <PaymentForm returnUrl={`/regSuccess`} />
                </Elements>
              )}
            </div>
          </div>
          <div className="flex-col flex-wrap justify-center items-center md:w-1/2 lg:w-3/4 my-20 hidden md:flex h-full">
            <h1 className="text-[#8691A3A6] text-[200px] font-extrabold text-center lg:text-[150px] xl:text-[200px] md:text-[70px]">
              Feel That Adrenaline Rush!
            </h1>
          </div>
        </div>
        <DownloadApp />
      </div>
    </>
  );
}

export default SignUp;
