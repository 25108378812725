import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Resources() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container md:scale-75 scale-90  flex flex-col gap-5 justify-center -my-9">
      <div className="md:flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">Resources</h1>
      </div>

      <p className=" text-justify md:text-center font-semibold text-2xl text-[#828D9E] ">
        Check out Sydney Oztag’s resources library. Learn about what is needed
        to claim an insurance claim and what is included. Find other policies
        developed by AOSA to assist, guide and support associations in an ever –
        increasing litigious society.
      </p>

      <div className="w-full md:flex gap-5">
        <Link
          to={"/insurance"}
          className="w-1/2 md:flex flex-col border rounded-xl cursor-pointer no-underline text-black"
        >
          <img src="/images/policies.png" alt="policies image" />
          <h2 className="text-3xl font-bold text-center py-3">Insurance</h2>
        </Link>
        <Link
          to={"/policies"}
          className="w-1/2 md:flex flex-col border rounded-xl cursor-pointer no-underline text-black"
        >
          <img src="/images/documents.png" alt="documents image" />
          <h2 className="text-3xl font-bold text-center py-3">
            Policies & Documents
          </h2>
        </Link>
      </div>
    </div>
  );
}

export default Resources;
