import React from "react";
import { Link } from "react-router-dom";
import DownloadApp from "../components/DownloadApp";

function TheGame() {
  return (
    <div className="container flex flex-col justify-center items-center mt-10">
      <div className="flex justify-center items-center w-full gap-6 flex-wrap">
        <Link
          to={"/about"}
          className="no-underline w-full md:w-[45%] bg-[#f3faff] rounded-lg h-[300px] relative cursor-pointer"
        >
          <h2 className="h2Semibold pt-16 pl-16">About Sydney OzTag</h2>
        </Link>

        <Link
          to={"/oztagrules"}
          className="no-underline w-full md:w-[45%] bg-[#f3faff] rounded-lg h-[300px] relative cursor-pointer"
        >
          <h2 className=" h2Semibold pt-16 pl-16">OzTag Rules</h2>
        </Link>
        <Link
          to="/wheretoplay"
          className="w-full md:w-[92%] bg-[#f3faff] rounded-lg h-[300px] relative cursor-pointer no-underline"
        >
          <h2 className="h2Semibold pt-16 pl-16">Where To Play</h2>
        </Link>
      </div>
      <DownloadApp />
    </div>
  );
}

export default TheGame;
