import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../api";
import UnallocatedPlayersModal from "./adminPanel/UnallocatedPlayersModal";
import UnAllocatedPlayers from "./adminPanel/unAllocatedPlayers";
import ScoreState from "./adminPanel/scoresState";

function AdminDashboard({ setSelectedPage, scoresNeedUpdate, scoreLoading }) {
  const [numberOfPlayers, setNumberOfPlayers] = useState(0);
  const [dahboardData, setDashboardData] = useState(0);
  const [allocate, setAllocate] = useState(false);
  const [cookies] = useCookies(["token"]);
  const [unAllocatedPlayers, setUnAllocatedPlayers] = useState("");
  const [unAllocateLoader, setunAllocateLoader] = useState(false);
  // console.log(cookies)
  const getNumberOfPlayers = async () => {
    const response = await api.get("auth/api/v1/getAllPlayers", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setNumberOfPlayers(response.data.length);
  };

  const getDashboardData = async () => {
    const response = await api.get("auth/api/v1/getDashboardData", {
      headers: { token: cookies.token },
    });
    // console.log("dashboard data", response.data);
    setDashboardData(response.data);
  };
  const getUnallocatedPlayers = async () => {
    try {
      setunAllocateLoader(true);
      const response = await api.get("auth/api/v1/getUnallocatedPlayers", {
        headers: { token: cookies.token },
      });
      setUnAllocatedPlayers(response.data);
      setunAllocateLoader(false);
    } catch (error) {
      console.log(error.massage);
      setunAllocateLoader(false);
    }
  };

  useEffect(() => {
    getNumberOfPlayers();
    getDashboardData();
    getUnallocatedPlayers();
  }, []);
  useEffect((res) => {
    api
      .get("/auth/api/v1/getDashboardData", {
        headers: { token: cookies.token },
      })
      .then((res) => {
        // console.log("response", res);
      })
      .catch((err) => {});
  }, []);
  // console.log(unAllocatedPlayers.length);
  return (
    <>
      <UnallocatedPlayersModal show={allocate} setShow={setAllocate} />
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-75 -my-20 -mx-20">
        <div className="flex gap-5 flex-row ">
          <div className="w-3/5   relative border rounded-lg pt-10 pl-10  transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <ScoreState
              setSelectedPage={setSelectedPage}
              numberOfScores={scoresNeedUpdate}
              loading={scoreLoading}
            />
            <svg
              width="134"
              height="165"
              viewBox="0 0 134 165"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="float-right"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.0004 0.636368C44.3144 0.636368 0.636719 44.314 0.636719 98C0.636719 151.686 44.3144 195.364 98.0004 195.364C151.686 195.364 195.364 151.686 195.364 98C195.364 44.314 151.686 0.636368 98.0004 0.636368ZM98.0004 15.2409C143.793 15.2409 180.759 52.2069 180.759 98C180.759 143.793 143.793 180.759 98.0004 180.759C52.2072 180.759 15.2413 143.793 15.2413 98C15.2413 52.2069 52.2072 15.2409 98.0004 15.2409ZM98.0004 44.45C81.9555 44.45 68.7913 57.6142 68.7913 73.6591V76.0932C68.754 78.7266 71.0486 83.5102 76.6587 83.5102C82.2688 83.5102 82.2688 78.7266 82.2316 76.0932V73.6591C82.2316 65.5091 87.8477 59.0545 98.0004 59.0545C108.153 59.0545 112.605 68.264 112.605 73.6591C112.605 80.5782 109.742 86.7193 104.447 90.5646C100.81 93.2062 99.3023 94.1742 96.393 98.1381C93.4837 102.102 91.5533 107.628 91.5533 114.254C91.5161 116.888 92.0444 120.122 94.3191 121.449C96.5937 122.777 99.4667 122.725 100.81 122.311C102.152 121.898 104.961 119.178 105.303 115.039C105.645 110.9 110.022 104.308 111.22 102.676C112.418 101.043 114.493 99.4231 117.372 97.161C123.13 92.6368 127.209 86.4031 127.209 73.6591C127.209 60.9151 114.045 44.45 98.0004 44.45ZM98.0004 135.806C92.6231 135.806 88.264 140.165 88.264 145.542C88.264 150.92 92.6231 155.279 98.0004 155.279C103.378 155.279 107.737 150.92 107.737 145.542C107.737 140.165 103.378 135.806 98.0004 135.806Z"
                fill="#828D9E"
              />
            </svg>
          </div>
          <div className="w-2/5 border rounded-lg p-10 flex flex-col items-center transition-shadow	duration-500 ease-in-out  hover:shadow-xl ">
            <h2>{dahboardData?.ordersToFulfill}</h2>
            <h5 className="h5Regular">Orders To Fulfill</h5>
            <hr />
            <h5
              className="cursor-pointer"
              onClick={() => setSelectedPage("Order")}
            >
              Go To Shop
            </h5>
          </div>
        </div>
        {/* <div className="w-full border rounded-lg gap-5 pt-10 pl-10 relative transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
          <h3 className="h3semibold ">
            Missing Information in the following sections. Update now!
          </h3>

          <ul className="list-disc absolute bottom-10">
            <li className="h3semibold">Mens - Div 2</li>
            <li className="h3semibold">Womens - Div 1</li>
            <li className="h3semibold">Bankstown Sports Team</li>
          </ul>
          <svg
            width="148"
            height="196"
            viewBox="0 0 148 196"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="float-right"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M92.0004 0.636368C97.9752 0.636368 102.819 5.47983 102.819 11.4545L102.83 17.6321C139.523 22.8861 167.728 54.4447 167.728 92.5909V152.091H175.841C180.322 152.091 183.955 155.723 183.955 160.205C183.955 164.686 180.322 168.318 175.841 168.318L112.955 168.318C113.4 170.047 113.637 171.859 113.637 173.727C113.637 185.677 103.95 195.364 92.0004 195.364C80.051 195.364 70.3641 185.677 70.3641 173.727C70.3641 171.859 70.6008 170.047 71.0458 168.318L8.15953 168.318C3.6785 168.318 0.0458984 164.686 0.0458984 160.205C0.0458984 155.723 3.6785 152.091 8.15953 152.091H16.2732V92.5909C16.2732 54.4409 44.4838 22.8799 81.1823 17.6305V11.4545C81.1823 5.47983 86.0257 0.636368 92.0004 0.636368ZM92.0004 33.0909C59.941 33.0909 33.8036 58.4463 32.5477 90.1979L32.5004 92.5909V152.091H151.5V92.5909C151.5 59.73 124.861 33.0909 92.0004 33.0909Z"
              fill="#828D9E"
            />
          </svg>
        </div> */}
        <div className="flex gap-5 flex-row">
          <div className="w-3/5   relative border rounded-lg pt-10 pl-10 flex transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <div className="w-2/3">
              <UnAllocatedPlayers
                setAllocate={setAllocate}
                numberOfPlayers={unAllocatedPlayers.length}
                players={unAllocatedPlayers}
                loader={unAllocateLoader}
              />
            </div>
            <div className="w-1/3">
              <svg
                width="137"
                height="169"
                viewBox="0 0 137 169"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 "
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M152.477 119.636C173.389 119.636 195.364 136.588 195.364 157.5V173.727C195.364 185.677 185.677 195.364 173.728 195.364H22.2731C10.3236 195.364 0.636719 185.677 0.636719 173.727V157.5C0.636719 136.588 23.0271 119.636 43.9386 119.636H152.477ZM43.8689 135.864C26.4205 135.864 17.7493 144.69 16.9233 155.885L16.864 157.5V168.318C16.864 173.866 21.0402 178.439 26.4205 179.064L27.6822 179.136H168.319C173.866 179.136 178.439 174.96 179.064 169.58L179.137 168.318V157.5C179.137 146.094 168.319 135.777 152.368 135.864C136.417 135.95 43.8689 135.864 43.8689 135.864ZM98.0004 0.636353C127.874 0.636353 152.091 24.8537 152.091 54.7273C152.091 84.6008 127.874 108.818 98.0004 108.818C68.1268 108.818 43.9094 84.6008 43.9094 54.7273C43.9094 24.8537 68.1268 0.636353 98.0004 0.636353ZM98.0004 16.8636C77.0888 16.8636 60.1367 33.8157 60.1367 54.7273C60.1367 75.6388 77.0888 92.5909 98.0004 92.5909C118.912 92.5909 135.864 75.6388 135.864 54.7273C135.864 33.8157 118.912 16.8636 98.0004 16.8636Z"
                  fill="#828D9E"
                />
              </svg>
            </div>
          </div>
          <div className="w-2/5 border rounded-lg p-10 flex flex-col items-center transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <h2>{dahboardData?.registersPlayers}</h2>
            <h5 className="h5Regular">Registered Players</h5>
            <hr />
            <h5
              className="cursor-pointer"
              onClick={() => setSelectedPage("players")}
            >
              Go To Player List
            </h5>
          </div>
        </div>
        <div className="flex gap-5 flex-row">
          <div className="w-2/5 border rounded-lg p-10 flex flex-col items-center transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <h2>{dahboardData?.regesteredTeams}</h2>
            <h5 className="h5Regular">Registered Teams</h5>
            <hr />
            <h5
              className="cursor-pointer"
              onClick={() => setSelectedPage("teams")}
            >
              Go To Team List
            </h5>
          </div>
          <div className="w-2/5 border rounded-lg p-10 flex flex-col items-center transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <h2>{dahboardData?.gamesPlayed}</h2>
            <h5 className="h5Regular">Games Played</h5>
            <hr />
            <h5
              className="cursor-pointer"
              onClick={() => setSelectedPage("riveraly")}
            >
              Go To Competitions
            </h5>
          </div>
          <div className="w-2/5 border rounded-lg p-10 flex flex-col items-center transition-shadow	duration-500 ease-in-out  hover:shadow-xl">
            <h2>{dahboardData?.goalsScored}</h2>
            <h5 className="h5Regular">Goals Scored</h5>
            <hr />
            <h5
              className="cursor-pointer"
              onClick={() => setSelectedPage("scores")}
            >
              Go To Scores
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
