import React, { useEffect, useState } from "react";
import "./MainHeader.scss";
import { Link, useNavigate } from "react-router-dom";
import { Select, Sidebar } from "flowbite-react";
// import Select from 'react-select'
import "react-dropdown/style.css";
import { CgMenu } from "react-icons/cg";
import { useCookies } from "react-cookie";
import { MdLogout } from "react-icons/md";
import { BsFacebook, BsInstagram, BsPerson } from "react-icons/bs";
import api from "../api";
import { useSelector } from "react-redux";
import RegPopup from "./auth/RegPopup";
import MenuButton from "./menuButtons";

function MainHeader() {
  const [cartProducts, setCartProducts] = useState([]);
  const state = useSelector((state) => state.cart);
  const [cookies, setCookie] = useCookies(["token"]);
  const path = window.location.pathname;

  const calculatSum = (cartProducts) => {
    const sumArray = [];
    cartProducts.forEach((item) => {
      sumArray.push(item.quantity);
    });
    // console.log("summ array", sumArray);
    return sumArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };

  useEffect(() => {
    if (cookies.token) {
      api
        .get("ecommerce/api/v1/cartList", { headers: { token: cookies.token } })
        .then((res) => {
          // console.log("response of cart", res.data.cart);
          setCartProducts(res.data.cart);
        });
    }
  }, [cookies.token]);

  useEffect(() => {
    setCartProducts(state);
  }, [state]);

  let navigate = useNavigate();
  // function handleChange(value) {
  //   navigate(`${value}`);
  //   value = "";
  // }

  const handleLogout = () => {
    setCookie("token", "");
    navigate("/");
  };
  const [regModal, setRegModal] = useState(false);

  const handleProfile = async () => {
    const newUser = await api.get("/auth/api/v1/validateToken", {
      headers: { token: cookies.token },
    });
    if (newUser.data.roleId === 1) navigate("/PlayerProfile");
    if (newUser.data.roleId === 2) navigate("/TeamProfile");
    if (newUser.data.roleId > 2) navigate("/admin");
  };
  const navlinks = [
    { title: "Home", path: "/" },
    { title: "Shop", path: `/shop` },
    { title: "The game", path: "/TheGame" },
    { title: "Competition", path: "/competition" },
    { title: "Tournments", path: "/tournments" },
    { title: "resources", path: "/resources" },
  ];
  const [showNav, setShowNav] = useState(false);
  // console.log(cookies.token);
  return (
    <>
      <RegPopup regModal={regModal} setRegModal={setRegModal} />
      <div className=" hidden md:flex flex-col justify-center  w-full  items-center">
        <div
          className="w-full flex justify-between px-14  items-center pt-2 "
          // style={{ scale: "0.8" }}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2 ">
              <BsFacebook size={16} opacity={0.5} />
              <span className="facebookText text-[12px]">Facebook</span>
            </div>
            <div className="flex items-center gap-2  ">
              <div>
                <BsInstagram size={16} opacity={0.5} />
              </div>
              <span className="twitterText text-[12px] ">Instagram</span>
            </div>
          </div>

          <div className="flex gap-5 items-center py-2">
            <div className="flex items-center">
              {cookies?.token ? (
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => handleProfile()}
                >
                  <BsPerson size={16} opacity={0.5} />
                  <span className="no-underline text-[#828D9E] text-sm">
                    Profile
                  </span>
                </div>
              ) : (
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => setRegModal(true)}
                >
                  <BsPerson size={16} opacity={0.5} />
                  <span className="no-underline text-[#828D9E] text-sm">
                    Register
                  </span>
                </div>
              )}
            </div>

            <div className="flex items-center">
              {cookies?.token ? (
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => handleLogout()}
                >
                  <MdLogout size={16} opacity={0.5} />
                  <span className="no-underline text-[#828D9E] text-sm">
                    Logout
                  </span>
                </div>
              ) : (
                <>
                  <div>
                    <Link to="/login">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.0357 13C17.9687 13 20 14.567 20 16.5V18C20 19.1046 19.1046 20 18 20H4C2.89543 20 2 19.1046 2 18V16.5C2 14.567 4.0697 13 6.0027 13H16.0357ZM5.99626 14.5C4.38338 14.5 3.58183 15.3159 3.50549 16.3507L3.5 16.5V17.5C3.5 18.0128 3.88604 18.4355 4.38338 18.4933L4.5 18.5H17.5C18.0128 18.5 18.4355 18.114 18.4933 17.6166L18.5 17.5V16.5C18.5 15.4456 17.5 14.492 16.0256 14.5C14.5511 14.508 5.99626 14.5 5.99626 14.5ZM11 2C13.7614 2 16 4.23858 16 7C16 9.76142 13.7614 12 11 12C8.23858 12 6 9.76142 6 7C6 4.23858 8.23858 2 11 2ZM11 3.5C9.067 3.5 7.5 5.067 7.5 7C7.5 8.933 9.067 10.5 11 10.5C12.933 10.5 14.5 8.933 14.5 7C14.5 5.067 12.933 3.5 11 3.5Z"
                          fill="#828D9E"
                        />
                      </svg>
                    </Link>
                    <Link
                      to="/login"
                      className="no-underline text-[#828D9E] h5Normal"
                    >
                      Login
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <hr className="mt-0 mb-1 w-full" />
        <div
          className="w-full flex justify-center items-center "
          style={{ scale: "0.8" }}
        >
          <div className="flex w-4/5 max-w-full items-center m-auto justify-between ">
            <img src="./oztagLogoHeader.png" alt="" className="w-fit" />
            {/* <Select options={options} /> */}
            {/* <Select className='w-1/5'>
                        <option value="0" selected>All</option>
                    </Select> */}
            {/* <input type="text" placeholder='Search' className='input w-2/5' /> */}
            {/* <select className='input'>
                    <option value="0" selected>AUD</option>
                </select> */}
            <div className="flex items-center">
              <Link
                to={"/ShoppingCart"}
                className="flex gap-2 items-center no-underline cursor-pointer"
              >
                <svg
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="51"
                    height="51"
                    rx="7.5"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.9076 22.8382C36.978 22.8382 37.0482 22.8437 37.1176 22.8545C37.8086 22.9622 38.297 23.5711 38.2704 24.2533L38.255 24.4119L36.4578 35.9419C36.0994 37.7525 34.5446 38.2044 32.6759 38.2651L32.1819 38.2727H19.6472L19.1532 38.2651C17.3659 38.207 15.8662 37.7906 15.4248 36.1704L15.3713 35.9419L13.7404 24.3925C13.7315 24.3294 13.7271 24.2657 13.7271 24.2019C13.7271 23.5026 14.2535 22.9262 14.9317 22.8474L15.0907 22.8382H36.9076ZM35.5991 24.75H16.7113C16.6808 24.75 16.6503 24.752 16.6201 24.7561C16.2884 24.8009 16.0448 25.0772 16.0301 25.4002L16.0357 25.5231L17.3445 35.211C17.5086 36.036 18.2475 36.2606 19.0964 36.3201L19.4656 36.3368L19.8652 36.3409H32.182C33.3137 36.3409 34.2731 36.2797 34.4847 35.211L36.2698 25.5548C36.2773 25.5142 36.281 25.473 36.281 25.4318C36.281 25.0552 35.9757 24.75 35.5991 24.75ZM30.6945 27.7329C31.2593 27.7329 31.7172 28.1908 31.7172 28.7557V32.1648C31.7172 32.7296 31.2593 33.1875 30.6945 33.1875C30.1297 33.1875 29.6718 32.7296 29.6718 32.1648V28.7557C29.6718 28.1908 30.1297 27.7329 30.6945 27.7329ZM25.9218 27.7329C26.4866 27.7329 26.9445 28.1908 26.9445 28.7557V32.1648C26.9445 32.7296 26.4866 33.1875 25.9218 33.1875C25.3569 33.1875 24.899 32.7296 24.899 32.1648V28.7557C24.899 28.1908 25.3569 27.7329 25.9218 27.7329ZM21.149 27.7329C21.7139 27.7329 22.1718 28.1908 22.1718 28.7557V32.1648C22.1718 32.7296 21.7139 33.1875 21.149 33.1875C20.5842 33.1875 20.1263 32.7296 20.1263 32.1648V28.7557C20.1263 28.1908 20.5842 27.7329 21.149 27.7329ZM25.9316 13.7274C26.2083 13.7238 26.486 13.8164 26.6969 14.0053L32.1935 18.9273C32.6075 19.2979 32.6075 19.8989 32.1935 20.2696C31.7795 20.6403 31.1084 20.6403 30.6944 20.2696L25.9327 16.0042L21.1703 20.2696C20.7563 20.6403 20.0852 20.6403 19.6712 20.2696C19.2572 19.8989 19.2572 19.2979 19.6712 18.9273L25.1678 14.0053C25.3784 13.8167 25.6555 13.7241 25.9316 13.7274Z"
                    fill="#828D9E"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="51"
                    height="51"
                    rx="7.5"
                    stroke="#E2E5EA"
                    strokeMiterlimit="0"
                    strokeLinejoin="bevel"
                  />
                </svg>
                {cookies.token ? (
                  <label className="bg-[#00b5ff] text-center text-white rounded-full w-6 h-6 items-center -m-2 -mt-12 -ml-4 ">
                    {calculatSum(cartProducts)}
                  </label>
                ) : (
                  ""
                )}
                <label className=" font-inter font-semibold text-[#1e354e]   text-[16px]">
                  Shopping Cart
                </label>
                {/*<label className='cartPrice text-[12px]'>$0.00</label>*/}
              </Link>
            </div>{" "}
          </div>
        </div>
        <hr className="mt-1 mb-1 w-full" />
        <div
          className=" flex m-auto justify-center items-center w-full "
          style={{ scale: "0.8" }}
        >
          <ul className="flex gap-10 h-8 shrink-0  ">
            {/* <ul className='tableNav mb-0'> */}
            <li className="  mr-32">
              {/* <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '22px ' }} >
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.1562 15C19.6222 15 20 15.3358 20 15.75C20 16.1642 19.6222 16.5 19.1562 16.5H2.84375C2.37776 16.5 2 16.1642 2 15.75C2 15.3358 2.37776 15 2.84375 15H19.1562ZM19.1562 10.5C19.6222 10.5 20 10.8358 20 11.25C20 11.6642 19.6222 12 19.1562 12H2.84375C2.37776 12 2 11.6642 2 11.25C2 10.8358 2.37776 10.5 2.84375 10.5H19.1562ZM19.1562 6C19.6222 6 20 6.33579 20 6.75C20 7.16421 19.6222 7.5 19.1562 7.5H2.84375C2.37776 7.5 2 7.16421 2 6.75C2 6.33579 2.37776 6 2.84375 6H19.1562Z" fill="#242A33" />
                                </svg> */}

              <Link
                to="/shop"
                className={`flex items-center  gap-2 no-underline ${
                  path === "/shop" ? "border-b-2" : "text-[#1E354E]"
                }  text-[16px] font-semibold  hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                <CgMenu className=" text-[#1E354E] " />
                Shop
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={` flex items-center  gap-2 no-underline ${
                  path === "/" ? "border-b-2" : "text-[#1E354E]"
                } no-underline  text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/TheGame"
                className={` ${
                  path === "/TheGame" ? "border-b-2" : "text-[#1E354E]"
                }  no-underline text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                The Game
              </Link>
            </li>
            <li>
              {/* <label >Competition</label> */}
              <Link
                to="/competition"
                className={` ${
                  path === "/competition" ? "border-b-2" : "text-[#1E354E]"
                }  no-underline  text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                Competition
              </Link>
            </li>
            <li>
              <Link
                to="/tournments"
                className={`${
                  path === "/tournments" ? "border-b-2" : "text-[#1E354E]"
                } no-underline  text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                Tournments
              </Link>
            </li>
            <li>
              <Link
                to={"/podcast"}
                className={` ${
                  path === "/podcast" ? "border-b-2" : "text-[#1E354E]"
                } no-underline  text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                Podcast
              </Link>
            </li>
            <li>
              <Link
                to={"/resources"}
                className={` ${
                  path === "/resources" ? "border-b-2" : "text-[#1E354E]"
                } no-underline  text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all`}
              >
                Resources
              </Link>
            </li>
          </ul>
        </div>
        <hr className="mt-1 mb-1 w-full" />
      </div>
      <div className=" block md:hidden lg:shadow-none sticky w-full left-0 top-0 z-20 shadow-md">
        <div className="px-[%] sm:px-[3%] 2xl:max-w-[auto] 2xl:mx-auto bg-[#f8fcff] relative z-50">
          <div className="flex flex-row items-center gap-3  lg:gap-10 lg:pt-5 py-3 pr-5 lg:pr-10">
            <div className="cursor-pointer px-0">
              <img
                src={`${"./oztagLogoHeader.png"}`}
                alt="logo"
                className="hidden md:hidden lg:inline-block w-[100px] h-[21px]"
                width={339}
                height={54}
                loading="lazy"
              />

              {/* *********************************************************************************** */}
            </div>
            <div className="lg:hidden w-full justify-between flex items-center">
              <div className="">
                <img
                  src="./oztagLogoHeader.png"
                  className=" w-[150px]"
                  alt=""
                />
              </div>

              <div className="flex  w-36 justify-between">
                <div>
                  <svg
                    onClick={() => {
                      navigate("/ShoppingCart");
                    }}
                    width="48"
                    height="48"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`inline cursor-pointer`}
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="51"
                      height="51"
                      rx="7.5"
                      fill="none"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.9076 22.8382C36.978 22.8382 37.0482 22.8437 37.1176 22.8545C37.8086 22.9622 38.297 23.5711 38.2704 24.2533L38.255 24.4119L36.4578 35.9419C36.0994 37.7525 34.5446 38.2044 32.6759 38.2651L32.1819 38.2727H19.6472L19.1532 38.2651C17.3659 38.207 15.8662 37.7906 15.4248 36.1704L15.3713 35.9419L13.7404 24.3925C13.7315 24.3294 13.7271 24.2657 13.7271 24.2019C13.7271 23.5026 14.2535 22.9262 14.9317 22.8474L15.0907 22.8382H36.9076ZM35.5991 24.75H16.7113C16.6808 24.75 16.6503 24.752 16.6201 24.7561C16.2884 24.8009 16.0448 25.0772 16.0301 25.4002L16.0357 25.5231L17.3445 35.211C17.5086 36.036 18.2475 36.2606 19.0964 36.3201L19.4656 36.3368L19.8652 36.3409H32.182C33.3137 36.3409 34.2731 36.2797 34.4847 35.211L36.2698 25.5548C36.2773 25.5142 36.281 25.473 36.281 25.4318C36.281 25.0552 35.9757 24.75 35.5991 24.75ZM30.6945 27.7329C31.2593 27.7329 31.7172 28.1908 31.7172 28.7557V32.1648C31.7172 32.7296 31.2593 33.1875 30.6945 33.1875C30.1297 33.1875 29.6718 32.7296 29.6718 32.1648V28.7557C29.6718 28.1908 30.1297 27.7329 30.6945 27.7329ZM25.9218 27.7329C26.4866 27.7329 26.9445 28.1908 26.9445 28.7557V32.1648C26.9445 32.7296 26.4866 33.1875 25.9218 33.1875C25.3569 33.1875 24.899 32.7296 24.899 32.1648V28.7557C24.899 28.1908 25.3569 27.7329 25.9218 27.7329ZM21.149 27.7329C21.7139 27.7329 22.1718 28.1908 22.1718 28.7557V32.1648C22.1718 32.7296 21.7139 33.1875 21.149 33.1875C20.5842 33.1875 20.1263 32.7296 20.1263 32.1648V28.7557C20.1263 28.1908 20.5842 27.7329 21.149 27.7329ZM25.9316 13.7274C26.2083 13.7238 26.486 13.8164 26.6969 14.0053L32.1935 18.9273C32.6075 19.2979 32.6075 19.8989 32.1935 20.2696C31.7795 20.6403 31.1084 20.6403 30.6944 20.2696L25.9327 16.0042L21.1703 20.2696C20.7563 20.6403 20.0852 20.6403 19.6712 20.2696C19.2572 19.8989 19.2572 19.2979 19.6712 18.9273L25.1678 14.0053C25.3784 13.8167 25.6555 13.7241 25.9316 13.7274Z"
                      fill={` ${
                        path === "/ShoppingCart" ? "#1E354E" : "#828D9E"
                      }`}
                    />
                  </svg>
                  {cookies.token ? (
                    <label
                      className={` bg-[#00b5ff] text-center text-white rounded-full w-6 h-6 items-center -ml-4 -mt-24 `}
                    >
                      {calculatSum(cartProducts)}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div onClick={() => setShowNav(!showNav)}>
                  {" "}
                  <MenuButton />
                </div>
              </div>
            </div>

            {/* <div
              className="lg:hidden ml-auto "
              onClick={() => setShowNav(!showNav)}
            >
              <MenuButton />
            </div> */}
            {/*  */}
          </div>
        </div>

        {/* nav small screen */}
        <div
          className="w-full h-full -z-10"
          //   style={{ baclgrounColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <Sidebar
            className={`fixed top-0 left-0 translation-all duration-300  ${
              showNav ? "w-full" : "w-0 -translate-x-80"
            }`}
          >
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                {navlinks.map((item) => {
                  return (
                    <Sidebar.Item
                      key={navlinks.indexOf(item)}
                      className="border-b-2 no-underline"
                      href={item.path}
                    >
                      {/* <Link
                        key={item.title}
                        to={item.path}
                        className="text-black"
                        onClick={()=>setShowNav(false)}
                        style={{ textDecoration: 'none', color: 'white' }}
                      >
                      </Link> */}
                      {item.title}
                    </Sidebar.Item>
                  );
                })}
                {cookies.token ? (
                  <>
                    <Sidebar.Item
                      className={`border-b-2 no-underline`}
                      href={"/admin"}
                    >
                      <svg
                        className=" mr-4 w-6 h-6 text-neutral-600 dark:text-white inline"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth=""
                          d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>{" "}
                      Profile{" "}
                    </Sidebar.Item>
                    <Sidebar.Item
                      className={`border-b-2 no-underline`}
                      href={"/"}
                      onClick={handleLogout}
                    >
                      <svg
                        className="inline mr-4"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z"
                          fill="#777777"
                        />
                      </svg>
                      Logout
                    </Sidebar.Item>
                  </>
                ) : (
                  <Sidebar.Item
                    className={`border-b-2 no-underline`}
                    href={"/login"}
                  >
                    {" "}
                    Login{" "}
                  </Sidebar.Item>
                )}
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </div>
      </div>
    </>
  );
}

export default MainHeader;
