import React, { useEffect } from "react";

function AboutOztag() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container scale-75 -my-96 flex flex-col gap-5">
      <img src="/images/aboutoztag.png" alt="about image" />
      <p className="text-xl font-semibold leading-10">
        Sydney Oztag is an organisation that aims to provide opportunities for
        people to participate at all levels, by developing, promoting and
        managing the game of Oztag in Sydney. We encourage females and males
        from ages 6 to 60 to join our community.
      </p>
      <p className="text-xl font-semibold leading-10">
        We want to raise awareness about the growing popularity of the sport in
        Australia, which has several competitions in different cities such as
        Sydney, Brisbane, and Canberra. And, we also want to provide
        opportunities for people to participate at all levels of Oztag.
      </p>
      <p className="text-xl font-semibold leading-10">
        Sydney Oztag runs junior and senior competitions in Sydney City,
        Canterbury, Homebush, Ryde/Eastwood, Meadowbank, Carlingford, St Ives,
        Hornsby and Dural.
      </p>
      <p className="text-xl font-semibold leading-10">
        Oztag was created in 1992 by former NRL Player and Coach Perry Haddock.
        Oztag is a non-contact version of Rugby League.
      </p>
      <div className="flex flex-col items-center">
        <h1 className="text-[40px] font-medium text-[#1E354E]">Play Oztag</h1>
        <p className="text-base font-medium text-[#828D9E]">
          Oztag is a non-tackling, fun and safe game for all ages, fitness
          levels and genders.
        </p>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className=" w-full gap-3 flex flex-col  ">
          <h3 className="text-2xl font-semibold">Fun</h3>
          <p className="text-base font-normal text-[#828D9E]">
            Oztag is a FUN, fast-paced and energetic sport!
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            It's a sport that is great to watch, easy to play and safe for
            everyone regardless of age or ability.The attraction of Oztag is
            that there is no training, no volunteer duties and team coaches
            aren’t required.
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Oztag competitions are currently run in midweek after school/work in
            both summer and winter
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            It's a sport that is great to watch, easy to play and safe for
            everyone regardless of age or ability. Many friends, family and work
            colleagues form teams primarily for fun and fitness.
          </p>
          <h3 className="text-lg font-semibold">Ready to have some fun?</h3>
        </div>
        <div className="w-full  ">
          <img src="/images/about1.png" alt="about image" />
        </div>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full order-2 lg:order-1 ">
          <img src="/images/about2.png" alt="about image" />
        </div>
        <div className="w-full gap-3 flex flex-col lg:order-2 ">
          <h3 className="text-2xl font-semibold">Get Fit</h3>
          <p className="text-base font-normal text-[#828D9E]">
            Oztag is designed to allow players to fully participate in a
            non-contact version of rugby league. Oztag is a non-contact sport
            which is team oriented.
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Each game can be as challenging as you want. Chase someone to make a
            Tag OR chase the ball off a kick off.{" "}
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Go for a run with the ball & score a try. The great thing is you can
            work Oztag into whatever workouts because it focuses on Passing,
            catching, tagging, evasive and kicking .{" "}
          </p>

          <h3 className="text-lg font-semibold">
            Start Oztag today to get that base level of fitness you have always
            been chasing.
          </h3>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full gap-3 flex flex-col">
          <h3 className="text-2xl font-semibold">Safe</h3>
          <p className="text-base font-normal text-[#828D9E]">
            Oztag is a limited contact sport that has no tackling. The aim is to
            grab the ‘TAG’ from the side of your opponent's shorts to stop them
            from scoring a try.
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Strict rules regulate the game in regards to contact so you can be
            assured safety is one of Oztags priorities{" "}
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Oztag aims to reduce gender discrimination, encourage fitness, and
            be a sport which is inclusive and enjoyable for all levels{" "}
          </p>
          <p className="text-base font-normal text-[#828D9E]">
            Looking for something to do in your spare time? Look no further,
            join Oztag Family! Come down on your own or as part of a team, we
            are looking forward to seeing you.Play Oztag Today
          </p>
        </div>
        <div className="w-full">
          <img src="/images/about3.png" alt="about image" />
        </div>
      </div>

      <div className="flex flex-col items-center w-full">
        <h1 className="text-[40px] font-medium text-[#1E354E]">
          Contact Us Now
        </h1>
        <div className="flex md:flex-row flex-col w-full  items-center justify-center mt-5 gap-5">
          <div className="flex flex-col w-full md:w-1/5 gap-2">
            <label className="text-base font-semibold" htmlFor="YourName">
              Your Name
            </label>
            <input
              type="text"
              name="YourName"
              id="YourName"
              className="input"
              placeholder="Name"
            />
            <label className="text-base font-semibold" htmlFor="EmailAddress">
              Email Address
            </label>
            <input
              type="email"
              name="EmailAddress"
              id="EmailAddress"
              className="input"
              placeholder="Email"
            />
          </div>
          <div className="flex flex-col w-full md:w-2/5 gap-2">
            <label className="text-base font-semibold" htmlFor="YourMessage">
              Your Message
            </label>
            <textarea
              name="YourMessage"
              id="YourMessage"
              rows={6}
              className="input"
              placeholder="Email"
            />
          </div>
        </div>
        <button className="input bg-[#00B5FF] text-white my-4">
          Send Message
        </button>
      </div>
    </div>
  );
}

export default AboutOztag;
