import React, { Fragment, useEffect, useState } from "react";
import NewPlayerForm from "./NewPlayerForm";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import api from "../api";
import { failNotification } from "./Notifications";

export const PhoneRegExp =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gm;

function TeamRegForm3({
  currentStep = 0,
  players = [],
  setPlayers,
  teamLeader,
  setCurrentStep,
}) {
  let columns = [
    {
      dataField: "firstName",
      text: "First Name",
      style: { fontSize: "small" },
    },
    { dataField: "lastName", text: "Last Name", style: { fontSize: "small" } },
    { dataField: "email", text: "E-mail", style: { fontSize: "small" } },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      style: { fontSize: "small" },
    },
    { dataField: "address", text: "Address", style: { fontSize: "small" } },
    {
      dataField: "dateOfBirth",
      text: "Date Of Birth",
      style: { fontSize: "small" },
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {/* <FiEdit size={20} className='cursor-pointer hover:text-green-500 text-xl mt-1' onClick={() => openModal()} /> */}
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRowRemove(row)}
            />
          </div>
        );
      },
    },
  ];
  const handleRowRemove = (playerData) => {
    let newarr = players.filter((player) => player.email != playerData.email);
    setPlayers([...newarr]);
  };

  const { SearchBar } = Search;
  let [isOpen, setIsOpen] = useState(false);
  const [readTerms, setReadTerms] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const notifyinfo = (msg) =>
    toast.info(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values)
      if (readTerms) {
        // setCurrentStep(currentStep + 1)
        try {
          const email = await api.get("/auth/api/v1/checkEmail", {
            params: { email: values.email },
          });

          var myarr = players.filter((player) => player.email == values.email);
          if (myarr.length > 0 || values.email == teamLeader.email) {
            notifyinfo("you have already registered this email.");
          } else {
            setPlayers([...players, values]);
            closeModal();
            formik.resetForm();
          }
          // setTeamLeader(values)
          // setCurrentStep(currentStep+1)
        } catch (error) {
          // console.log(error.response.data)
          failNotification(error.response.data);
          // notifyerror(error.response.data)
        }
        // setPlayers([...players, values])
      } else {
        notifyinfo("Please check the terms of Insurance and  Regulations");
      }
      // console.log(values);

      // const newUser = await api.post('/auth/api/v1/SignUp', values)
      // if (newUser.data.errors) {
      //     // notifyerror(newUser.data.errors[0].msg)
      // } else {
      //     // notifysuccess('Register Complete Successfully')
      //     // setCookie('token', newUser.data.token);
      //     // console.log(cookies.token)
      // }
    },
  });

  return (
    <div className={currentStep !== 3 ? "hidden" : "w-5/6"}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add New Player
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstName"
                          name="firstName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-500">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="lastName"
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-500">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="email"
                        >
                          E-mail
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="email"
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Address
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="address"
                          name="address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-red-500">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="dateOfBirth"
                        >
                          Date Of Birth
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfBirth}
                        />
                        {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth ? (
                          <div className="text-red-500">
                            {formik.errors.dateOfBirth}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="text-red-500">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full flex flex-col col-span-2">
                        <div className="flex flex-row my-5 items-center">
                          <input
                            checked={readTerms}
                            onChange={() => setReadTerms(!readTerms)}
                            type="checkbox"
                            className="w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
                          />
                          <label className="font-medium text-base ml-3 ">
                            This player has read & agreed to the insurance
                            Condititions of Play & the Competition Regulations
                          </label>
                        </div>
                      </div>
                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <h1 className="font-semibold text-4xl my-5">
        Players Details
        <span className="font-semibold text-2xl mx-2">(optional)</span>
      </h1>

      {/* <BootstrapTable keyField='id' data={data} columns={columns} /> */}
      <ToolkitProvider keyField="id" data={players} columns={columns} search>
        {(props) => (
          <div>
            <div className="flex w-full justify-between">
              <SearchBar {...props.searchProps} className="input" />
              <button className="normalButton" onClick={() => openModal()}>
                Add Player
              </button>
            </div>
            <hr />
            <BootstrapTable striped condensed {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>

      <div className="w-full flex gap-5 justify-center col-span-2 items-center">
        <button
          className="normalButton w-fit"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </button>
        <button
          type="submit"
          className="normalButton w-fit"
          onClick={() => setCurrentStep(currentStep + 1)}
        >
          Next
        </button>
      </div>

      {/* {numberOfPlayers.map((player, i) => <NewPlayerForm playerNum={i + 1} />)}
            <div className='flex my-5 items-center'>
                <button className='normalButton mr-5' onClick={() => setNumberOfPlayers([...numberOfPlayers, [0]])}>+</button>
                <span className='font-semibold text-2xl '>Add Player</span>
            </div> */}
    </div>
  );
}

export default TeamRegForm3;
