import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import AddSeason from "./adminPanel/AddSeason";
import { useCookies } from "react-cookie";
import api from "../api";
import "bootstrap/dist/css/bootstrap.css";
import ReactLoading from "react-loading";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

// {
//         headers: { token: cookies.token },
//       }
function AdminSeasons() {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const cancelButtonRef = useRef(null);
  const [Rivelries, setRivelries] = useState([]);
  const [cookies] = useCookies(["token"]);
  const [rivFilter, setRivFilter] = useState();
  const [games, setGames] = useState([]);
  const getAllRivelries = async () => {
    const response = await api.get("rivelary/api/v1/getRivelaries", {
      params: rivFilter,
    });
    // console.log(response.data);
    setRivelries(response.data);
  };

  useEffect(() => {
    getAllRivelries();
  }, []);
  useEffect(() => {
    api
      .get("games/api/v1/getSeasonGames", {
        params: { _id: "640e041fac392b00dc649310" },
      })
      .then((res) => {})
      .catch((err) => {});
  }, []);

  const getGamesOfSeason = (id) => {
    api(`games/api/v1/getSeasonGames?_id=${id}`)
      .then((res) => {
        // console.log("response games", res.data);
        setGames(res.data);
      })
      .catch((err) => {});
  };
  let columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      dataField: "field.fieldName",
      text: "Compitation",
      sort: true,
    },
    {
      dataField: "firstTeam.teamName",
      text: "First team",
      sort: true,
    },
    {
      dataField: "firstTeamScore",
      text: "First Score ",
      sort: true,
    },
    {
      dataField: "secondTeam.teamName",
      text: "Second team",
      sort: true,
    },
    {
      dataField: "secondTeamScore",
      text: "Second Score",
      sort: true,
    },
    {
      dataField: "finished",
      text: "Status",
      sort: true,
      formatter: (cell, row) => {
        // console.log("row", row);
        return <p>{row.finished === true ? "Finished" : "Not-finished"} </p>;
      },
    },

    // {
    //   text: "Actions",
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="flex justify-center gap-5 items-start">
    //         {/* <FiEdit
    //           size={20}
    //           className="cursor-pointer hover:text-green-500 text-xl mt-1"
    //           onClick={() => handleEdit(row)}
    //         /> */}
    //       </div>
    //     );
    //   },
    // },
  ];

  const { SearchBar } = Search;

  return (
    <>
      <AddSeason
        show={open}
        setShow={setOpen}
        getAllRivelries={getAllRivelries}
      />
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[4/5] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {/* {type == "add" ? "Add Season" : "Edit Field"} */}
                  </Dialog.Title>
                  <div className="mt-2">
                    <ToolkitProvider
                      keyField="_id"
                      data={games}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="flex w-full justify-between">
                            <SearchBar
                              {...props.searchProps}
                              className="input "
                            />
                          </div>
                          <hr />
                          <BootstrapTable
                            classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                            pagination={paginationFactory({
                              showTotal: true,
                            })}
                            hover={true}
                            striped
                            bordered={true}
                            loading={true}
                            condensed
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {Rivelries.length > 0 ? (
        <div className="flex flex-col w-4/5 -mx-20 scale-90">
          <div className="flex w-full flex-row-reverse h-fit py-3">
            <button
              className="input bg-[#0FA9E8] text-white"
              onClick={() => setOpen(true)}
            >
              Add Season
            </button>
          </div>
          <div className="flex w-full">
            <table className="table ">
              <thead>
                <tr>
                  <th className="p-3">Comptetion Name</th>
                  <th className="p-3">Seasons</th>
                </tr>
                {/* <th>Matches</th> */}
              </thead>
              <tbody>
                {Rivelries?.map((item) => {
                  return (
                    <tr className="">
                      <td className="w-1/3 align-middle border">
                        <div className=" text-[#1E354E] font-semibold text-2xl flex flex-col items-center  ">
                          {item.rivelaryName}
                        </div>
                      </td>
                      {item.Seasons?.map((season) => {
                        return (
                          <tr className="w-full flex justify-between border">
                            <td className="text-[#828D9E]">
                              {season.seasonName}
                            </td>
                            <td
                              className="text-[#828D9E] cursor-pointer border-b-2"
                              onClick={() => {
                                getGamesOfSeason(season._id);
                                setShow(true);
                              }}
                            >
                              View Matches
                            </td>
                          </tr>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </>
  );
}

export default AdminSeasons;
