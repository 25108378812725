
import { ActionTypes } from '../action-types'

const modalReducer = (state = { login: true, reg: false, main: false, addedit: false ,selectedTask:{}}, action) => {

    switch (action.type) {
        case ActionTypes.SHOWLOGINMODAL:
            return { ...state, login: true }
        case ActionTypes.CLOSELOGINMODAL:
            return { ...state, login: false }
        case ActionTypes.SHOWREGMODAL:
            return { ...state, reg: true }
        case ActionTypes.CLOSEREGMODAL:
            return { ...state, reg: false }
        case ActionTypes.SHOWTODOMAIN:
            return { ...state, main: true }
        case ActionTypes.CLOSETODOMAIN:
            return { ...state, main: false }
        case ActionTypes.SHOWADDEDIT:
            return { ...state, addedit: true }
        case ActionTypes.CLOSEADDEDIT:
            return { ...state, addedit: false }
        case ActionTypes.SETSELECTEDTASK:
            return { ...state, selectedTask: action.payload }
        default:
            return state
    }

}

export default modalReducer