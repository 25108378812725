import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit, FiFileText } from "react-icons/fi";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import api from "../../api";
import PaymentLogDetailsModal from "./PaymentLogDetailsModal";
import Loader from "../loader";

function AdminPaymentLogs() {
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);

  const getLogs = async () => {
    setLoading(true);
    const response = await api.get("auth/api/v1/getPaymentLogs", {
      headers: { token: cookies.token },
    });
    // console.log(response.data ,'===getAllPlayers==<');
    setLoading(false);
    setLogs(response.data);
  };

  useEffect(() => {
    getLogs();
  }, []);

  const [visible, setVisible] = useState(false);
  let columns = [
    {
      dataField: "_id",
      text: "id",
      style: { fontSize: "small", width: "210px" },
      hidden: true,
    },
    // {
    //   dataField: "customer.customerId",
    //   text: "Customer",
    //   style: { fontSize: "small", width: "104px" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "150px", textAlign: "center" };
    //   },
    // },
    {
      dataField: "customer.email",
      text: "Email",
      style: { fontSize: "small", width: "104px" },
      headerStyle: (colum, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "customer.fullName",
      text: "Name",
      style: { fontSize: "small", width: "104px" },
      headerStyle: (colum, colIndex) => {
        return { width: "120px", textAlign: "center" };
      },
    },
    {
      dataField: "payment_intent_id",
      text: "payment id",
      style: { fontSize: "small", width: "104px" },
      headerStyle: (colum, colIndex) => {
        return { width: "220px", textAlign: "center" };
      },
    },
    // {
    //   dataField: "metadata.object",
    //   text: "Operation",
    //   style: { fontSize: "small" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "152px", textAlign: "center" };
    //   },
    // },
    {
      dataField: "amountPaid.amountAfter",
      text: "Amount",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "72px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <div>
            $ {row.amountPaid.amountAfter / 100}.
            {row.amountPaid.amountAfter.toString().slice(-2)}
          </div>
        );
      },
    },
    // {
    //   dataField: "currency",
    //   text: "Currency",
    //   style: { fontSize: "small" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "92px", textAlign: "center" };
    //   },
    // },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        if (row.status === "requires_payment_method") {
          return <span className="bg-red-500 p-1 rounded">incomplete</span>;
        } else {
          return <span className="bg-green-400 p-1 rounded">{row.status}</span>;
        }
      },
    },
    {
      dataField: "regPlatform",
      text: "Platform",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
    // {
    //   dataField: "created_at",
    //   text: "Created At",
    //   style: { fontSize: "small" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "94px", textAlign: "center" };
    //   },
    //   formatter: (cell, row) => {
    //     return <div>{new Date(row.createdAt).toLocaleDateString()}</div>;
    //   },
    // },
    // {
    //   dataField: "regPlatform",
    //   text: "Platform",
    //   style: { fontSize: "small" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "90px", textAlign: "center" };
    //   },
    // },
    // {
    //   dataField: "paid",
    //   text: "Status",
    //   style: { fontSize: "small" },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "88px", textAlign: "center" };
    //   },
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="text-center flex">
    //         {row.paid ? (
    //           <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
    //             Active
    //           </span>
    //         ) : (
    //           <>
    //             <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
    //               Pending
    //             </span>
    //           </>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      text: "More..",
      // style: { fontSize: "small" },

      headerStyle: (colum, colIndex) => {
        return { width: "60px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <>
            <div className="flex justify-center gap-3 items-start mt-2">
              <FiFileText
                className="cursor-pointer hover:text-green-500 text-xl "
                onClick={() => handleShowDetails(row)}
              />
            </div>
          </>
        );
      },
    },
  ];

  const handleShowDetails = (log) => {
    setSelectedLog(log);
    setShowDetails(true);
  };

  // This is my custom search component
  const MySearch = (props) => {
    let input;
    const handleOnCahngeSearch = () => {
      if (input.value.toLowerCase() === "incomplete") {
        props.onSearch(`requires_payment_method`);
        return;
      }

      props.onSearch(input.value);
    };
    return (
      <div>
        <input
          className="form-control"
          ref={(n) => (input = n)}
          type="text"
          onChange={handleOnCahngeSearch}
        />
      </div>
    );
  };

  return (
    <>
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      <PaymentLogDetailsModal
        show={showDetails}
        setShow={setShowDetails}
        log={selectedLog}
        setSelectedLog={setSelectedLog}
      />
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider keyField="_id" data={logs} columns={columns} search>
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <MySearch {...props.searchProps} className="input " />
                <div className="flex gap-2"></div>
              </div>
              <hr />
              {loading ? (
                <div className="flex justify-center items-center w-full">
                  {" "}
                  <Loader />{" "}
                </div>
              ) : (
                <BootstrapTable
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminPaymentLogs;
