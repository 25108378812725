import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const ScoreState = ({ setSelectedPage, numberOfScores, loading }) => {
  if (loading) {
    return (
      <div className="flex w-full justify-center my-10">
        <ClipLoader
          color="#00B5FF"
          loading={loading}
          size={50}
          speedMultiplier={1}
        />
      </div>
    );
  }
  if (numberOfScores <= 0) {
    return (
      <>
        <h3 className="h3semibold ">No Scores Need to be updated!</h3>
        <button
          className="input bottom-0 absolute mb-10"
          onClick={() => setSelectedPage("scores")}
        >
          Move to Scores
        </button>
      </>
    );
  }
  return (
    <>
      <h3 className="h3semibold ">Some Scores Need to be updated!</h3>
      <button
        className="input bottom-0 absolute mb-10"
        onClick={() => setSelectedPage("scoresToBeUpadted")}
      >
        Update Now
      </button>
    </>
  );
};

export default ScoreState;
