import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";
import { MdRemoveCircle } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";

function GenerateGames({ show, setShow, selectedRivalry }) {
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    setShow(false);
  }

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Senior");
  const [showTable, setShowTable] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [fields,setFields] = useState([]);

  const getDivisions = async () => {
    setLoading(true);
    let mydivisions = await api.get("/rivelary/api/v1/getRivelaryDivisons", {
      headers: { token: cookies.token },
      params: { _id: selectedRivalry._id, type: type },
    });
    setDivisions(mydivisions.data);
    setLoading(false);
  };
  const getFields = async () => {
    const venues=[]
     selectedRivalry?.venues?.forEach((venue)=>venues.push(venue?._id))
    setLoading(true);
    let myFields = await api.post("/crud/api/v1/getComptetionFieldsActive", {
      headers: { token: cookies.token },
      venues:venues,
    });
    setFields(myFields.data);
    setLoading(false);
  };
  useEffect(() => {
    getDivisions();
    getFields()
    setSelectedSubDiv('');

  }, [selectedRivalry, type]);
  const [subDivs, setSubDivs] = useState([]);
  const [selectedData, setSelectedData] = useState({
    firstTeam: "",
    secondTeam: "",
    barrier: "",
  });
  const [barrierList, setBarrierList] = useState([]);
  const [generatedGames, setGeneratedGames] = useState([]);
  const [selectedDiv, setSelectedDiv] = useState("");
  const [selectedSubDiv, setSelectedSubDiv] = useState("");
  const [barrier, setBarrier] = useState("teams");
  const [teams, setTeams] = useState([]);
  const [gameType, setGameType] = useState("Senior");

  const handleDivisionChange = async (divisionId) => {
    setBarrierList([]);
    setSelectedSubDiv("");
    setSelectedDiv(divisionId);
    let selectedDivision = divisions.filter(
      (division) => division._id == divisionId
    );
    // let myArray = new Array(selectedDivision[0].subDivisionNo).fill(0);
    // myArray.forEach((item, index) => (myArray[index] = index + 1));
    // setSubDivs(myArray);
    const response = await api.get("/rivelary/api/v1/getDivisionSubsActive", {
      params: { _id: divisionId },
    });
    setGameType(selectedDivision[0]?.type);
    setSubDivs(response.data);
  };

  const handleSubDivChange = async (subDivNo) => {
    setBarrierList([]);
    setSelectedSubDiv(subDivNo);
    const response = await api.post("/games/api/v1/getSubDivTeams", {
      division: selectedDiv,
      subDiv: subDivNo,
    });
    setTeams(response.data);
  };

  const handleBarrierType = (barrier) => {
    setBarrier(barrier);
    setSelectedData({ ...selectedData, barrier: "" });
  };

  const handleAddBarrier = () => {
    // console.log(selectedData);
    if (barrier === "field") {
      // add barrier to list
      setBarrierList([...barrierList, { ...selectedData, type: barrier }]);
      return;
    }
    if (selectedData.firstTeam === selectedData.secondTeam) {
      failNotification("Please Change the Teams in Barrier");
      return;
    }
    if (selectedData.firstTeam === "" || selectedData.secondTeam === "") {
      failNotification("Please check missing data");
      return;
    }
    if (barrier === "teams") {
      // add barrier to list
      setBarrierList([...barrierList, { ...selectedData, type: barrier }]);
      return;
    }
    
    if (selectedData.barrier === "") {
      failNotification("please add barrier value");
      return;
    }
    setBarrierList([...barrierList, { ...selectedData, type: barrier }]);
    return;
  };

  const handleRemoveBarrier = (index2) => {
    let newList = barrierList.filter((barrier, index) => index != index2);
    setBarrierList(newList);
  };

  const handleSubmitBarriers = async () => {
    if (selectedSubDiv === "" || selectedDiv === "") {
      failNotification("Please select division and sub Division");
      return;
    }
    setLoading(true);
    setShowTable(true);
    let data = {
      barrierList: barrierList,
      division: selectedDiv,
      subDiv: selectedSubDiv,
      gameType: gameType,
    };

    const response = await api.post("/rivelary/api/v1/generateGames", data, {
      headers: { token: cookies.token },
    });
    setGeneratedGames(response?.data?.matchArray);
    setLoading(false);
    // console.log(response.data);
  };
  console.log(fields )
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <GeneratedTable
                  show={showTable}
                  setShow={setShowTable}
                  loading={loading}
                  generatedGames={generatedGames}
                  regenerateGames={handleSubmitBarriers}
                />
                <Dialog.Title as="h3" className="text-2xl font-semibold">
                  Generate Games
                </Dialog.Title>
                <div className="mt-2 flex flex-col gap-3">
                  <div className="flex w-full gap-3">
                    <div className="flex flex-col w-1/2">
                      <p className="text-xl font-semibold">Type</p>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <option value={"Senior"}>Senior</option>
                        <option value={"Junior"}>Junior</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full gap-3">
                    <div className="flex flex-col w-1/2">
                      <p className="text-xl font-semibold">Select Divison</p>
                      <select
                        className="input w-full"
                        value={selectedDiv}
                        onChange={(e) => {
                          handleDivisionChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          select Division
                        </option>
                        {divisions?.map((division) => {
                          return (
                            <option key={division._id} value={division._id}>
                              {division.divisionName} / {division.night} /{" "}
                              {division.type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <p className="text-xl font-semibold">
                        Select sub Divison
                      </p>
                      <select
                        className="input w-full"
                        id="subDiv"
                        name="subDiv"
                        value={selectedSubDiv}
                        onChange={(e) => handleSubDivChange(e.target.value)}
                      >
                        <option value={""} hidden>
                          Please Select Sub Division
                        </option>
                        {subDivs?.map((division) => {
                          return (
                            <option value={division._id}>
                              {division.subDivName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <hr />
                  <p className="text-2xl font-semibold">Barriers</p>
                  <div className="flex w-full gap-3">
                    <div className="flex flex-col w-1/2">
                      <p className="text-xl font-semibold">Barriers Types</p>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        onChange={(e) => handleBarrierType(e.target.value)}
                        value={barrier}
                      >
                        <option value={"teams"}>Teams</option>
                        <option value={"singleTeam"}>Single Team</option>
                        <option value={"time"}>Time</option>
                        <option value={"night"}>Night</option>
                        <option value={"field"}>field</option>
                      </select>
                    </div>
                    {barrier == "night" ? (
                      <div className="flex flex-col w-1/2">
                        <p className="text-xl font-semibold">Nights</p>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          value={selectedData.barrier}
                          onChange={(e) =>
                            setSelectedData({
                              ...selectedData,
                              barrier: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Select Barrier</option>
                          {selectedRivalry?.days.map((day) => {
                            return (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    ) :barrier == "field" ? (
                      <div className="flex flex-col w-1/2">
                        <p className="text-xl font-semibold">Fields</p>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          value={selectedData.barrier}
                          onChange={(e) =>
                            setSelectedData({
                              ...selectedData,
                              barrier: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Select Barrier</option>
                          {fields?.map((field) => {
                            return (
                              <option key={field?._id} value={field?._id}>
                                {field?.fieldName}/{field?.venue?.venueName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    ) :
                    
                    barrier == "time" || barrier === "singleTeam" ? (
                      <div className="flex flex-col w-1/2">
                        <p className="text-xl font-semibold">Time</p>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          value={selectedData.barrier}
                          onChange={(e) =>
                            setSelectedData({
                              ...selectedData,
                              barrier: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Select Barrier</option>
                          {fields?.map((element) => {
                            return element?.timeSlots?.map((slot) => {
                              return (
                                <option key={slot} value={slot}>
                                  {slot}
                                </option>
                              );
                            });
                          })}
                          {/* <option value={1}>6:00</option>
                          <option value={2}>6:45</option>
                          <option value={3}>7:30</option>
                          <option value={4}>8:15</option> */}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {barrier!=='field'?
                  <div className="flex w-full gap-3">
                    <div className="flex flex-col w-1/2">
                      <p className="text-xl font-semibold">First Team</p>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        value={selectedData.firstTeam}
                        onChange={(e) =>
                          setSelectedData({
                            ...selectedData,
                            firstTeam: e.target.value,
                          })
                        }
                      >
                        <option value={""} hidden>
                          Please Select first Team
                        </option>
                        {teams?.map((team) => {
                          return (
                            <option value={team._id}>{team.teamName}</option>
                          );
                        })}
                      </select>
                    </div>
                    {barrier !== "singleTeam" ? (
                      <div className="flex flex-col w-1/2">
                        <p className="text-xl font-semibold">Second Team</p>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          value={selectedData.secondTeam}
                          onChange={(e) =>
                            setSelectedData({
                              ...selectedData,
                              secondTeam: e.target.value,
                            })
                          }
                        >
                          <option value={""} hidden>
                            Please Select Second Team
                          </option>
                          {teams?.map((team) => {
                            return (
                              <option value={team._id}>{team.teamName}</option>
                            );
                          })}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  :''}
                  <div className="flex flex-row-reverse">
                    <button
                      className="normalButton py-2 px-3 text-sm"
                      onClick={() => {
                        handleAddBarrier();
                      }}
                    >
                      + Add Barrier
                    </button>
                  </div>
                  <div className="flex">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>First Team</th>
                          <th>Second Team</th>
                          <th>Barrier Type</th>
                          <th>Barrier</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {barrierList?.map((b, index) => {
                          let team1, team2, time;
                          if(b.type!=='field'){
                            team1 = teams.filter(
                              (team) => team._id === b.firstTeam
                            );
                            team2 = teams.filter(
                              (team) => team._id === b.secondTeam
                            );
                          }
                          // if (b.type === "time") {
                          //   if (b.barrier === "1") time = "6:00";
                          //   if (b.barrier === "2") time = "6:45";
                          //   if (b.barrier === "3") time = "7:30";
                          //   if (b.barrier === "4") time = "8:15";
                          // }
                          return (
                            <tr key={index}>
                              <td>{team1 &&team1[0].teamName}</td>
                              <td>
                                {b.type !== "singleTeam"
                                  ? team2&& team2[0].teamName
                                  : ""}
                              </td>
                              <td>{b.type}</td>
                              <td>
                                {b.type === "time" || b.type === "singleTeam"
                                  ? b.barrier
                                  :b.type === "field"?fields.filter((field)=>field._id==b.barrier)[0].fieldName :b.barrier}
                              </td>
                              <td>
                                <MdRemoveCircle
                                  size={20}
                                  className={"cursor-pointer"}
                                  onClick={() => {
                                    handleRemoveBarrier(index);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex justify-center">
                    <button
                      className="normalButton py-2 px-3 text-sm"
                      onClick={handleSubmitBarriers}
                    >
                      Generate Games
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default GenerateGames;

function GeneratedTable({
  show,
  setShow,
  generatedGames,
  loading,
  regenerateGames,
}) {
  // let gameTimes = {
  //   1: "6:00",
  //   2: "6:45",
  //   3: "7:30",
  //   4: "8:15",
  // };
  const [cookies] = useCookies(["token"]);

  const confirmGenerateGames = async () => {
    try {
      const response = await api.post(
        "/rivelary/api/v1/confirmGameGeneration",
        generatedGames,
        {
          headers: { token: cookies.token },
        }
      );
      successNotification("Matches generated successfully");
      setShow(false);
    } catch (error) {
      failNotification(error.msg);
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-2xl font-semibold">
                  Generated Games
                </Dialog.Title>
                <div className="mt-2 flex flex-col gap-3 items-center">
                  {loading ? (
                    <ClipLoader
                      color="#0FA9E8"
                      loading={loading}
                      size={50}
                      speedMultiplier={1}
                      className="my-5"
                    />
                  ) : (
                    <table className="table my-3">
                      <thead>
                        <tr>
                          <th>Team 1</th>
                          <th>Team 2</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Field</th>
                          <th>Round</th>
                        </tr>
                      </thead>
                      <tbody>
                        {generatedGames.map((game) => {
                          return (
                            <tr>
                              <td>{game.firstTeam.teamName}</td>
                              <td>{game.secondTeam.teamName}</td>
                              <td>
                                {new Date(game.gameDate).toLocaleDateString()}
                              </td>
                              <td>{game.gameTime}</td>
                              <td>{game.field.fieldName}</td>
                              <td>{game.round}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  <div className="flex w-full gap-3">
                    <div className="flex justify-center gap-3">
                      <button
                        className="normalButton py-2 px-3 text-sm"
                        onClick={() => regenerateGames()}
                      >
                        Regenerate Games
                      </button>
                      <button
                        className="rounded-lg border-2 border-[#00B5FF] text-[#00B5FF] font-semibold py-2 px-3 text-sm"
                        onClick={() => confirmGenerateGames()}
                      >
                        Apply and Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
