import React, { Fragment, useEffect, useState } from "react";
import api from "../api";
import AdminAddNewGameModal from "./AdminAddNewGameModal";
import AdminGameScoreCard from "./AdminGameScoreCard";
import ReactLoading from "react-loading";
import AdminScoreFilter from "./AdminScoreFilter";
function AdminScores() {
  const [visible, setVisible] = useState(false);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [modalType, setModalType] = useState(false);
  let [loading, setLoading] = useState(false);

  const getAllGames = async (query) => {
    try {
      setLoading(true);

      const areas = await api.get(
        `/games/api/v1/getAllGames${query ? query : ""}`
      );
      setGames(areas.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllGames();
  }, []);

  const handleEdit = (selectedGame) => {
    setSelectedGame(selectedGame);
    setModalType(true);
    setVisible(true);
  };

  return (
    <>
      <AdminAddNewGameModal
        show={visible}
        setShow={setVisible}
        getAllGames={getAllGames}
        type={modalType}
        selectedGame={selectedGame}
      />
      <div className="w-4/5 bg  h-fit flex flex-col  pr-20 mt-5">
        <div className="flex justify-between items-center ">
          <AdminScoreFilter getAllGames={getAllGames} />
          <button
            className="input bg-[#0FA9E8] text-white"
            onClick={() => {
              setModalType(false);
              setVisible(!visible);
            }}
          >
            + Add Game
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          {loading && (
            <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
          )}
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          {games?.map((game) => {
            return (
              <AdminGameScoreCard
                key={game._id}
                gameData={game}
                getAllGames={getAllGames}
                handleEdit={handleEdit}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default AdminScores;
