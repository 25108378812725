import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AdminAddNewTeamModal from "./AdminAddNewTeamModal";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminEditTeamsModal from "./adminPanel/AdminEditTeamsModal";
import ConfirmRemoveTeam from "./adminPanel/ConfirmRemoveTeam";
import AdminAddNewItemModal from "./AdminAddNewItemModal";
import ConfirmRemoveProduct from "./ConfirmRemoveProduct";
import ReactAudioPlayer from "react-audio-player";
import AdminAddNewItemPoadCast from "./AdminAddNewItemPoadCast";
import AdminEditPodCastModal from "./AdminEditPodCastModal";
import ConfirmRemoveGeneral from "./adminPanel/ConfirmRemoveGeneral";
import Loader from "./loader";
function AdminPodCast() {
  const [teams, setTeams] = useState([]);
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);

  const getAllTeams = async () => {
    setLoading(true);
    const response = await api.get("ecommerce/api/v1/allVoices", {
      headers: { token: cookies.token },
    });
    setLoading(false);
    // console.log(response.data)
    setTeams(response.data.PoadCasts);
  };

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  useEffect(() => {
    getAllTeams();
  }, [visible, editModal]);
  let columns = [
    // { dataField: '_id', text: 'Order No', style: { fontSize: 'small', width: '210px' } },
    { dataField: "title", text: "Title", style: { fontSize: "small" } },
    { dataField: "host.name", text: "Host", style: { fontSize: "small" } },
    { dataField: "guest.name", text: "Guest", style: { fontSize: "small" } },
    {
      dataField: "description",
      text: "Description",
      style: { fontSize: "small" },
    },
    {
      dataField: "voice",
      text: "Audio",
      formatter: (cell, row) => {
        return (
          <div>
            <ReactAudioPlayer
              src={process.env.REACT_APP_SERVER_URL + row.voice}
              controls
              className="w-full mt-5"
            />
            {/*<p>test</p>*/}
          </div>
        );
      },
      style: { fontSize: "small" },
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cell, row) => {
        return (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}${row.image}`}
            width={100}
            height={100}
          />
        );
      },
      style: { fontSize: "small" },
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleOpenEdit(row._id)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemoveBtn(row)}
            />
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => {
    setVisible(true);
  };

  const handleOpenEdit = (teamId) => {
    // console.log(teamId)
    setSelectedTeam(teamId);
    setEditModal(true);
  };

  const handleRemoveBtn = (teamId) => {
    // console.log(teamId)
    setSelectedTeam(teamId);
    setDeleteModal(true);
  };

  return (
    <>
      <AdminAddNewItemPoadCast show={visible} setShow={setVisible} />
      <AdminEditPodCastModal
        show={editModal}
        setShow={setEditModal}
        teamId={selectedTeam}
      />
      <ConfirmRemoveGeneral
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        apiUrl="ecommerce/api/v1/deletePadCast"
        selected={selectedTeam}
        resetFc={getAllTeams}
      />
      {/* 
      <ConfirmRemoveProduct
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        teamId={selectedTeam}
        getAllTeams={getAllTeams}
      /> */}
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="teamName"
          data={teams}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                <button
                  className="normalButton py-2 text-sm"
                  onClick={() => handleOpenAdd()}
                >
                  Add Podcast
                </button>
              </div>
              <hr />
              {loading ? (
                <div className="flex justify-center items-center w-full">
                  {" "}
                  <Loader />{" "}
                </div>
              ) : (
                <BootstrapTable
                  loading={true}
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminPodCast;
