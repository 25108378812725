import React from "react";

export default function NoStrikePolicy() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">No Strike Policy </h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3 className="text-center">(Zero Tolerance)</h3>
        <h3 className="text-center">
          as adopted by AOSA for domestic competitions
        </h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Any player found guilty of striking another player first will be
            sent off and will be disqualified for the remainder of the season or
            a period determined by the competition convenor with no right of
            appeal. The convenor may vary the length of the suspension after
            considering the severity of the incident and on what round the
            incident occurred. i.e. if the strike was done during the finals
            series the suspension may carry over to the next season.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Any player found guilty of striking another player in retaliation
            will be automatically sent from the field and will also serve a
            suspension. The duration of this suspension will be at the convenors
            discretion.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ No registration money will be refunded to the guilty player and or
            transferred to team members if a player is suspended for striking.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Players are not permitted to participate in any Representative
            Tournament if they are currently serving a domestic suspension for
            striking.
          </li>
        </ul>
      </div>
    </div>
  );
}
