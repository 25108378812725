import React from "react";

export default function TPEES() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">
        Tournament Player Eligibility Policy – Seniors{" "}
      </h1>
      <h1 className="text-center pb-8">Updated 16 June 2022</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          For a player to be eligible to compete in an official Australian Oztag
          Ltd (AO) Senior Representative Tournament they must meet the below
          requirements. Associations who play in-eligible players will receive
          tough penalties.
        </p>
        <p>These penalties are outlined at the end of this policy.</p>
        <p className="font-semibold">
          Player requirements to be eligible to compete in an Australian Oztag
          Senior representative tournament.
        </p>
        <p>
          Australian Championships: Registered Oztag players are eligible from
          anywhere in Australia if they meet the below criteria. State
          Championships: Players must be registered in the state of the
          tournament & meet the below criteria.
        </p>
        <p>
          • Players must be a registered Oztag player for the Association /
          Region they are representing.
        </p>
        <p>
          To be eligible to play for that Association/ Region you must meet the
          following criteria:
        </p>
        <p>
          1. Have played 7 games in ONE TEAM per season in either of the
          proceeding * two seasons prior to the tournament. Eg A player would
          play in a team playing one game per week over a seven week period. A
          referee is eligible providing the referee has refereed at least one
          game a week over a seven week period during the season. Refereeing
          multiple games in a week does not count.
        </p>
        <p>
          * The Proceeding two seasons are any senior domestic competitions
          offered by the Association they are representing that has a minimum of
          10 competition rounds plus semi-final & grand finals that is scheduled
          to be or has been completed by the first day of the tournament. A
          player playing in several divisions on several nights does not
          accumulate the 7 games this way. Only one team in one competition
          counts.
        </p>
        <p>
          • 2. Players who have not played the seven games due to extenuating
          circumstances may apply in writing to the tournament director for
          permission to play. E.g. injury, returning from overseas, the domestic
          competition doesn’t finish before the tournament is played etc.
          Players need to submit their request in writing. If a player is
          granted an exemption they may only qualify to represent an Association
          / Region at which they have previously played.
        </p>
        <p>
          • An exception will be made for players who are still eligible (15
          years +) to play both juniors & seniors, but don’t have a senior
          domestic competition in their area. If this is the case they must be a
          registered player at the Junior Competition. In this instance a Junior
          Association would be permitted to enter a Senior Team in a Senior AO
          Representative Tournament.
        </p>
        <p>
          • If there is a senior domestic competition offered by the Association
          / Region you are representing you must be a registered player in this
          senior competition to represent at a senior Tournament
        </p>
        <p>
          • If there is a senior competition offered in the area then a junior
          Association is not permitted to enter teams in a senior representative
          tournament.
        </p>

        <p>
          • If there is both a Senior Association and a Junior Association in
          the same area (LGA), whether a single person holds the licence to
          operate both or 2 different people hold the licence, players must be
          registered in the Senior competition to be eligible to compete in a
          Senior Representative tournament. Only being registered in the Junior
          competition does not qualify a player for a Senior Representative
          Tournament, regardless of the player's age.
        </p>
        <p>Age eligibility requirements for each division:</p>
        <p>
          NOTE: Players can only play in a senior domestic competition when they
          turn 15.{" "}
        </p>
        <p>
          Men’s 20’s / Women’s 20’s / Mixed 20’s: Players must still be 20 on
          the first day of the tournament.
        </p>
        <p>Men’s / Women’s / Mixed Open: No age limit. </p>
        <p>
          Women’s 30’s: Players must be 30 years or older by 31st December of
          the year of the tournament. Teams are entitled to TWO exceptions (must
          be aged 28 or 29 by the first day of the tournament). Expires Dec 2023
        </p>
        <p>
          Women’s 40’s: Players must be 40 years or older by 31st December of
          the year of the tournament. Teams are entitled to TWO exceptions (must
          be aged 38 or 39 by the first day of the tournament). Expires Dec 2023
        </p>
        <p>
          Mixed Seniors: Women must be 30 years or older, men must be 35 years
          or older with a transition period to allow TWO players under age
          (either 33 or 34 year old in 2022 & 2 x 34 year olds in 2023).
        </p>
        <p>
          Men’s 30’s, 35’s, 40’s, 45’s, 50’s & 55’s: Players must be the age of
          the division they are playing in or older by 31st December the year of
          the tournament.
        </p>
        <p>
          Proof of age – if a player’s age is questioned a birth certificate,
          passport or other identification must be supplied upon request within
          seven days of the tournament. If it is proven after the conclusion of
          the tournament, a title if won will be stripped from the Association
          and no winner will be awarded for that division. Other penalties
          outlined in this policy will also apply.
        </p>
        <h3>Complaints</h3>
        <p>
          Any Association / coach / player wishing to make an official complaint
          regarding the tournament are required to fill out the complaints form
          and submit it to the tournament director. Once a complaint has been
          received the Tournament Director will investigate the eligibility of
          the player. Associations who cannot provide evidence at the time of
          the complaint will have seven days after the tournament to produce the
          evidence to show the player was eligible to play.
        </p>
        <h3>PENALTIES</h3>
        <p>
          • First Offence: Any team playing an ineligible player/s will be
          disqualified from the tournament. If it is proven after the conclusion
          of the tournament, a title if won will be stripped from the
          Association and the runners up be awarded that division. The coach,
          manager and player/s involved will be suspended from playing /
          coaching at any representative level for any association for a period
          of 12 months. This disqualification will include the same tournament
          if it falls outside the 12 months the following year. The
          association/region will also be fined $500. THE ONUS IS ON THE COACH &
          MANAGER TO ENSURE ALL PLAYERS ARE ELIGIBLE.
        </p>
        <p>
          • Second Offence: The coach, manager and player/s involved will be
          suspended from playing / coaching at any representative level for any
          association for a period of 12 months. The association will receive a
          $1000.00 fine.
        </p>
        <p>
          • Third Offence: If an association is caught on 3 separate occasions
          of playing ineligible players the association may be disqualified from
          entering teams in any division at any AO tournament for 12 months or a
          period set by the AO
        </p>
      </div>
    </div>
  );
}
