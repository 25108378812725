import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <hr className="w-full" />
      <div
        className="md:w-full  grid grid-cols-1 md:grid-cols-5"
        style={{ scale: "0.8" }}
      >
        <div className="md:list1position md:col-span-2  ">
          <div className="mb-3">
            <img src="./oztagLogoHeader.png" alt="" className="w-fit" />
          </div>
          <p className="fadedText">
            Sydney OzTag aims to provide opportunities for people to participate
            at all levels
          </p>
          <p className="fadedText">Got Question?</p>
          <p className="strongText">0418 469 203</p>
          <p className="fadedText">Payment Method</p>
          <ul className="paymentList">
            <li className="border-none">
              <svg
                width="56"
                height="18"
                viewBox="0 0 56 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.2669 17.7404H19.7305L22.5679 0.315918H27.104L24.2669 17.7404Z"
                  fill="#828D9E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M36.6427 0C32.1628 0 29.0082 2.37257 28.9888 5.76463C28.9516 8.26729 31.2475 9.65732 32.9646 10.4918C34.7197 11.3445 35.3162 11.901 35.3162 12.6611C35.2984 13.8284 33.8981 14.3665 32.592 14.3665C30.7809 14.3665 29.8104 14.0891 28.3357 13.4396L27.7383 13.1612L27.1035 17.0727C28.1675 17.5541 30.1277 17.9813 32.1628 18C36.9227 18 40.0216 15.6641 40.0583 12.0494C40.0764 10.0658 38.8641 8.54593 36.2505 7.30397C34.6638 6.50671 33.6922 5.96912 33.6922 5.15338C33.7108 4.4118 34.514 3.65223 36.305 3.65223C37.7798 3.61503 38.8634 3.9671 39.6842 4.31941L40.0945 4.50443L40.7114 0.741829C39.8167 0.389269 38.3975 0 36.6427 0Z"
                  fill="#828D9E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M48.5514 6.69212C48.5325 6.72932 48.9242 5.67263 49.1482 5.02393L49.4652 6.52532C49.4652 6.52532 50.3243 10.6962 50.5108 11.5673C49.8019 11.5673 47.6363 11.5673 46.7402 11.5673C47.1138 10.5663 48.5514 6.69212 48.5514 6.69212ZM48.8319 0.315918C47.7498 0.315918 46.9277 0.630782 46.4609 1.76163L39.7227 17.7401H44.4826C44.4826 17.7401 45.2662 15.5896 45.4346 15.1264C45.9568 15.1264 50.5872 15.1264 51.259 15.1264C51.3892 15.7381 51.8003 17.7401 51.8003 17.7401H56.0005L52.3408 0.315918H48.8319Z"
                  fill="#828D9E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9421 0.315918L11.4995 12.1977L11.0141 9.78797C10.1927 7.0074 7.61678 3.98638 4.74219 2.48425L8.81147 17.7219H13.6086L20.739 0.315918H15.9421V0.315918Z"
                  fill="#828D9E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.37328 0.315918H0.074668L0 0.667985C5.69337 2.11395 9.46398 5.59938 11.0132 9.78871L9.42652 1.7806C9.16531 0.667738 8.36257 0.352628 7.37328 0.315918Z"
                  fill="#DDDFE3"
                />
              </svg>
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-3 md:col-span-3">
          <div className="flex flex-col gap-3">
            <p className="strongText">Company</p>
            <Link to="/about" className="fadedText no-underline" >
              About Us
            </Link>
            <Link to="/tournments" className="fadedText no-underline">
              Tournaments
            </Link>
            <Link to="/teamsinfo" className="fadedText no-underline">
              Teams
            </Link>
            <Link to="/shop" className="fadedText no-underline">
              Shop
            </Link>
            {/* <p className='fadedText'>Tournaments</p>
                        <p className='fadedText'>Teams</p>
                        <p className='fadedText'>Shop</p> */}
          </div>
          <div className="flex flex-col gap-3">
            <p className="strongText">
              <Link to={"thegameinfo"} className="no-underline text-black">
                The Game
              </Link>
            </p>
            {/* <p className='fadedText'>How To Play</p>
                        <p className='fadedText'>OzTag Rules</p>
                        <p className='fadedText'>Where To Play</p>
                        <p className='fadedText'>Delivery & Returns</p> */}
            <Link to="/howtoplay" className="fadedText no-underline">
              How To Play
            </Link>
            <Link to="/oztagrules" className="fadedText no-underline">
              OzTag Rules
            </Link>
            <Link to="/wheretoplay" className="fadedText no-underline">
              Where To Play
            </Link>
            <Link to="/shop" className="fadedText no-underline">
              Delivery & Returns
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            <p className="strongText">
              <Link to={"resources"} className="no-underline text-black">
                Resources
              </Link>
            </p>

            {/* <p className='fadedText'>Insurance</p>
                        <p className='fadedText'>Privacy Policy</p>
                        <p className='fadedText'>Terms of Use</p>
                        <p className='fadedText'>Other Policies</p> */}
            <Link to="/insurance" className="fadedText no-underline">
              Insurance
            </Link>
            <Link to="/policies" className="fadedText no-underline">
              Policies
            </Link>
            <Link to="/about" className="fadedText no-underline">
              Terms of Use
            </Link>
            {/* <Link to='/shop' className='fadedText no-underline'>Other Policies</Link> */}
          </div>
        </div>
      </div>
      <hr className="w-full" />
      <div className="md:copyRightDiv " style={{ scale: "0.8" }}>
        <div className="copywrite">
          <p className="fadedText">
            © Copyright 2022 Sydney OzTag. All Right Reserved. Built By Feur
            Media House
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
