import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Privacy Policy</h1>
      <h1 className="text-center ">Australian Oztag</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3>Overview</h3>
        <p>
          Australian Oztag (AO) collects personal information on its players,
          and other stakeholders. This personal information is securely stored
          electronically and on occasions in hard copy kept in the office. The
          information is only accessible by AO staff. The information is
          generally sourced from tournament registration forms and
          representative players. Staff members can only access the information
          with approval from the general manager & must identify the purpose for
          accessing the information.
        </p>
        <p>
          In conducting its business AO may be required to distribute personal
          information to other organisations. AO will only distribute the
          information it has permission to do so with and will only distribute
          information to organisations with acceptable privacy policies in
          place. All personal information collected is stored electronically or
          in hard copy with all reasonable means taken to protect the
          information from loss, theft and misuse. All AO staff are informed of
          and required to abide by this privacy policy to ensure the security
          and appropriate use/handling of all stored information.
        </p>
        <p>
          Should any player, or other stakeholder feel that AO has breached this
          policy please contact the AO General Manager. All complaints will be
          investigated and appropriate action taken to rectify the breach. AO
          will only release personal information upon receipt of a written
          request signed by the individual in question. Each request will be
          treated as a single occurrence released to the named party only, and
          subsequent enquiries will require a new written request.
        </p>
        <h3>Photography</h3>
        <p>Senior Policy</p>
        <p>
          Photography and images will be used by AO and its venues. In doing so
          the following principles will be applied:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Anyone taking photographs or recording at any Oztag event must
            have a valid reason for doing so.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ All concerns regarding inappropriate or intrusive photography
            should be reported in confidence to the player’s coach, Venue
            Managers,tournament director, or Australian Oztag.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Any person who objects to having their photograph or footage of
            them taken should notify the coach or Australian Oztag otherwise it
            is presumed that photographs may be taken and used by AO in any
            Oztag related publications or press releases.
          </li>
        </ul>
        <h3>Junior Policy</h3>
        <p>
          AO appreciates that photography of players is a sensitive area in
          relation to junior players. Therefore, a policy on the
          video/photographic images of children under the age of 18 has been
          produced. First and foremost:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Any adult seeking to take photographs of any player must first
            speak with the coach of that team ideally or as an alternative, the
            Venue Managers, tournament director. This is a fundamental
            requirement, not just a courtesy.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ If any of the players' parents or carers object, then no
            photographs may be taken.
          </li>
        </ul>
        <p>In addition:</p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ All young people must be appropriately dressed for the activity
            taking place.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Photography or recording should focus on the activity rather than
            a particular young person and personal details, such as their
            address, should never be revealed.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Where an individual has achieved success in the game, permission
            must be gained from a parent/guardian and the young person to use
            the images and relevant details.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Wherever possible, photographs/recordings should represent the
            diverse range of young people participating in Oztag.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Anyone taking photographs or recording at any Oztag event must
            have a valid reason for doing so.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ All concerns regarding inappropriate or intrusive photography
            should be reported in confidence to the player’s coach, Venue
            Managers, tournament director, or AO.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Any parent/guardian who objects to their child being photographed
            or footage of them being taken should notify the coach or AO
            otherwise it is presumed that photographs may be taken and used on
            Oztag related publications or in press-releases.
          </li>
        </ul>
        <h3>Media Release</h3>
        <p>
          If at any time a photograph or video footage (image and voice
          recordings) of any player/s (seniors or juniors) is used for
          promotional events for AO or individual venues within AO, then
          authorisation must be completed by the player. If the player is a
          child (under the age of 18), then authorisation must be completed by
          the player's parent / guardian.
        </p>
        <h3>Technology and Privacy</h3>
        <p>
          There are privacy considerations for use of technologies e.g. sending
          SMS or emails. Any information posted or distributed via technologies
          to a mass audience (i.e. not to a specific individual) should not
          include identifying information such as:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>⦁ first name and/or surname</li>
          <li style={{ all: "unset" }}>
            ⦁ contact details – Phone, email, addresses
          </li>
        </ul>
        <h3>Respecting Privacy</h3>
        <p>
          If at any time a player (or other recipient) decides that they don't
          want to receive messages anymore (either through email or on SMS) they
          can unsubscribe. To opt out a player needs to simply reply to the
          email with “opt out” or “stop”.
        </p>
        <h3>Further Advice</h3>
        <p>
          There is additional information to be aware of in the use of
          technology in relation to privacy:
        </p>
        <p>
          ⦁ The principles of Data Protection relate to the Australian Privacy
          Act 1988, as well as the Privacy Amendment (Enhancing Privacy
          Protection) Act 2012
        </p>
      </div>
    </div>
  );
}
