import { useFormik } from "formik";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";
import { TiDeleteOutline } from "react-icons/ti";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import ReactLoading from "react-loading";

import "leaflet/dist/leaflet.css";

import { Map } from "react-leaflet";
import Loader from "../loader";

function AddField({
  type = "add",
  show,
  setShow,
  getAllFields,
  selectedField,
}) {
  //   console.log(selectedField);
  const [isLoading, setIsLoading] = useState(false);
  const [venues, setVenues] = useState([]);
  const [selectedAvenu, setSelectedAvenue] = useState("");
  const [initialValues, setInitialValues] = useState({
    fieldName: "",
    address: "",
    venue: "",
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeInput, setTimeInput] = useState("");
  const [loading, setLoading] = useState(false);

  const [cookies] = useCookies(["token"]);
  function closeModal() {
    // setInitialValues({
    //   fieldName: "",
    //   address: "",
    // venue:selectedAvenu

    // });
    setShow(false);
  }

  useEffect(() => {
    // console.log(selectedField);
    if (selectedField && type !== "add") {
      setTimeSlots(selectedField?.timeSlots);
      setInitialValues({
        ...initialValues,
        ...selectedField,
        venue: selectedField?.venue?._id,
      });
      setSelectedAvenue(selectedField?.venue?._id);
      if (selectedField.latlng) {
        setPosition(JSON.parse(selectedField.latlng));
        setCenter(JSON.parse(selectedField.latlng));
      }
    } else {
      setSelectedAvenue("");
      setInitialValues({
        fieldName: "",
        address: "",
        image: "",
        venue: "",
      });
      setTimeSlots([]);
      formik.resetForm();
    }
  }, [selectedField, type]);

  useEffect(() => {
    api
      .get("crud/api/v1/getVenues", {
        headers: { token: cookies.token },
      })
      .then((res) => {
        setVenues(res.data);
      })
      .catch((err) => {});
    // console.log("edited avenueeeee");
  }, []);

  const handleRemoveTimeSlot = (selectedSlot) => {
    let filtered = timeSlots.filter((slot) => slot !== selectedSlot);
    setTimeSlots(filtered);
  };
  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, timeInput]);
  };

  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      fieldName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      setLoading(true);
      if (timeSlots.length === 0) {
        // return failNotification('please insert timeslots')
      }
      const values2 = { ...values, venue: selectedAvenu };
      const formData = new FormData();

      Object.entries(values2).forEach((item) => {
        let [key, value] = item;
        formData.append(key, value);
      });
      formData.delete("timeSlots");
      formData.delete("latlng");
      timeSlots.forEach((item, i) => {
        formData.append(`timeSlots[${i}]`, item);
      });
      formData.append("latlng", JSON.stringify(position));
      try {
        setIsLoading(true);
        if (type == "add") {
          const newUser = await api.post("/crud/api/v1/addField", formData, {
            headers: { token: cookies.token },
          });
          setLoading(false);
          successNotification("Field Add Successfully");
          getAllFields();
          setCenter({
            lat: 51.505,
            lng: -0.09,
          });
          setPosition({
            lat: 51.505,
            lng: -0.09,
          });
          setShow(false);
          formik.resetForm();
        } else {
          const newUser = await api.post("/crud/api/v1/editField", formData, {
            headers: { token: cookies.token },
          });
          setLoading(false);
          successNotification("Field Edited Successfully");
          getAllFields();
          setShow(false);
          formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        setLoading(false);
        // console.log(error.response.data.message);
        failNotification(error.response.data.errors[0].msg);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const [center, setCenter] = useState({
    lat: 51.505,
    lng: -0.09,
  });
  const [position, setPosition] = useState(center);
  function DraggableMarker() {
    const map = useMap();
    const timer = React.useRef(null);
    useEffect(() => {
      startTimer();

      return () => clearTimeout(timer.current);
    }, [map]);

    const startTimer = () => {
      timer.current = setTimeout(() => {
        map.invalidateSize();
      }, 250);
    };

    const [draggable, setDraggable] = useState(true);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
            setCenter(marker.getLatLng());
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup>
          <span
          // onClick={toggleDraggable}
          >
            {draggable
              ? "Marker is draggable"
              : "Click here to make marker draggable"}
          </span>
        </Popup>
      </Marker>
    );
  }
  // function MyComponent() {
  //   const map = useMapEvent('viewreset',() => {
  //     map.setView([position.lat, position.lng], map.getZoom())
  //   })
  //   return null
  // }
  const [map, setMap] = useState(null);

  function DisplayPosition({ map }) {
    const [position, setPosition] = useState(() => map.getCenter());

    const onClick = useCallback(() => {
      map.setView(center, 13);
    }, [map]);

    const onMove = useCallback(() => {
      setPosition(map.getCenter());
    }, [map]);

    useEffect(() => {
      map.on("move", onMove);
      return () => {
        map.off("move", onMove);
      };
    }, [map, onMove]);

    return (
      <p>
        <button onClick={onClick}>Center</button>
      </p>
    );
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add Field" : "Edit Field"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="fieldName"
                      >
                        Field Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="fieldName"
                        name="fieldName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fieldName}
                      />
                      {formik.touched.fieldName && formik.errors.fieldName ? (
                        <div className="text-red-500">
                          {formik.errors.fieldName}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Address
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="address"
                        name="address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <div className="text-red-500">
                          {formik.errors.address}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Image
                      </label>
                      {selectedField?.image && type !== "add" ? (
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}${selectedField?.image}`}
                        />
                      ) : (
                        ""
                      )}
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="image"
                        name="image"
                        type="file"
                        onChange={(e) => {
                          formik.setFieldValue("image", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        value={undefined}
                      />
                      {formik.touched.image && formik.errors.image ? (
                        <div className="text-red-500">
                          {formik.errors.image}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="location"
                      >
                        Location
                      </label>
                      <MapContainer
                        center={center}
                        zoom={13}
                        scrollWheelZoom={false}
                        ref={setMap}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <DraggableMarker />
                        {/* <MyComponent /> */}
                      </MapContainer>

                      {map ? <DisplayPosition map={map} /> : null}

                      <div className="flex gap-4">
                        <div className="flex flex-col gap-2">
                          <span>Lat</span>
                          <input
                            value={position.lat}
                            onChange={(e) => {
                              setPosition({ ...position, lat: e.target.value });
                              setCenter({ ...center, lat: e.target.value });
                            }}
                            type="number"
                            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <span>Lng</span>
                          <input
                            value={position.lng}
                            onChange={(e) => {
                              setPosition({ ...position, lng: e.target.value });
                              setCenter({ ...center, lng: e.target.value });
                            }}
                            type="number"
                            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Select avenue
                      </label>
                      {/* <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="image"
                        name="image"
                        type="file"
                        onChange={(e) => {
                          formik.setFieldValue("image", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        value={undefined}
                      /> */}
                      <select
                        onChange={(e) => {
                          setSelectedAvenue(e.target.value);
                          // console.log("dropdown", e.target.value);
                        }}
                        value={selectedAvenu}
                      >
                        <option className="text-red-500" value="">
                          Please select
                        </option>
                        {venues.map((item) => {
                          return (
                            <option className="text-red-500" value={item._id}>
                              {item.venueName}
                            </option>
                            // <p>{item.venueName}</p>
                          );
                        })}
                      </select>
                      {/* {venues.map((item) => {
                        return (
                          <option value={item._id}>{item.address}</option>
                          // <p>{item.venueName}</p>
                        );
                      })} */}
                    </div>

                    <div className="w-full flex gap-2">
                      <input
                        type="time"
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	w-1/2"
                        min="00:00"
                        max="24:00"
                        value={timeInput}
                        onChange={(e) => setTimeInput(e.target.value)}
                      />
                      <span
                        className="normalButton w-fit py-2 "
                        onClick={() => handleAddTimeSlot()}
                      >
                        Add time slot
                      </span>
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="rivalry"
                      >
                        Time Slots
                      </label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Slot</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {timeSlots?.map((slot) => {
                            return (
                              <tr>
                                <td>{slot}</td>
                                <td>
                                  <TiDeleteOutline
                                    size={30}
                                    className="cursor-pointer hover:text-red-500 text-xl"
                                    onClick={() => handleRemoveTimeSlot(slot)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button
                        type="submit"
                        className="normalButton w-fit py-2 flex"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <ReactLoading
                            type="spin"
                            color="#FFFFFF"
                            width={24}
                            height={24}
                          />
                        )}
                        {type == "add" ? "Add Field" : "Edit Field"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddField;
