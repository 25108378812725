import React from "react";

export default function SmokingPolicies() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">
        No smoking in spectator areas at public sports grounds
      </h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          Section 6A of the Smoke-free Environment Act 2000 makes a number of
          outdoor public places smoke-free. From 7 January 2013, smoking is
          banned in spectator areas at public sports grounds and other
          recreational areas in NSW. However, smoking will only be banned when
          an organised sporting event is being held. Major sporting facilities
          are included under the definition of a public sports ground and will
          be required to comply with the new law. Other outdoor sporting
          facilities, such as Local Council playing fields, are also covered by
          the new law.
        </p>
        <h3>
          Will smoking be banned only in spectator areas which are covered?
        </h3>
        <p>
          No. The smoking ban applies to all spectator areas at sports grounds
          and other recreational areas when they are being used for an organised
          sporting event. The law applies to both covered and uncovered
          spectator areas and whether seating is provided or not.
        </p>
        <h3>
          Will the ban only cover the time that the players are competing?
        </h3>
        <p>
          No. The smoking ban applies during the entire duration of the
          organised sporting event. This will include not just when the players
          are competing but pre match and half time games and entertainment that
          form part of the sporting event.
        </p>
        <h3>
          Will there be signage to indicate where smoking is not permitted?
        </h3>
        <p>
          Due to the vast array of different sports grounds and recreational
          areas, the new law does not require signs to be displayed to indicate
          that smoking is not permitted in spectator areas at sports grounds and
          other recreational areas during organised sporting events. However,
          signage may be required in the future if regulations are made
          requiring a class of sports grounds or other recreational areas to
          display signs.
        </p>
        <h3>What is a spectator area?</h3>
        <p>
          A spectator area is an area set aside for or being used by spectators
          to watch an organised sporting event at a sports ground or other
          recreational area, but only when an organised sporting event is being
          held there.
        </p>
        <h3>How will this be enforced?</h3>
        <p>
          NSW Health is responsible for the administration and enforcement of
          the Smoke-free Environment Act 2000. NSW Health Inspectors are
          authorised to enforce the ban at public sports grounds and
          recreational areas during organised sporting events.
        </p>
        <p>
          Some major sporting facilities have already banned smoking in outdoor
          seating areas but permit smoking in designated outdoor smoking areas
          within the facility. The new law will prohibit smoking in all
          spectator areas of the facility, regardless of whether seating is
          provided or the area is covered. Where major sporting facilities have
          already introduced smoke-free outdoor policies which extend beyond the
          new laws, these can continue to apply
        </p>
        <p>
          Penalties of up to $550 apply to individuals who smoke in spectator
          areas at public sports grounds or other recreational areas during
          organised sporting events.
        </p>
        <h3>Why is this new Act in place?</h3>
        <p>
          Public sports grounds are a popular public outdoor setting for
          exercise and recreation and often attract large numbers of people,
          particularly families with children.
        </p>
        <p>
          There is no safe level of exposure to second-hand tobacco smoke. This
          is the smoke which smokers exhale after inhaling from a lit cigarette
        </p>
        <p>
          In adults, breathing second-hand tobacco smoke can increase the risk
          of cardiovascular disease, lung cancer and other lung diseases. It can
          exacerbate the effects of other illnesses such as asthma and
          bronchitis. Exposing ex-smokers to other people’s tobacco smoke
          increases the chance of relapsing to smoking.
        </p>
        <p>
          For children, inhaling second-hand tobacco smoke is even more
          dangerous. This is because children’s airways are smaller, and their
          immune systems are less developed, which makes them more likely to
          suffer negative health consequences of second-hand tobacco smoke such
          as bronchitis, pneumonia and asthma.
        </p>
        <p>
          Creating smoke-free outdoor areas, such as in public sports grounds
          and recreational areas, can provide a supportive environment for those
          who have quit and make smoking less visible to children and young
          people.
        </p>
        <h3>How does this affect Local Council bans on smoking?</h3>
        <p>
          Many NSW councils, under the provisions of the Local Government Act
          1993, have progressively introduced their own smoking bans. Where
          these bans are in place, they can continue to be enforced by Local
          Council rangers.
        </p>
        <p>
          NOTE: The ban on smoking in commercial outdoor dining areas and within
          4 metres of a pedestrian entrance to or exit from licenced premises,
          restaurants and cafes does not apply until 6 July 2015.
        </p>
        <p>
          For more information Please contact the Tobacco Information Line on
          1800 357 412 or visit the NSW Health
        </p>
        <p>
          website: <a href="www.health.nsw.gov.au">www.health.nsw.gov.au </a>
        </p>
        <p>
          The Tobacco Information Line can be accessed by non-English speaking
          people via the Translating and Interpreting Service (TIS) on 13 14 50.
        </p>
      </div>
    </div>
  );
}
