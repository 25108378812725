import React from "react";
import ReactAudioPlayer from "react-audio-player";

function PodcastCard(props) {
  // console.log("item", props?.item?.title);
  return (
    <div className="flex flex-col-reverse md:flex-row  justify-between border-2 rounded-2xl">
      <div className="flex flex-col p-8 gap-3">
        <h2 className="font-semibold text-2xl">{props?.item?.title}</h2>
        <p>{props?.item?.description}</p>
        <div className="flex items-center gap-5">
          <div className="flex flex-col">
            <span className="text-lg font-semibold">
              Host : {props.item?.host?.name}
            </span>
            <span className="text-lg font-normal text-[#828D9E]">
              {props.item?.host?.title}
            </span>
          </div>
          <span>With</span>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">
              Host : {props.item?.guest?.name}
            </span>
            <span className="text-lg font-normal text-[#828D9E]">
              {props.item?.guest?.title}
            </span>
          </div>
        </div>
        <ReactAudioPlayer
          src={`${process.env.REACT_APP_SERVER_URL}${props?.item?.voice}`}
          controls
          className="w-full mt-5 "
        />
      </div>
      <img
        src={`${process.env.REACT_APP_SERVER_URL}${props?.item?.image}`}
        alt="podcastCardImg"
        className="w-fit"
      />
    </div>
  );
}

export default PodcastCard;
