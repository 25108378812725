import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useState } from "react";
import api from "../../api";
import { useCookies } from "react-cookie";
import { failNotification, successNotification } from "../Notifications";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentForm, PaymentIntent } from "../../utils/PaymentIntent";
import { loadStripe } from "@stripe/stripe-js";
import { checkDiscount } from "../../utils/CheckDiscount";

function AddNewTeamPopup(props) {
  const { show, setShow, playerInfo, getPlayerInfo } = props;
  const [teamCode, setTeamCode] = useState();
  const [cookies] = useCookies(["token"]);
  const [payNow, setPayNow] = useState(true);
  const [dicountPercentage, setDiscountPercentage] = useState(0);
  const [discountMsg, setDiscountMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [teamCodeValidation, setTeamCodeValidation] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [compPrice, setCompPrice] = useState(undefined);
  const [discount, setDiscount] = useState("");
  const [options, setOptions] = useState("");
  const handleTeamCode = async (teamCode) => {
    setErrorMsg("");
    try {
      const response = await api.post("/auth/api/v1/checkTeamCode", {
        teamCode,
      });
      // console.log(response);
      if (response?.status === 200) {
        setTeamCodeValidation(() => true);
        setTeamCode(() => teamCode);
        setTeamName(() => response.data.team.teamName);
        if (new Date(response.data.team.rivelary.priceLimitDate) > new Date()) {
          setCompPrice(response.data.team.rivelary.priceBefore);
        } else {
          setCompPrice(response.data.team.rivelary.priceAfter);
        }
      } else {
        setTeamCodeValidation(false);
      }
    } catch (error) {
      setTeamCodeValidation(false);
      failNotification(error.response.data.msg);
    }
  };

  const handleRegisterTeam = async () => {
    try {
      await api.put(
        "/auth/api/v1/addTeamToPlayer",
        { teamCode },
        { headers: { token: cookies.token } }
      );
      successNotification("Team registered successfully");
    } catch (e) {
      failNotification(e.response.data.msg);
    } finally {
      setTeamCode();
      setShow(false);
      getPlayerInfo();
    }
  };

  const handleDiscountChange = async (code) => {
    if (code.length === 6) {
      const discountResponse = await api.get(
        `crud/api/v1/getDiscountByCode?code=${code}`
      );
      if (!discountResponse?.data) {
        setDiscountMsg("invalid code");
      } else {
        setDiscount(discountResponse.data);
        setDiscountMsg(
          `Discount Percentage is ${discountResponse?.data.value} %`
        );
        setDiscountPercentage(discountResponse?.data.value);
      }
    } else {
      setDiscountMsg("");
      setDiscountPercentage(0);
    }
  };

  const handlePaySubmit = async () => {
    try {
      successNotification("Team registered successfully");
      setTeamName("");
    } catch (e) {
      failNotification(e.response.data.msg);
    } finally {
      setTeamCode();
      setShow(false);
      getPlayerInfo();
      setTeamName("");
    }
  };

  const handleSubmitForm = async () => {
    // const { amount, discount, payOnPremises, teamCode } = req.body;

    // const payOnPremises = !payNow;

    const supscribeToteam = await api.post(
      "auth/api/v1/paymentIntentForSubscriberToTeam",
      { payOnPremises: !payNow, amount: compPrice * 100, discount, teamCode },
      { headers: { token: cookies.token } }
    );
    setOptions({
      clientSecret: supscribeToteam?.data.client_secret,
    });
    console.log(supscribeToteam);
    if (payNow) {
      setPayModal(true);
    } else {
      handlePaySubmit();
    }
  };

  const [payModal, setPayModal] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  // const stripePromise = loadStripe(
  //   "pk_test_51MsY0jFsHqSwSqLjKF3AK1VtLSLKohx2a5qovxDUfYRZMRPVVQiWn9fecVI9vQv1zr7exO453wsgvvuXVWXEXtrP00Sk3GXXVl"
  // );

  return (
    <Fragment>
      {payModal && teamCodeValidation && options ? (
        <Elements stripe={stripePromise} options={options}>
          <PaymentIntent
            options={options}
            payModal={payModal}
            setPayModal={setPayModal}
            playerData={playerInfo}
            handlePaySubmit={handlePaySubmit}
            discount={dicountPercentage}
            price={compPrice}
          />
        </Elements>
      ) : null}
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Register New Team
                  </Dialog.Title>

                  <div className="mt-5 flex flex-col gap-3">
                    <input
                      onChange={(e) => handleTeamCode(e.target.value)}
                      type="text"
                      placeholder="Team Code"
                      className='className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"'
                    ></input>
                    {!teamCodeValidation && teamCode && (
                      <span className="text-red-500"> Code is not valid</span>
                    )}
                    {teamCodeValidation && teamCode && (
                      <span> The team name is {teamName} </span>
                    )}
                    <div className="flex flex-row my-3 items-center">
                      <input
                        type="checkbox"
                        checked={!payNow}
                        onChange={() => setPayNow(!payNow)}
                        className="w-4 h-4 text-[#00B5FF] bg-[#fff] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
                      />
                      <label className="  font-medium text-base ml-3 ">
                        On premises payment
                      </label>
                    </div>
                    {!payNow ? (
                      ""
                    ) : (
                      <>
                        <p className="font-medium text-base mt-3">
                          Discount Code
                        </p>
                        <input
                          maxLength={10}
                          placeholder="Discount Code"
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          onChange={(e) => handleDiscountChange(e.target.value)}
                        />
                        <p
                          className={`text-${
                            dicountPercentage > 0 ? "green-500" : "red-500"
                          }`}
                        >
                          {discountMsg}
                        </p>
                      </>
                    )}
                    <button
                      className="text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2"
                      onClick={() => handleSubmitForm()}
                    >
                      {!payNow ? "Register" : "Pay Now"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}

export default AddNewTeamPopup;
