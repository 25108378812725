import React from "react";

export default function TPEPJ() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">
        Tournament player eligibility policy - juniors
      </h1>
      <h1 className="text-center pb-8">updated march 2019</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          For a player to be eligible to compete in an official AO Junior
          Representative Tournament they must meet the below requirements.
          Associations who play in-eligible players will receive tough
          penalties. These penalties are outlined at the end of this policy.
        </p>
        <p className="font-semibold">
          Player requirements to be eligible to compete in an AO Junior
          representative tournament:
        </p>
        <p>
          1. Players must have a current Individual Registration Number with AO
          and be recorded on AO’s national database.
        </p>
        <p>
          2. Players must be a registered player in the Association / Region
          they are representing and have played a minimum of seven games in the
          one team over a seven week period in the Association’s
          <span className="font-semibold">
            Current* Junior Domestic Competition which must run for 10 weeks or
            more.
          </span>
        </p>
        <p className="pl-8">
          2.1 Current season means a junior competition offered over the spring
          or summer leading into the tournament. The competition can finish
          after the tournament date. Players will only qualify for the
          Association / Region if they have played a minimum of 7 games in the
          one team over a 7 week period in the junior domestic competition they
          are representing prior to the tournament. If the competition has not
          completed 7 or more rounds the player must have participated in the
          domestic competition from last season.
        </p>
        <p>
          3. An exception will be made for players who are still eligible (15
          years +) to play both juniors & seniors, but don’t have a junior
          domestic competition in their area. If this is the case they must be a
          registered player at the Senior Competition. In this instance a Senior
          Association would be permitted to enter a Junior Team in a Junior AO
          Representative Tournament.
        </p>
        <p className="font-semibold">
          4. If there is a junior domestic competition offered by the
          Association / Region you are representing you must be a registered
          player in that junior competition to represent at a Junior Tournament.
        </p>
        <p className="font-semibold">
          5. If there is a junior competition offered in the area/region then a
          Senior Association is not permitted to enter teams in the Junior
          representative tournament.
        </p>
        <p>
          6. If there is both a Junior Association and a Senior Association in
          the same area (LGA), whether a single person holds the licence to
          operate both or 2 different people hold the licence, players must be
          registered in the Junior competition to be eligible to compete in a
          Junior Representative tournament. Only being registered in the senior
          competition does not qualify a player for a Junior Representative
          Tournament, regardless of the player’s age.
        </p>
        <p>
          7. Players who have not played the seven games due to extenuating
          circumstances may apply in writing to the tournament director for
          permission to play. E.g. injury etc. If a player is granted an
          exemption they may only qualify to represent an Association / Region
          at which they have previously played.
        </p>
        <p>
          8. Age divisions are determined by the age you turn that year – e.g.
          if you turn 11 in December the year of the tournament you will be
          playing in the 11’s division at the tournament regardless if the
          player is still only 10 when the tournament is played.
        </p>

        <p>
          9. Proof of age – if a player’s age is questioned a birth certificate,
          passport or other identification must be supplied upon request. In
          some instances you may be given within seven days of the tournament to
          produce. If a breach of the policy is proven the team will be subject
          to penalties outlined in this policy. If it was the winning team they
          will be stripped of the title and the second placed team will be
          crowned champions.
        </p>
        <p>
          10. Players who miss the Summer Junior Competition due to being
          contracted in any Harold Matthews, SG Ball league teams and are
          eligible to compete in the Junior State Cup and Junior Nationals, must
          show prior history in Junior Oztag, will be given dispensation to
          compete.
        </p>
        <h3>Complaints</h3>
        <p>
          11. If an official complaint is received the Tournament Director will
          investigate the eligibility of the player. Complainants may remain
          anonymous.
        </p>
        <p>
          12. Associations who cannot provide evidence at the time of the
          complaint will have seven days after the tournament to produce the
          evidence to show the player was eligible to play.
        </p>
        <h3>PENALTIES</h3>
        <p>
          <span className="font-semibold"> 13. First Offence:</span>
          Any team playing an ineligible player/s will be disqualified from the
          tournament. If it is proven after the conclusion of the tournament, a
          title if won will be stripped from the Association and the runners up
          be awarded that division. The coach, manager and player/s involved
          will be suspended from playing / coaching at any representative level
          for any association for a period of 12 months. This disqualification
          will include the same tournament if it falls outside the 12 months the
          following year. The association/region will also be fined $500. THE
          ONUS IS ON THE COACH & MANAGER TO ENSURE ALL PLAYERS ARE ELIGIBLE.
        </p>
        <p>
          <span className="font-semibold">14. Second Offence:</span>The coach,
          manager and player/s involved will be suspended from playing /
          coaching at any representative level for any association for a period
          of 12 months. The association will receive a $1000.00 fine.
        </p>
        <p>
          <span className="font-semibold"> 15. Third Offence:</span>: If an
          association is caught on 3 separate occasions of playing ineligible
          players the association may be disqualified from entering teams in any
          division at any AO tournament for 12 months or a period set by the AO.
        </p>
      </div>
    </div>
  );
}
