import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  failNotification,
  successNotification,
} from "../components/Notifications";
import { useState } from "react";

import api from "../api";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import PaymentForm from "../components/paymentForm";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../components/loader";

export const PaymentIntent = ({
  options,
  payModal,
  setPayModal,
  playerData,
  handlePaySubmit,
  discount,
  price,
}) => {
  const stripe = useStripe();
  const element = useElements();
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState("Pay");
  const [errMsg, setErrMsg] = useState("");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setProcessing(true);
  //   setStatus("Processing ...");
  //   console.log(e);
  //   const cardElement = element.getElement("card");
  //   try {
  //     const paymentIntent = await api.post("/ecommerce/api/v1/checkout", {
  //       amount: ((100 - discount) / 100) * price * 100,
  //     });
  //     const paymentMethodObj = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: cardElement,
  //       billing_details: {
  //         email: playerData.email,
  //         phone: playerData.phoneNumber,
  //         name: playerData.firstName,
  //       },
  //     });
  //     if (paymentMethodObj.error) {
  //       setErrMsg(paymentMethodObj.error.message);
  //       setProcessing(false);
  //       setStatus("Pay");
  //       return;
  //     }
  //     const confirmPayment = await stripe.confirmCardPayment(
  //       paymentIntent.data,
  //       { payment_method: paymentMethodObj.paymentMethod.id }
  //     );
  //     if (confirmPayment.error) {
  //       setErrMsg(confirmPayment.error.message);
  //       setProcessing(false);
  //       setStatus("Pay");
  //       return;
  //     }
  //     setStatus("Successfully Payment is complete");
  //     // handlePay();
  //     successNotification("Payment submitted successfully");
  //     handlePaySubmit();
  //   } catch (error) {
  //     setErrMsg(error.message);
  //     setProcessing(false);
  //     setStatus("Pay");
  //     failNotification(error.message);
  //   }
  // };

  return (
    <Transition appear show={payModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setPayModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Register Now
                </Dialog.Title>
                <div className="flex justify-between border-y-2 py-2  mb-6">
                  <span>Amount to be paid</span>
                  <span>$ {((100 - discount) / 100) * price}</span>
                </div>

                <PaymentForm returnUrl={`/`} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
