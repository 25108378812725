import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
// import { createPortal } from "react-dom";
import FieldModal from "./FieldModal";

function GameDetailModal({ show, setShow, gameData }) {
  const cancelButtonRef = useRef(null);

  const [showField, setShowField] = useState(false);

  const handleOpenField = () => {
    setShowField(true);
    // return<FieldModal show={true} setShow={setShowField} selectedField={gameData?.field} />
  };

  return (
    <>
      <FieldModal
        show={showField}
        setShow={setShowField}
        selectedField={gameData?.field}
      />

      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShow}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 scale-90">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex flex-col sm:items-center">
                      <div className="flex justify-center w-full">
                        <div className="flex flex-col justify-center items-center px-16 py-16 w-full y-fit">
                          <h2 className="text-[32px] font-semibold mb-5">
                            Game Details
                          </h2>
                          <h3 className="text-[28px] font-semibold my-3">
                            {gameData?.gameDate?.slice(0, 10)}
                          </h3>
                          <h3 className="text-[28px] font-semibold my-3">
                            {gameData?.rivelary?.rivelaryName}
                          </h3>
                          <h3 className="text-[28px] font-semibold my-3">
                            {gameData?.area?.areaName}
                          </h3>
                          <h3
                            className="text-[28px] font-semibold my-3 cursor-pointer"
                            onClick={() => handleOpenField()}
                          >
                            {gameData?.field?.fieldName}
                          </h3>
                          {/* <h5>Final Score</h5> */}
                          <div className="flex flex-col my-3 w-full">
                            <div className="scoreDiv items-center">
                              <span className="mt1 heading3">
                                {gameData.firstTeam?.teamName}
                              </span>
                              {/* <div className="timeField">
                                <div className="innerTimeField">
                                  <span className="timeText heading3">
                                    {gameData.firstTeamScore}
                                  </span>
                                </div>
                              </div> */}
                              <span className="mt1 heading3">VS</span>

                              {/* <div className="timeField">
                                <div className="innerTimeField">
                                  <span className="timeText heading3">
                                    {gameData.secondTeamScore}
                                  </span>
                                </div>
                              </div> */}
                              <span className="mt1 heading3">
                                {gameData.secondTeam?.teamName}
                              </span>
                            </div>
                            <div className="w-full flex justify-center">
                              <button
                                className="py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-1/4"
                                onClick={() => setShow(false)}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setShowResetPassModal(false)}
                                        >
                                            Deactivate
                                        </button>
                                        <button
                                            type="button"
                                            className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setShowResetPassModal(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>

    // <>
    //     {show ? <>
    //         <div className="flex items-center min-h-screen px-4 py-8 fixed inset-0 bg-black opacity-80 z-10" onClick={() => {
    //             //  setSteps(1)
    //             setShow(false)
    //         }}>

    //         </div>
    //         <div className="fixed inset-x-1/4  md:w-1/2 y-fit top-1/4  my-auto   bg-white rounded-md shadow-lg flex flex-col z-20">
    //             <div className='w-full text-right'>
    //                 <span className='px-3 cursor-pointer' onClick={() => setShow(false)}>X</span>
    //             </div>
    // <div className="flex justify-center w-full">
    //     <div className='flex flex-col justify-center items-center px-16 py-16 w-full y-fit'>
    //         <h2 className='text-[32px] font-semibold mb-5'>Game Details</h2>
    //         <h3 className='text-[28px] font-semibold my-3'>Compeition Name</h3>
    //         <h3 className='text-[28px] font-semibold my-3'>Area</h3>
    //         <h3 className='text-[28px] font-semibold my-3'>Field 1</h3>
    //         <h5>Final Score</h5>
    //         <div className='flex flex-col my-3 w-full'>

    //             <div className='scoreDiv'>

    //                 <span className='mt1 heading3'>Chester Hill</span>
    //                 <div className='timeField'>
    //                     <div className='innerTimeField'>
    //                         <span className='timeText heading3'>2</span>
    //                     </div>
    //                     <span className='timeTypeText'>-</span>
    //                 </div>
    //                 <span className='mt1 heading3'>VS</span>

    //                 <div className='timeField'>
    //                     <div className='innerTimeField'>
    //                         <span className='timeText heading3'>3</span>
    //                     </div>
    //                     <span className='timeTypeText'>-</span>
    //                 </div>
    //                 <span className='mt1 heading3'>Five Dock</span>

    //             </div>
    //             <div className='w-full flex justify-center'>

    //             <button className='py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-1/4' onClick={() => setShow(false)}>Done</button>
    //             </div>

    //         </div>
    //     </div>
    // </div>
    //         </div>
    //     </> : ''}
    // </>
  );
}

export default GameDetailModal;
