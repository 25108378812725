import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import api from "../api";
import CheckoutForm from "./checkoutForm";

// const stripePromise = loadStripe("pk_test_51MsY0jFsHqSwSqLjKF3AK1VtLSLKohx2a5qovxDUfYRZMRPVVQiWn9fecVI9vQv1zr7exO453wsgvvuXVWXEXtrP00Sk3GXXVl")

const CheckOut = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

  return (
    <div className={""}>
      <CheckoutForm />
    </div>
  );
};
export default CheckOut;
