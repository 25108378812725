import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CompetitionTable from "../components/CompetitionTable";
import DownloadApp from "../components/DownloadApp";
import { useCookies } from "react-cookie";
import api from "../api";
// import Sp from "../components/SpecialOfferItem";
import SpecialOfferItemTwo from "../components/specialOfferitemTwo";
import LatestScores from "../components/LatestScores";
import Tables from "../components/Tables";
import ComingGameCard from "../components/ComingGameCard";
import { Carousel } from "react-bootstrap";
import GameScoreCard from "../components/GameScoreCard";
import ClipLoader from "react-spinners/ClipLoader";
import ComptetionTables from "../components/comptetions/ComptetionTables";
import { failNotification } from "../components/Notifications";
import CompetitioGeneralInfo from "./CompetitioGeneralInfo";
import CarouselHome from "../components/CarouselHome";
import SponsorSlider from "../components/SponsorSlider";
import CommonSlider from "../components/CommonSlider";

function CompetitionInner() {
  const location = useLocation();
  const [divisions, setDivisions] = useState([]);
  const [type, setType] = useState("Senior");
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");

  const [games, setGames] = useState([]);
  const [generalInfo, setGeneralInfo] = useState();

  const getLatestGames = async () => {
    setLoading(true);
    try {
      const compGames = await api.get(
        "/games/api/v1/getUpcomingGamesComptetion",
        { params: { _id: location.state._id, type: type } }
      );
      setGames(compGames.data);
    } catch (error) {
      // console.log(error.response.data.msg);
      // failNotification(error.response.data.msg);
      setGames([]);
    } finally {
      setLoading(false);
    }
  };

  const getDivisions = async () => {
    setGeneralInfo("");
    setLoading(true);
    try {
      const compt = await api.get(
        "/rivelary/api/v1/getRivelaryDivisons",
        { params: { _id: location.state._id, type: type } }
        // { headers: { token: cookies.token } }
      );
      setDivisions(compt.data);
      getLatestGames();
      // console.log(user)
    } catch {
      // console.log("error");
      // navigate('/')
    }
  };
  const getGeneralInfo = async () => {
    try {
      setType("");
      setLoading(true);
      const info = await api.get(
        "/rivelary/api/v1/getRivelaryGeneralInfo",
        { params: { _id: location.state._id } }
        // { headers: { token: cookies.token } }
      );
      setGeneralInfo(info.data);
    } catch (error) {
      // failNotification(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDivisions();
    setTitle(location.state.rivelaryName);
  }, [type]);
  // console.log(generalInfo, "-=-=-generalInfo=-=<<");
  return (
    <Fragment>
      <CommonSlider
        query={`position=competition&competition=${location.state._id}`}
      />

      <h1 className="font-bold text-center my-5">{title}</h1>
      <div className="w-full  flex flex-row justify-center">
        <button
          className={`px-20 py-2 rounded-l-md ${
            type === "Senior" ? "bg-[#00B5FF] text-white" : "bg-[#E2E5EA]"
          } `}
          onClick={() => setType("Senior")}
        >
          Senior
        </button>
        <button
          className={`px-20 py-2 ${
            type === "Junior" ? "bg-[#00B5FF] text-white" : "bg-[#E2E5EA]"
          }`}
          onClick={() => setType("Junior")}
        >
          Junior
        </button>
        <button
          className={`px-20 py-2 rounded-r-md ${
            generalInfo ? "bg-[#00B5FF] text-white" : "bg-[#E2E5EA]"
          }`}
          onClick={() => getGeneralInfo()}
        >
          General Info
        </button>
      </div>
      {games.length > 0 && !generalInfo ? (
        <div className="mainFeaturedProductsDiv">
          <div className="innerDiv">
            <p className="headerTextFeatured">Upcoming Games</p>
            <p className="normalTextFeatured">
              High quality training and game jerseys and other merchandise to
              support your game play
            </p>
            <div className="w-full  mt-10">
              <Carousel variant="dark">
                {games?.map((game) => {
                  return (
                    <Carousel.Item key={game._id}>
                      <ComingGameCard gameData={game} />
                    </Carousel.Item>
                  );
                })}
                {/* <Carousel.Item>
            <ComingGameCard />
          </Carousel.Item>
          <Carousel.Item>
            <ComingGameCard />
          </Carousel.Item> */}
              </Carousel>
            </div>
            {/* <div className="itemsMainDiv">
            {games.map((game) => {
              return (
                <div className="itemsDiv" key={game._id}>
                  <ComingGameCard gameData={game} />
                </div>
              );
            })}
            <div className="itemsDiv">
              <SpecialOfferItemTwo />
            </div>
            <div className="itemsDiv">
              <SpecialOfferItemTwo />
            </div>
          </div> */}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="mainDiv">
        {games.length > 0 && !generalInfo ? (
          <div className="bigDiv">
            <p className="headerTextFeatured">Latest Scores</p>
            <p className="normalTextFeatured">
              View all the latest game scores
            </p>
            <div className="gameScoreCardWrapper">
              {loading ? (
                <ClipLoader
                  color="#0FA9E8"
                  loading={loading}
                  size={50}
                  speedMultiplier={1}
                />
              ) : (
                ""
              )}
              {games?.map((game) => {
                return <GameScoreCard key={game._id} gameData={game} />;
              })}
            </div>
            {/* <div className="buttonDiv">
              <button className="normalButton mt20">
                <Link to="/scores" className="no-underline text-white">
                  All Scores
                </Link>
              </button>
            </div> */}
          </div>
        ) : (
          ""
        )}

        {/* <LatestScores /> */}
        {divisions.length > 0 ? (
          <ComptetionTables title={title} divisions={divisions} />
        ) : (
          ""
        )}
      </div>
      {/* General Info */}
      {generalInfo && <CompetitioGeneralInfo competition={generalInfo} />}
      <div className="container">
        <SponsorSlider />
        <DownloadApp />
      </div>
    </Fragment>
  );
}

export default CompetitionInner;
