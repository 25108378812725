import React from 'react'
import OrderSummary from './OrderSummary'
import Stepper from './Stepper'

function TeamRegForm4({ currentStep = 0 ,setCurrentStep}) {
    return (
        <div className={currentStep !== 4 ? 'hidden' : 'hidden'}>
            <h1 className='font-semibold text-4xl my-5'>Payment Details</h1>
            <div className='flex flex-row justify-center w-[1280px] gap-5'>
                <div className='w-2/3'>

                    <div className='border-l pl-5'>
                        <h2 className='font-semibold text-2xl my-5'>1. Personal Details</h2>
                        <div className='grid grid-cols-2 gap-3 my-10'>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>First Name</p>
                                <input type="text" placeholder='First Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Last Name</p>
                                <input type="text" placeholder='Last Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>E-mail</p>
                                <input type="email" placeholder='E-mail' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Phone Number</p>
                                <input type="number" placeholder='Phone Number' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                        </div>
                        <div className='flex flex-row my-5 items-center'>
                            <input type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                            <label className='font-medium text-base ml-3 '>I agree to the Privacy Policy and Terms and Conditions</label>
                        </div>
                    </div>

                    <div className='border-l pl-5'>
                        <h2 className='font-semibold text-2xl my-5'>2. Shipping Details</h2>
                        <div className='grid grid-cols-2 gap-3 my-10'>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Street Address</p>
                                <input type="text" placeholder='Street Address' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>ZIP Code</p>
                                <input type="text" placeholder='ZIP Code' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>City</p>
                                <input type="text" placeholder='City' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Country</p>
                                <input type="text" placeholder='United States' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                        </div>

                    </div>
                    <div className='border-l pl-5'>
                        <h2 className='font-semibold text-2xl my-5'>4. Payment Method</h2>
                        <div className='grid grid-cols-2 gap-3 my-10'>
                            <div className=' border border-[#E2E5EA] rounded-lg flex justify-center items-center py-4 cursor-pointer' >
                                <svg width="200" height="48" viewBox="0 0 75 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28.0438 4.02539H23.8934C23.6094 4.02539 23.3679 4.22819 23.3236 4.50375L21.645 14.9639C21.6116 15.1703 21.7742 15.3564 21.9873 15.3564H23.9687C24.2527 15.3564 24.4942 15.1536 24.5385 14.8774L24.9912 12.0561C25.0349 11.78 25.2771 11.5772 25.5605 11.5772H26.8743C29.6083 11.5772 31.1861 10.2769 31.5982 7.70019C31.7839 6.57288 31.606 5.68713 31.069 5.06681C30.4791 4.38565 29.4329 4.02539 28.0438 4.02539ZM28.523 7.84583C28.296 9.30954 27.1582 9.30954 26.0579 9.30954H25.4316L25.871 6.57596C25.8971 6.41074 26.0428 6.28906 26.2127 6.28906H26.4997C27.2492 6.28906 27.9562 6.28906 28.3215 6.70897C28.5394 6.95948 28.6061 7.33168 28.523 7.84583Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M40.4491 7.79894H38.4617C38.2923 7.79894 38.1461 7.92062 38.12 8.08584L38.032 8.6322L37.893 8.43418C37.4628 7.82042 36.5033 7.61523 35.5457 7.61523C33.3494 7.61523 31.4736 9.25013 31.1083 11.5435C30.9183 12.6875 31.1884 13.7814 31.8487 14.5443C32.4543 15.2458 33.3209 15.538 34.352 15.538C36.1216 15.538 37.1029 14.4197 37.1029 14.4197L37.0143 14.9624C36.9809 15.17 37.1435 15.3561 37.3553 15.3561H39.1456C39.4302 15.3561 39.6705 15.1533 39.7154 14.8771L40.7896 8.19142C40.8236 7.98564 40.6615 7.79894 40.4491 7.79894ZM37.6788 11.6008C37.487 12.7168 36.5858 13.4659 35.4364 13.4659C34.8593 13.4659 34.3981 13.284 34.1019 12.9392C33.8082 12.5969 33.6966 12.1096 33.79 11.5668C33.969 10.4604 34.8854 9.68674 36.0172 9.68674C36.5816 9.68674 37.0404 9.87105 37.3426 10.2188C37.6454 10.5701 37.7656 11.0604 37.6788 11.6008Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M51.0353 7.79883H49.0381C48.8476 7.79883 48.6685 7.89188 48.5605 8.04755L45.806 12.0355L44.6383 8.20323C44.5649 7.96345 44.3398 7.79883 44.0849 7.79883H42.1223C41.8838 7.79883 41.7181 8.02787 41.794 8.24856L43.9939 14.5937L41.9257 17.4633C41.763 17.6893 41.9269 18.0001 42.2079 18.0001H44.2026C44.392 18.0001 44.5692 17.9094 44.6766 17.7567L51.3193 8.33266C51.4783 8.1072 51.3151 7.79883 51.0353 7.79883Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M57.6473 4.02539H53.4963C53.2129 4.02539 52.9714 4.22819 52.9271 4.50375L51.2485 14.9639C51.2151 15.1703 51.3778 15.3564 51.5896 15.3564H53.7196C53.9175 15.3564 54.0868 15.2144 54.1178 15.0211L54.5941 12.0561C54.6378 11.78 54.88 11.5772 55.1634 11.5772H56.4766C59.2112 11.5772 60.7884 10.2769 61.2011 7.70019C61.3874 6.57288 61.2084 5.68713 60.6713 5.06681C60.082 4.38565 59.0364 4.02539 57.6473 4.02539ZM58.1261 7.84572C57.8997 9.30944 56.7619 9.30944 55.661 9.30944H55.0353L55.4753 6.57586C55.5014 6.41064 55.6458 6.28896 55.8164 6.28896H56.1034C56.8523 6.28896 57.5599 6.28896 57.9252 6.70887C58.1431 6.95938 58.2092 7.33157 58.1261 7.84572Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M70.052 7.79894H68.0657C67.8952 7.79894 67.7502 7.92062 67.7247 8.08584L67.6367 8.6322L67.4971 8.43418C67.0668 7.82042 66.108 7.61523 65.1504 7.61523C62.9541 7.61523 61.0789 9.25013 60.7136 11.5435C60.5242 12.6875 60.7931 13.7814 61.4534 14.5443C62.0602 15.2458 62.9256 15.538 63.9567 15.538C65.7263 15.538 66.7076 14.4197 66.7076 14.4197L66.619 14.9624C66.5856 15.17 66.7482 15.3561 66.9612 15.3561H68.7509C69.0343 15.3561 69.2758 15.1533 69.3201 14.8771L70.3949 8.19142C70.4277 7.98564 70.265 7.79894 70.052 7.79894ZM67.2817 11.6008C67.0911 12.7168 66.1887 13.4659 65.0393 13.4659C64.4634 13.4659 64.001 13.284 63.7048 12.9392C63.4111 12.5969 63.3006 12.1096 63.3929 11.5668C63.5731 10.4604 64.4883 9.68674 65.6201 9.68674C66.1845 9.68674 66.6433 9.87105 66.9455 10.2188C67.2495 10.5701 67.3697 11.0604 67.2817 11.6008Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M72.3953 4.3124L70.6919 14.964C70.6585 15.1704 70.8211 15.3565 71.0329 15.3565H72.7455C73.0301 15.3565 73.2716 15.1537 73.3153 14.8775L74.9951 4.41797C75.0285 4.21159 74.8659 4.0249 74.6541 4.0249H72.7364C72.5671 4.0255 72.4214 4.14718 72.3953 4.3124Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.41012 17.3893L4.72751 15.4078L4.02052 15.3917H0.644531L2.99067 0.770691C2.99795 0.726553 3.02162 0.685397 3.05621 0.656171C3.0908 0.626944 3.1351 0.61084 3.18122 0.61084H8.87361C10.7634 0.61084 12.0675 0.997347 12.7484 1.76022C13.0676 2.1181 13.2709 2.49208 13.3693 2.90364C13.4724 3.33547 13.4742 3.85141 13.3735 4.48068L13.3662 4.52661V4.92981L13.6854 5.10756C13.9543 5.24773 14.1679 5.40818 14.3317 5.59189C14.6048 5.89787 14.7814 6.28676 14.8561 6.74783C14.9331 7.22201 14.9077 7.78627 14.7814 8.42508C14.6358 9.15992 14.4003 9.79992 14.0823 10.3236C13.7898 10.8061 13.4172 11.2064 12.9748 11.5165C12.5524 11.8112 12.0505 12.0349 11.4831 12.178C10.9333 12.3188 10.3064 12.3898 9.61884 12.3898H9.17583C8.85904 12.3898 8.55136 12.5019 8.30983 12.7029C8.06769 12.9081 7.90748 13.1884 7.85832 13.495L7.82495 13.6733L7.2642 17.1656L7.23872 17.2939C7.23204 17.3344 7.22051 17.3547 7.20352 17.3684C7.18835 17.3809 7.1665 17.3893 7.14526 17.3893H4.41012Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.9874 4.57324C13.9704 4.68001 13.951 4.78916 13.9291 4.9013C13.1784 8.68942 10.6102 9.99805 7.33009 9.99805H5.66C5.25886 9.99805 4.92084 10.2844 4.85833 10.6732L4.00326 16.0032L3.76112 17.5141C3.72046 17.7693 3.92072 17.9996 4.18289 17.9996H7.14499C7.49576 17.9996 7.79373 17.7491 7.84895 17.4091L7.87808 17.2612L8.43579 13.7826L8.4716 13.5917C8.52622 13.2506 8.82479 13 9.17556 13H9.61857C12.4884 13 14.735 11.8548 15.3917 8.5409C15.666 7.15651 15.524 6.00057 14.7982 5.1876C14.5785 4.94245 14.306 4.73906 13.9874 4.57324Z" fill="#DDDFE3" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2012 4.26532C13.0865 4.23251 12.9682 4.20269 12.8468 4.17585C12.7248 4.1496 12.5998 4.12634 12.4711 4.10606C12.0208 4.03449 11.5275 4.00049 10.9989 4.00049H6.53722C6.42738 4.00049 6.323 4.02494 6.22954 4.06908C6.02381 4.1663 5.87088 4.35777 5.83387 4.59218L4.88473 10.5007L4.85742 10.6731C4.91993 10.2842 5.25795 9.9979 5.65909 9.9979H7.32918C10.6093 9.9979 13.1775 8.68867 13.9282 4.90114C13.9507 4.78901 13.9695 4.67986 13.9865 4.57309C13.7965 4.47408 13.5908 4.38938 13.3693 4.31721C13.3147 4.29932 13.2582 4.28202 13.2012 4.26532Z" fill="#828D9E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.83438 4.59215C5.8714 4.35774 6.02433 4.16628 6.23006 4.06965C6.32412 4.02552 6.4279 4.00106 6.53774 4.00106H10.9994C11.528 4.00106 12.0214 4.03506 12.4717 4.10663C12.6003 4.12691 12.7253 4.15018 12.8473 4.17642C12.9687 4.20326 13.087 4.23308 13.2017 4.26589C13.2588 4.28259 13.3152 4.29989 13.3704 4.31718C13.5919 4.38936 13.7977 4.47465 13.9876 4.57307C14.2109 3.17317 13.9858 2.22003 13.2157 1.35695C12.3667 0.406786 10.8343 0 8.87356 0H3.18117C2.78064 0 2.43898 0.286301 2.37708 0.67579L0.00606186 15.4471C-0.0406667 15.7394 0.188728 16.003 0.488519 16.003H4.00287L4.88525 10.5007L5.83438 4.59215Z" fill="#828D9E" />
                                </svg>

                            </div>
                            <div className=' border border-[#E2E5EA] rounded-lg flex flex-col justify-center items-center cursor-pointer' >
                                <span>Add New Card</span>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Card Number</p>
                                <input type="number" placeholder='12345 6789 1011 1213' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Card Holder Name</p>
                                <input type="text" placeholder='Trevor Jensen' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>Expiry Date</p>
                                <input type="month" className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium text-base mt-3 '>CVC/CVV</p>
                                <input type="number" placeholder='CVC/CVV' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                            </div>
                        </div>
                        <div className='flex flex-row my-5 items-center'>
                            <label className='font-medium text-base ml-3 fadedText'>Your transaction is secured with SSL encryption</label>
                        </div>
                    </div>
                </div>
                <div className='w-1/3'>
                    <OrderSummary />
                </div>
            </div>
            <div className='w-full flex gap-5 justify-center col-span-2 items-center'>
                <button className='normalButton w-fit' onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                <button type='submit' className='normalButton w-fit' onClick={() => setCurrentStep(currentStep + 1)}>Next</button>
            </div>


        </div>
    )
}

export default TeamRegForm4