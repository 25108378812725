import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { failNotification, successNotification } from "./Notifications";
import OrderSummary from "./OrderSummary";
import { checkDiscount } from "../utils/CheckDiscount";
import PaymentForm from "./paymentForm";
import { object } from "yup/lib/locale";
function TeamRegForm5({
  currentStep = 0,
  setCurrentStep,
  teamData,
  teamLeader,
  players,
  setShow,
  admin,
}) {
  const [cookies] = useCookies(["token"]);

  // const stripePromise = loadStripe(
  //   "pk_test_51MsY0jFsHqSwSqLjKF3AK1VtLSLKohx2a5qovxDUfYRZMRPVVQiWn9fecVI9vQv1zr7exO453wsgvvuXVWXEXtrP00Sk3GXXVl"
  // );

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  const [divisionData, setDivisionData] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [options, setOptions] = useState("");
  const [price, setPrice] = useState(0);
  const [dicountPercentage, setDiscountPercentage] = useState(0);
  const [discountMsg, setDiscountMsg] = useState("");
  const [discount, setDiscount] = useState("");

  useEffect(() => {
    // console.log("Team leader reg5", teamLeader);
  }, [teamLeader]);
  useEffect(() => {
    const getDivision = async () => {
      const division = await api.get("/rivelary/api/v1/getDivisionDetailed", {
        params: { _id: teamData?.division },
      });
      setDivisionData(division.data);

      if (new Date() > new Date(divisionData?.competition.priceLimitDate)) {
        setPrice(divisionData?.competition.priceAfter);
      } else {
        setPrice(divisionData?.competition.priceBefore);
      }
    };

    getDivision();
  }, [
    teamData,
    price,
    divisionData?.competition.priceLimitDate,
    divisionData?.competition.priceAfter,
    divisionData?.competition.priceBefore,
  ]);
  // console.log(divisionData)
  const navigate = useNavigate();
  const handlePay = async (paid = true) => {
    //////////////////////////////////////////////////////////
    let amount =
      ((+players.length + 1) * price * 100 * (100 - dicountPercentage)) / 100;

    const formData = new FormData();
    // Object.entries(teamData).forEach((team) => {
    //   let [key, value] = team;
    //   formData.append(`team_${key}`, value);
    // });
    // Object.entries(teamLeader).forEach((item) => {
    //   let [key, value] = item;
    //   if (key === "image") {
    //     formData.append("teamleaderImage", value);
    //   } else {
    //     formData.append(`leader_${key}`, value);
    //   }
    // });

    // Object.entries(discount).length > 0
    //   ? Object.entries(discount).forEach((item) => {
    //       let [key, value] = item;
    //       formData.append(`discount_${key}`, value);
    //     })
    const team = JSON.stringify(teamData);
    const discountObj = JSON.stringify(discount);
    // formData.append("team", team);
    // formData.append("teamleader", leader);
    const newPlayers = JSON.stringify(players);
    formData.append("players", newPlayers);
    formData.append("discount", discountObj);
    if (admin === "admin") {
      formData.append("payOnPremises", true);
    } else {
      formData.append("payOnPremises", !paid);
    }
    formData.append("team", team);
    formData.append("teamleaderImage", teamLeader.image);
    let newTeamleader = {};
    teamLeader.regPlatform = "Web";
    Object.entries(teamLeader).forEach((item) => {
      let [key, value] = item;
      if (key !== "image") newTeamleader[`${key}`] = value;
    });
    const leader = JSON.stringify(newTeamleader);
    formData.append("teamleader", leader);

    if (paid === true) {
      formData.append("amount", (+players.length + 1) * price * 100);
    }
    if (paid === false) {
      formData.append("amount", 0);
    }

    const newTeam = await api.post("/auth/api/v1/signupTeam", formData);
    setOptions({
      clientSecret: newTeam?.data.client_secret,
    });
    if (paid === false && admin !== "admin") navigate("/regSuccess");
    if (admin === "admin") successNotification("team registerd successfully");
    setShow(false);
    // formData.append("paid", paid);
    // formData.append("teamId", newTeam.data.team[0]._id);
    // formData.append("roleId", 2);
    // formData.append("regPlatform", "web");
    // const newTeamLeader = await api.post("/auth/api/v1/SignUp", formData);
    // let NewPlayersArray = players.map((player) => ({
    //   ...player,
    //   teamId: newTeam.data.team[0]._id,
    //   paid: paid,
    //   regPlatform: "web",
    // }));
    // const newPlayers = await api.post(
    //   "/auth/api/v1/addTeamPlayers",
    //   NewPlayersArray
    // );

    /////////////////////////////////////////////////////////////
  };

  const handleShowPaymentForm = (status) => {
    setShowPaymentForm(status);
    handlePay(true);
  };

  const handleDiscountChange = async (code) => {
    setShowPaymentForm(false);
    if (code.length === 6) {
      const discounObj = await api.get(
        `crud/api/v1/getDiscountByCode?code=${code}`
      );
      // console.log(discounObj);
      if (!discounObj) {
        setDiscountMsg(discounObj.msg);
      } else {
        setDiscount(discounObj.data);
        setDiscountMsg(`Discount Percentage is ${discounObj.data.value} %`);
        setDiscountPercentage(discounObj.data.value);
      }
    } else {
      setDiscountMsg("");
      setDiscountPercentage(0);
    }
  };

  return (
    <div className={currentStep !== 4 ? "hidden" : ""}>
      <h1 className="font-semibold text-4xl my-5">Review Details</h1>

      <div className="flex flex-row justify-center w-full">
        <div className="w-2/3 mr-20">
          <h1 className="font-semibold text-2xl ">Delegate Details</h1>
          <table className="w-full">
            <tr className="border-b">
              <td className="py-3 ">
                <h5>Name</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {teamLeader?.firstName} {teamLeader?.lastName}
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-3">
                <h5>Email</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {teamLeader?.email}
              </td>
            </tr>
            <tr>
              <td className="py-3">
                <h5>Phone Number</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {teamLeader?.phoneNumber}
              </td>
            </tr>
          </table>
          <hr />
          <h1 className="font-semibold text-2xl ">Team Details</h1>
          <table className="w-full">
            <tr className="border-b">
              <td className="py-3 ">
                <h5>Team Name</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {teamData?.teamName}
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-3">
                <h5>Divosion</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {divisionData?.divisionName}
              </td>
            </tr>
            <tr>
              <td className="py-3">
                <h5>Night</h5>
              </td>
              <td className="py-3 " dir="rtl">
                {teamData?.night}
              </td>
            </tr>
          </table>
          <hr />
          <h1 className="font-semibold text-2xl ">Players Details</h1>
          <h5 className="py-3  ">Players Names</h5>
          <table className="w-full mb-5">
            {players?.map((player) => (
              <tr>
                <td>
                  {player.firstName} {player.lastName}
                </td>
                <td className="text-center">{player.email}</td>
                <td dir="rtl">{player.phoneNumber}</td>
              </tr>
            ))}
          </table>
          {!options && admin !== "admin" && (
            <div className="flex items-center gap-2">
              <p className="font-semibold">Discount Code</p>

              <input
                type="text"
                placeholder="Discount Code"
                className="input"
                onChange={(e) => handleDiscountChange(e.target.value)}
              />
            </div>
          )}
          <p
            className={`text-${
              dicountPercentage > 0 ? "green-500" : "red-500"
            }`}
          >
            {discountMsg}
          </p>

          <div className="flex flex-col gap-2">
            {!showPaymentForm && admin !== "admin" && (
              <button
                className="normalButton"
                onClick={() => handleShowPaymentForm(true)}
              >
                Pay Now
              </button>
            )}
            {showPaymentForm && options && (
              <Elements stripe={stripePromise} options={options}>
                {/* <CheckoutForm /> */}
                {/* <PaymentForm
                  teamLeader={teamLeader}
                  handlePay={handlePay}
                  numberOfPlayers={players.length + 1}
                  pricing={divisionData?.competition}
                  discount={dicountPercentage}
                /> */}
                <PaymentForm returnUrl={`/regSuccess`} />
              </Elements>
            )}

            {!options && (
              <button className="normalButton" onClick={() => handlePay(false)}>
                Pay On Premises
              </button>
            )}
          </div>
        </div>
        <div className="w-1/3">
          <OrderSummary
            numberOfPlayers={players?.length + 1}
            pricing={divisionData?.competition}
            discount={dicountPercentage}
          />
        </div>
      </div>
      <div className="w-full flex gap-5 justify-center col-span-2 items-center my-4">
        <button
          className="normalButton w-fit"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default TeamRegForm5;
