import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import AddField from "../AddField";
import ConfirmRemoveGeneral from "../ConfirmRemoveGeneral";
import api from "../../../api";
import Loader from "../../loader";

function AdminFields() {
  const [fieldData, setFieldData] = useState([]);
  const [modalType, setModalType] = useState("add");
  const [cookies] = useCookies(["token"]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removeApi, setRemoveApi] = useState("");
  const [fieldModal, setFieldModal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [loading, setLoading] = useState(false);

  const getAllFields = async () => {
    setLoading(true);
    const response = await api.get("crud/api/v1/getFieldsActive", {
      headers: { token: cookies.token },
    });
    setLoading(false);
    // console.log(response.data)
    setFieldData(response.data);
  };

  useEffect(() => {
    getAllFields();
  }, []);

  const handleAddNew = () => {
    setModalType("add");
    setFieldModal(true);
  };

  const handleEdit = (data) => {
    setSelectedField(data);
    setModalType("edit");
    setFieldModal(true);
  };
  const handleRemove = (data) => {
    setSelectedField(data);
    setRemoveApi("crud/api/v1/removeField");
    setConfirmRemove(true);
  };

  const { SearchBar } = Search;

  let columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      dataField: "fieldName",
      text: "Field Name",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "image",
      text: "Map",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <img
              src={`${process.env.REACT_APP_SERVER_URL}${row.image}`}
              width={50}
              height={50}
              alt="field_image"
            />
          </div>
        );
      },
    },
    {
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmRemoveGeneral
        confirmRemove={confirmRemove}
        setConfirmRemove={setConfirmRemove}
        apiUrl={removeApi}
        selected={selectedField}
        resetFc={getAllFields}
      />

      <AddField
        show={fieldModal}
        setShow={setFieldModal}
        getAllFields={getAllFields}
        type={modalType}
        selectedField={selectedField}
      />
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="_id"
          data={fieldData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                <button
                  className="normalButton py-2 text-sm"
                  onClick={() => handleAddNew()}
                >
                  Add Field
                </button>
              </div>
              <hr />
              {loading ? (
                <div className=" h-4/5 w-full flex justify-center items-center ">
                  <Loader />
                </div>
              ) : (
                <BootstrapTable
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminFields;
