import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

const initialState = {
  loading: true,
  products: [],
  error: null,
  shopCategories: {
    loading: true,
    categories: [],
    error: null,
  },
};

export const fetchProducts = createAsyncThunk(
  "shop/fetchProducts",
  async (filters) => {
    try {
      const response = await api.get("ecommerce/api/v1/getProducts", {
        params: { ...filters },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const fetchShopCategories = createAsyncThunk(
  "shop/fetchShopCategories",
  async (filters) => {
    try {
      const response = await api.get("crud/api/v1/shopCategory");
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const productsSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        // Add user to the state array
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        // Add user to the state array
        state.products = action.payload;
        state.loading = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        // Add user to the state array
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchShopCategories.pending, (state, action) => {
        // Add user to the state array
        state.shopCategories.loading = true;
      })
      .addCase(fetchShopCategories.fulfilled, (state, action) => {
        // Add user to the state array
        state.shopCategories.categories = action.payload;
        state.shopCategories.loading = false;
      })
      .addCase(fetchShopCategories.rejected, (state, action) => {
        // Add user to the state array
        state.shopCategories.error = action.payload;
        state.shopCategories.loading = false;
      })
  },
});

export default productsSlice.reducer;
