import { useFormik } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import ReactLoading from "react-loading";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";
import ReactCrop from "react-image-crop";
import { useDebounceEffect } from "./CRUD/useDebounceEffect";
import { canvasPreview } from "./CRUD/canvasPreview";

function EditSlider({
  show,
  setShow,
  getAllFields,
  selectedField = {
    title: "",
    description: "",
    season: "",
    position: "",
  },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [comptData, setComptData] = useState("");
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    const getRivelaries = async () => {
      const compt = await api.get("/rivelary/api/v1/getRivelaries", {
        params: { rivelaryType: "Competition" },
      });
      setComptData(() => compt.data);
      return compt;
    };
    getRivelaries();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedField,
    validationSchema: Yup.object({
      title: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      description: Yup.string()
        .min(6, "Please insert a valid description")
        .required("Required"),
      // seaso;n: Yup.string().min(3, "Please insert a valid season"),
      position: Yup.string().required("Position is required"),
      competition: Yup.string().when("position", {
        is: "competition",
        then: Yup.string().required("Competition is required"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      onDownloadCropClick();
      setSubmitting(false);
      const formData = new FormData();
      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        formData.append(key, value);
      });
      try {
        setIsLoading(true);
        await api.put("/crud/api/v1/slider", formData, {
          headers: { token: cookies.token },
        });
        successNotification("Slider Edited Successfully");
        getAllFields();
        setShow(false);
      } catch (error) {
        failNotification(error.response.data.message);
      } finally {
        setIsLoading(true);
      }
    },
  });
  // console.log(formik.errors, "===<<");

  // console.log(selectedField , initialValues ,formik.values,'===,,,,<<<<');
  // console.log(formik.errors, "errors ==== <", formik.values);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const blobUrlRef = useRef("");
  const [imgSrc, setImgSrc] = useState(null);
  const [formatedImage, setFormatedImage] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    // x: 1400,
    // y: 400,
    width: 35,
    height: 10,
  });

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  function onDownloadCropClick() {
    // console.log(previewCanvasRef.current);
    if (!previewCanvasRef.current) {
      //   throw new Error("Crop canvas does not exist");
      return;
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      hiddenAnchorRef.current.href = blobUrlRef.current;

      console.log(hiddenAnchorRef.current);
      const myFile = new File([blob], "slider.JPG", {
        type: blob.type,
      });

      formik.setFieldValue("image", myFile);
    });
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Slider
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="fieldName"
                      >
                        Title
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="title"
                        name="title"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <div className="text-red-500">
                          {formik.errors.title}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Description
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="description"
                        name="description"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-red-500">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Season
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="season"
                        name="season"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.season}
                      />
                      {formik.touched.season && formik.errors.season ? (
                        <div className="text-red-500">
                          {formik.errors.season}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="position"
                      >
                        Position
                      </label>
                      <select
                        name="position"
                        id="position"
                        {...formik.getFieldProps("position")}
                        // value={category}
                        // onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="" key="position">
                          Select position
                        </option>
                        <option value="home" key="home">
                          home
                        </option>
                        <option value="competition" key="competition">
                          competition
                        </option>
                        <option value="sponsor" key="sponsor">
                          Sponsor
                        </option>
                      </select>
                      {formik.touched.position && formik.errors.position && (
                        <div className="text-red-500">
                          {formik.errors.position}
                        </div>
                      )}
                    </div>
                    {formik.values.position === "competition" && (
                      <div className="w-full flex flex-col gap-2">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="competition"
                        >
                          competition
                        </label>
                        <select
                          name="competition"
                          id="competition"
                          {...formik.getFieldProps("competition")}

                          // value={selectedCompetition}
                          // onChange={(e) => {
                          //   setSelectedCompetition(e.target.value);
                          // }}
                        >
                          {comptData.map((rivelary) => (
                            <option value={rivelary._id} key={rivelary._id}>
                              {rivelary.rivelaryName}
                            </option>
                          ))}
                        </select>
                        {formik.touched.competition &&
                          formik.errors.competition && (
                            <div className="text-red-500">
                              {formik.errors.competition}
                            </div>
                          )}
                      </div>
                    )}

                    {/* <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Image
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="image"
                        name="image"
                        type="file"
                        onChange={(e) => {
                          console.log(e.target.files[0])
                          formik.setFieldValue("image", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        value={undefined}
                      />
                    </div> */}
                    <div className="mt-2">
                      <input
                        type="file"
                        accept="image/*"
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="image"
                        name="image"
                        onChange={(e) => {
                          onSelectFile(e);
                          formik.setFieldValue("image", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        value={undefined}
                      />

                      <ReactCrop
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        aspect={35 / 10}
                        onComplete={(c) => setCompletedCrop(c)}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={imgSrc}
                          // style={{
                          //   transform: `scale(${scale}) rotate(${rotate}deg)`,
                          // }}
                          // onLoad={onImageLoad}
                        />
                        {/* <formatedImage  ref={imgRef} /> */}
                      </ReactCrop>
                      {!!completedCrop && (
                        <>
                          <div>
                            <canvas
                              ref={previewCanvasRef}
                              style={{
                                border: "1px solid black",
                                objectFit: "contain",
                                width: completedCrop.width,
                                height: completedCrop.height,
                              }}
                            />
                          </div>
                          <div>
                            {/* <button onClick={onDownloadCropClick}>
                              Download Crop
                            </button> */}
                            <a
                              href="#hidden"
                              ref={hiddenAnchorRef}
                              download
                              style={{
                                position: "absolute",
                                top: "-200vh",
                                visibility: "hidden",
                              }}
                            >
                              Hidden download
                            </a>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button
                        type="submit"
                        className="normalButton w-fit py-2 flex"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <ReactLoading
                            type="spin"
                            color="#FFFFFF"
                            width={24}
                            height={24}
                          />
                        )}
                        Edit slider
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditSlider;
