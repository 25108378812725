import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { PinInput } from 'react-input-pin-code'
import { Dialog, Transition } from '@headlessui/react';


function ResetPasswordModal({ show, setShow }) {

    const [steps, setSteps] = useState(1)
    const [values, setValues] = React.useState(['', '', '', '', '', '']);
    const step1 = () => <div >
        <p className='font-medium text-base '>E-mail</p>
        <input type="email" placeholder='E-mail' className='border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	' />
        <span className='font-medium text-sm text-[#828D9E] '>A password reset email will be sent to your email address </span>

    </div>
    const step2 = () => <div >

        <h2 className='text-lg font-semibold'>6 digit code</h2>
        <span className='font-medium text-sm text-[#828D9E] '>Enter the 6 digit code that was sent to your email
        </span>
        <div className='flex flex-row justify-center items-center'>
            <PinInput values={values} onChange={(value, index, values) => setValues(values)} size={'lg'} onComplete={() => console.log(values)} />
        </div>
    </div>

    const step3 = () => <div className=''>

        <p className='font-medium text-base '>New Password</p>
        <input type="password" placeholder='New Password' className='border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	' />
        <p className='font-medium text-base '>Re-enter New Password</p>
        <input type="password" placeholder='Re-enterNew Password' className='border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	' />

    </div>

    const handleSteps = () => {
        if (steps === 1) {
            //verifiy input
            setSteps(2)
        }
        else if (steps === 2) {
            //verify code
            setSteps(3)
        } else {
            // change password then got to login page (close the modal)
            setSteps(1)
            setShow(false)
        }
    }

    return (
        

        <>
            {show ? <>
                <div className="flex items-center min-h-screen px-4 py-8 fixed inset-0 bg-black opacity-80 z-10" onClick={() =>{
                     setSteps(1)
                     setShow(false)
                }}>

                </div>
                <div className="absolute inset-x-1/3 w-fit md:w-1/4 y-fit top-1/3 max-w-lg my-auto   bg-white rounded-md shadow-lg flex flex-col z-20">
                    <div className='w-full text-right'>
                        <span className='px-3 cursor-pointer' onClick={() => setShow(false)}>X</span>
                    </div>
                    <div className="flex justify-center">
                        <div className='flex flex-col justify-center items-center px-16 py-16 w-full y-fit'>
                            <h2 className='text-3xl font-semibold'>Reset Password</h2>
                            <span className='font-medium text-sm text-[#828D9E] '>Enter your details below</span>

                            {/* <Link to='/signup' className='no-underline font-medium text-base text-[#828D9E]'>
                                Don’t have an account?
                            </Link> */}
                            <div className='flex flex-col my-3 w-full'>

                                {(steps===1)?step1():steps===2?step2():step3()}
                                {/* <p className='font-medium text-base mt-3'>Password</p>
                                <input type="password" placeholder='Password' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' /> */}
                                <button className='py-3 bg-[#00B5FF] rounded-lg my-3 text-white' onClick={() => handleSteps()}>Submit</button>
                                <span className='font-medium text-sm text-[#828D9E] mt-3 cursor-pointer' onClick={() => setShow(false)}>
                                    Already a member? Log in
                                </span>
                                {/* <span className='font-medium text-sm text-[#828D9E] '>Privacy Policy and Terms of Service apply</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </> : ''}
        </>
    )
}

export default ResetPasswordModal