import React from 'react'

function TournmentTable({group='testgroup' , division='divisiontest'}) {
  return (
    <div className='container flex flex-col my-20'>
            <p className='h2Semibold'>{group}</p>
            <h5 >{division}</h5>
            <table className='teamsTable'>
                <thead>
                    <tr className='h5Normal'>
                        <th className='teamNames '>Team</th>
                        <th>MP</th>
                        <th>W</th>
                        <th>D</th>
                        <th>L</th>
                        <th>GF</th>
                        <th>GA</th>
                        <th>GD</th>
                        <th>Pts</th>
                    </tr>
                </thead>
                <tbody className='h5Regular'>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Canturbury</td>
                        <td>3</td>
                        <td>3</td>
                        <td>0</td>
                        <td>0</td>
                        <td>12</td>
                        <td>0</td>
                        <td>12</td>
                        <td>3</td>
                    </tr>
                </tbody>
            </table>
            
        </div>
  )
}

export default TournmentTable