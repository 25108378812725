import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AdminAddNewTeamModal from "./AdminAddNewTeamModal";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import { FaRandom } from "react-icons/fa";
import ReactLoading from "react-loading";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminAddNewRiveralyModal from "./AdminAddNewRiveralyModal";
import AdminAddRivelary from "./adminPanel/AdminAddRivelary";
import ConfirmRemoveGeneral from "./adminPanel/ConfirmRemoveGeneral";
import GenerateGames from "./adminPanel/GenerateGamesModal";

function AdminRiveraly() {
  //   const [visible, setVisible] = useState(false);

  const [Rivelries, setRivelries] = useState([]);
  const [cookies] = useCookies(["token"]);
  const [rivFilter, setRivFilter] = useState();

  const getAllRivelries = async () => {
    const response = await api.get("rivelary/api/v1/getRivelaries", {
      params: rivFilter,
    });
    // console.log(response.data)
    setRivelries(response.data);
  };
  //   const getAllComptetions = async () => {
  //     const response = await api.get("rivelary/api/v1/getRivelaries", {
  //     });
  //     // console.log(response.data)
  //     setRivelries(response.data);
  //   };

  useEffect(() => {
    getAllRivelries();
  }, [rivFilter]);

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState("add");
  const [generate, setGenerate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRivalry, setSelectedRivalry] = useState("");
  let columns = [
    {
      dataField: "_id",
      text: "Rivalry ID",
      style: { fontSize: "small", width: "210px" },
      hidden: true,
    },
    {
      dataField: "rivelaryName",
      text: "Rivalry Name",
      style: { fontSize: "small" },
    },
    { dataField: "area.areaName", text: "area", style: { fontSize: "small" } },
    {
      dataField: "rivelaryType",
      text: "Type",
    },
    // {
    //   dataField: "division.divisionName",
    //   text: "division",
    //   style: { fontSize: "small" },
    // },
    // { dataField: 'email', text: 'E-mail', style: { fontSize: 'small' } },
    // { dataField: 'phoneNumber', text: 'Phone Number', style: { fontSize: 'small' } },
    // { dataField: 'address', text: 'Address', style: { fontSize: 'small' } },
    // { dataField: 'dateOfBirth', text: 'Date Of Birth', style: { fontSize: 'small' } },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FaRandom
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleGenerateGames(row)}
            />
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleOpenEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemoveBtn(row)}
            />
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => {
    setSelectedRivalry();

    setEditModal("add");

    setVisible(true);
  };

  const handleOpenEdit = (rivalryId) => {
    // console.log(rivalryId)
    setSelectedRivalry(rivalryId);
    setEditModal("edit");
    setVisible(true);
  };

  const handleGenerateGames = (rivalryId) => {
    // console.log(rivalryId)
    setSelectedRivalry(rivalryId);
    setGenerate(true);
    // setVisible(true);
  };

  const handleRemoveBtn = (rivalryId) => {
    // console.log(rivalryId)
    setSelectedRivalry(rivalryId);
    setDeleteModal(true);
  };
  return (
    <>
      <GenerateGames
        show={generate}
        setShow={setGenerate}
        selectedRivalry={selectedRivalry}
      />
      <ConfirmRemoveGeneral
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        apiUrl="rivelary/api/v1/removeRivalry"
        selected={selectedRivalry}
        resetFc={getAllRivelries}
      />
      <AdminAddRivelary
        show={visible}
        setShow={setVisible}
        type={editModal}
        selectedRivalry={selectedRivalry}
        getAllRivelries={getAllRivelries}
      />
      {/* <AdminAddNewRiveralyModal show={visible} setShow={setVisible} /> */}
      {Rivelries.length > 0 ? (
        <div className="w-4/5   h-fit flex flex-col gap-2 -mx-20 scale-90 mt-5">
          <ul className="flex gap-10 h-8">
            <li
              className="no-underline text-[#1E354E] text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all cursor-pointer"
              onClick={() => setRivFilter()}
            >
              All Rivalries
            </li>
            <li
              className="no-underline text-[#1E354E] text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all cursor-pointer"
              onClick={() => setRivFilter({ rivelaryType: "Competition" })}
            >
              Compeitions
            </li>
            <li
              className="no-underline text-[#1E354E] text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all cursor-pointer"
              onClick={() => setRivFilter({ rivelaryType: "Tournament" })}
            >
              Tournaments
            </li>
            <li
              className="no-underline text-[#1E354E] text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all cursor-pointer"
              onClick={() => setRivFilter({ rivelaryType: "Cup" })}
            >
              Cups
            </li>
          </ul>

          <div>
            <ToolkitProvider
              keyField="_id"
              data={Rivelries}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <div className="flex w-full justify-between">
                    <SearchBar {...props.searchProps} className="input " />
                    <button
                      className="normalButton py-2 text-sm"
                      onClick={() => handleOpenAdd()}
                    >
                      Add Rivelry
                    </button>
                  </div>
                  <hr />

                  <BootstrapTable
                    loading={true}
                    classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                    pagination={paginationFactory({
                      showTotal: true,
                    })}
                    hover={true}
                    striped
                    // bordered={true}
                    condensed
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </>
  );
}

export default AdminRiveraly;
