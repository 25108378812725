import React from "react";
import "./SpecialOfferItem.scss";
import { Link } from "react-router-dom";

function SpecialOfferItem(props) {
  return (
    <Link
      to={`/productDetails/${props?.product?._id}`}
      className="featuredItemOffer"
    >
      {/* <svg
        width="306"
        height="308"
        viewBox="0 0 306 308"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="specialItem"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.476 7.0758L180.139 4.61059C179.711 2.43551 178.657 2.03223 176.394 1.81478C176.394 1.81478 163.633 1 152.882 1C142.132 1 129.371 1.81478 129.371 1.81478C127.108 2.03223 126.054 2.43551 125.626 4.61059L125.289 7.0758C131.96 6.89186 142.306 6.66619 152.882 6.66619C163.459 6.66619 173.805 6.89186 180.476 7.0758Z"
          fill="#FAFBFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.476 7.0758L180.139 4.61059C179.711 2.43551 178.657 2.03223 176.394 1.81478C176.394 1.81478 163.633 1 152.882 1C142.132 1 129.371 1.81478 129.371 1.81478C127.108 2.03223 126.054 2.43551 125.626 4.61059L125.289 7.0758C131.96 6.89186 142.306 6.66619 152.882 6.66619C163.459 6.66619 173.805 6.89186 180.476 7.0758Z"
          stroke="#8691A3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.847 187.477C124.047 187.477 100.399 185.743 97.8467 183.531L98.351 286.788C98.351 286.788 97.8991 294.425 107.652 300.262C113.756 304.169 131.681 307 152.847 307C174.012 307 191.936 304.17 198.041 300.263C207.796 294.426 207.343 286.788 207.343 286.788L207.847 183.531C205.295 185.743 181.647 187.477 152.847 187.477Z"
          fill="#FAFBFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.847 187.477C124.047 187.477 100.399 185.743 97.8467 183.531L98.351 286.788C98.351 286.788 97.8991 294.425 107.652 300.262C113.756 304.169 131.681 307 152.847 307C174.012 307 191.936 304.17 198.041 300.263C207.796 294.426 207.343 286.788 207.343 286.788L207.847 183.531C205.295 185.743 181.647 187.477 152.847 187.477Z"
          stroke="#8691A3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M194.67 99.561C194.671 99.5623 194.672 99.5635 194.674 99.5642C192.132 96.9299 190.565 93.3448 190.565 89.3918V85.803H190.467L189.49 8.957C189.49 8.04666 188.754 7.30892 187.845 7.30892C187.845 7.30892 184.999 7.20271 180.423 7.07628C173.756 6.89231 163.417 6.66663 152.847 6.66663C142.278 6.66663 131.938 6.89294 125.271 7.07628C120.695 7.20271 117.849 7.30892 117.849 7.30892C116.94 7.30892 116.204 8.04666 116.204 8.957L115.227 85.803H115.129V89.3918C115.129 93.3448 113.562 96.9299 111.021 99.5642C111.022 99.5635 111.023 99.5623 111.024 99.561C102.532 107.638 97.6138 120.221 97.6138 135.951L97.8467 183.53C100.399 185.743 124.047 187.477 152.847 187.477C181.647 187.477 205.295 185.743 207.847 183.53L208.08 135.951C208.08 120.221 203.162 107.638 194.67 99.561Z"
          fill="#F1F3F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M194.67 99.561C194.671 99.5623 194.672 99.5635 194.674 99.5642C192.132 96.9299 190.565 93.3448 190.565 89.3918L190.467 85.803L189.49 8.957C189.49 8.04666 188.754 7.30892 187.845 7.30892C187.845 7.30892 184.999 7.20271 180.423 7.07628C173.756 6.89231 163.417 6.66663 152.847 6.66663C142.278 6.66663 131.938 6.89294 125.271 7.07628C120.695 7.20271 117.849 7.30892 117.849 7.30892C116.94 7.30892 116.204 8.04666 116.204 8.957L115.227 85.803L115.129 89.3918C115.129 93.3448 113.562 96.9299 111.021 99.5642C111.022 99.5635 111.023 99.5623 111.024 99.561C102.532 107.638 97.6138 120.221 97.6138 135.951L97.8467 183.53C100.399 185.743 124.047 187.477 152.847 187.477C181.647 187.477 205.295 185.743 207.847 183.53L208.08 135.951C208.08 120.221 203.162 107.638 194.67 99.561Z"
          stroke="#8691A3"
        />
      </svg> */}
      <img
        src={`${process.env.REACT_APP_SERVER_URL}` + props?.product?.image}
        alt="SpecialOffer"
      />
      <p className="productText">{props?.product?.name}</p>
      <p className="productText">${props?.product?.price}</p>
    </Link>
  );
}

export default SpecialOfferItem;
