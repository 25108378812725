import React, { useEffect, useState } from "react";

function OrderSummary({ pricing, numberOfPlayers, discount }) {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (new Date() > new Date(pricing?.priceLimitDate)) {
      setPrice(pricing?.priceAfter);
    } else {
      setPrice(pricing?.priceBefore);
    }
  }, [pricing]);

  return (
    <div className=" border border-[#E2E5EA] rounded-lg flex flex-col items-center   my-40">
      <div className="border-b w-full flex justify-center items-center">
        <h1 className="font-semibold text-2xl my-5 ">Order Summary</h1>
      </div>
      <div className="flex items-center py-3">
        <div className="mx-3">
          <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.8894 50.0908C72.6757 50.0908 81.909 57.2136 81.909 65.9999V72.8181C81.909 77.8389 77.8389 81.909 72.8181 81.909H9.18173C4.16096 81.909 0.0908203 77.8389 0.0908203 72.8181V65.9999C0.0908203 57.2136 9.49856 50.0908 18.2849 50.0908H63.8894ZM18.2556 56.909C10.9244 56.909 7.28098 60.6175 6.93394 65.3214L6.909 65.9999V70.5454C6.909 72.8764 8.66373 74.7977 10.9244 75.0602L11.4545 75.0908H70.5454C72.8764 75.0908 74.7977 73.3361 75.0602 71.0755L75.0908 70.5454V65.9999C75.0908 61.2074 70.5454 56.8728 63.8434 56.909C57.1413 56.9452 18.2556 56.909 18.2556 56.909ZM40.9999 0.0908203C53.5518 0.0908203 63.7272 10.2662 63.7272 22.8181C63.7272 35.37 53.5518 45.5454 40.9999 45.5454C28.448 45.5454 18.2726 35.37 18.2726 22.8181C18.2726 10.2662 28.448 0.0908203 40.9999 0.0908203ZM40.9999 6.909C32.2136 6.909 25.0908 14.0317 25.0908 22.8181C25.0908 31.6044 32.2136 38.7272 40.9999 38.7272C49.7863 38.7272 56.909 31.6044 56.909 22.8181C56.909 14.0317 49.7863 6.909 40.9999 6.909Z"
              fill="#828D9E"
            />
          </svg>
        </div>
        <div>
          <p>Player Registration</p>
          <p className="font-semibold text-xl">$ {price}</p>
          {/* <input type="number" className='border w-1/2 rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid' disabled/> */}
        </div>
      </div>
      <hr />
      {/* <div className='flex border-b border-t w-full justify-center items-center py-2'>

                <p>Apply Discount Code</p>
            </div> */}
      <hr />
      <div className="w-full px-3 border-b">
        <table className="w-full">
          <tr>
            <td>Subtotal</td>
            <td dir="rtl">$ {price * numberOfPlayers}</td>
          </tr>
          {discount && (
            <tr>
              <td>Discount {discount} %</td>
              <td dir="rtl">
                -$ {price * numberOfPlayers * discount / 100}
              </td>
            </tr>
          )}
          <tr>
            <td>Tax Included</td>
            <td dir="rtl">10%</td>
          </tr>
        </table>
      </div>
      <div className="w-full px-3 my-5">
        <table className="w-full">
          <tr>
            <td className="font-semibold text-xl">Order total</td>
            <td dir="rtl">
              $ {(price * numberOfPlayers * (100 - discount)) / 100}
            </td>
            {/* <td dir='rtl'>$ {Math.ceil(0.85*pricing*numberOfPlayers*1.1)}</td> */}
          </tr>
        </table>
      </div>
    </div>
  );
}

export default OrderSummary;
