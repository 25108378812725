// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
  useFormik,
} from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import api from "../api";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/cartReducer";
import { checkDiscount } from "../utils/CheckDiscount";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PayFormation from "../components/paymentComp.Jsx";
import PaymentForm from "../components/paymentForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const CheckoutForm = () => {
  const [cartProducts, setCartProducts] = useState([]);
  const [cookies, setCookie] = useCookies(["token"]);
  const [isProcessing, setProcessing] = useState(false);
  const [status, setStatus] = useState("Pay");
  const [errMsg, setErrMsg] = useState("");
  const [authUser, setAuthUser] = useState();
  const [dicountPercentage, setDiscountPercentage] = useState(0);
  const [discountMsg, setDiscountMsg] = useState("");
  const [clientsSecret, setClientSecret] = useState("");
  const [discount, setDiscount] = useState("");
  const handleDiscountChange = async (code) => {
    if (code.length === 6) {
      const discounObj = await api.get(
        `crud/api/v1/getDiscountByCode?code=${code}`
      );
      // console.log(discounObj);
      if (!discounObj) {
        setDiscountMsg(discounObj.msg);
      } else {
        setDiscount(discounObj.data);
        setDiscountMsg(`Discount Percentage is ${discounObj.data.value} %`);
        setDiscountPercentage(+discounObj.data.value);
      }
    } else {
      setDiscountMsg("");
      setDiscountPercentage(0);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    api
      .get("auth/api/v1/getPlayerData", { headers: { token: cookies.token } })
      .then((res) => {
        setAuthUser(res.data);
      });
  }, []);
  useEffect(() => {
    api
      .get("ecommerce/api/v1/cartList", { headers: { token: cookies.token } })
      .then((res) => {
        setCartProducts(res.data.cart);
      });
  }, []);
  // console.log('cart' , cartProducts)
  const calculatSum = (cartProducts) => {
    const sumArray = [];
    cartProducts.forEach((item) => {
      sumArray.push(item.quantity * item.item.price);
    });
    return sumArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };
  const productIds = (cartProducts) => {
    const ids = [];
    cartProducts.forEach((item) => {
      ids.push(item.item._id);
    });
    return ids;
  };

  const initialValues = {
    first_name: authUser?.firstName,
    last_name: authUser?.lastName,
    email: authUser?.email,
    phone: authUser?.phoneNumber,
    address: authUser?.address,
  };
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email(),
    address: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
  });

  const deleteCart = () => {
    api
      .post(
        "/ecommerce/api/v1/deleteAllCart",
        {},
        { headers: { token: cookies.token } }
      )
      .then((res) => {});
  };
  // const submitOrder = (products, address, phone, paymentMethod) => {
  //   api
  //     .post(
  //       "/ecommerce/api/v1/createOrder",
  //       {
  //         products,
  //         address,
  //         phone,
  //         paymentMethod,
  //       },
  //       { headers: { token: cookies.token } }
  //     )
  //     .then((res) => {})
  //     .catch((err) => {});
  // };
  const handlesubmit = async (values) => {
    setProcessing(true);
    setStatus("Processing ...");
    // const cardElement = element.getElement("card");
    try {
      const paymentIntent = await api.post(
        "/ecommerce/api/v1/createOrderPaymentIntent",
        {
          phone: values.phone,
          address: values.address,
          discount,
        },
        { headers: { token: cookies.token } }
      );
      setClientSecret(paymentIntent.data.client_secret);
      // const paymentMethodObj = await stripe.createPaymentMethod({
      //   type: "card",
      //   // card: cardElement,
      //   billing_details: {
      //     email: authUser.email,
      //     phone: values.phone,
      //     name: values.name,
      //   },
      // });
      // if (paymentMethodObj.error) {
      //   setErrMsg(paymentMethodObj.error.message);
      //   setProcessing(false);
      //   setStatus("Pay");
      //   return;
      // }
      // const confirmPayment = await stripe.confirmCardPayment(
      //   paymentIntent.data,
      //   { payment_method: paymentMethodObj.paymentMethod.id }
      // );
      // if (confirmPayment.error) {
      //   setErrMsg(confirmPayment.error.message);
      //   setProcessing(false);
      //   setStatus("Pay");
      //   return;
      // }
      setStatus("Successfully Payment is complete");
      // submitOrder(
      //   productIds(cartProducts),
      //   values.address,
      //   values.phone,
      //   "Visa"
      // );
      dispatch(addCart([]));
    } catch (err) {
      setErrMsg(err.message);
      setProcessing(false);
      setStatus("Pay");
    }
  };
  const options = {
    clientSecret: clientsSecret,
  };

  const handleCartChange = (e) => {
    if (e.error) return setErrMsg(e.error.message);
    setErrMsg("");
  };

  // const stripe = useStripe();
  // const element = useElements();
  return (
    <>
      <section className={" w-full flex justify-center "}>
        {/*<p>{calculatSum(cartProducts)}</p>*/}
        <Formik
          validationSchema={SignupSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handlesubmit(values);
          }}
          enableReinitialize={true}
        >
          {({ errors, touched }) => (
            <Form className=" w-1/5 ">
              <div className="flex flex-col w-full my-3 relative">
                <Field
                  type={"text"}
                  name="first_name"
                  className={`w-full flex rounded-lg border-2  ${
                    errors.first_name && touched.first_name
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="First Name"
                />
              </div>
              <div className="flex flex-col w-full my-3 relative">
                <Field
                  type={"text"}
                  name="last_name"
                  className={`w-full flex rounded-lg border-2  ${
                    errors.last_name && touched.last_name
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="Last Name"
                />
              </div>
              <div className="flex flex-col w-full my-3 relative">
                <Field
                  type={"email"}
                  name="email"
                  className={`w-full flex rounded-lg border-2  ${
                    errors.email && touched.email ? "border-red-500" : ""
                  }`}
                  placeholder="Email"
                />
              </div>
              <div className="flex flex-col w-full my-3 relative">
                <Field
                  type={"text"}
                  name="address"
                  className={`w-full flex rounded-lg border-2  ${
                    errors.address && touched.address ? "border-red-500" : ""
                  }`}
                  placeholder="Address"
                />
              </div>
              <div className="flex flex-col w-full my-3">
                <Field
                  type="text"
                  name="phone"
                  className={`w-full flex rounded-lg border-2  ${
                    errors.phone && touched.phone ? "border-red-500" : ""
                  }`}
                  placeholder="Phone Number"
                />
              </div>
              <>
                <p className="font-medium text-base mt-3">Discount Code</p>
                <input
                  maxLength={10}
                  placeholder="Discount Code"
                  className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                  onChange={(e) => handleDiscountChange(e.target.value)}
                />
                <p
                  className={`text-${
                    dicountPercentage > 0 ? "green-500" : "red-500"
                  }`}
                >
                  {discountMsg}
                </p>
              </>

              {/* <CardElement
              className={"my-3"}
              options={{ hidePostalCode: true }}
              onChange={handleCartChange}
            /> */}

              <button
                disabled={isProcessing}
                type="submit"
                className={`w-full py-2 bg-black text-white rounded-lg flex  justify-center ${
                  isProcessing ? "opacity-20" : ""
                }`}
              >
                proceed to pay
              </button>
              <span className="text-red-500">
                {errMsg}
                {/* // ? " please fill the required fields with a valid data"
                // : ""} */}
              </span>
            </Form>
          )}
        </Formik>
      </section>
      <div className=" w-full flex justify-center  items-center mt-6 text-center">
        {clientsSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm returnUrl={`/checkSuccess`} />
          </Elements>
        )}
      </div>
    </>
  );
};
export default CheckoutForm;
