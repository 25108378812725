import { Modal, Select } from "flowbite-react";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { BsEye, BsPerson, BsPhone } from "react-icons/bs";
import { CgCalendar, CgLock } from "react-icons/cg";
import { CiCircleRemove, CiCoins1, CiEdit, CiMedal } from "react-icons/ci";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { TbSoccerField } from "react-icons/tb";
import { IoMedalOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import api from "../api";
import EditPlayerModal from "../components/EditPlayerDataModal";
import ClipLoader from "react-spinners/ClipLoader";
import { useFormik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import * as Yup from "yup";
import {
  failNotification,
  successNotification,
} from "../components/Notifications";
import ChangePasswordModal from "../components/auth/ChangePasswordModal";
import EditProfileDataPopup from "../components/playerProfile/EditProfileDataPopup";
import AddTeamModal from "../components/AddTeamModal";
import AddNewTeamPopup from "../components/playerProfile/AddNewTeamPopup";
import DeleteTeamLeadProfile from "./DeleteTeamLeadProfile";
import { PhoneRegExp } from "../components/TeamRegForm3";

function TeamProfile() {
  let [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    dateOfBirth: "",
    email: "",
  });
  const [editData, setEditData] = useState(false);
  const [userData, setUserData] = useState();
  const [teamData, setTeamData] = useState();
  const [players, setPlayers] = useState();
  const [codeVisible, setCodeVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  const [changePassword, setChangePassword] = useState(false);
  const [editProfileData, setEditProfileData] = useState(false);
  const [modal2, setModal2] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // console.log(userData, "=====<");
  const navigate = useNavigate();
  const getPlayerInfo = async () => {
    setLoading(true);

    try {
      const user = await api.get("/auth/api/v1/getPlayerData", {
        headers: { token: cookies.token },
      });

      setUserData(user.data);
      const team = await api.post(
        "/auth/api/v1/getTeamData",
        user.data.subscriptions[0].team,
        { headers: { token: cookies.token } }
      );
      setTeamData(team.data[0]);
      try {
        const players = await api.post(
          "/auth/api/v1/getTeamPlayers",
          user.data.subscriptions[0].team,
          { headers: { token: cookies.token } }
        );
        setPlayers(players.data);
      } catch (error) {
        setPlayers([]);
      }

      // console.log(players)
    } catch {
      console.log("error");
      setLoading(false);
      // navigate('/')
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPlayerInfo();
  }, []);
  // useEffect(() => {
  //     if (userData) {

  //         // console.log(userData.dateOfBirth)
  //         setInitialValues({ ...initialValues, ...userData, dateOfBirth: new Date(userData.dateOfBirth).toISOString().slice(0, 10) })
  //     }
  //     // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  // }, [userData])
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      if (editData) {
        try {
          const newUser = await api.patch(
            "/auth/api/v1/updatePlayerDataFromTeam",
            values,
            { headers: { token: cookies.token } }
          );
          getPlayerInfo();
          successNotification("Player Data Updated Successfully");
          closeModal();
          resetForm();
          // console.log(newUser)
        } catch (error) {
          failNotification(error.response.data.message);
          console.log("error");
        }
      } else {
        try {
          const email = await api.get("/auth/api/v1/checkEmail", {
            params: { email: values.email },
          });

          const newUser = await api.post("/auth/api/v1/addTeamPlayers", [
            {
              ...values,
              teamId: userData.subscriptions[0].team,
              paid: false,
              regPlatform: "Web",
            },
          ]);
          getPlayerInfo();
          successNotification("Player Add Successfully");
          closeModal();

          // console.log(newUser)
        } catch (error) {
          failNotification(error.response.data.msg);
        }
      }
    },
  });

  const handleEdit = (player) => {
    setEditData(true);
    setInitialValues({
      ...player,
      dateOfBirth: new Date(player.dateOfBirth).toISOString().slice(0, 10),
    });
    openModal();
  };
  const handleAdd = () => {
    setEditData(false);
    setInitialValues({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: "",
      email: "",
    });
    openModal();
  };
  const handleAddTeam = () => {
    setModal2(() => true);
  };

  const handleRemove = async (player) => {
    try {
      await api.put(
        "/auth/api/v1/removePlayerFromTeam",
        { teamId: teamData._id, playerId: player._id },
        { headers: { token: cookies.token } }
      );
      successNotification("player removed successfully");
      getPlayerInfo();
    } catch (error) {
      failNotification(error.response.data.msg);
    }
  };

  return (
    <>
      <EditProfileDataPopup
        show={editProfileData}
        setShow={setEditProfileData}
        userData={userData}
        reset={getPlayerInfo}
      />

      <ChangePasswordModal
        changePassword={changePassword}
        setChangePassword={setChangePassword}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add Player
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstName"
                          name="firstName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-500">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="lastName"
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-500">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="email"
                        >
                          E-mail
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="email"
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Address
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="address"
                          name="address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-red-500">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="dateOfBirth"
                        >
                          Date Of Birth
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfBirth}
                        />
                        {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth ? (
                          <div className="text-red-500">
                            {formik.errors.dateOfBirth}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="text-red-500">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>

                      {/* <div className='w-full flex flex-col col-span-2'>
                                                <div className='flex flex-row my-5 items-center'>
                                                    <input checked={readTerms} onChange={() => setReadTerms(!readTerms)} type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                                                    <label className='font-medium text-base ml-3 '>This player has read & agreed to the insurance Condititions of Play & the Competition Regulations</label>
                                                </div>

                                            </div> */}
                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          Apply Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <EditPlayerModal show={showModal} setShow={setShowModal} />
      <AddNewTeamPopup
        show={modal2}
        setShow={setModal2}
        playerInfo={userData}
        getPlayerInfo={getPlayerInfo}
      />
      {loading ? (
        <div className="flex w-full justify-center my-10">
          <ClipLoader
            color="#00B5FF"
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <div className="container scale-75  flex flex-col gap-5 justify-center ">
          <h1 className="font-semibold text-3xl text-center">Profile</h1>
          <div className="w-full flex justify-between gap-2">
            <button
              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
              onClick={() => handleAddTeam()}
            >
              Register to another team
            </button>
            <button
              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
              onClick={() => setEditProfileData(true)}
            >
              Edit Profile
            </button>
          </div>
          <h2 className="font-semibold text-2xl">Delegate Details</h2>
          <div className="grid grid-cols-4 gap-x-20 gap-y-5">
            <div className="flex gap-2 items-center">
              <BsPerson className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Full Name</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>
                {userData?.firstName} {userData?.lastName}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <MdEmail className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Email</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.email}</span>
            </div>
            <div className="flex gap-2 items-center">
              <BsPhone className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Phone Number</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.phoneNumber}</span>
            </div>
            <div className="flex gap-2 items-center">
              <MdLocationPin className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Address</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.address}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CgCalendar className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Date of Birth</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>
                {new Date(userData?.dateOfBirth).toLocaleDateString()}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <CgLock className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Player Code</span>
            </div>
            <div className="flex gap-2 flex-row-reverse items-center">
              <BsEye
                onClick={() => setCodeVisible(!codeVisible)}
                className="cursor-pointer"
              />
              <span>{codeVisible ? userData?.playerCode : "**********"}</span>
            </div>
            <button className="input" onClick={() => setChangePassword(true)}>
              Change Password
            </button>
            {/* <label htmlFor="firstName" className='font-semibold text-base'>First Name</label>
                    <label htmlFor="lastName" className='font-semibold text-base'>Last Name</label>
                    <input type="text" className='input' placeholder='First Name' id='firstName' />
                    <input type="text" className='input' placeholder='Last Name' id='lastName' />
                    <label htmlFor="email" className='font-semibold text-base'>Email</label>
                    <label htmlFor="password" className='font-semibold text-base'>Password</label>
                    <input type="email" className='input' placeholder='Email' id='email' />
                    <input type="password" className='input' placeholder='Password' id='password' /> */}
          </div>
          <h2 className="font-semibold text-2xl">Team Details</h2>
          <div className="grid grid-cols-4 gap-x-20 gap-y-5">
            <div className="flex gap-2 items-center">
              <BsPerson className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Team Name</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.teamName}</span>
            </div>
            <div className="flex gap-2 items-center">
              <TbSoccerField className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Division</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.division?.divisionName}</span>
            </div>
            <div className="flex gap-2 items-center">
              <MdLocationPin className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Area</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.area?.areaName}</span>
            </div>
            <div className="flex gap-2 items-center">
              <RiTeamLine className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Night</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.night}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CiMedal className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Competition</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.rivelary?.rivelaryName}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CiMedal className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Sub Division</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.subDiv?.subDivName}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CiMedal className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Team Code</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{teamData?.teamCode}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CiCoins1 className="text-[#828D9E]" />
              <span className="text-[#828D9E]"> Team Status </span>
            </div>
            <div
              className={` ${
                teamData.status === "active" ? "text-green-500" : "text-red-500"
              } flex gap-2 flex-row-reverse font-semibold `}
            >
              <span>{teamData?.status}</span>
            </div>

            {/* <label htmlFor="teamName" className='font-semibold text-base'>Team Name</label>
                    <label htmlFor="night" className='font-semibold text-base'>Night</label>
                    <input type="text" className='input' placeholder='Team Name' id='teamName' />
                    <Select>
                        <option value="1">Saturday</option>
                        <option value="2">Sunday</option>
                        <option value="3">Monday</option>
                        <option value="4">Tuesday</option>
                        <option value="5">Wednesday</option>
                        <option value="6">Thursday</option>
                        <option value="7">Friday</option>
                    </Select>
                    <label htmlFor="division" className='font-semibold text-base col-span-2'>Division</label>
                    <Select>
                        
                    </Select> */}
          </div>
          {/* <h2 className='font-semibold text-2xl'>Payment Information</h2>
                <div className='flex gap-5'>

                    <div className='px-32 py-10 border-2 rounded-lg flex w-fit hover:border-[#00B5FF]'>
                        <img src="./images/PayPal.png" alt="paypalLogo" />
                    </div>
                    <div className='px-32 py-10 border-2 rounded-lg flex w-fit flex-col items-center gap-2 hover:border-[#00B5FF]'>
                        <span className=''>+</span>
                        <span className='text-lg font-semibold'>Add New Card</span>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-20 gap-y-5'>
                    <label htmlFor="cardNumber" className='font-semibold text-base'>Card Number</label>
                    <label htmlFor="cardHolderName" className='font-semibold text-base'>Card Holder Name</label>
                    <input type="text" className='input' placeholder='Card Number' id='cardNumber' />
                    <input type="text" className='input' placeholder='Card Holder Name' id='cardHolderName' />
                    <label htmlFor="expirydate" className='font-semibold text-base col-span-2'>Expiry Date</label>
                    <input type="month" className='input' id='expirydate' />
                </div> */}
          <h2 className="font-semibold text-lg">My Teams</h2>
          <div className="w-full flex-col justify-between gap-y-4">
            <table className=" table">
              <thead className="text-lg tracking-wider">
                <th className="text-center">Team Name</th>
                <th className="text-center">Team Status</th>
                <th className="text-center"> Team Code</th>
                <th className="text-center"> Player Payment Status</th>
              </thead>
              <tbody>
                {userData?.subscriptions?.map((subscription) => (
                  <tr key={subscription._id}>
                    <td className="text-base text-center font-semibold text-[#828D9E] ">
                      {subscription.team.teamName}
                    </td>
                    <td
                      className={` ${
                        subscription.team.status === "active"
                          ? "text-green-500"
                          : "text-red-500"
                      } text-base text-center font-semibold text-[#828D9E]`}
                    >
                      {subscription.team.status}
                    </td>
                    <td className="text-base text-center font-semibold text-[#828D9E]">
                      {subscription.team.teamCode}
                    </td>
                    <td className=" text-center text-base font-semibold text-[#828D9E]">
                      {subscription.paidStatus ? "Paid" : "Not Paid"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <h2 className="font-semibold text-lg">Players Information</h2>
          <div className="grid grid-cols-2 gap-x-20 gap-y-2">
            {players?.map((player) => {
              return (
                <div
                  className="flex items-center justify-between"
                  key={player._id}
                >
                  <span className="text-base font-semibold text-[#828D9E]">
                    {player.firstName} {player.lastName}
                  </span>
                  <span className="text-base font-semibold text-[#828D9E]">
                    {player.playerCode}
                  </span>
                  <CiEdit
                    className="text-2xl font-semibold text-[#828D9E] cursor-pointer hover:text-green-500 transition-all"
                    onClick={() => handleEdit(player)}
                  />
                  <CiCircleRemove
                    className="text-2xl font-semibold text-[#828D9E] cursor-pointer hover:text-red-500 transition-all"
                    onClick={() => handleRemove(player)}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex justify-between">
            <DeleteTeamLeadProfile
              players={players}
              teamId={teamData?._id}
              playerId={userData?._id}
            />
            <button
              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
              onClick={() => handleAdd()}
            >
              Add Player
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default TeamProfile;
