import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Tabs } from "flowbite-react";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { useCookies } from "react-cookie";
import api from "../api";
import AddField from "./adminPanel/AddField";
import ConfirmRemoveGeneral from "./adminPanel/ConfirmRemoveGeneral";
import AddArea from "./adminPanel/AddArea";
import AddDiscount from "./adminPanel/AddDiscount";
import AddSubDiv from "./adminPanel/AddSubDiv";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
function CRUD(props) {
  const { SearchBar } = Search;

  const [areaData, setAreaData] = useState([]);
  const [fieldData, setFieldData] = useState([]);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [subDivisions, setSubDivisions] = useState([]);
  const [cookies] = useCookies(["token"]);

  const getAllAreas = async () => {
    const response = await api.get("crud/api/v1/getAreasActive", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setAreaData(response.data);
  };

  const getAllDiscounts = async () => {
    const response = await api.get("crud/api/v1/getDiscountsActive", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setDiscountCodes(response.data);
  };

  const getAllFields = async () => {
    const response = await api.get("crud/api/v1/getFieldsActive", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setFieldData(response.data);
  };
  const getActiveSubDivisions = async () => {
    const response = await api.get("rivelary/api/v1/getAllSubDivisions", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setSubDivisions(response.data);
  };

  useEffect(() => {
    getAllAreas();
    getAllFields();
    getAllDiscounts();
    getActiveSubDivisions();
  }, []);

  const subDivisionsColumns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      style: { fontSize: "12px", width: "210px" },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: "subDivName",
      text: "SubDivision Name",
      sort: true,
    },
    {
      dataField: "division.divisionName",
      text: "Division",
      sort: true,
    },

    {
      dataField: "rivelary.rivelaryName",
      text: "Rivalry",
      sort: true,
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  const areaColumns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      style: { fontSize: "12px", width: "210px" },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      dataField: "areaName",
      text: "Area Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
    },

    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];
  const fieldColumns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      dataField: "fieldName",
      text: "Field Name",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  const discountsColumns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      // dataField: "value",
      text: "Value",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {row.value} %
          </div>
        );
      },
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {row.code}
          </div>
        );
      },
    },
    {
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    hideSizePerPage: true,
    nextPageText: ">",
    prePageText: "<",
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
  });
  const [activeTab, setActiveTab] = useState("area");
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removeApi, setRemoveApi] = useState("");
  const [resetFc, setResetFc] = useState();
  const [areaModal, setAreaModal] = useState(false);
  const [subDivModal, setSubDivModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [fieldModal, setFieldModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const active = "text-[#00B5FF]  border-[#00B5FF]";
  const notActive = " text-[#828D9E]  border-[#828D9E]";
  function handleShowData() {
    if (activeTab == "area") {
      return areaData;
    } else if (activeTab == "field") {
      return fieldData;
    } else if (activeTab == "subDiv") {
      return subDivisions;
    }
    return discountCodes;
  }
  function handleShowColumns() {
    if (activeTab == "area") {
      return areaColumns;
    } else if (activeTab == "field") {
      return fieldColumns;
    } else if (activeTab == "discount") {
      return discountsColumns;
    }
    return subDivisionsColumns;
  }

  const handleAddNew = () => {
    setModalType("add");
    setSelectedField("");
    if (activeTab === "area") {
      setAreaModal(true);
    } else if (activeTab === "field") {
      setFieldModal(true);
    } else if (activeTab === "discount") {
      setDiscountModal(true);
    } else if (activeTab === "subDiv") {
      setSubDivModal(true);
    }
  };

  const handleEdit = (data) => {
    setSelectedField(data);
    setModalType("edit");

    if (activeTab === "area") {
      setAreaModal(true);
    } else if (activeTab === "field") {
      setFieldModal(true);
    } else if (activeTab === "subDiv") {
      setSubDivModal(true);
    }
  };

  const handleRemove = (data) => {
    setSelectedField(data);

    if (activeTab === "area") {
      setRemoveApi("crud/api/v1/removeArea");
      // setResetFc(()=>()=>getAllAreas())
    } else if (activeTab === "field") {
      setRemoveApi("crud/api/v1/removeField");
      // setResetFc(()=>()=>getAllFields())

      // setFieldModal(true);
    }else if (activeTab === "subDiv") {
      setRemoveApi("rivelary/api/v1/removeSubDivision");
      // setResetFc(()=>()=>getAllFields())

      // setFieldModal(true);
    } else {
      setRemoveApi("crud/api/v1/removeDiscount");
    }

    setConfirmRemove(true);
  };
  return (
    <>
      <ConfirmRemoveGeneral
        confirmRemove={confirmRemove}
        setConfirmRemove={setConfirmRemove}
        apiUrl={removeApi}
        selected={selectedField}
        resetFc={
          activeTab === "field"
            ? getAllFields
            : activeTab === "area"
            ? getAllAreas
            : activeTab === "subDiv"
            ? getActiveSubDivisions
            : getAllDiscounts
        }
      />
      <AddField
        show={fieldModal}
        setShow={setFieldModal}
        getAllFields={getAllFields}
        type={modalType}
        selectedField={selectedField}
      />
      <AddArea
        show={areaModal}
        setShow={setAreaModal}
        getAllAreas={getAllAreas}
        type={modalType}
        selectedArea={selectedField}
      />
      <AddDiscount
        show={discountModal}
        setShow={setDiscountModal}
        getAllDiscounts={getAllDiscounts}
      />
      <AddSubDiv
        show={subDivModal}
        setShow={setSubDivModal}
        getAllSubDivs={getActiveSubDivisions}
        type={modalType}
        selectedSubDiv={selectedField}
      />
      <div className="w-3/4 h-fit flex flex-col gap-2 px-10 my-5">
        <div className="flex justify-between mb-10">
          <ul className="flex text-center flex-wrap -mb-px ">
            <li
              onClick={() => setActiveTab("area")}
              className={`flex items-center justify-center p-4 text-sm font-medium cursor-pointer  rounded-t-lg border-b-2 ${
                activeTab == "area" ? active : notActive
              } `}
            >
              Area
            </li>
            <li
              onClick={() => setActiveTab("field")}
              className={`flex items-center justify-center p-4 text-sm font-medium cursor-pointer   rounded-t-lg border-b-2 ${
                activeTab == "field" ? active : notActive
              } `}
            >
              Field
            </li>
            <li
              onClick={() => setActiveTab("discount")}
              className={`flex items-center justify-center p-4 text-sm font-medium  cursor-pointer  rounded-t-lg border-b-2 ${
                activeTab == "discount" ? active : notActive
              } `}
            >
              Discount
            </li>
            <li
              onClick={() => setActiveTab("subDiv")}
              className={`flex items-center justify-center p-4 text-sm font-medium  cursor-pointer  rounded-t-lg border-b-2 ${
                activeTab == "subDiv" ? active : notActive
              } `}
            >
              Sub Divisions
            </li>
          </ul>
          <button
            className="inline-flex normalButton py-2 text-sm"
            onClick={handleAddNew}
          >
            Add {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
          </button>
        </div>
        <ToolkitProvider keyField="_id" data={handleShowData()} columns={handleShowColumns()} search>
          {(props) => (
            <div>
              <div className="flex flex-col gap-3 w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />

                <BootstrapTable
                  search
                  keyField="_id"
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  // data={handleShowData()}
                  // columns={handleShowColumns()}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                {...props.baseProps}

                  // alwaysShowAllBtns={true}
                  // selectRow={{
                  //   mode: "checkbox",
                  //   clickToSelect: false,
                  //   classes: "selection-row"
                  // }}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}
export default CRUD;
