import React, { useEffect, useState } from "react";
import TeamInfoCard from "../components/TeamInfoCard";
import api from "../api";
import { ClipLoader } from "react-spinners";

function TeamsInfo() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTeams = async () => {
    setLoading(true);
    try {
      const res = await api.get("/auth/api/v1/getAllTeamsHome");
      setTeams(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTeams();
    window.scrollTo(0, 0)
  }, []);


  if (loading) {
    return (
      <div className="flex w-full justify-center h-screen items-center">
        <ClipLoader
          color="#00B5FF"
          loading={loading}
          size={50}
          speedMultiplier={1}
        />
      </div>
    );
  }

  return (
    <div className=" container p-10 flex flex-col gap-5 justify-center">
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">
          Understand the Dynamics of Oztag
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          {teams.map((team) => {
            return <TeamInfoCard  team={team}/>;
          })}
        </div>
      </div>
    </div>
  );
}

export default TeamsInfo;
