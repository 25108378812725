import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { useCookies } from "react-cookie";

import { HiArrowRight } from "react-icons/hi";
import api from "../api";

function AddTeamModal({ show, setShow,getPlayerInfo }) {
  // console.log(show)
  // console.log('testsss')
  const cancelButtonRef = useRef(null);
  const [cookies] = useCookies(["token"]);
  const [teamName, setTeamName] = useState("");
  const [teamCodeValidation, setTeamCodeValidation] = useState(true);
  const [teamCode, setTeamCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [userData, setUserData] = useState("");
  const handleTeamCode = async (teamCode) => {
    setErrorMsg("");
    try {
      const response = await api.post("/auth/api/v1/checkTeamCode", {
        teamCode,
      });
      // console.log(response);
      if (response?.status === 200) {
        setTeamCodeValidation(() => true);
        setTeamCode(() => teamCode);
        setTeamName(() => response.data.team.teamName);
      } else {
        setTeamCodeValidation(false);
      }
    } catch (error) {
      setTeamCodeValidation(false);
    }
  };

  const addTeamToPlayer = async () => {
    if (teamCodeValidation && teamCode) {
      setLoading(true);
      try {
        const response = await api.put(
          "/auth/api/v1/addTeamToPlayer",
          { teamCode },
          { headers: { token: cookies.token } }
        );
        getPlayerInfo()
        setShow(false)
        // console.log(response.data.msg);
      } catch (err) {
        setErrorMsg(err.response.data.msg);
      }

      setLoading(false);
    } else {
      return;
    }
  };
  // const getPlayerInfo = async () => {
  //   try {
  //     const user = await api.get("/auth/api/v1/getPlayerData", {
  //       headers: { token: cookies.token },
  //     });
  //     setUserData(user.data);
  //     // console.log(user)
  //   } catch {
  //     // console.log("error");
  //     // navigate("/");
  //   }
  // };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 scale-90">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* body start */}
                  <div className="flex justify-center w-full">
                    <div className="flex flex-col justify-center   py-10 w-full y-fit gap-3">
                      <div className="grid grid-cols-2 gap-x-5 gap-y-5 w-full">
                        <label
                          htmlFor="TeamCode"
                          className="font-semibold text-base"
                        >
                          Team Code
                        </label>

                        <input
                          onChange={(e) => handleTeamCode(e.target.value)}
                          type="text"
                          className="input"
                          placeholder="Team Code"
                          id="firstName"
                        />
                        {teamCodeValidation === false ? (
                          <span className="text-red-500 block  w-full text-center">
                            {" "}
                            code isn't valid{" "}
                          </span>
                        ) : (
                          <span>The team you add is {teamName}</span>
                        )}
                      </div>
                      <div className="flex justify-center my-3">
                        <button
                          className="px-10 mx-3 py-3 bg-[#00B5FF] text-white rounded-lg"
                          onClick={addTeamToPlayer}
                        >
                          {loading ? " Loading...." : "Add the Team"}
                        </button>
                        <br />
                        <span className=" block text-red-500">{errorMsg}</span>
                      </div>
                    </div>
                  </div>

                  {/* body end */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddTeamModal;
