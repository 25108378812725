import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AdminAddNewTeamModal from "./AdminAddNewTeamModal";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminEditTeamsModal from "./adminPanel/AdminEditTeamsModal";
import ConfirmRemoveTeam from "./adminPanel/ConfirmRemoveTeam";
import AdminAddNewItemModal from "./AdminAddNewItemModal";
import ConfirmRemoveProduct from "./ConfirmRemoveProduct";
import ConfirmRemoveGeneral from "./adminPanel/ConfirmRemoveGeneral";
import Loader from "./loader";
function AdminShop() {
  const [teams, setTeams] = useState([]);
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);

  const getAllTeams = () => {
    setLoading(true);
    api.post("ecommerce/api/v1/products").then((res) => {
      // console.log(res.data.products)
      setLoading(false);
      setTeams(res.data.products);
    });
  };

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [itemType, setItemType] = useState("item");
  useEffect(() => {
    getAllTeams();
  }, [editModal, visible]);
  let columns = [
    // { dataField: '_id', text: 'Order No', style: { fontSize: 'small', width: '210px' } },
    { dataField: "name", text: "name", style: { fontSize: "small" } },
    // { dataField: 'product_model', text: 'Product Model', style: { fontSize: 'small' } },
    {
      dataField: "product_Gender",
      text: "Product Gender",
      style: { fontSize: "small" },
    },
    {
      dataField: "product_category.categoryName",
      text: "Category",
      style: { fontSize: "small" },
    },
    { dataField: "price", text: "Price", style: { fontSize: "small" } },
    { dataField: "itemType", text: "Type", style: { fontSize: "small" } },
    {
      dataField: "availability",
      text: "Availability",
      style: { fontSize: "small" },
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cell, row) => {
        return (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}` + row.image}
            width={100}
            height={100}
          />
        );
      },
      style: { fontSize: "small" },
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleOpenEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemoveBtn(row)}
            />
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = (type) => {
    setItemType(type);
    setVisible(true);
    setEditModal(false);
  };

  const handleOpenEdit = (Product) => {
    // console.log(Product)
    setSelectedTeam(Product);
    setEditModal(true);
    setVisible(true);
  };

  const handleRemoveBtn = (teamId) => {
    // console.log(teamId)
    setSelectedTeam(teamId);
    setDeleteModal(true);
  };

  return (
    <>
      <AdminAddNewItemModal
        show={visible}
        setShow={setVisible}
        itemType={itemType}
        edit={editModal}
        selectedProduct={selectedTeam}
      />
      {/* <AdminAddNewItemModal show={editModal} setShow={setEditModal} teamId={selectedTeam}/> */}
      {/* <ConfirmRemoveProduct  confirmRemove={delet eModal} setConfirmRemove={setDeleteModal} teamId={selectedTeam} getAllTeams={getAllTeams}/> */}
      <ConfirmRemoveGeneral
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        apiUrl="ecommerce/api/v1/deleteProduct"
        selected={selectedTeam}
        resetFc={getAllTeams}
      />

      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="teamName"
          data={teams}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                <div className="flex gap-2">
                  <button
                    className="normalButton py-2 text-sm"
                    onClick={() => handleOpenAdd("item")}
                  >
                    Add Product
                  </button>
                  <button
                    className="normalButton py-2 text-sm"
                    onClick={() => handleOpenAdd("package")}
                  >
                    Add Package
                  </button>
                </div>
              </div>
              <hr />
              {loading ? (
                <div className=" h-4/5 w-full flex justify-center items-center ">
                  <Loader />
                </div>
              ) : (
                <BootstrapTable
                  loading={true}
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminShop;
