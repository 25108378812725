import React from "react";

export default function ChildrenProtectionPolicy() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Child Protection Policy</h1>
      <h1 className="text-center ">POLICY STATEMENT</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          The Australian Oztag (AO) has an obligation to ensure that the health,
          safety and welfare is maintained for all children participating in
          Oztag related competitions and tournaments. AO aims to provide a safe
          environment for all junior participants and to educate conveners,
          coaches, referees and volunteers on child abuse and how any such abuse
          should be reported.
        </p>
        <h3>Key Objectives</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Provide information to assist Associations, Referees and coaches
            in identifying and dealing with all forms of child abuse.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Provide education to all Associations, Referees and coaches
            regarding their legal responsibilities under child protection
            legislation and the working with children check.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Identify and outline procedures for raising concerns or
            complaints.
          </li>
        </ul>
        <h3>Overview</h3>
        <p>
          Children have the right to be safe from any form of abuse whilst
          involved in sport. Child protection involves legal as well as moral
          obligations. In AO it is also governed by policy requirements
          applicable to all parties involved with Oztag. Every person bound by
          this policy must always place the safety and welfare of children above
          all other considerations.
        </p>
        <h3>What Is Child Protection?</h3>
        <p>
          Child protection is about keeping children safe from abuse and
          protecting them from people who are unsuitable to work with children.
          Under current child protection legislation, a child is generally
          defined as a person less than 18 years of age. Child protection is a
          responsibility of AO and all parties involved with Oztag. It is a
          particular responsibility of people whose work involves a direct
          relationship with children.
        </p>
        <h3>What Is Child Abuse?</h3>
        <p>
          Child abuse relates to children at risk of harm. This could be due to:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Physical abuse including non-accidental injury and/or harm to a
            child.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Sexual abuse/sexual misconduct comprises any sexual act or sexual
            threat imposed on a child, including suggestive behavior and
            inappropriate touching.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Emotional abuse comprises behaviours that may psychologically harm
            a child, including severe verbal abuse, and threats of abuse or
            excessive and unreasonable demands.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Neglect including situations where a child is harmed by the
            failure to provide them with the basic physical and emotional
            necessities of life.
          </li>
        </ul>
        <h3>Is Child Protection An Issue For Ao And Sport Generally?</h3>
        <p>
          Sport is a particularly vulnerable area for potential child abuse
          because it:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Involves a large number of people under the age of 18
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Frequently involves overnight trips
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Usually involves close relationships between adults and children,
            where the adults are in positions of trust and are able to assert
            authority and power over children
          </li>
        </ul>
        <p>
          While our obligation toward protecting children is universal, there
          are particular activities conducted by AO that involve one or more of
          the above situations. These include, but need not be limited to
          regular season competitions and representative tournaments.
        </p>
        <h3>Policy Requirements</h3>
        <p>
          AO acknowledges that all our parties provide a valuable contribution
          to the positive experiences of our juniors. AO aims to ensure this
          continues and to protect the safety and welfare of its junior
          participants.
        </p>
        <p>Several measures will be used to achieve this such as:</p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ AO requires all those who have contact with children to obtain a
            “working with children government check” which ensures they don’t
            have any previous convictions for sex and violence crimes against
            children.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Prohibiting any form of abuse against children.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Providing opportunities for our juniors to contribute to and
            provide feedback on our program development.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Ensuring our codes of conduct, particularly for roles associated
            with junior sport, are promoted, enforced and reviewed.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Providing procedures for raising concerns or complaints.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ AO requires that any child who is abused or anyone who reasonably
            suspects that a child has been or is being abused by someone with
            our sport, to report it immediately to the police and to AO.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ All allegations of child abuse will be dealt with promptly,
            seriously, sensitively and confidentially.
          </li>
        </ul>
        <h3>Working With Children Check</h3>
        <p>
          To provide any junior tournaments or clinics, venue managers and
          development staff must possess a “working with children check”. A copy
          of each staff member's “working with children check” must be kept as a
          reference by AO.
        </p>
        <h3>Taking Of Images Of Children</h3>
        <p>
          There is a risk that images of children may be used inappropriately or
          illegally. AO requires that individuals and associations, wherever
          possible, obtain permission from a child’s parent/guardian before
          taking an image of a child that is not their own. They should also
          make sure the parent/guardian understands how the image will be used.{" "}
        </p>
        <p>
          To respect people’s privacy, we do not allow camera phones, videos and
          cameras to be used inside changing areas, showers and toilets which we
          control or are used in connection with our sport.
        </p>
        <p>
          When using a photo of a child, we will not name or identify the child
          or publish personal information, such as residential address, email
          address or telephone number, without the consent of the child’s
          parent/guardian. We will not provide information about a child’s
          hobbies, interests, school or the like, as this can be used by
          paedophiles or other persons to “groom” a child.
        </p>
        <p>
          We will only use images of children that are relevant to our sport and
          we will ensure that they are suitably clothed in a manner that
          promotes participation in the sport. We will seek permission from the
          parents/guardians of the children before using images. We require our
          member associations and clubs to do likewise.
        </p>
      </div>
    </div>
  );
}
