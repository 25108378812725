import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { PinInput } from "react-input-pin-code";
import { Link, useNavigate } from "react-router-dom";
import ResetPasswordModal from "../components/ResetPasswordModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../action-creators";
// import { useCookies, withCookies } from 'react-cookie'
import api from "../api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

function Login() {

  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const cancelButtonRef = useRef(null);

  const [steps, setSteps] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [values, setValues] = React.useState(["", "", "", "", "", ""]);
  const step1 = () => (
    <div className="mt-20">
      <p className="font-medium text-base ">E-mail</p>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="E-mail"
        className="border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	"
      />
      <span className="font-medium text-sm text-[#828D9E] ">
        A password reset email will be sent to your email address{" "}
      </span>
    </div>
  );
  const step2 = () => (
    <div className="mt-20">
      <h2 className="text-lg font-semibold">6 digit code</h2>
      <span className="font-medium text-sm text-[#828D9E] ">
        Enter the 6 digit code that was sent to your email
      </span>
      <div className="flex flex-row justify-center items-center">
        <PinInput
          values={values}
          onChange={(value, index, values) => setValues(values)}
          size={"lg"}
          onComplete={() => console.log(values)}
        />
      </div>
    </div>
  );

  const step3 = () => (
    <div className="flex flex-col mt-8">
      <p className="font-medium text-base ">New Password</p>
      <input
        type="password"
        placeholder="New Password"
        className="border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <p className="font-medium text-base ">Re-enter New Password</p>
      <input
        type="password"
        placeholder="Re-enterNew Password"
        className="border rounded-lg border-[#E2E5EA] w-full py-2 px-2 mb-3 focus:border-[#E2E5EA] border-solid	"
        value={confirmPass}
        onChange={(e) => setConfirmPass(e.target.value)}
      />
    </div>
  );

  const handleSteps = async () => {
    if (steps === 1) {
      //verifiy input
      try {
        const newUser = await api.post("/auth/api/v1/sendMailOTP", {
          email: email,
        });
        setSteps(2);
      } catch (error) {
        // console.log(error)
        notifyerror(error.response.data.msg);
      }
    } else if (steps === 2) {
      //verify code
      let refactOtp = 0;
      let intValues = [];
      values?.forEach((str) => {
        if (str !== "") {
          intValues.push(parseInt(str, 10));
        }
      });
      refactOtp = intValues.reduce((accum, digit) => accum * 10 + digit, 0);

      try {
        const newUser = await api.post("/auth/api/v1/verifyOTP", {
          otp: refactOtp,
        });
        // console.log(newUser.data)
        setCookie("token", newUser.data.token);
        setSteps(3);
      } catch (error) {
        // console.log(error)
        notifyerror(error.response.data.msg);
      }
    } else {
      // change password then got to login page (close the modal)
      if (password === confirmPass) {
        try {
          const newUser = await api.post(
            "/auth/api/v1/resetPassword",
            {
              password: password,
            },
            {
              headers: { token: cookies.token },
            }
          );
          setSteps(1);
          setShowResetPassModal(false);
        } catch (error) {
          notifyerror(error.response.data.msg);
        }
      } else {
        notifyerror("password does not match");
      }
    }
  };
  const [cookies, setCookie] = useCookies(["token"]);
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });
  const notifysuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      theme: "colored",
    });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Must be 6 characters or more")
        .required("Required"),

      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        const newUser = await api.post("/auth/api/v1/Login", values);
        notifysuccess("login success");
        setCookie("token", newUser.data.token);
        navigate("/");
      } catch (error) {
        notifyerror(error.response.data.errors[0].msg);
      }
      // if (newUser.data.errors) {
      // } else {
      // }
    },
  });

  return (
    <>
      <div className="flex flex-row justify-center items-center scale-90">
        <div className="flex flex-col justify-center items-center px-0 md:px-16 py-16 w-fit">
          <h2 className="text-3xl font-semibold">Hi, Welcome Back!</h2>
          <Link
            to="/signup"
            className="no-underline font-medium text-base text-[#828D9E]"
          >
            Don’t have an account?
          </Link>
          <div className="flex flex-col">
            <form className="flex flex-col" onSubmit={formik.handleSubmit}>
              <label className="font-medium text-base mt-3" htmlFor="firstName">
                E-mail
              </label>
              <input
                className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : null}

              <label className="font-medium text-base mt-3" htmlFor="password">
                Password
              </label>
              <input
                className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="text-red-500">{formik.errors.password}</div>
              ) : null}
              <span
                className="font-medium text-sm text-[#828D9E] my-3 cursor-pointer"
                onClick={() => setShowResetPassModal(true)}
              >
                Forgot Password?
              </span>
              <button
                type="submit"
                className="py-3 bg-[#00B5FF] rounded-lg mb-3 text-white"
              >
                Sign In
              </button>
            </form>

            {/* <p className='font-medium text-base mt-3'>E-mail</p>
                        <input type="email" placeholder='E-mail' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' /> */}

            {/* <p className='font-medium text-base mt-3'>Password</p>
                        <input type="password" placeholder='Password' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' /> */}
            {/* <span className='font-medium text-sm text-[#828D9E] my-3 cursor-pointer' onClick={() => setShowResetPassModal(true)}>
                            Forgot Password?
                        </span> */}
            {/* <button className='py-3 bg-[#00B5FF] rounded-lg mb-3 text-white'>Sign In</button> */}
            <span className="font-medium text-sm text-[#828D9E] ">
              This site is protected by reCAPTCHA and the Google
            </span>
            <span className="font-medium text-sm text-[#828D9E] ">
              Privacy Policy and Terms of Service apply
            </span>
          </div>
        </div>
      </div>
      {/* <ResetPasswordModal show={showResetPassModal} setShow={setShowResetPassModal} /> */}
      <Transition.Root show={showResetPassModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowResetPassModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/4 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex flex-col sm:items-center">
                      <div className="mt-3  sm:mt-0  sm:text-left w-full">
                        <h2 className="text-3xl font-semibold">
                          Reset Password
                        </h2>
                        <span className="font-medium text-sm text-[#828D9E] ">
                          Enter your details below
                        </span>
                        <div className="">
                          <div className="flex flex-col my-3 w-full">
                            {steps === 1
                              ? step1()
                              : steps === 2
                              ? step2()
                              : step3()}

                            <div className="mt-20 flex flex-col">
                              <button
                                className="py-3 bg-[#00B5FF] rounded-lg my-3 text-white "
                                onClick={() => handleSteps()}
                              >
                                Submit
                              </button>
                              <span
                                className="font-medium text-sm text-[#828D9E] mt-3 cursor-pointer"
                                onClick={() => setShowResetPassModal(false)}
                              >
                                Already a member? Log in
                              </span>
                            </div>
                            {/* <span className='font-medium text-sm text-[#828D9E] '>Privacy Policy and Terms of Service apply</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setShowResetPassModal(false)}
                                        >
                                            Deactivate
                                        </button>
                                        <button
                                            type="button"
                                            className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setShowResetPassModal(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Login;
