import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import Stepper from '../components/Stepper'
import TeamRegForm1 from '../components/TeamRegForm1'
import TeamRegForm2 from '../components/TeamRegForm2'
import TeamRegForm3 from '../components/TeamRegForm3'
import TeamRegForm4 from '../components/TeamRegForm4'
import TeamRegForm5 from '../components/TeamRegForm5'

function TeamReg() {
  const [currentStep, setCurrentStep] = useState(1);

  const [players, setPlayers] = useState([])
  const [teamData, setTeamData] = useState()
  const [teamLeader, setTeamLeader] = useState()
  const [cookies, setCookie] = useCookies(['token']);
  useEffect(()=>{
    setCookie('token','')
  },[])
  // const MultiStep = multistep
  // const steps = [
  //   { title: 'Delegate', component: <TeamRegForm1 /> },
  //   { title: 'Team', component: <TeamRegForm2 /> },
  //   { title: 'Players', component: <TeamRegForm3 /> },
  //   { title: 'Payment', component: <TeamRegForm4 /> },
  //   { title: 'review', component: <TeamRegForm5 /> },
  // ]
  // const next = () => {
  //   alert('next')
  // }
  return (

    <div className='flex flex-col justify-center items-center scale-90'>
      <div>

        <h1 className='font-semibold text-4xl my-5'>Team Registration</h1>
      </div>
      <Stepper currentStep={currentStep} />
      <div className='w-full flex flex-col justify-center items-center'>


        <TeamRegForm1 currentStep={currentStep} setCurrentStep={setCurrentStep} setTeamLeader={setTeamLeader}/>
        <TeamRegForm2 currentStep={currentStep} setCurrentStep={setCurrentStep} setTeamData={setTeamData}/>
        <TeamRegForm3 currentStep={currentStep} setCurrentStep={setCurrentStep} setPlayers={setPlayers} players={players} teamLeader={teamLeader}/>
        <TeamRegForm4 currentStep={currentStep} setCurrentStep={setCurrentStep}/>
        <TeamRegForm5 currentStep={currentStep} setCurrentStep={setCurrentStep}  players={players} teamLeader={teamLeader} teamData={teamData}/>

        {/* <div className='flex justify-center gap-5'>

          {currentStep > 1 ? <button className='normalButton mr-3 my-5' onClick={() => setCurrentStep((prev) => prev - 1)}>Back</button> : ''}
          <button
            className="normalButton min-w-[20%] my-5"
            onClick={() => {

              currentStep === steps.length
                ? setComplete(true)
                : setCurrentStep((prev) => prev + 1);
            }}
          >{currentStep === 5 ? 'Pay' : 'Next'}</button>
        </div> */}
      </div>
    </div>
  )
}

export default TeamReg