import { useState } from "react";
import { BsSdCard } from "react-icons/bs";
import { MdRemoveCircle } from "react-icons/md";

const AdminEditProductQuantity = ({ size, onRemove, index, onEdit }) => {
  const [editQuantity, setEditQuantity] = useState(+size.quantity);
  const [newSize, setNewSize] = useState(size);
  const handleDecrement = () => {
    if (editQuantity <= 0) return;
    setEditQuantity((quantity) => +quantity - 1);
  };
  const handleSubmitSize = () => {
    const newSizeForm = { ...newSize, quantity: editQuantity };
    onEdit(newSizeForm);
  };
  return (
    <>
      {" "}
      <tr className="flex gap-x-6 border w-full">
        <td>{index + 1}</td>
        <td>{size.size}</td>
        <td>{size.quantity}</td>
        <td>
          <button
            type="button"
            className="border inline-flex justify-center items-center rounded-full w-5 h-5 hover:bg-cyan-500 "
            onClick={() => setEditQuantity((quantity) => +quantity + 1)}
          >
            +
          </button>
          <span className="  mx-2 ">{editQuantity}</span>
          <button
            type="button"
            className="border inline-flex justify-center items-center rounded-full w-5 h-5 hover:bg-cyan-500 "
            onClick={handleDecrement}
          >
            -
          </button>
          <button
            type="button"
            onClick={handleSubmitSize}
            className=" inline-flex justify-center items-center mx-2  "
          >
            <svg
              className="w-5 h-5 text-green-500 underline hover:no-underline "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
          </button>
        </td>
        <td>
          <span className="cursor-pointer" onClick={() => onRemove(index)}>
            <MdRemoveCircle />
          </span>
        </td>
      </tr>
    </>
  );
};

export default AdminEditProductQuantity;
