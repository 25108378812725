import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { failNotification } from "../components/Notifications";
import api from "../api";
import { useCookies } from "react-cookie";

const DeleteTeamLeadProfile = ({ players, playerId, teamId }) => {
  const [cookies, setCookie] = useCookies(["token"]);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [newDelegate, setDelegate] = useState("");
  const handleRemoveTeamlead = async () => {
    if (players.length > 0 && !newDelegate) {
      failNotification("Delegate is required");
      return;
    }
    try {
      await api.delete(`/auth/api/v1/deletePlayer/${playerId}`, {
        data: { newTeamleaderId: newDelegate, teamId: teamId },
        headers: { token: cookies.token },
      });
      setCookie("token", "");
      window.location.replace("/");
    } catch (error) {
      failNotification(error.response.data.message);
    }
  };
  return (
    <>
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong
          onClick={() => setDeleteAccountModal(true)}
          class="font-bold underline underline-offset-4 cursor-pointer"
        >
          DELETE ACCOUNT !{" "}
        </strong>

        <span class="block sm:inline">
          {" "}
          Are you sure form delete the account
        </span>
      </div>
      <Transition appear show={deleteAccountModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setDeleteAccountModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Please Confirm Delete Team Lead Account
                  </Dialog.Title>
                  <div className="mt-5 flex flex-col gap-3">
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="teamLead"
                      >
                        Team Leader
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="rivelary"
                        name="rivelary"
                        onChange={(e) => {
                          setDelegate(e.target.value);
                        }}
                      >
                        <option value={""} hidden>
                          Assign replacement team delegate{" "}
                        </option>
                        {players?.map((player) => (
                          <option key={player._id} value={player._id}>
                            {player.firstName} {player.lastName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      className="text-white bg-[#00B5FF]  rounded-lg w-full py-2"
                      onClick={() => handleRemoveTeamlead()}
                    >
                      Confirm
                    </button>
                    <button
                      className="text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2"
                      onClick={() => setDeleteAccountModal(false)}
                    >
                      Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default DeleteTeamLeadProfile;
