import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useState } from "react";
import api from "../../api";
import { useCookies } from "react-cookie";
import { failNotification, successNotification } from "../Notifications";
import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { PhoneRegExp } from "../TeamRegForm3";

function EditProfileDataPopup({ show, setShow, userData, reset }) {
  const [teamCode, setTeamCode] = useState();
  const [cookies, setCookie] = useCookies(["token"]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    dateOfBirth: "",
    email: "",
  });

  useEffect(() => {
    if (userData) {
      // console.log(userData.dateOfBirth)
      setInitialValues({
        ...initialValues,
        ...userData,
        dateOfBirth: new Date(userData.dateOfBirth).toISOString().slice(0, 10),
      });
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [userData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values)
      const formData = new FormData();
      console.log("values", values);
      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        const val = [
          "email",
          "firstName",
          "lastName",
          "dateOfBirth",
          "address",
          "phoneNumber",
          "image",
        ];
        if (val.includes(key) && value) {
          console.log(key, value, "=====<<");
          formData.append(key, value);
          console.log("approved keys", key);
        }
      });

      try {
        const newUser = await api.post(
          "/auth/api/v1/updatePlayerDataFromProfile",
          formData,
          { headers: { token: cookies.token } }
        );
        successNotification("Player Data Updated Successfully");
        setShow(false);
        reset();
        // console.log(newUser)
      } catch (error) {
        console.log("error");
      }
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Profile
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="grid grid-cols-2 gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="firstName"
                      >
                        First Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="firstName"
                        name="firstName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text-red-500">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="lastName"
                      >
                        Last Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="lastName"
                        name="lastName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="text-red-500">
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="email"
                      >
                        E-mail
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="email"
                        name="email"
                        type="email"
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.email}
                        // disabled={true}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="address"
                      >
                        Address
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="address"
                        name="address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <div className="text-red-500">
                          {formik.errors.address}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="dateOfBirth"
                      >
                        Date Of Birth
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dateOfBirth}
                      />
                      {formik.touched.dateOfBirth &&
                      formik.errors.dateOfBirth ? (
                        <div className="text-red-500">
                          {formik.errors.dateOfBirth}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="phoneNumber"
                      >
                        Phone Number
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="tel"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                      />
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="text-red-500">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="confirmPassword"
                      >
                        Image
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="image"
                        name="image"
                        type="file"
                        onChange={(e) => {
                          formik.setFieldValue("image", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        value={undefined}
                      />
                      {formik.touched.image && formik.errors.image ? (
                        <div className="text-red-500">
                          {formik.errors.image}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className='w-full flex flex-col col-span-2'>
                                                <div className='flex flex-row my-5 items-center'>
                                                    <input checked={readTerms} onChange={() => setReadTerms(!readTerms)} type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                                                    <label className='font-medium text-base ml-3 '>This player has read & agreed to the insurance Condititions of Play & the Competition Regulations</label>
                                                </div>

                                            </div> */}

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        Apply Changes
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditProfileDataPopup;
