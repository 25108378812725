import React, { useEffect } from "react";
import AOSANoStrikePolicy from "../../docs/AOSA No Strike Policy.pdf";
import AOSARepresentativePlayersCODEOFCONDUCT from "../../docs/AOSA Representative Players - CODE OF CONDUCT.pdf";
import AppealPolicy from "../../docs/Appeal Policy.pdf";
import ChildProtectionPolicy from "../../docs/Child Protection Policy.pdf";
import CoachesCodeofConduct from "../../docs/Coaches Code of Conduct.pdf";
import CodeofConductPlayersParentsSpectators from "../../docs/Code of Conduct - Players, Parents _ Spectators.pdf";
import OfficialsCodeofConduct from "../../docs/Officials Code of Conduct.pdf";
import OztagLawsofTheGame from "../../docs/Oztag - Laws of The Game.pdf";
import PlayersCodeofConduct from "../../docs/Players Code of Conduct.pdf";
import PlayersSuspensions from "../../docs/Players Suspensions.pdf";
import PrivacyPolicy from "../../docs/Privacy Policy.pdf";
import SmokingPolicy from "../../docs/Smoking Policy.pdf";
import SocialMediaPolicy from "../../docs/Social Media Policy_.pdf";
import Tournamentplayereligibilitypolicyjuniors from "../../docs/Tournament player eligibility policy - juniors.pdf";
import TournamentPlayerEligibilityPolicySeniors from "../../docs/Tournament Player Eligibility Policy – Seniors.pdf";
import WeatherPolicy from "../../docs/Weather Policy.pdf";
import { Link } from "react-router-dom";

function index() {
  window.scrollTo(0, 0);
  return (
    <div className="container scale-75  flex flex-col gap-5 justify-center -my-20">
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">Policies & Documents</h1>
      </div>

      <div className="w-full flex items-center gap-20">
        <div className="w-1/2 gap-3 flex flex-col">
          <p className="text-2xl font-semibold text-[#828D9E]">
            Policies that outline recommended guidelines are an important
            contributor towards effective risk management in all sporting
            organisations.
          </p>
          <p className="text-2xl font-semibold text-[#828D9E]">
            The below policies developed by AOSA are designed to assist, guide
            and support associations in an ever – increasing litigious society.
          </p>
          <p className="text-2xl font-semibold text-[#828D9E]">
            By associations adopting and adhering to these policies, it will
            hopefully reduce the associated risk and potential liability
            associations may encounter.
          </p>
        </div>
        <div className="w-1/2">
          <img src="/images/documents2.png" alt="rules image" />
        </div>
      </div>
      <h2 className="text-3xl font-semibold">
        Visit the below links for more details:
      </h2>

      <table className="text-2xl font-semibold text-[#828D9E]">
        <tr>
          <td>
            <Link to={"lawsofthegame"}>Laws of the game</Link>
            {/* <a href={OztagLawsofTheGame} target="_blank">
            </a> */}
          </td>
          <td>
            <Link to={"officialcodeofconduct"}>Officials Code of Conduct</Link>
            {/* <a href={OfficialsCodeofConduct} target="_blank">
              Officials Code of Conduct
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"playerscodeofconduct"}>Players Code of Conduct</Link>
            {/* 
            <a href={PlayersCodeofConduct} target="_blank">
              Players Code of Conduct
            </a> */}
          </td>
          <td>
            <Link to={"couchescodeofconduct"}>Coaches Code of Conduct</Link>
            {/* <a href={CoachesCodeofConduct} target="_blank">
              Coaches Code of Conduct
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"tpeps"}>Senior Tournament Eligibility Guidelines</Link>
            {/* 
            <a href={TournamentPlayerEligibilityPolicySeniors} target="_blank">
              Senior Tournament Eligibility Guidelines
            </a> */}
          </td>
          <td>
            <Link to={"tpepj"}>Junior Tournament Eligibility Guidelines</Link>

            {/* <a href={Tournamentplayereligibilitypolicyjuniors} target="_blank">
              Junior Tournament Eligibility Guidelines
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"weatherPolicy"}>Extreme Weather Policy</Link>

            {/* <a href={WeatherPolicy} target="_blank">
              Extreme Weather Policy
            </a> */}
          </td>
          <td>
            <Link to={"codeOfConduct"}>
              Code of conduct (players, parents & spectators)
            </Link>
            {/* 
            <a href={CodeofConductPlayersParentsSpectators} target="_blank">
              Code of conduct (players, parents & spectators)
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"weatherPolicy"}>Extreme Weather Policy</Link>

            {/* <a href={WeatherPolicy} target="_blank">
              Extreme Weather Policy
            </a> */}
          </td>
          <td>
            <Link to={"noStrikePolicy"}>AOSA No Strike Policy</Link>
            {/* <a href={AOSANoStrikePolicy} target="_blank">
              AOSA No Strike Policy
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"smokingPolicies"}>Smoking Policy</Link>
            {/* <a href={SmokingPolicy} target="_blank">
              Smoking Policy
            </a> */}
          </td>
          <td>
            <Link to={"socialMediaPolicy"}>Social Media Policy</Link>

            {/* <a href={SocialMediaPolicy} target="_blank">
              Social Media Policy
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"appealPolicy"}>Appeals Policy</Link>

            {/* <a href={AppealPolicy} target="_blank">
              Appeals Policy
            </a> */}
          </td>
          <td>
            <Link to={"privacyPolicy"}>Privacy Policy</Link>

            {/* <a href={PrivacyPolicy} target="_blank">
              Privacy Policy
            </a> */}
          </td>
        </tr>
        <tr>
          <td>
            <Link to={"childrenProtectionPolicy"}>Child Protection Policy</Link>
          </td>
          <td>
            <Link to={"playersSus"}>
              Player / Coach Suspension & Life Ban List
            </Link>

            {/* <a href={PlayersSuspensions} target="_blank">
              Player / Coach Suspension & Life Ban List
            </a> */}
          </td>
        </tr>
      </table>
    </div>
  );
}

export default index;
