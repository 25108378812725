import React, { useEffect, useState } from "react";
import "../Tables.scss";
import { Link } from "react-router-dom";
import api from "../../api";
import { Select } from "flowbite-react";
function ComptetionTables({title,divisions}) {
  const [division, setDivision] = useState([]);
  const [selectedDiv,setSelectedDiv] = useState('');
  const [subDivs, setSubDivs] = useState([]);
  const [scores, setScores] = useState([]);

  const getTable = async () => {
    // const table = await api.get("/rivelary/api/v1/getPopularTable");
    setDivision(divisions);
    setSelectedDiv(divisions[0]?._id)
    getSubDiv(divisions[0]?._id)
    // getTeamsScores(divisions[0]._id,divisions[0].subDivisionNo[0])
  };

  useEffect(()=>{
    getTable()
  },[divisions])
  
  const getSubDiv = async(divisionId) => {
    const response = await api.get("/rivelary/api/v1/getDivisionSubsActive", {
      params: { _id: divisionId },
    });
    setSubDivs(response.data)
    if(response.data.length>0){
        getTeamsScores(divisionId,response.data[0]?._id)
    }else{
        setScores([])
    }
   
  };
  const getTeamsScores =async (div,sub)=>{
    try {
        const teams = await api.post(
          "/rivelary/api/v1/getScores",{divison:div,subDiv:sub}
        //   { params: { _id: location.state._id } }
          // { headers: { token: cookies.token } }
        );
        // console.log(teams)
        setScores(teams.data?.subDivTeams)
        // setDivisions(compt.data);
        // console.log(user)
      } catch {
        console.log("error");
        // navigate('/')
      }
}
const handleDivisionChange=(divisionId)=>{
    setSelectedDiv(divisionId)
    getSubDiv(divisionId)

}

// console.log(division)
  return (
    <div className="bigDiv">
      <p className="headerTextFeatured">Competition Tables</p>
      <p className="h2Semibold">{title}</p>
      <Select className="w-fit" onChange={(e)=>handleDivisionChange(e.target.value)}>
        {division?.map((div)=>{
            return(
                <option key={div?._id} value={div?._id}>{div?.divisionName} / {div?.night}</option>
            )
        })}
      </Select>
      {/* <p className="h2Semibold">{division[0]?.divisionName}</p> */}
      {/* <h5 >{division}</h5> */}
      <br />
      <ul className="flex gap-10 h-8">
        {subDivs?.map((sub)=>{
            return <li key={sub} onClick={()=>getTeamsScores(selectedDiv,sub._id)}>{sub.subDivName}</li>
        })}
      </ul>
      <hr />
      <table className="teamsTable">
        <thead>
          <tr>
            <th className="teamNames">Team</th>
            <th>MP</th>
            <th>W</th>
            <th>D</th>
            <th>L</th>
            <th>GF</th>
            <th>GA</th>
            <th>GD</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody className="h5Regular">
          {scores?.map((score,i)=>{
            // console.log(score)
            return<tr key={'score-'+i}>
            <td>{score.teamName}</td>
            <td>{score.scores.gp?score.scores.gp:0}</td>
            <td>{score.scores.w?score.scores.w:0}</td>
            <td>{score.scores.d?score.scores.d:0}</td>
            <td>{score.scores.l?score.scores.l:0}</td>
            <td>{score.scores.gf?score.scores.gf:0}</td>
            <td>{score.scores.ga?score.scores.ga:0}</td>
            <td>{(score.scores.gf?score.scores.gf:0)-(score.scores.ga?score.scores.ga:0)}</td>
            <td>{score.scores.pt?score.scores.pt:0}</td>
          </tr>
          })}
        
        </tbody>
      </table>

      {/* <div className="buttonDiv">
        <button className="normalButton mt20">
          <Link to="/competition" className="no-underline text-white">
            All ComptetionTables
          </Link>
        </button>
      </div> */}
    </div>
  );
}

export default ComptetionTables;
