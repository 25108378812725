import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";

function AddDiscount({ show, setShow, getAllDiscounts }) {
  //   console.log(selectedArea);
  const [initialValues, setInitialValues] = useState({
    value: 0,
  });
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    setInitialValues({
      value: 0,
    });
    setShow(false);
  }

  //   useEffect(() => {
  //     // console.log(selectedArea);
  //     if (selectedArea) {
  //       setInitialValues({
  //         ...initialValues,
  //         ...selectedArea,
  //       });
  //     } else {
  //       setInitialValues({
  //         value: 0,
  //       });
  //       formik.resetForm();
  //     }
  //     // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  //   }, [selectedArea]);
  // console.log(initialValues)
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      value: Yup.number()
        .integer()
        .min(1, "Discount percentage should be Greater than 1%")
        .max(99, "Discount percentage should be Less than 100%")
        .required("Discount value is required")
        .typeError("Discount percentage should be digits"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        await api.post("/crud/api/v1/addDiscount", values, {
          headers: { token: cookies.token },
        });
        successNotification("Discount Added Successfully");
        getAllDiscounts();
        setShow(false);
        formik.resetForm();
      } catch (error) {
        failNotification(error.response.data.message);
      }
    },
  });
  const { errors } = formik;

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Discount
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="value"
                      >
                        Discount Percentage
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="value"
                        name="value"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      {formik.touched.value && formik.errors.value ? (
                        <div className="text-red-500">{errors.value}</div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        Add Discount
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddDiscount;
