import React, { useEffect } from "react";

function OztagRules() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container scale-75  flex flex-col gap-5 justify-center">
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">
          Some of Oztag’s New International Rules for All Ages
        </h1>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="wfull gap-3 flex flex-col">
          <ol className="list-decimal flex flex-col gap-4">
            <li  style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold",display:"flex" }}><span>1.</span> <span>Scorers kick off.</span> </li>
            <li style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold", display:"flex" }} >
            <span>2.</span> <span>The receiving team can touch the ball from a kick off or drop out
              before it travels 10 metres.</span> 
            </li>
            <li  style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold",display:"flex" }}>
              <span>3.</span><span>Any player from the kicking team who is chasing a short kick-off
              or drop out who intimidates a receiving player attempting to pick
              up the ball within the 10 metres, will be penalised.</span>  
            </li>
            <li  style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold",display:"flex" }}>
             <span> 4. </span><span>If, from a short kick-off or drop out, the receiving team plays at
              the ball or the ball touches them within the 10 metres the ball
              will be deemed in play.</span>
            </li>
            <li  style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold",display:"flex" }}>
             <span>5. </span> <span>If a player is late tagged in a passing play the referee should
              continue with the play. If or when the ball carrier is tagged, the
              referee will restart the tag count with that tag being zero tag.</span>
            </li>
            <li  style={{ all: "unset" ,fontSize:"16px" , fontWeight :"bold",display:"flex" }}>
              <span> 6.</span><span>The attacking team (the team in possession of the ball) is allowed
              to dive on a ball that is the result of a poor pass, knock on or
              back. The defending team can only bend over and pick it up.</span> 
            </li>
          </ol>
        </div>
        <div className="w-full">
          <img src="/images/oztagrules.png" alt="rules image" />
        </div>
      </div>
    </div>
  );
}

export default OztagRules;
