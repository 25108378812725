import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../api";
import { failNotification } from "../components/Notifications";

const DeletePlayerProfile = ({ playerId }) => {
  const [cookies, setCookie] = useCookies(["token"]);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const handleRemovePlayer = async () => {
    try {
      await api.delete(`/auth/api/v1/deletePlayer/${playerId}`, {
        headers: { token: cookies.token },
      });
      setCookie("token", "");
      window.location.replace('/');
    } catch (error) {
      failNotification(error.response.data.message);
    }
  };

  return (
    <>
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong
          onClick={() => setDeleteAccountModal(true)}
          class="font-bold underline underline-offset-4 cursor-pointer"
        >
          DELETE ACCOUNT !{" "}
        </strong>

        <span class="block sm:inline">
          {" "}
          Are you sure form delete the account
        </span>
      </div>
      <Transition appear show={deleteAccountModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setDeleteAccountModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Please Confirm Delete Player Account
                  </Dialog.Title>
                  <div className="mt-5 flex flex-col gap-3">
                    <button
                      className="text-white bg-[#00B5FF]  rounded-lg w-full py-2"
                      onClick={() => handleRemovePlayer()}
                    >
                      Confirm
                    </button>
                    <button
                      className="text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2"
                      onClick={() => setDeleteAccountModal(false)}
                    >
                      Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default DeletePlayerProfile;
