import React from "react";

export default function AppealPolicy() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Appeal Policy </h1>
      <h1 className="text-center ">Version 2 </h1>
      <h1 className="text-center ">Updated November 2012 </h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3>Rights Of Appeal</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Any person, team, association or region who was a party to the
            proceedings may appeal against the finding of the Association to the
            AOSA.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ An appeal may only be lodged if the sentence or penalty given by
            the Convener or Judiciary exceeds two (2) weeks.
          </li>
        </ul>
        <h3>Fees</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Such an Appeal shall be accompanied by an Appeal fee of $200 and
            must be lodged with AOSA within 14 days of receiving the Notice of
            Appeal.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ In any case where an Appeal has been upheld by the Appeals
            Tribunal, the Appeal fee shall be repaid in full to the Appellant by
            AOSA.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ In any case where an Appeal has not been upheld by the Appeals
            Tribunal, the Appeal fee shall be forfeited.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ The appellant may at any time decide to withdraw their appeal in
            writing and the proceedings will be concluded. Any fees paid will be
            forfeited in this instance.
          </li>
        </ul>
        <h3>Timing</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ An appeal will be heard as soon as practicable and within two (2)
            weeks of lodgment. If the Appellant is not available to appear in
            person at the set time within the two (2) week period, the appeal
            shall be heard in their absence.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ The Appeals Chairman will take steps to convene the Appeals
            Committee and advise the Appellant of the date, time and venue of
            the hearing.
          </li>
        </ul>
        <h3>Appeal Basis</h3>
        <p>
          An Appeal must be based on at least one of the following two
          parameters:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ That a denial of Justice has occurred and/or
          </li>
          <li style={{ all: "unset" }}>
            ⦁ That the disciplinary measure(s) imposed is unjust and or
            unreasonable.
          </li>
        </ul>
        <h3>Powers Of Appeals Tribunal</h3>
        <p>
          The Appeals tribunal shall have the power to uphold or dismiss the
          appeal and the power to increase or decrease the penalty imposed.
        </p>
        <h3>Finality</h3>
        <p>
          The decision by the Appeals Tribunal shall be final and binding. Once
          the decision has been given, the proceedings are thereby concluded.{" "}
        </p>
        <h3>Leave To Appeal</h3>
        <p>
          The Appellant may not participate in games pending the appeal. The
          Appellant will not be granted leave to appeal and the penalty/sentence
          handed down by the original judiciary or Association stands until the
          Appeal process is concluded.
        </p>
        <h1 className="text-center pt-8">AOSA – Player Appeal Form</h1>
        <h3>Appellant Information</h3>
        <p>
          Information contained in the Application to Appeal Form must be
          completed in <span className="underline">BLOCK</span> letters. Please
          complete <span className="underline">ALL</span> fields.
        </p>
        <p>Address for lodgment of this form:</p>
        <p>
          Australian Oztag Sports Association PO Box 703 Cronulla NSW 2230
          Email: info@oztag.com.au Fax: (02) 9522 3599
        </p>
        <p>
          You will receive a receipt upon lodgment of your Appeals form. Please
          indicate below how you would like to receive this receipt.
        </p>
        <p>
          □ Email: _____________________________ or □ Mail:
          ____________________________
        </p>
        <p>Office:________________________________________________________</p>
        <h3>Player Contact Details:</h3>
        <p>
          Association Name: _______________________________Venue:
          _____________________
        </p>
        <p>
          Player Name: ________________________________________________________{" "}
        </p>
        <p>
          Address:
          ___________________________________________________________________
        </p>
        <p>
          Suburb: ___________________ State: ____________P/C:
          ____________________{" "}
        </p>
        <p>
          Mobile: ________________________ Email:
          __________________________________________________
        </p>
        <table className="table border-2 border-black">
          <tr>
            <td className="border-2 border-black">Date Suspension was given</td>
            <td className="border-2 border-black"></td>
          </tr>
          <tr>
            <td className="border-2 border-black">Appeal is based on one of the following</td>
            <td className="border-2 border-black">
              <p>⦁ That a denial of natural justice has occurred</p>
              <p>
                ⦁ That the disciplinary measure(s) imposed is unjust and/or
                unreasonable
              </p>
            </td>
          </tr>
          <tr>
            <td className="border-2 border-black">Mandatory sentence or decision by Association</td>
            <td className="border-2 border-black"></td>
          </tr>
          <tr>
            <td className="border-2 border-black">Grounds on which the appeal is based </td>
            <td className="border-2 border-black">(attach additional pages if necessary)</td>
          </tr>
          <tr>
            <td className="border-2 border-black">Appeals fee attached (be aware that fee may be forfeited) </td>
            <td className="border-2 border-black">$200</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
