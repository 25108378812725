import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../../api";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import ConfirmRemoveGeneral from "../ConfirmRemoveGeneral";
import AddArea from "../AddArea";
import AddDiscount from "../AddDiscount";
import { BiReset } from "react-icons/bi";
import { successNotification } from "../../Notifications";
import Loader from "../../loader";
function AdminDiscounts() {
  const [discountCodes, setDiscountCodes] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [modalType, setModalType] = useState("add");
  const [cookies] = useCookies(["token"]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removeApi, setRemoveApi] = useState("");
  const [discountModal, setDiscountModal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [loading, setLoading] = useState(false);

  const getAllDiscounts = async () => {
    setLoading(true);
    const response = await api.get("crud/api/v1/getDiscounts", {
      headers: { token: cookies.token },
    });
    setLoading(false);
    // console.log(response.data)
    setDiscountCodes(response.data);
  };

  useEffect(() => {
    getAllDiscounts();
  }, []);

  const handleAddNew = () => {
    setModalType("add");
    setDiscountModal(true);
  };

  const handleEdit = (data) => {
    setSelectedField(data);
    setModalType("edit");
    setDiscountModal(true);
  };
  const handleRemove = (data) => {
    setSelectedField(data);
    setRemoveApi("crud/api/v1/removeDiscount");
    setConfirmRemove(true);
  };

  const { SearchBar } = Search;

  const [visible, setVisible] = useState(false);
  let columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      // dataField: "value",
      text: "Value",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {row.value} %
          </div>
        );
      },
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {row.code}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <BiReset
              title="Toggle Status"
              size={30}
              className="cursor-pointer hover:text-yellow-500 text-xl"
              onClick={() => handleToggleStatus(row)}
            />
            {/* <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            /> */}
          </div>
        );
      },
    },
  ];

  const handleToggleStatus = async (discount) => {
    if (discount?.status === "active") {
      const response = await api.post(
        "crud/api/v1/toggleDiscount",
        { _id: discount?._id, status: "inactive" },
        {
          headers: { token: cookies.token },
        }
      );
    } else {
      const response = await api.post(
        "crud/api/v1/toggleDiscount",
        { _id: discount?._id, status: "active" },
        {
          headers: { token: cookies.token },
        }
      );
    }
    successNotification("Status toggled Successfully");
    getAllDiscounts();
  };

  return (
    <>
      <ConfirmRemoveGeneral
        confirmRemove={confirmRemove}
        setConfirmRemove={setConfirmRemove}
        apiUrl={removeApi}
        selected={selectedField}
        resetFc={getAllDiscounts}
      />

      <AddDiscount
        show={discountModal}
        setShow={setDiscountModal}
        getAllDiscounts={getAllDiscounts}
        type={modalType}
        selectedArea={selectedField}
      />
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="_id"
          data={discountCodes}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                <button
                  className="normalButton py-2 text-sm"
                  onClick={() => handleAddNew()}
                >
                  Add Discount
                </button>
              </div>
              <hr />
              {loading ? (
                <div className="flex justify-center items-center w-full">
                  {" "}
                  <Loader />{" "}
                </div>
              ) : (
                <BootstrapTable
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  condensed
                  {...props.baseProps}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

export default AdminDiscounts;
