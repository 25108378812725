import {
  BsFacebook,
  BsLinkedin,
  BsInstagram,
  BsTelegram,
  BsTwitter,
} from "react-icons/bs";
import { Link } from "react-router-dom";

function CompetitioGeneralInfo({competition}) {

  // const competition = {
  //   _id: "",
  //   socialInfo: [
  //     {
  //       platform: "LinkedIn",
  //       link: "https://github.com",
  //     },
  //     {
  //       platform: "Facebook",
  //       link: "",
  //     },
  //     {
  //       platform: "Instagram",
  //       link: "https://github.com",
  //     },
  //     {
  //       platform: "Twitter",
  //       link: "https://github.com",
  //     },
  //     {
  //       platform: "Telegram",
  //       link: "",
  //     },
  //   ],
  //   contactInfo: {
  //     email: "aadmin@gmail.com",
  //     phone: "01512151212",
  //   },
  //   otherInfo: [{ key: "Address", value: "new assuit" }],
  // };
  return (
    <div className="container flex justify-center items-center mt-5">
      <div className=" w-6/12 space-y-5">
        {/* header section contain the social info */}
        {competition?.socialInfo?.length > 0 && (
          <div className="my-2 pb-4   border-b-2 border-dashed border-gray-400 flex justify-center items-center space-x-8">
            {competition.socialInfo.map((socail) => (
              <>
                {socail.platform === "Facebook" && socail.link && (
                  <Link
                    to={socail.link}
                    className="text-gray-400 hover:text-[#00B5FF]"
                  >
                    <BsFacebook className="w-[40px] h-[40px]" />
                  </Link>
                )}
                {socail.platform === "Twitter" && socail.link && (
                  <Link
                    to={socail.link}
                    className="text-gray-400 hover:text-[#00B5FF]"
                  >
                    <BsTwitter className="w-[40px] h-[40px]" />
                  </Link>
                )}
                {socail.platform === "Instagram" && socail.link && (
                  <Link
                    to={socail.link}
                    className="text-gray-400 hover:text-[#00B5FF]"
                  >
                    <BsInstagram className="w-[40px] h-[40px]" />
                  </Link>
                )}
                {socail.platform === "LinkedIn" && socail.link && (
                  <Link
                    to={socail.link}
                    className="text-gray-400 hover:text-[#00B5FF]"
                  >
                    <BsLinkedin className="w-[40px] h-[40px]" />
                  </Link>
                )}
                {socail.platform === "Telegram" && socail.link && (
                  <Link
                    to={socail.link}
                    className="text-gray-400 hover:text-[#00B5FF]"
                  >
                    <BsTelegram className="w-[40px] h-[40px]" />
                  </Link>
                )}
              </>
            ))}
          </div>
        )}
        {/* section contain the contact info  */}
        {competition?.contactInfo?.email && (
          <div className="h-14 flex justify-between items-center border-b border-gray-300">
            <p className="font-medium">Email Address</p>
            <p className="text-gray-500">{competition.contactInfo.email}</p>
          </div>
        )}
        {competition?.contactInfo?.phone && (
          <div className="h-14 flex justify-between items-center border-b border-gray-300">
            <p className="font-medium">Mobile Phone</p>
            <p className="text-gray-500">{competition.contactInfo.phone}</p>
          </div>
        )}
        {/* section contain the other info */}
        {competition?.otherInfo?.length > 0 && (
          <>
            <h6 className="text-center text-gray-600">Other Info</h6>
            {competition.otherInfo.map((element) => (
              <div className="h-14 flex justify-between items-center border-b border-gray-300">
                <p className="font-medium">{element.key}</p>
                <p className="text-gray-500">{element.value}</p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
export default CompetitioGeneralInfo;
