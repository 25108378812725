import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ClipLoader from "react-spinners/ClipLoader";
import api from "../../api";
import { successNotification } from "../Notifications";

function UnallocatedPlayersModal({ show, setShow }) {
  const [cookies] = useCookies(["token"]);
  const [players, setPlayers] = useState();
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [teams, setTeams] = useState();
  const [loading, setLoading] = useState(true);
  const [teamsModal, setTeamsModal] = useState(false);
  const getUnallocatedPlayers = async () => {
    try {
      setLoading(true);
      const response = await api.get("auth/api/v1/getUnallocatedPlayers", {
        headers: { token: cookies.token },
      });
      setPlayers(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error.massage);
      setLoading(false);
    }
  };

  const getAllTeams = async () => {
    try {
      const response = await api.get("auth/api/v1/getAllTeams?status=active", {
        headers: { token: cookies.token },
      });
      setTeams(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnallocatedPlayers();
    getAllTeams();
  }, []);
  //   console.log(players);

  const handleAllocateBtn = (player) => {
    setSelectedPlayer(player);
    setTeamsModal(true);
  };

  const handleAllocate = async () => {
    try {
      const response = await api.put(
        "auth/api/v1/addPlayerToTeam",
        { playerId: selectedPlayer._id, teamId: selectedTeam },
        {
          headers: { token: cookies.token },
        }
      );
      setPlayers((prev) =>
        prev.filter((_player) => _player._id !== response.data.player._id)
      );
      successNotification("Player allocated successfully");
      setTeamsModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Transition appear show={teamsModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setTeamsModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Select Team
                  </Dialog.Title>
                  <div className="mt-5 flex flex-col gap-3">
                    <div className="flex justify-between">
                      <label>Player Code: {selectedPlayer.playerCode}</label>
                      <label>
                        Name: {selectedPlayer.firstName}{" "}
                        {selectedPlayer.lastName}
                      </label>
                    </div>
                    <select
                      value={selectedTeam}
                      onChange={(e) => setSelectedTeam(e.target.value)}
                      className="rounded-lg w-full"
                    >
                      <option hidden value="">
                        Please select a team
                      </option>
                      {teams?.map((team) => {
                        return (
                          <option value={team._id} key={team._id}>
                            {team.teamName}
                          </option>
                        );
                      })}
                    </select>
                    <div className="text-center">
                      <button
                        className="normalButton w-1/2"
                        onClick={() => handleAllocate()}
                      >
                        Allocate Now
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Unallocated Players
                  </Dialog.Title>
                  <div className="mt-5 flex flex-col gap-3">
                    {loading ? (
                      <div className="flex w-full justify-center my-10">
                        <ClipLoader
                          color="#00B5FF"
                          loading={loading}
                          size={50}
                          speedMultiplier={1}
                        />
                      </div>
                    ) : (
                      <table>
                        <thead>
                          <th>Player Code</th>
                          <th>Player Name</th>
                          <th>Email</th>
                          <th>Area</th>
                          <th>Allocate</th>
                        </thead>
                        <tbody>
                          {players?.map((player) => {
                            return (
                              <tr className="border-b" key={player._id}>
                                <td className="py-2">{player.playerCode}</td>
                                <td>
                                  {player.firstName} {player.lastName}
                                </td>
                                <td>{player.email}</td>
                                <td>{player?.area?.areaName || "--"}</td>
                                <td>
                                  <button
                                    className=" border-2 border-[#00B5FF] text-[#00B5FF] px-3 py-1"
                                    onClick={() => {
                                      handleAllocateBtn(player);
                                    }}
                                  >
                                    Allocate Now
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                    {/* <button
                    className="text-white bg-[#00B5FF]  rounded-lg w-full py-2"
                    onClick={() => handleTeamRemove()}
                  >
                    Confirm
                  </button>
                  <button
                    className="text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2"
                    onClick={() => setConfirmRemove(false)}
                  >
                    Back
                  </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default UnallocatedPlayersModal;
