import { Dialog, Transition } from "@headlessui/react";
import { Button } from "flowbite-react";
import { Fragment, useRef, useState } from "react";

const ShopFilter = ({ shopCategories, setCurrentPage, filter, setFilter }) => {
  const [show, setShow] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleClearFilters = () => {
    setCurrentPage(1);
    setFilter({
      product_Gender: null,
      availability: null,
      itemType: null,
    });
  };

  const handleGenderChange = (gender, checked) => {
    if (!checked) {
      setCurrentPage(1);
      setFilter((prev) => ({ ...prev, product_Gender: null }));
      return;
    }
    setCurrentPage(1);
    setFilter({ ...filter, product_Gender: gender });
  };

  const handleAvailabilityChange = (availability, checked) => {
    if (!checked) {
      setCurrentPage(1);
      setFilter((prev) => ({ ...prev, availability: null }));
      return;
    }
    setCurrentPage(1);
    setFilter({ ...filter, availability: availability });
  };

  const handleCategoryChange = (cat, checked) => {
    if (!checked) {
      setCurrentPage(1);
      setFilter((prev) => ({ ...prev, product_category: null }));
      return;
    }
    setCurrentPage(1);
    setFilter({ ...filter, product_category: cat });
  };

  const content = (
    <>
      <h1 className="h2Semibold my-3">Catalog</h1>
      <hr />
      <h4 className="h4Semibold mt-2 mb-2">Gender</h4>
      <div className="flex flex-col gap-y-3">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            checked={filter.product_Gender === "Women" ? true : false}
            onChange={(e) => handleGenderChange("Women", e.target.checked)}
          />
          <span className="h5Normal">Women</span>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            checked={filter.product_Gender === "Men" ? true : false}
            onChange={(e) => handleGenderChange("Men", e.target.checked)}
          />
          <span className="h5Normal">Men</span>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            checked={filter.product_Gender === "Unisex" ? true : false}
            onChange={(e) => handleGenderChange("Unisex", e.target.checked)}
          />
          <span className="h5Normal">Unisex</span>
        </div>
      </div>
      <hr />
      <h4 className="h4Semibold mt-2 mb-2">Availability</h4>
      <div className="flex flex-col gap-y-3">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            checked={filter.availability === true ? true : false}
            onChange={(e) => handleAvailabilityChange(true, e.target.checked)}
          />
          <span className="h5Normal">Avaialble</span>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            checked={filter.availability === false ? true : false}
            onChange={(e) => handleAvailabilityChange(false, e.target.checked)}
          />
          <span className="h5Normal">Not available</span>
        </div>
      </div>
      <hr />

      {shopCategories.length > 0 ? (
        <>
          <h4 className="h4Semibold mt-2 mb-2">Category</h4>
          <div className="flex flex-col gap-y-3">
            {shopCategories?.map((cat) => {
              return (
                <div className="flex items-center" key={cat?._id}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={
                      filter.product_category === cat?._id ? true : false
                    }
                    onChange={(e) =>
                      handleCategoryChange(cat?._id, e.target.checked)
                    }
                  />
                  <span className="h5Normal">{cat?.categoryName}</span>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
      <div className="flex justify-center mt-3">
        <button
          className="border rounded-lg border-[#E2E5EA] h-14 w-2/3"
          onClick={() => handleClearFilters()}
        >
          Clear Settings
        </button>
      </div>
      <hr />
    </>
  );
  const filterModal = (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 scale-90">
                <div className="container">{content}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <div className="w-1/4 flex flex-col mt-4">
      <button
        className="normalButton px-2 block md:hidden"
        onClick={() => setShow(true)}
      >
        filter
      </button>
      {filterModal}
      <div className="hidden md:flex flex-col">{content}</div>
    </div>
  );
};
export { ShopFilter };
