import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import api from "../api";
import RegPopup from "./auth/RegPopup";

const CommonSlider = ({ query, allowRegister = false }) => {
  const [regModal, setRegModal] = useState(false);
  const [sliders, setSlider] = useState([]);
  useEffect(() => {
    api
      .get(`crud/api/v1/slider?${query}`)
      .then((res) => {
        setSlider(res.data.sliders);
      })
      .catch((err) => {});
  }, [query]);
  return (
    <>
      <RegPopup regModal={regModal} setRegModal={setRegModal} />
      {sliders.length > 0 && (
        <div className="carouselContainer mt-16">
          <Carousel variant="dark" controls={true}>
            {sliders?.map((item, index) => {
              return (
                <Carousel.Item key={item._id}>
                  <div className="flex flex-row justify-center w-full">
                    <div
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_SERVER_URL + item.image
                        })`,
                        backgroundSize: "cover",
                        width: "100%",
                        height: "380px",
                      }}
                      className="flex w-full h-full  relative"
                    >
                      <img
                        alt="slider_image"
                        src={`${process.env.REACT_APP_SERVER_URL}` + item.image}
                        width={1400}
                        height={400}
                        className="h-[380px] w-full object-cover object-center absolute "
                      />
                      <div className=" w-full md:w-2/4 lg:w-1/4 flex flex-col p-10 pt-20 gap-3 md:ml-10 md:pl-20 z-10">
                        <label className=" bg-white text-black w-fit px-4">
                          {item?.season}
                        </label>
                        <h3 className="text-white">
                          {/* It's a Sport. It's a Game. It's FUN! */}
                          {item.title}
                        </h3>
                        <p className="text-white">
                          {/* Join Australia’s fastest growing sport Today! */}
                          {item.description}
                        </p>
                        {allowRegister && (
                          <button
                            className="text-white bg-[#00B5FF]  rounded-lg w-full py-3"
                            onClick={() => setRegModal(true)}
                          >
                            Register Now!
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default CommonSlider;
