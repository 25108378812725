import React, { useEffect, useState } from "react";
import api from "../api";
import { useCookies } from "react-cookie";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/cartReducer";

function ShopCard({ name, teamName, price, image, id }) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [addedToCart, setAddToCart] = useState(false);
  const reload = () => {
    navigate(`/productDetails/${id}`);
    window.location.reload();
  };
  // const handlAddCart = () => {
  //   setLoading(true);
  //   api
  //     .post(
  //       "ecommerce/api/v1/addToCart",
  //       { itemId: id },
  //       { headers: { token: cookies.token } }
  //     )
  //     .then((res) => {
  //       dispatch(addCart(res.data.cart.products))
  //       setLoading(false);
  //       setAddToCart(true);
  //     })
  //     .catch((item) => {
  //       setLoading(false);
  //       setAddToCart(false);
  //     });
  // };
  //  navigate(`/ProductDetails/${id}`)
  return (
    <div className="flex flex-col cursor-pointer justify-center items-center border rounded-lg px-4 pt-4 ml-0 md:ml-5 w-full hover:shadow-md">
      <img
        src={`${process.env.REACT_APP_SERVER_URL}` + image}
        alt=""
        onClick={reload}
      />
      <div className="flex flex-col items-start w-full py-4">
        <h5>{name}</h5>
        <h5 className="h4Semibold">${price}</h5>
        {/* <button
          className={`border rounded-lg px-4 py-3 ${
            addedToCart ? "opacity-30" : ""
          }`}
          onClick={handlAddCart}
          disabled={addedToCart}
        >
          {loading ? (
            <ReactLoading type="spin" color="#00B5FF" width={24} height={24} />
          ) : addedToCart ? (
            "Added To Cart"
          ) : (
            "Add to Cart"
          )}
        </button> */}
      </div>
    </div>
  );
}

export default ShopCard;
