import React from "react";

export default function Officialcodeofconduct() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">Officials Code of Conduct</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Give all people equal opportunities to participate.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Create pathways for people to participate in sport not just as a
            player but also as a coach, referee, administrator etc.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Ensure that rules, equipment, length of games and training
            schedules are modified to suit the age, ability and maturity level
            of players.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Ensure that everyone involved in Oztag emphasizes fair play, and
            not winning at all costs.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Remember, you set an example. Your behavior and comments should be
            positive and supportive.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Make it clear that abusing people in any way is unacceptable and
            will result in disciplinary action.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Be consistent, objective and courteous when making decisions.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Condemn unsporting behavior and promote respect for all opponents.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Place safety and welfare of the participants about all else.
          </li>
        </ul>
      </div>
    </div>
  );
}
