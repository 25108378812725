import React from "react";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import api from "../../../api";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { failNotification, successNotification } from "../../Notifications";
export default function EditTeamDataModal({
  show,
  setShow,
  teamData,
  resetFunc,
  selectedTeam,
}) {
  const closeModal = () => {
    setShow(false);
  };
  const [competitions, setCompetitions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subDivs, setSubDivs] = useState([]);
  const [selectedSubDiv, setSelectedSubDiv] = useState();
  const [selectedDiv, setSelectedDiv] = useState();
  const [selectedRivalry, setSelectedRivalry] = useState();
  const [areas, setAreas] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const getComp = async () => {
    const response = await api.get("/rivelary/api/v1/getRivelaries");
    setCompetitions(response.data);
  };

  useEffect(() => {
    getComp();
  }, []);
  const [cookies] = useCookies(["token"]);

  const handlecompetitionChange = (id) => {
    if (competitions.length > 0) {
      const selectedRivelary = competitions?.filter((item) => item._id === id);

      setSelectedRivalry(selectedRivelary[0]);
      setDivisions(selectedRivelary[0]?.divisions);
      setSubDivs([]);
    }
  };
  // const handleAreaChange = (id) => {
  //   setSelectedArea(id);
  // };
  const handleDivisionChange = (id) => {
    if (competitions.length > 0) {
      const selectedDivision = divisions?.filter((item) => item._id === id);
      setSelectedDiv(selectedDivision[0]);
      setSubDivs(selectedDivision[0]?.subDivisionNo);
    }
  };
  const handleSubDivChange = (selectedSubDiv) => {
    setSelectedSubDiv(selectedSubDiv);
  };

  // const fetchAreas = async () => {
  //   const response = await api
  //     .get("/crud/api/v1/getAreas")
  //     .then((res) => setAreas(res.data));
  //   return response;
  // };

  useEffect(() => {
    if (competitions.length > 0) {
      handlecompetitionChange(teamData?.rivelary?._id);
      handleDivisionChange(teamData?.division?._id);
      handleSubDivChange(teamData?.subDiv);
    }
    // fetchAreas();
  }, [teamData]);

  //   const handleTeamChange = (selectedTeam) => {
  //     setSelectedTeam(selectedTeam);
  //   };

  const handleRegisterTeam = async () => {
    if (selectedTeam === "") {
      return failNotification("Please Select Team");
    }
    try {
      const response = await api.put(
        "/auth/api/v1/editTeam",
        {
          _id: selectedTeam,
          area: selectedRivalry?.area?._id,
          rivelary: selectedRivalry?._id,
          division: selectedDiv?._id,
          subDiv: selectedSubDiv,
          // area: selectedArea,
        },
        {
          headers: { token: cookies.token },
        }
      );

      successNotification("Team Edited successfully");
      setShow(false);

      return resetFunc();
    } catch {
      return failNotification("Problem Happened");
    }
  };

  // console.log(teamData, "=====<<");

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Team
                </Dialog.Title>
                <div className="mt-2">
                  <div className="w-full flex flex-col gap-2">
                    <label
                      className="font-medium text-base mt-3"
                      htmlFor="rivelary"
                    >
                      Rivalry
                    </label>
                    <select
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      id="rivelary"
                      name="rivelary"
                      onChange={(e) => {
                        handlecompetitionChange(e.target.value);
                      }}
                      value={selectedRivalry?._id}
                    >
                      <option value={""} hidden>
                        Please Select rivelary
                      </option>
                      {competitions?.map((rivalry) => {
                        return (
                          <option key={rivalry._id} value={rivalry._id}>
                            {rivalry.rivelaryName} / {rivalry.area.areaName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="w-full flex flex-col gap-2">
                    <label
                      className="font-medium text-base mt-3"
                      htmlFor="team"
                    >
                      Team
                    </label>
                    <select
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      id="team"
                      name="team"
                      onChange={(e) => {
                        handleTeamChange(e.target.value);
                        // formik.handleChange(e);
                      }}
                    >
                      <option value={""} hidden>
                        Please Select Team
                      </option>
                      {teams?.map((team) => {
                        return (
                          <option key={team._id} value={team._id}>
                            {team.teamName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    className="normalButton my-2"
                    onClick={() => handleRegisterTeam()}
                  >
                    Register
                  </button> */}

                  <div className="w-full flex flex-col gap-2">
                    <label
                      className="font-medium text-base mt-3"
                      htmlFor="division"
                    >
                      Division
                    </label>
                    <select
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      id="division"
                      name="division"
                      onChange={(e) => {
                        handleDivisionChange(e.target.value);
                        // formik.handleChange(e);
                      }}
                      value={selectedDiv?._id}
                    >
                      <option value={""} hidden>
                        Please Select Division
                      </option>
                      {divisions?.map((division) => {
                        return (
                          <option key={division._id} value={division._id}>
                            {division.divisionName} / {division.night}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <label
                      className="font-medium text-base mt-3"
                      htmlFor="division"
                    >
                      Sub Division
                    </label>
                    <select
                      className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                      id="subDivision"
                      name="subDivision"
                      onChange={(e) => {
                        handleSubDivChange(e.target.value);
                        // formik.handleChange(e);
                      }}
                      value={teamData?.subDiv?._id}
                    >
                      <option value={""} hidden>
                        Please Select sub-division
                      </option>
                      {subDivs?.map((subdiv) => {
                        return (
                          <option key={subdiv._id} value={subdiv._id}>
                            {subdiv.subDivName}
                          </option>
                        );
                      })}
                    </select>

                    {/* {areas.length > 0 && (
                      <div className=" w-full flex flex-col gap-2">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="division"
                        >
                          Area
                        </label>
                        <select
                          onChange={(e) => handleAreaChange(e.target.value)}
                          name="area"
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        >
                          <option value="">No Selection</option>
                          {areas?.map((area) => (
                            <option value={area._id} key={area._id}>
                              {area.areaName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )} */}
                    <button
                      className="normalButton my-2"
                      onClick={() => handleRegisterTeam()}
                    >
                      Edit Team Data
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
