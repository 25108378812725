import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import api from "../api";
import GameScoreCard from "./GameScoreCard";
import "./LatestScores.scss";

function LatestScores() {
  const [games, setGames] = useState([]);
  let [loading, setLoading] = useState(false);

  const getLatestGames = async () => {
    try {
      setLoading(true);
      const areas = await api.get("/games/api/v1/getLatestGames");
      setGames(areas.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatestGames();
  }, []);
  // console.log(games);
  return (
    <div className="md:bigDiv md:w-[1280px] flex flex-col mx-2 my-16">
      <p className="headerTextFeatured">Latest Scores</p>
      <p className="normalTextFeatured">View all the latest game scores</p>
      {games.length > 0 && (
        <div className="gameScoreCardWrapper">
          {loading ? (
            <ClipLoader
              color="#0FA9E8"
              loading={loading}
              size={50}
              speedMultiplier={1}
            />
          ) : (
            ""
          )}
          {games?.map((game) => {
            return <GameScoreCard key={game._id} gameData={game} />;
          })}
        </div>
      )}
      {games.length > 0 && (
        <div className="buttonDiv">
          <button className="normalButton mt20">
            <Link to="/scores" className="no-underline text-white">
              All Scores
            </Link>
          </button>
        </div>
      )}
      {games.length === 0 && (
        <p className="text-lg font-semibold text-center">No Scores Found</p>
      )}
    </div>
  );
}

export default LatestScores;
