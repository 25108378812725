// import logo from './logo.svg';
import "./App.css";
import "@fontsource/inter";
import Footer from "./components/Footer";
import MainHeader from "./components/MainHeader";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-input-checkbox/lib/react-input-checkbox.min.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import TeamReg from "./pages/TeamReg";
import Shop from "./pages/Shop";
import ProductDetails from "./pages/ProductDetails";
import TheGame from "./pages/TheGame";
import Tournments from "./pages/Tournments";
import Competition from "./pages/Competition";
import TournmentInner from "./pages/TournmentInner";
import CompetitionInner from "./pages/CompetitionInner";
import Scores from "./pages/Scores";
import ShoppingCart from "./pages/ShoppingCart";
import AdminPanel from "./pages/AdminPanel";
import WhereToPlay from "./pages/WhereToPlay";
import AboutOztag from "./pages/AboutOztag";
import TheGameInfo from "./pages/TheGameInfo";
import OztagRules from "./pages/OztagRules";
import HowToPlay from "./pages/HowToPlay";
import TeamsInfo from "./pages/TeamsInfo";
import Resources from "./pages/Resources";
import Insurance from "./pages/Insurance";
import Policies from "./pages/Policies";
import TeamProfile from "./pages/TeamProfile";
import PlayerProfile from "./pages/PlayerProfile";
import Podcast from "./pages/Podcast";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useCookies, withCookies } from "react-cookie";
import UpcomingGames from "./pages/UpcomingGames";
import Checkout from "./pages/checkout";
import RegSuccess from "./pages/regSuccess";
import CheckSuccess from "./pages/checkSuccess";
import Lawsofthegame from "./pages/Policies/Lawsofthegame";
import Officialcodeofconduct from "./pages/Policies/Officialcodeofconduct";
import Playerscodeofconduct from "./pages/Policies/Playerscodeofconduct";
import Couchescodeofconduct from "./pages/Policies/Couchescodeofconduct";
import TPEES from "./pages/Policies/TPEES";
import TPEPJ from "./pages/Policies/TPEPJ";
import WeatherPolicy from "./pages/Policies/WeatherPolicy";
import CodeOfConduct from "./pages/Policies/CodeOfConduct";
import NoStrikePolicy from "./pages/Policies/NoStrikePolicy";
import SmokingPolicies from "./pages/Policies/SmokingPolicies";
import SMPolicies from "./pages/Policies/SMPolicies";
import AppealPolicy from "./pages/Policies/AppealPolicy";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import ChildrenProtectionPolicy from "./pages/Policies/ChildrenProtectionPolicy";
import PlayersSus from "./pages/Policies/PlayersSus";
function App() {
  const [cookies, setCookie] = useCookies(["token"]);

  return (
    <div className="App">
      <Router>
        <ToastContainer limit={1} />

        <MainHeader />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/signUp" element={<SignUp />}></Route>
          <Route exact path="/teamReg" element={<TeamReg />}></Route>
          <Route exact path="/shop" element={<Shop />}></Route>
          <Route
            exact
            path="/productDetails/:id"
            element={<ProductDetails />}
          ></Route>
          <Route exact path="/theGame" element={<TheGame />}></Route>
          <Route exact path="/tournments" element={<Tournments />}></Route>
          <Route exact path="/competition" element={<Competition />}></Route>
          <Route
            exact
            path="/tournmentInner"
            element={<TournmentInner />}
          ></Route>
          <Route
            exact
            path="/competitionInner"
            element={<CompetitionInner />}
          ></Route>
          <Route exact path="/scores" element={<Scores />}></Route>
          <Route exact path="/shoppingCart" element={<ShoppingCart />}></Route>
          <Route exact path="/admin" element={<AdminPanel />}></Route>
          <Route exact path="/wheretoplay" element={<WhereToPlay />}></Route>
          <Route exact path="/about" element={<AboutOztag />}></Route>
          <Route exact path="/thegameinfo" element={<TheGameInfo />}></Route>
          <Route exact path="/oztagrules" element={<OztagRules />}></Route>
          <Route exact path="/howtoplay" element={<HowToPlay />}></Route>
          <Route exact path="/teamsinfo" element={<TeamsInfo />}></Route>
          <Route exact path="/resources" element={<Resources />}></Route>
          <Route exact path="/insurance" element={<Insurance />}></Route>
          <Route exact path="/policies" element={<Policies />} />
          <Route exact path="/policies/lawsofthegame" element={<Lawsofthegame />} />
          <Route exact path="/policies/officialcodeofconduct" element={<Officialcodeofconduct />} />
          <Route exact path="/policies/playerscodeofconduct" element={<Playerscodeofconduct />} />
          <Route exact path="/policies/couchescodeofconduct" element={<Couchescodeofconduct />} />
          <Route exact path="/policies/tpeps" element={<TPEES />} />
          <Route exact path="/policies/tpepj" element={<TPEPJ />} />
          <Route exact path="/policies/weatherPolicy" element={<WeatherPolicy />} />
          <Route exact path="/policies/codeOfConduct" element={<CodeOfConduct />} />
          <Route exact path="/policies/noStrikePolicy" element={<NoStrikePolicy />} />
          <Route exact path="/policies/smokingPolicies" element={<SmokingPolicies />} />
          <Route exact path="/policies/socialMediaPolicy" element={<SMPolicies />} />
          <Route exact path="/policies/appealPolicy" element={<AppealPolicy />} />
          <Route exact path="/policies/privacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/policies/childrenProtectionPolicy" element={<ChildrenProtectionPolicy />} />
          <Route exact path="/policies/playersSus" element={<PlayersSus />} />
          <Route exact path="/teamProfile" element={<TeamProfile />}></Route>
          <Route
            exact
            path="/playerProfile"
            element={<PlayerProfile />}
          ></Route>
          <Route exact path="/podcast" element={<Podcast />}></Route>
          <Route
            exact
            path="/upcomingGames"
            element={<UpcomingGames />}
          ></Route>
          <Route exact path="/checkout" element={<Checkout />}></Route>
          <Route exact path="/regSuccess" element={<RegSuccess />}></Route>
          <Route exact path="/checkSuccess" element={<CheckSuccess />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default withCookies(App);
