import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import api from "../api";
import ReactLoading from "react-loading";

function AdminAddNewItemPodCast({ show, setShow }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const cancelButtonRef = useRef(null);
  const initialValues = {
    title: "",
    host: { name: "", title: "" },
    guest: { name: "", title: "" },
    description: "",
    image: null,
  };
  const SignupSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    description: Yup.string().required("required"),
    host: Yup.object({
      name: Yup.string().required("required"),
      title: Yup.string().required("required"),
    }),
    guest: Yup.object({
      name: Yup.string().required("required"),
      title: Yup.string().required("required"),
    }),
    image: Yup.mixed().required("A file is required"),
    voice: Yup.mixed()
      .required("A file is required")
      .test("fileType", "Only voice files are allowed", (value) => {
        if (!value) return false; // Handle case when no file is selected
        const allowedExtensions = ["mp3", "wav", "ogg"]; // Add more extensions if needed
        const fileExtension = value.name.split(".").pop().toLowerCase();
        return allowedExtensions.includes(fileExtension);
      }),
  });
  const handlesubmit = async (values) => {
    setLoading(true);
    // console.log(values);
    if (image === "") {
      setImage(null);
    }
    const formData = new FormData();
    function traverseObject(obj, formData, parentKey) {
      for (let key in obj) {
        let value = obj[key];
        let fullKey = parentKey ? parentKey + "[" + key + "]" : key;
        if (typeof value === "object" && key !== "voice") {
          if (key === "image") {
            formData.append(fullKey, value);
          } else {
            traverseObject(value, formData, fullKey);
          }
        } else {
          formData.append(fullKey, value);
        }
      }
    }

    traverseObject(values, formData);

    // console.log("form data", formData)
    api
      .post("ecommerce/api/v1/addVoice", formData)
      .then((res) => {
        // console.log("response for add Product", res)
        setShow(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
              enterTo="opacity-100 translate-y-0 "
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 "
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* body start */}
                  <Formik
                    validationSchema={SignupSchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      handlesubmit(values);
                    }}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form className="w-full px-32">
                        <h3 className="text-[32px] font-semibold mb-4 text-center">
                          Add New Item
                        </h3>
                        <div className={"grid grid-cols-2 gap-x-5"}>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>Host Name</label>
                            <Field
                              type="text"
                              name="host.name"
                              className={`input ${
                                errors.host?.name && touched.host?.name
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Host Name"
                            />
                          </div>

                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Host title
                            </label>
                            <Field
                              type="text"
                              name="host.title"
                              className={`input ${
                                errors.host?.title && touched.host?.title
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Host Title"
                            />
                          </div>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Guest name
                            </label>
                            <Field
                              type="text"
                              name="guest.name"
                              className={`input ${
                                errors.guest?.name && touched.guest?.name
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Guest Name"
                            />
                          </div>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Guest title
                            </label>
                            <Field
                              type="text"
                              name="guest.title"
                              className={`input ${
                                errors.guest?.title && touched.guest?.title
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Guest Title"
                            />
                          </div>
                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold"}>
                              Item Title
                            </label>
                            <Field
                              type="text"
                              name="title"
                              className={`input ${
                                errors.title && touched.title
                                  ? "border-red-600"
                                  : ""
                              }`}
                              placeholder="Item Title"
                            />
                          </div>

                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>Image</label>
                            <Field
                              type="file"
                              name="image"
                              value={undefined}
                              onChange={(event) => {
                                // setImage(event.target.files[0])
                                setFieldValue("image", event.target.files[0]);
                                // console.log('event' , event)
                              }}
                              className={`input ${
                                errors.image && touched.image
                                  ? "border-red-600"
                                  : ""
                              }`}
                            />
                          </div>

                          <div className="flex flex-col  gap-3 w-full mt-5">
                            <label className={"font-semibold "}>Voice</label>
                            <Field
                              type="file"
                              name="voice"
                              accept="audio/mp3,audio/*;capture=microphone"
                              value={undefined}
                              onChange={(event) => {
                                // setImage(event.target.files[0])
                                setFieldValue("voice", event.target.files[0]);
                                // console.log('event' , event)
                              }}
                              className={`input ${
                                errors.voice && touched.voice
                                  ? "border-red-600"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col  gap-3 w-full mt-5">
                          <label className={"font-semibold "}>
                            Description
                          </label>
                          <Field
                            as={"textarea"}
                            type="string"
                            name="description"
                            className={`input ${
                              errors.description && touched.description
                                ? "border-red-600"
                                : ""
                            }`}
                            placeholder="Description"
                          />
                        </div>

                        <button
                          type="submit"
                          className="py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-full flex justify-center"
                        >
                          {loading ? (
                            <ReactLoading
                              type="spin"
                              color="white"
                              width={24}
                              height={24}
                            />
                          ) : (
                            "  Save Podcast"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminAddNewItemPodCast;
