import React from "react";

export default function Lawsofthegame() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">Laws of The Game</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3>Players Equipment </h3>
        <p>
          Moulded stud football boots, screw-in football boots or bladed
          football boots are NOT permitted. Astro turf, touch football shoes
          (rubber soled), Joggers and Runners are acceptable. Players must wear
          approved OzTag shorts (Sporting Spectrum can order them in for you
          before the start of the competition!). Uniform t-shirts are not
          compulsory however we do encourage teams to wear similar colour team
          shirts.
        </p>
        <h3>Scoring </h3>
        <p>
          You may score a try from anywhere on the field. One point is awarded
          for a male try and two points are awarded for a female try (the ball
          must be passed to the female before the try line to be awarded the 2
          points)
        </p>
        <h3>Tags</h3>
        <p>
          The defender must remove one tag to stop the attacker's progress. Hold
          up the tag then drop it to the ground. This marks the spot where the
          attacker will play the ball. (The attacker must return to the marked
          spot). The attacker must retreat to where the tag occurred and play
          the ball with your foot. You do not need your tags on to play the
          ball. You will be penalised if you delay playing the ball to put your
          tags on.
        </p>
        <h3>Marker</h3>
        <p>
          There is one marker in the ball. The marker cannot move until the
          dummy half has picked up the ball. If there is no dummy half, the
          marker must stand still until the referee has signalled 3 seconds is
          up then the referee will call for a changeover. Do not move from the
          marker otherwise you will be penalised.
        </p>
        <h3>Kicking</h3>
        <p>
          Kicking on the last two tackles is allowed but not above shoulder
          height. The attacking team cannot dive on a kicked ball in any
          situation, but can kick on.
        </p>
        <h3>Non-Tackle </h3>
        <p>
          The game is non-tackle. An attacker cannot deliberately bump into a
          defender nor can a defender deliberately bump or move into an
          attacker’s path to block. (The onus is on the attacking player to
          avoid the defender).
        </p>
        <h3>General</h3>
        <p>
          The ball carrier is not allowed to protect their tag or fend off
          defenders (whoever initiates contact will be penalised). A knock back
          is play on; knock on advantage rule applies (as per rugby league).
          Defensive line can move forward only when the dummy half touches the
          ball. If there is no dummy half the defending team has to wait three
          seconds before they can advance to the ball. Dummy half can run and be
          caught with the ball. An attacker must stop and play the ball if he is
          in possession with only one tag on.
        </p>
        <p>
          Dummy half is the only player that can pass or kick with only one tag.
          However if the dummy half runs with the ball with only one tag on,
          will have to stop and play the ball and touch count continues. The
          ball must be carried across the try line and grounded in all
          situations to score a try. Simultaneous tag is play on. (If in the
          referee’s determination he cannot decide, then the pass is allowed -
          play on. The advantage goes to the attacking team). Attackers once
          tagged must play the ball with their foot on the mark where the tag
          has landed.
        </p>
        <h3>Kick Offs </h3>
        <p>
          Kick offs commence play, and line dropouts apply. (If a ball lands in
          the field of play then rolls across the try line whether touched or
          not, line drop out follows). Dead ball line is try line for all kicks.
          Kick offs are taken 10m behind the half way line. The ball must travel
          past the 10m line on the other side of half way.
        </p>
        <h3>Cautioned Players </h3>
        <p>
          Players guilty of misconduct and unsportsmanlike behaviour will be
          cautioned and may lead to further action. Examples of such behaviour
          include: Kicking or tripping an opponent Attempting to kick or trip an
          opponent Jumping or charging at an opponent in a violent or dangerous
          manner Holding or pushing an opponent Dissent or abuse to a referee’s
          decision.
        </p>
        <h3>Sin Bin </h3>
        <p>
          Due to the social nature of the competition the referee has the option
          to use the SIN BIN to cool players off. The player can be sin binned
          for up to 5 minutes depending on the severity of the incident. Sin
          Binned players CANNOT be replaced, but the player can return to the
          field after they have served the sentence. Further misconduct will
          result in that player being sent off!
        </p>
        <h3>Sending Off</h3>
        <p>
          A player can be sent off the field if, in the opinion of the referee,
          the offending player is guilty of:
        </p>
        <ul className="flex flex-col">
          <li style={{all:"unset" ,fontWeight:"bold"}} > ⦁ Violent conduct </li>
          <li style={{all:"unset" ,fontWeight:"bold"}} > ⦁ Serious foul play (eg. spitting, punching, striking). </li>
          <li style={{all:"unset" ,fontWeight:"bold"}} > ⦁ Using foul or abusive language </li>
        </ul>
        <p>Players that have been sent off CAN NOT return to the field.</p>
        <h3>Suspension </h3>
        <p>
          Any player sent off will earn an automatic two match suspension. This
          is to include two played games and does not include washed out games.
          Further action may be taken depending on the severity of the incident.
        </p>
      </div>
    </div>
  );
}
