import React from "react";
import { useState } from "react";
import GameDetailModal from "./GameDetailModal";
import "./GameScoreCard.scss";
function GameScoreCard({ gameData }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="w-full border-2 py-10 md:py-0 md:p-10 lg:p-12">
        <GameDetailModal show={show} setShow={setShow} gameData={gameData} />
        <p className="heading4">{gameData.gameDate.slice(0, 10)}</p>
        <p className="heading3">{gameData.rivelary.rivelaryName}</p>
        <p className="heading5">Final Score</p>
        <div className="scoreDiv items-center">
          <span className="mt1 heading3">{gameData.firstTeam?.teamName}</span>
          <div className="timeField">
            <div className="innerTimeField">
              <span className="timeText heading3">
                {gameData.firstTeamScore}
              </span>
            </div>
          </div>
          <span className="mt1 heading3">VS</span>

          <div className="timeField">
            <div className="innerTimeField">
              <span className="timeText heading3">
                {gameData.secondTeamScore}
              </span>
            </div>
          </div>
          <span className="mt1 heading3">{gameData.secondTeam?.teamName}</span>
        </div>
        <div className="flex justify-center">
          <button className="normalButton mt20 " onClick={() => setShow(true)}>
            Game Details
          </button>
        </div>
      </div>
    </>
  );
}

export default GameScoreCard;
