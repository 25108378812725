import React, { useEffect, useState } from "react";
import "./ComingGames.scss";
import Carousel from "react-bootstrap/Carousel";
import ComingGameCard from "./ComingGameCard";
import api from "../api";
import { Link } from "react-router-dom";
import FieldModal from "./FieldModal";

function ComingGames() {
  const [games, setGames] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [showField, setShowField] = useState(false);
  let [loading, setLoading] = useState(false);

  const getLatestGames = async () => {
    setLoading(true);
    const areas = await api.get("/games/api/v1/getUpcomingGames");
    setGames(areas.data);
    setLoading(false);
  };

  useEffect(() => {
    getLatestGames();
  }, []);
  return (
    <>
      <FieldModal
        show={showField}
        setShow={setShowField}
        selectedField={selectedField}
      />
      <div className="md:bigDiv md:w-[1280px] flex flex-col mx-2 my-16">
        <div className="grid grid-cols-1 md:grid-cols-2 container items-center">
          <div className="flex flex-col justify-center w-full ">
            <p className="headerText">Next Games</p>
            <p className="normalText">
              Never miss a beat! Get all game details from one place!
            </p>
            {games.length > 0 && (
              <Link
                to="/upcomingGames"
                className="normalButton w-1/2 no-underline hover:text-white "
              >
                All Upcoming Games
              </Link>
            )}
            {/* <button className="normalButton w-1/2">All Upcoming Games</button> */}
          </div>
          {games.length > 0 && (
            <div className="w-full  mt-10">
              <Carousel variant="dark">
                {games?.map((game) => {
                  return (
                    <Carousel.Item key={game._id}>
                      <ComingGameCard
                        gameData={game}
                        showField={setShowField}
                        setSelectedField={setSelectedField}
                      />
                    </Carousel.Item>
                  );
                })}
                {/* <Carousel.Item>
            <ComingGameCard />
          </Carousel.Item>
          <Carousel.Item>
            <ComingGameCard />
          </Carousel.Item> */}
              </Carousel>
            </div>
          )}
        </div>
        {games.length === 0 && (
          <p className="text-lg font-semibold text-center">No Games Found</p>
        )}
      </div>
    </>
  );
}

export default ComingGames;
