import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../api";
import DownloadApp from "../components/DownloadApp";
import GameScoreCard from "../components/GameScoreCard";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((game) => <GameScoreCard gameData={game} />)}
    </>
  );
}

function Scores({ itemsPerPage = 8 }) {
  const [games, setGames] = useState([]);

  const getAllGames = async () => {
    const areas = await api.get("/games/api/v1/getAllGames?finished=true");
    setGames(areas.data);
  };

  useEffect(() => {
    getAllGames();
  }, []);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = games.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(games.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % games.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <div className="container scale-90">
      <div className="flex justify-start items-center flex-col pb-20">
        <h1 className="text-6xl font-semibold text-[#1E354E]">Latest Scores</h1>
        {/* <h5 className="h4Regular">Use below filters for easier navigation</h5> */}
      </div>
      {/* <div className="w-full flex">
        <table className="w-full">
          <tr>
            <td dir="ltr">
              <div className="flex ">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2 cursor-pointer"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 0C0.447715 0 0 0.447715 0 1V3C0 3.55228 0.447715 4 1 4H3C3.55228 4 4 3.55228 4 3V1C4 0.447715 3.55228 0 3 0H1ZM7.25 1.3C6.83579 1.3 6.5 1.63579 6.5 2.05C6.5 2.46421 6.83579 2.8 7.25 2.8H17.25C17.6642 2.8 18 2.46421 18 2.05C18 1.63579 17.6642 1.3 17.25 1.3H7.25ZM7.25 8.3C6.83579 8.3 6.5 8.63579 6.5 9.05C6.5 9.46421 6.83579 9.8 7.25 9.8H17.25C17.6642 9.8 18 9.46421 18 9.05C18 8.63579 17.6642 8.3 17.25 8.3H7.25ZM7.25 15.3C6.83579 15.3 6.5 15.6358 6.5 16.05C6.5 16.4642 6.83579 16.8 7.25 16.8H17.25C17.6642 16.8 18 16.4642 18 16.05C18 15.6358 17.6642 15.3 17.25 15.3H7.25ZM1 7C0.447715 7 0 7.44772 0 8V10C0 10.5523 0.447715 11 1 11H3C3.55228 11 4 10.5523 4 10V8C4 7.44772 3.55228 7 3 7H1ZM1 14C0.447715 14 0 14.4477 0 15V17C0 17.5523 0.447715 18 1 18H3C3.55228 18 4 17.5523 4 17V15C4 14.4477 3.55228 14 3 14H1Z"
                    fill="#DDDFE3"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2 cursor-pointer"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 0C0.447715 0 0 0.447715 0 1V4C0 4.55228 0.447715 5 1 5H4C4.55228 5 5 4.55228 5 4V1C5 0.447715 4.55228 0 4 0H1ZM7.5 0C6.94772 0 6.5 0.447715 6.5 1V4C6.5 4.55228 6.94772 5 7.5 5H10.5C11.0523 5 11.5 4.55228 11.5 4V1C11.5 0.447715 11.0523 0 10.5 0H7.5ZM14 0C13.4477 0 13 0.447715 13 1V4C13 4.55228 13.4477 5 14 5H17C17.5523 5 18 4.55228 18 4V1C18 0.447715 17.5523 0 17 0H14ZM1 6.5C0.447715 6.5 0 6.94772 0 7.5V10.5C0 11.0523 0.447715 11.5 1 11.5H4C4.55228 11.5 5 11.0523 5 10.5V7.5C5 6.94772 4.55228 6.5 4 6.5H1ZM7.5 6.5C6.94772 6.5 6.5 6.94772 6.5 7.5V10.5C6.5 11.0523 6.94772 11.5 7.5 11.5H10.5C11.0523 11.5 11.5 11.0523 11.5 10.5V7.5C11.5 6.94772 11.0523 6.5 10.5 6.5H7.5ZM14 6.5C13.4477 6.5 13 6.94772 13 7.5V10.5C13 11.0523 13.4477 11.5 14 11.5H17C17.5523 11.5 18 11.0523 18 10.5V7.5C18 6.94772 17.5523 6.5 17 6.5H14ZM1 13C0.447715 13 0 13.4477 0 14V17C0 17.5523 0.447715 18 1 18H4C4.55228 18 5 17.5523 5 17V14C5 13.4477 4.55228 13 4 13H1ZM7.54545 13.0909C6.99317 13.0909 6.54545 13.5386 6.54545 14.0909V17C6.54545 17.5523 6.99317 18 7.54545 18H10.4545C11.0068 18 11.4545 17.5523 11.4545 17V14.0909C11.4545 13.5386 11.0068 13.0909 10.4545 13.0909H7.54545ZM14 13C13.4477 13 13 13.4477 13 14V17C13 17.5523 13.4477 18 14 18H17C17.5523 18 18 17.5523 18 17V14C18 13.4477 17.5523 13 17 13H14ZM14.5 1.5H16.5V3.5H14.5V1.5ZM8 1.5H10V3.5H8V1.5ZM1.5 1.5H3.5V3.5H1.5V1.5ZM14.5 8H16.5V10H14.5V8ZM8 8H10V10H8V8ZM1.5 8H3.5V10H1.5V8ZM14.5 14.5H16.5V16.5H14.5V14.5ZM8 14.5H10V16.5H8V14.5ZM1.5 14.5H3.5V16.5H1.5V14.5Z"
                    fill="#828D9E"
                  />
                </svg>
              </div>
            </td>
            <td dir="ltr" className="flex  flex-row-reverse gap-3">
              <select className="border rounded-lg border-[#E2E5EA] h-14 w-fit px-3">
                <option value="" selected>
                  Division
                </option>
              </select>
              <select className="border rounded-lg border-[#E2E5EA] h-14 w-fit px-3">
                <option value="" selected>
                  Compeition
                </option>
              </select>
            </td>
          </tr>
        </table>
      </div> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-4 w-full">
        <Items currentItems={currentItems} />
      </div>
      <ReactPaginate
        containerClassName="pagination"
        activeLinkClassName="paginationButtonsActive"
        pageLinkClassName="paginationButtons"
        previousLinkClassName="previousButtons"
        nextLinkClassName="nextButtons"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />

      <DownloadApp />
    </div>
  );
}

export default Scores;
