import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Grid } from "swiper";
import "swiper/css/grid";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SwiperContainer = (props) => {
  return (
    <div>
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        slidesPerView={4}
        spaceBetween={50}
        breakpoints={{
          // when window width is >= 576px
          200: {
            slidesPerView: 1,
            // navigation: false,
            pagination: { clickable: true },
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
            spaceBetween: 2,
            pagination: { clickable: true },
          },
          // when window width is >= 1024px
          1024: {
            spaceBetween: 50,
            slidesPerView: 4,
            pagination: { clickable: true },
          },
          // when window width is >= 1280px
          1280: {
            spaceBetween: 50,
            slidesPerView: 4,
            pagination: { clickable: true },
          },
          1500: {
            spaceBetween: 20,
            slidesPerView: 5,
            pagination: { clickable: true },
          },
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {props.children}
      </Swiper>
    </div>
  );
};
export default SwiperContainer;
