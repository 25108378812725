import React, { useEffect } from "react";
import { RiTeamLine } from "react-icons/ri";
import { BsArrowRight, BsCalendarEvent } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { GiSoccerField, GiFist, GiStairsGoal, GiFemale } from "react-icons/gi";
import { FcIdea } from "react-icons/fc";

function HowToPlay() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container scale-75  flex flex-col gap-5 justify-center -my-80">
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">How to Play Oztag</h1>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full gap-3 flex flex-col">
          <h3 className="text-2xl font-semibold">Understand Oztag</h3>
          <table className="w-full  gap-3">
            <tr>
              <td>
                <RiTeamLine className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]"> Team Limits</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">
                Minimum 11 players /team. 8 players on the field
              </td>
            </tr>
            <tr>
              <td>
                <BiTimeFive className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Match Duration</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">40 min - 20 min*</td>
            </tr>
            <tr>
              <td>
                <GiSoccerField className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Oztag Field</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">70m x 50m (half a soccer/Rugby field)</td>
            </tr>
            <tr>
              <td>
                <GiFist className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Skills</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">
                Passing, Catching, Tagging, Evasive and Kicking
              </td>
            </tr>
            <tr>
              <td>
                <GiStairsGoal className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Fitness Level</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">All fitness levels</td>
            </tr>
            <tr>
              <td>
                <GiFemale className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Team’s Sex</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">Males ,Females , Mixed Team</td>
            </tr>
            <tr>
              <td>
                <BsCalendarEvent className="text-[#828D9E] w-5 h-5 my-3 mr-2" />
              </td>
              <td className="text-lg text-[#828D9E]">Ages</td>
              <td>
                <BsArrowRight className="text-[#828D9E]" />
              </td>
              <td className="text-lg">From 6 To 60 years</td>
            </tr>
            <tr>
              <td>
                <FcIdea className="w-5 h-5 mr-2 my-3" />
              </td>
              <td colSpan={3} className="text-lg">
                Game time may be varied to suit age groups and conditions.
              </td>
            </tr>
          </table>
        </div>
        <div className="w-full">
          <img src="/images/about4.png" alt="about image" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">
          What You Will Need to Get Started?
        </h1>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full order-2 lg:order-1">
          <img src="/images/getstarted.png" alt="get started image" />
        </div>
        <div className="w-full gap-3 flex flex-col lg:order-2">
          <h3 className="text-2xl font-semibold">Understand Oztag</h3>
          <ol className="list-decimal flex flex-col gap-3">
            <li className="listItem">
              Shorts: Official Oztag Shorts with the Velcro patch
            </li>
            <li className="listItem">
              A Tag: A strip of cloth is attached to the Velcro
            </li>
            <li className="listItem">
              T-Shirts: Get matching shirts for your team (Make it FUN!)
            </li>
            <li className="listItem">
              Shoes: Astro turf, touch football shoes (rubber soled), Joggers or
              Runners
            </li>
            <li className="listItem">Let’s Play!</li>
          </ol>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-3 w-full">
        <h1 className="text-3xl font-semibold">
          Understand the Dynamics of Oztag
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2  w-full gap-3">
        <div className="w-full border-2 rounded-xl pt-5 pb-2 px-2">
          <h1 className="text-center text-lg font-semibold mb-3">
            The main goal of the game
          </h1>
          <ol className="list-decimal flex flex-col gap-3">
            <li className="text-base font-normal">
              Defenders prevent this by tagging the ball carrier (removing the
              tag from the shorts). Players must wear official ‘Oztag shorts’
              with a velcro patch on each side and a ‘tag’ is supplied{" "}
            </li>
            <li className="text-base font-normal">
              In Oztag football the attacking team has six plays or tags to try
              and score a try or promote the ball down field as close to the
              line as possible.{" "}
            </li>
            <li className="text-base font-normal">
              All skills are utilised in Oztag football including kicking and
              passing becomes an attacking weapon, and if a player is put into a
              gap, there is a high probability they will make a clean break
            </li>
            <li className="text-base font-normal">
              When attacking the line, fancy moves such as around-the-corner
              passes work well, often making the defender stoop low to remove
              the tag.
            </li>
          </ol>
        </div>
        <div className="w-full border-2 rounded-xl pt-5 pb-2 px-2">
          <h1 className="text-center text-lg font-semibold mb-3">
            The game is a low/medium-contact sport and{" "}
            <span className="text-[#00B5FF]">the rules</span>
          </h1>
          <ol className="list-decimal flex flex-col gap-3">
            <li className="text-base font-normal">
              A defender cannot impede the progress of an attacker, so if you
              try to get a tag and bump the attacker you may well be penalised.
            </li>
            <li className="text-base font-normal">
              The rules allow for the ball to go to ground with the advantage
              rule applying this results in a lot of broken field play.
            </li>
            <li className="text-base font-normal">
              Teams kick off to commence play and restart play after a try has
              been scored.
            </li>
            <li className="text-base font-normal">
              Line drop-outs are taken from the centre of the try line.
            </li>
            <li className="text-base font-normal">
              Kicking in general play is allowed but it must be below shoulder
              height of the referee.{" "}
            </li>
          </ol>
        </div>
      </div>

      <div className="flex flex-col items-center w-full">
        <h1 className="text-[40px] font-medium text-[#1E354E]">
          Contact Us Now
        </h1>
        <div className="flex md:flex-row flex-col w-full items-center justify-center mt-5 gap-5">
          <div className="flex flex-col w-full md:w-1/5 gap-2">
            <label className="text-base font-semibold" htmlFor="YourName">
              Your Name
            </label>
            <input
              type="text"
              name="YourName"
              id="YourName"
              className="input"
              placeholder="Name"
            />
            <label className="text-base font-semibold" htmlFor="EmailAddress">
              Email Address
            </label>
            <input
              type="email"
              name="EmailAddress"
              id="EmailAddress"
              className="input"
              placeholder="Email"
            />
          </div>
          <div className="flex flex-col w-full md:w-2/5 gap-2">
            <label className="text-base font-semibold" htmlFor="YourMessage">
              Your Message
            </label>
            <textarea
              name="YourMessage"
              id="YourMessage"
              rows={6}
              className="input"
              placeholder="Email"
            />
          </div>
        </div>
        <button className="input bg-[#00B5FF] text-white my-4">
          Send Message
        </button>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-20">
        <div className="w-full  gap-3 flex flex-col">
          <h3 className="text-2xl font-semibold">Scoring</h3>
          <ul className="list-disc flex flex-col gap-3">
            <li className="text-lg font-semibold">
              1 Point will be awarded for each try
            </li>
            <li className="text-lg font-semibold">
              Winning Team will receive 3 points
            </li>
            <li className="text-lg font-semibold">
              Drawn Game will receive 2 points
            </li>
            <li className="text-lg font-semibold">
              A loss will receive 1 point
            </li>
          </ul>
        </div>
        <div className=" w-full ">
          <img src="/images/scoring.png" alt="scoring image" />
        </div>
      </div>
    </div>
  );
}

export default HowToPlay;
