import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import * as Yup from "yup";
import { failNotification } from "../Notifications";
import api from "../../api";
import { useCookies } from "react-cookie";

const AdminAssignDelegate = ({ players, teamId, getPlayerInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const formik = useFormik({
    initialValues: {
      playerId: "",
      teamId,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      playerId: Yup.string().required("Player is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        // send request
        await api.post("/auth/api/v1/addTeamLeader", values, {
          headers: { token: cookies.token },
        });
        closeModal();
        resetForm();
        getPlayerInfo();
      } catch (error) {
        failNotification(error.response.data.message);
      }
    },
  });
  return (
    <>
      <button
        className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
        onClick={() => openModal()}
      >
        Assign Delegate
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Assign Delegate
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="w-full my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      <select
                        className="w-full mb-3 border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        {...formik.getFieldProps("playerId")}
                      >
                        <option value={""} hidden>
                          Please Select Player
                        </option>
                        {players?.map((player) => {
                          return (
                            <option key={player._id} value={player._id}>
                              {player.firstName} {player.lastName}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.playerId && formik.errors.playerId && (
                        <div className="text-red-500">
                          {formik.errors.playerId}
                        </div>
                      )}
                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          Apply Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default AdminAssignDelegate;
