import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Stepper from "./Stepper";
import TeamRegForm1 from "./TeamRegForm1";
import TeamRegForm2 from "./TeamRegForm2";
import TeamRegForm3 from "./TeamRegForm3";
import TeamRegForm4 from "./TeamRegForm4";
import TeamRegForm5 from "./TeamRegForm5";

function AdminAddNewTeamModal({ show, setShow }) {
  const [currentStep, setCurrentStep] = useState(1);

  const [players, setPlayers] = useState([]);
  const [teamData, setTeamData] = useState();
  const [teamLeader, setTeamLeader] = useState();
  const [cookies, setCookie] = useCookies(["token"]);
  // console.log(show)
  // console.log('testsss')
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
              enterTo="opacity-100 translate-y-0 sm:scale-75"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-75"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-2/3 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* body start */}
                  <div>
                    <h1 className="font-semibold text-4xl my-5">
                      Team Registration
                    </h1>
                  </div>
                  <Stepper currentStep={currentStep} />
                  <div className="w-full flex flex-col justify-center items-center">
                    <TeamRegForm1
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      setTeamLeader={setTeamLeader}
                    />
                    <TeamRegForm2
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      setTeamData={setTeamData}
                    />
                    <TeamRegForm3
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      setPlayers={setPlayers}
                      players={players}
                      teamLeader={teamLeader}
                    />
                    <TeamRegForm4
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                    />
                    <TeamRegForm5
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      players={players}
                      teamLeader={teamLeader}
                      teamData={teamData}
                      setShow={setShow}
                      admin={"admin"}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminAddNewTeamModal;
