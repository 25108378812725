import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

function RegPopup({regModal,setRegModal}) {

    const navigate = useNavigate()

    return (
        <Transition appear show={regModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setRegModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Register Now
                                </Dialog.Title>
                                <div className="mt-5 flex flex-col gap-3">
                                    <button className='text-white bg-[#00B5FF]  rounded-lg w-full py-2' onClick={()=>{navigate('/teamReg');setRegModal(false)}}>Register As Team</button>
                                    <button className='text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2'  onClick={()=>{navigate('/signup');setRegModal(false)}}>Register As Player</button>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default RegPopup