import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import ReactLoading from "react-loading";
import ConfirmRemoveGeneral from "../ConfirmRemoveGeneral";
import api from "../../../api";
import AddSubDiv from "../AddSubDiv";

function AdminSubDivisions() {
  const [subDivisions, setSubDivisions] = useState([]);
  const [modalType, setModalType] = useState("add");
  const [cookies] = useCookies(["token"]);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removeApi, setRemoveApi] = useState("");
  const [selectedField, setSelectedField] = useState();
  const [subDivModal, setSubDivModal] = useState(false);

  const getActiveSubDivisions = async () => {
    const response = await api.get("rivelary/api/v1/getAllSubDivisions", {
      headers: { token: cookies.token },
    });
    // console.log(response.data)
    setSubDivisions(response.data);
  };

  useEffect(() => {
    getActiveSubDivisions();
  }, []);

  const handleAddNew = () => {
    setModalType("add");
    setSubDivModal(true);
  };

  const handleEdit = (data) => {
    setSelectedField(data);
    setModalType("edit");
    setSubDivModal(true);
  };
  const handleRemove = (data) => {
    setSelectedField(data);
    setRemoveApi("rivelary/api/v1/removeSubDivision");
    setConfirmRemove(true);
  };

  const { SearchBar } = Search;

  let columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      style: { fontSize: "12px", width: "210px" },
      headerStyle: (colum, colIndex) => {
        return { width: "56px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: "subDivName",
      text: "SubDivision Name",
      sort: true,
    },
    {
      dataField: "division.divisionName",
      text: "Division",
      sort: true,
    },
    {
      dataField: "division.type",
      text: "Type",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "64px", textAlign: "center" };
      },
    },

    {
      dataField: "rivelary.rivelaryName",
      text: "Rivalry",
      sort: true,
    },
    {
      // dataField: 'id',
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmRemoveGeneral
        confirmRemove={confirmRemove}
        setConfirmRemove={setConfirmRemove}
        apiUrl={removeApi}
        selected={selectedField}
        resetFc={getActiveSubDivisions}
      />

      <AddSubDiv
        show={subDivModal}
        setShow={setSubDivModal}
        getAllSubDivs={getActiveSubDivisions}
        type={modalType}
        selectedSubDiv={selectedField}
      />
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      {subDivisions.length > 0 ? <div className="w-4/5 bg  h-fit flex flex-col gap-5 scale-90 -mx-20 min-h-screen">
        <ToolkitProvider
          keyField="_id"
          data={subDivisions}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="flex w-full justify-between">
                <SearchBar {...props.searchProps} className="input " />
                <button
                  className="normalButton py-2 text-sm"
                  onClick={() => handleAddNew()}
                >
                  Add Sub Division
                </button>
              </div>
              <hr />

              <BootstrapTable
                classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                pagination={paginationFactory({
                  showTotal: true,
                })}
                hover={true}
                striped
                bordered={true}
                loading={true}
                condensed
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>:(
        <div className="w-full flex justify-center items-center">
        <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
      </div>
      )}
    </>
  );
}

export default AdminSubDivisions;
