import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";

function AddSeason({
  type = "add",
  show,
  setShow,
  getAllSubDivs,
  setChange,
  change,
  selectedSubDiv,
}) {
  //   console.log(selectedField);
  const [initialValues, setInitialValues] = useState({
    subDivName: "",
    division: "",
    rivelary: "",
  });
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    // setInitialValues({
    //   subDivName: "",
    //   division: "",
    //   rivelary: "",
    // });
    setShow(false);
  }
  const [competitions, setCompetitions] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const getComp = async () => {
    const response = await api.get("/rivelary/api/v1/getRivelaries");
    // console.log(response)
    setCompetitions(response.data);
  };

  const handlecompetitionChange = async (id) => {
    const selectedRivelary = competitions.filter((item) => item._id === id);
    setDivisions(selectedRivelary?.[0]?.divisions);
  };
  useEffect(() => {
    getComp();
    // console.log(selectedField);
    if (selectedSubDiv && type !== "add") {
      handlecompetitionChange(selectedSubDiv.rivelary?._id);
      setInitialValues({
        ...initialValues,
        _id: selectedSubDiv._id,
        division: selectedSubDiv.division?._id,
        rivelary: selectedSubDiv.rivelary?._id,
        subDivName: selectedSubDiv.subDivName,
      });
    } else {
      setInitialValues({
        subDivName: "",
        division: "",
        rivelary: "",
      });
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedSubDiv, type]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      //   email: Yup.string().email("Invalid email rivalry").required("Required"),
      subDivName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      division: Yup.string().required(),
      rivelary: Yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        if (type == "add") {
          const newUser = await api.post(
            "/rivelary/api/v1/addSingleSubDivision",
            values,
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("SubDivision Added Successfully");
          getAllSubDivs();
          setShow(false);
          setChange(!change);
          formik.resetForm();
        } else {
          const newUser = await api.put(
            "/rivelary/api/v1/editSubDivision",
            { ...values, _id: selectedSubDiv._id },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("SubDivision Edited Successfully");
          getAllSubDivs();
          setShow(false);
          setChange(!change);
          formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error.response.data.message);
        failNotification(error.response.data.message);
      }
    },
  });
  console.log(formik.values);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add SubDivision" : "Edit SubDivision"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="rivelary"
                      >
                        Rivalry
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="rivelary"
                        name="rivelary"
                        onChange={(e) => {
                          handlecompetitionChange(e.target.value);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.rivelary}
                      >
                        <option value={""} hidden>
                          Please Select rivelary
                        </option>
                        {competitions?.map((rivalry) => {
                          return (
                            <option key={rivalry._id} value={rivalry._id}>
                              {rivalry.rivelaryName} / {rivalry.area.areaName}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.rivelary && formik.errors.rivelary ? (
                        <div className="text-red-500">
                          {formik.errors.rivelary}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="division"
                      >
                        Division
                      </label>
                      <select
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="division"
                        name="division"
                        onChange={(e) => {
                          // handlecompetitionChange(e.target.value);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.division}
                      >
                        <option value={""} hidden>
                          Please Select Division
                        </option>
                        {divisions?.map((division) => {
                          return (
                            <option key={division._id} value={division._id}>
                              {division.divisionName} / {division.night} /{" "}
                              {division.type}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.division && formik.errors.division ? (
                        <div className="text-red-500">
                          {formik.errors.division}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="seasonName"
                      >
                        SubDivision Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="subDivName"
                        name="subDivName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subDivName}
                      />
                      {formik.touched.subDivName && formik.errors.subDivName ? (
                        <div className="text-red-500">
                          {formik.errors.subDivName}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        {type == "add" ? "Add Subdivision" : "Edit Subdivision"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddSeason;
