import { Dialog, Transition } from '@headlessui/react'
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react'
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';
import api from '../../api';
import { failNotification, successNotification } from '../Notifications';
import ClipLoader from "react-spinners/ClipLoader";

function ChangePasswordModal({ changePassword, setChangePassword }) {
    let [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);

    const [initialValues, setInitialValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',

    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .min(6, 'Must be 6 characters or more')
                .required('Required'),
            newPassword: Yup.string()
                .min(6, 'Must be 6 characters or more')
                .required('Required'),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(false);

            try {
                setLoading(true)
                const newUser = await api.post('/auth/api/v1/changePassword', values, { headers: { token: cookies.token } })
                // console.log(newUser)
                // getPlayerInfo()
                successNotification('Password Changed Successfully')
                setChangePassword(false)
                setLoading(false)
                // console.log(newUser)
            } catch (error) {
                if (error.response.status == 404) {
                    failNotification('Please check your old password')
                    setLoading(false)

                }
                // console.log(error)
            }

        },
    });
    return (
        <Transition appear show={changePassword} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setChangePassword(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Change Password
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form className='flex flex-col  ' onSubmit={formik.handleSubmit}>
                                        {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                                        <div className='w-full flex flex-col'>

                                            <label className='font-medium text-base my-3' htmlFor="oldPassword">Old Password</label>
                                            <input
                                                className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'
                                                id="oldPassword"
                                                name="oldPassword"
                                                type="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.oldPassword}
                                            />
                                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                                <div className='text-red-500'>{formik.errors.oldPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className='w-full flex flex-col'>

                                            <label className='font-medium text-base my-3' htmlFor="newPassword">New Password</label>
                                            <input
                                                className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'
                                                id="newPassword"
                                                name="newPassword"
                                                type="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.newPassword}
                                            />
                                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                                <div className='text-red-500'>{formik.errors.newPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className='w-full flex flex-col'>

                                            <label className='font-medium text-base my-3' htmlFor="confirmNewPassword">Confirm New Password</label>
                                            <input
                                                className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	'
                                                id="confirmNewPassword"
                                                name="confirmNewPassword"
                                                type="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.confirmNewPassword}
                                            />
                                            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                                                <div className='text-red-500'>{formik.errors.confirmNewPassword}</div>
                                            ) : null}
                                        </div>

                                        <div className='w-full flex flex-col my-3 items-center'>
                                            <button type='submit' className='normalButton w-fit py-2'>{loading ? <ClipLoader
                                                color="white"
                                                loading={loading}
                                                size={30}
                                                speedMultiplier={1}
                                            /> : 'Apply Changes'}</button>
                                        </div>

                                    </form>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ChangePasswordModal