import React from "react";

export default function WeatherPolicy() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Weather Policy</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <p>
          In the past we have experienced torrential rain before and during the
          tournament however it has not stopped the tournament from going ahead.
        </p>
        <p>
          A wet weather plan has been formulated to move all games onto the main
          arena if by chance the outside fields are closed.
        </p>
        <p>
          If for any reason the tournament is cancelled prior to the day of the
          tournament a notice will be placed on the website{" "}
          <a href="www.oztag.com.au">www.oztag.com.au</a> & all our social
          media.
        </p>
        <p>
          All coaches/managers/associations should check the website on the
          evening prior to the tournament if conditions are extremely wet. It
          will then be their responsibility to inform players/teams of the
          cancellation.{" "}
          <span className="italic">Entry fee will be fully refunded.</span>
        </p>
        <p>
          If the weather causes the tournament to be cancelled on the day of the
          tournament the decision will be made by 8am. It will be posted on the
          website/social media and a message left on the wet weather number.
        </p>
        <p>
          If you do not have access to the website, please call the Oztag wet
          weather number 0427 239 460 after this time.
        </p>
        <p className="font-semibold">
          Should the tournament commence and need to be stopped due to inclement
          weather the siren will sound three long blasts. Play will halt and all
          persons should seek cover. A decision will be made by the tournament
          director when play will resume or if the days play is to be abandoned.
        </p>
        <p>
          Exceptionally hot and humid weather will also be considered. In the
          event of extremely hot uncomfortable weather the tournament director
          will consult with Medical staff and determine whether play should
          continue, postponed or be abandoned.
        </p>
        <p>
          The tournament director’s decision will be final taking into
          consideration ‘his duty of care’ and the safety of everybody involved
          in the tournament.
        </p>
        <p>
          If the tournament is cancelled or abandoned, AO will make a decision
          whether or not the tournament will be rescheduled on another date.
        </p>

        <p>
          If the tournament is abandoned after it has commenced AO will consider
          a partial refund of the entry fee depending on when the tournament is
          abandoned.
        </p>
        <p>
          If play is abandoned during a grand final, the team leading at the
          time will be declared the champions. If the score is drawn then the
          team finishing highest in the regular competition will be crowned
          champions. If the grand final hasn’t been played then the team
          finishing highest in the regular competition will be declared
          champions. Should points be the same, for and against will declare the
          champion.
        </p>
      </div>
    </div>
  );
}
