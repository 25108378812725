import React from "react";

export default function Couchescodeofconduct() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">Coaches Code of Conduct</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Actively discourage foul play and /or unsportsmanlike behavior by
            players.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Never ridicule or yell at a player for making a mistake or not
            coming first.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Be reasonable in your demands on player’s time, energy and
            enthusiasm.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Operate within the rules and spirit of Oztag and teach your
            players to do the same.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Ensure that the time players spend with you is a positive
            experience. All people are deserving of equal attention and
            opportunities.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Avoid overplaying the talented players; the just average need and
            deserve equal time.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Ensure that equipment and facilities meet safety standards and are
            appropriate to the age and ability of all players.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Display control, respect and professionalism to all involved with
            the sport. This includes opponents, coaches, officials,
            administrators, parents and spectators. Encourage players to do the
            same.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Show concern and caution toward sick and injured players.
          </li>
        </ul>
      </div>
    </div>
  );
}
