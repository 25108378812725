import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { ToastContainer, toast } from "react-toastify";
export const successNotification = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    limit: 1,
  });
};

export const failNotification = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    limit: 1,
  });
};
export const infoNotification = (msg) => {
  toast.info(msg, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    limit: 1,
  });
};
