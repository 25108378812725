import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";
import TimeInput from "react-time-input";
import { TiDeleteOutline } from "react-icons/ti";

function AddSeason({
  type = "add",
  show,
  setShow,
  getAllRivelries,
  selectedField,
  setChange,
  change,
  selectedSubDiv,
}) {
  //   console.log(selectedField);
  const [initialValues, setInitialValues] = useState({
    venueName: "",
    address: "",
  });
  const [cookies] = useCookies(["token"]);
  function closeModal() {
    // setTimeSlots([])
    // setInitialValues({
    //   venueName: "",
    //   address: "",
    // });
    setShow(false);
  }
  const [competitions, setCompetitions] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeInput, setTimeInput] = useState("");
  const getComp = async () => {
    const response = await api.get("/rivelary/api/v1/getRivelaries");
    // console.log(response)
    setCompetitions(response.data);
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, timeInput]);
  };

  const handleRemoveTimeSlot = (selectedSlot) => {
    let filtered = timeSlots.filter((slot) => slot !== selectedSlot);
    setTimeSlots(filtered);
  };

  useEffect(() => {
    // getComp();
    if (selectedSubDiv && type !== "add") {
      setTimeSlots(selectedSubDiv?.timeSlots);
      setInitialValues({
        ...initialValues,
        ...selectedSubDiv,
      });
    } else {
      setInitialValues({
        venueName: "",
        address: "",
      });
      setTimeSlots([]);
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedSubDiv, type]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      //   email: Yup.string().email("Invalid email rivalry").required("Required"),
      venueName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      address: Yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        // if(timeSlots.length===0){
        //   return failNotification('please insert timeslots')
        // }
        if (type == "add") {
          // const newUser = await api.post("/crud/api/v1/addVenue", {...values,timeSlots:timeSlots}, {
          const newUser = await api.post(
            "/crud/api/v1/addVenue",
            { ...values },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("Venue Added Successfull");
          // getAllRivelries();
          setShow(false);
          setChange(!change);
          formik.resetForm();
        } else {
          const newUser = await api.put(
            "/crud/api/v1/editVenue",
            { ...values, _id: selectedSubDiv._id },
            // { ...values, _id: selectedSubDiv._id ,timeSlots:timeSlots },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("Edit avenue Successfully");
          // getAllRivelries();
          setShow(false);
          setChange(!change);
          formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error);
        // console.log(error.response.data.message);
        failNotification(error.response.data.msg);
      }
    },
  });
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {type == "add" ? "Add Venue" : "Edit Venue"}
                </Dialog.Title>
                <div className="mt-2">
                  <form
                    className="flex flex-col gap-3 my-10"
                    onSubmit={formik.handleSubmit}
                  >
                    {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="seasonName"
                      >
                        Venue Name
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="venueName"
                        name="venueName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.venueName}
                      />
                      {formik.touched.venueName && formik.errors.venueName ? (
                        <div className="text-red-500">
                          {formik.errors.venueName}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="rivalry"
                      >
                        Address
                      </label>
                      <input
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                        id="address"
                        name="address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <div className="text-red-500">
                          {formik.errors.address}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="w-full flex gap-2">
                      <input
                        type="time"
                        className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	w-1/2"
                        min="00:00"
                        max="24:00"
                        value={timeInput}
                        onChange={(e) => setTimeInput(e.target.value)}
                      />
                      <span
                        className="normalButton w-fit py-2 "
                        onClick={() => handleAddTimeSlot()}
                      >
                        Add time slot
                      </span>
                    </div>

                    <div className="w-full flex flex-col gap-2">
                      <label
                        className="font-medium text-base mt-3"
                        htmlFor="rivalry"
                      >
                        Time Slots
                      </label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Slot</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {timeSlots?.map((slot) => {
                            return (
                              <tr>
                                <td>{slot}</td>
                                <td>
                                  <TiDeleteOutline
                                    size={30}
                                    className="cursor-pointer hover:text-red-500 text-xl"
                                    onClick={() => handleRemoveTimeSlot(slot)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div> */}

                    <div className="w-full flex flex-col col-span-2 items-center">
                      <button type="submit" className="normalButton w-fit py-2">
                        {type == "add" ? "Add Venue" : "Edit Venue"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddSeason;
