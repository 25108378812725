import { Dialog, Switch, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../api";
import { failNotification, successNotification } from "./Notifications";
import ClipLoader from "react-spinners/ClipLoader";

function AdminAddEditScoreModal({ show, setShow, gameData, getAllGames }) {
  // console.log(show)
  const [cookies, setCookie] = useCookies(["token"]);
  let [loading, setLoading] = useState(false);

  const cancelButtonRef = useRef(null);
  const [scores, setScores] = useState({
    firstTeamScore: 0,
    secondTeamScore: 0,
    finished: false,
  });

  useEffect(() => {
    setScores({
      firstTeamScore: gameData.firstTeamScore,
      secondTeamScore: gameData.secondTeamScore,
      finished: gameData.finished,
    });
  }, [gameData]);

  const handleSubmit = async () => {
    if (scores.firstTeamScore < 0 || scores.secondTeamScore < 0) {
      failNotification("Scores cannot be a negative value.");
      return;
    }
    setLoading(true);

    try {
      await api.put(
        "/games/api/v1/updateGame",
        {
          _id: gameData._id,
          scores,
        },
        {
          headers: { token: cookies.token },
        }
      );
      successNotification("Game Edit Successfully");
      // getPlayerInfo();
      getAllGames();
      setLoading(false);
      setShow(false);
    } catch (error) {
      failNotification(error.response.data.message);
      setLoading(false);
    }
  };

  //   console.log(gameData);
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto scale-75">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* body start */}
                  <div className="flex justify-center w-full">
                    <div className="flex flex-col justify-center items-center px-16 py-16 w-full y-fit">
                      <h2 className="text-[18px] font-semibold mb-5">
                        {gameData.gameDate.slice(0, 10)}
                      </h2>
                      <h3 className="text-[24px] font-semibold my-2">
                        {gameData.rivelary.rivelaryName}
                      </h3>
                      <h3 className="text-[24px] font-semibold my-2">
                        {gameData?.area?.areaName}
                      </h3>
                      <h3 className="text-[24px] font-semibold my-2">
                        {gameData?.field?.fieldName}
                      </h3>
                      <h5 className="mt-5">Final Score</h5>
                      <div className="flex flex-col my-3 w-full">
                        <div className="flex justify-center gap-3 items-center">
                          <span className="mt1 heading3">
                            {gameData.firstTeam?.teamName}
                          </span>
                          <div className="timeField">
                            <div className="innerTimeField ">
                              <input
                                type="text"
                                className="p-3 rounded-lg heading3 w-full  border-none"
                                value={scores.firstTeamScore}
                                onChange={(e) =>
                                  setScores({
                                    ...scores,
                                    firstTeamScore: e.target.value,
                                  })
                                }
                              />
                            </div>
                            {/* <span className='timeTypeText'>-</span> */}
                          </div>
                          <span className="mt1 heading3">VS</span>

                          <div className="timeField">
                            <div className="innerTimeField">
                              <input
                                type="text"
                                className="p-3 rounded-lg heading3 w-full border-none  "
                                value={scores.secondTeamScore}
                                onChange={(e) =>
                                  setScores({
                                    ...scores,
                                    secondTeamScore: e.target.value,
                                  })
                                }
                              />
                            </div>
                            {/* <span className='timeTypeText'>-</span> */}
                          </div>
                          <span className="mt1 heading3">
                            {gameData.secondTeam?.teamName}
                          </span>
                        </div>
                        <div className="flex justify-center w-full items-center my-3">
                          <span className=" heading3"> finished :</span>
                          <input
                            type="checkbox"
                            className="checkbox mx-2"
                            checked={scores.finished ? true : false}
                            onChange={(e) =>
                              setScores({
                                ...scores,
                                finished: !scores.finished,
                              })
                            }
                          />
                        </div>
                        <div className="w-full flex justify-center mt-5">
                          <button
                            className="py-3 bg-[#00B5FF] rounded-lg my-3 text-white w-1/4"
                            onClick={handleSubmit}
                          >
                            {loading ? (
                              <ClipLoader
                                color="white"
                                loading={loading}
                                size={30}
                                speedMultiplier={1}
                              />
                            ) : (
                              "Edit Game"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* body end */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AdminAddEditScoreModal;
