import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeaturedItem from "./FeaturedItem";
import "./FeaturedProducts.scss";
import SpecialOfferItem from "./SpecialOfferItem";
import SwiperContainer from "./swiperContainer";
import { SwiperSlide } from "swiper/react";
import ShopCard from "./ShopCard";
import api from "../api";
function FeaturedProducts() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    api.post("ecommerce/api/v1/products").then((res) => {
      // console.log("res", res.data);
      setProducts(res.data.products);
    });
  }, []);

  // console.log("products", products[0]);
  return (
    <>
      <div className=" hidden md:block my-16">
        <div className="innerDiv">
          <p className="headerTextFeatured">OzTag Merchandise</p>
          <p className="normalTextFeatured">
            High quality training and game jerseys and other merchandise to
            support your game play
          </p>
          <div className="itemsMainDiv">
            <div className="itemsDiv">
              <FeaturedItem product={products[0]} />
              <FeaturedItem product={products[1]} />
              <FeaturedItem product={products[2]} />
              <FeaturedItem product={products[3]} />
            </div>
            <div className="itemsDiv">
              <SpecialOfferItem product={products[7]} />
            </div>
            <div className="itemsDiv">
              <FeaturedItem product={products[4]} />
              <FeaturedItem product={products[5]} />
              <FeaturedItem product={products[6]} />
              <FeaturedItem product={products[7]} />
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="/shop">
              <button className="normalButton">Shop</button>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="w-full ml-0 mt-10 block md:hidden">
        <SwiperContainer>
          {products.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ShopCard
                  name={item.name}
                  price={item.price}
                  id={item._id}
                  image={item.image}
                  teamName={item.team_name}
                />
              </SwiperSlide>
            );
          })}
        </SwiperContainer>
      </div> */}
    </>
  );
}

export default FeaturedProducts;
