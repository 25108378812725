import ClipLoader from "react-spinners/ClipLoader";

const UnAllocatedPlayers = ({
  setAllocate,
  numberOfPlayers,
  players,
  loader,
}) => {
  // console.log(numberOfPlayers);
  // console.log(players);
  if (loader) {
    return (
      <>
        {" "}
        <div className="flex w-full justify-center my-10">
          <ClipLoader
            color="#00B5FF"
            loading={loader}
            size={50}
            speedMultiplier={1}
          />
        </div>
      </>
    );
  }
  return (
    <>
      {numberOfPlayers > 0 ? (
        <>
          <h3 className="h3semibold ">ATTENTION!</h3>
          <h3 className="h3semibold ">Unallocated Player!</h3>
          <h5>One or more players have not been allocated to a team yet.</h5>
          <button className="input  mb-10" onClick={() => setAllocate(true)}>
            Allocate Now
          </button>
        </>
      ) : (
        <>
          <h3 className="h3semibold ">NO!</h3>
          <h3 className="h3semibold ">Unallocated Player!</h3>
          <h5>All Players has been Aloocated To the Teams</h5>
        </>
      )}
    </>
  );
};

export default UnAllocatedPlayers;
