import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";

import { MdRemove, MdRemoveCircleOutline } from "react-icons/md";
import { TbEditCircle } from "react-icons/tb";
import ClipLoader from "react-spinners/ClipLoader";
import {
  failNotification,
  infoNotification,
  successNotification,
} from "./Notifications";

function AdminAddNewGame({
  type = false,
  show,
  setShow,
  getAllGames,
  selectedGame,
}) {
  let [loading, setLoading] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    rivelaryName: "",
    area: "",
    division: "",
    rivelaryType: "",
    startDate: "",
    // email: "",
  });
  const [addDivison, setAddDivision] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    formik.resetForm();
    setInitialValues({
      rivelary: "",
      area: "",
      division: "",
      rivelaryType: "",
      startDate: "",
    });
    setDivisions([]);
    setShow(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  let mydivisions = [];
  const getDivisions = async () => {
    setLoading(true);
    mydivisions = await api.get("/rivelary/api/v1/getRivelaryDivisons", {
      headers: { token: cookies.token },
      params: { _id: selectedGame.rivelary._id },
    });
    // console.log( mydivisions.data);
    setDivisions(mydivisions.data);
    setLoading(false);
  };
  useEffect(() => {
    // console.log(selectedRivalry);
    if (selectedGame) {
      // console.log(selectedGame)
      setInitialValues({
        ...initialValues,
        ...selectedGame,
        area: selectedGame.area._id,
        rivelary: selectedGame.rivelary._id,
        division: selectedGame.division._id,
        subDiv: selectedGame.subDiv._id,
        venue: selectedGame.field.venue,
        field: selectedGame.field._id,
        firstTeam: selectedGame.firstTeam._id,
        secondTeam: selectedGame.secondTeam._id,
        gameDate: selectedGame.gameDate
          ? new Date(selectedGame.gameDate).toISOString().slice(0, 10)
          : "",
      });

      handleAreaChange(selectedGame?.area?._id);
      handleCompetitionChange(selectedGame?.rivelary?._id);
      handleDivisionChange(selectedGame?.division?._id);
      handleVenueChange(selectedGame?.field?.venue);
      handleFieldChange(selectedGame?.field?._id);
      setGameType(selectedGame.gameType);
      // getDivisions();
    } else {
      setInitialValues({
        rivelary: "",
        area: "",
        field: "",
        venue: "",
        division: "",
        subDiv: "",
        firstTeam: "",
        secondTeam: "",
        firstTeamScore: 0,
        secondTeamScore: 0,
        finished: false,
        gameDate: "",
        gameTime: "",
      });
      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedGame]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      rivelary: Yup.string().required("Required"),
      area: Yup.string().required("Required"),
      venue: Yup.string().required("Required"),
      field: Yup.string().required("Required"),
      division: Yup.string().required("Required"),
      subDiv: Yup.string().required("Required"),
      firstTeam: Yup.string().required("Required"),
      secondTeam: Yup.string()
        .required("Required")
        .notOneOf(
          [Yup.ref("firstTeam"), null],
          "Please select differant teams!"
        ),
      finished: Yup.boolean().required("Required"),
      gameDate: Yup.date().required("Required"),
      gameTime: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      // console.log(values);
      setLoading(true);
      try {
        const [hoursString, minuitesString] = values.gameTime.split(":");
        const hour = parseInt(hoursString);
        const minuites = parseInt(minuitesString);
        const newDate = new Date(values.gameDate);
        newDate.setHours(hour);
        newDate.setMinutes(minuites);
        values.gameDate = newDate;
        // console.log(values);
        if (type) {
          const games = await api.put(
            "/games/api/v1/editGame",
            { ...values, gameType: gameType },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("Game Updated Successfully");
        } else {
          const games = await api.post(
            "/games/api/v1/addGame",
            { ...values, gameType: gameType },
            {
              headers: { token: cookies.token },
            }
          );
          successNotification("Game Add Successfully");
        }
        // getPlayerInfo();
        getAllGames();
        setLoading(false);
        setShow(false);
      } catch (error) {
        // console.log(error.response.data.message);
        failNotification(error.response.data.msg);
        setLoading(false);
      }
    },
  });
  const [areas, setAreas] = useState([]);
  const [fields, setFields] = useState([]);
  const [venues, setVenues] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    const getAreas = async () => {
      const areas = await api.get("/crud/api/v1/getAreasActive");
      setAreas(areas.data);
    };
    getAreas();
    const getVenues = async () => {
      const venues = await api.get("/crud/api/v1/getVenuesActive");
      setVenues(venues.data);
    };
    getVenues();
  }, []);

  const [selectedDiv, setSelectedDiv] = useState();
  const [competitions, setCompetitions] = useState([]);
  const [subDivs, setSubDivs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [gameType, setGameType] = useState("Senior");
  const [divType, setDivType] = useState("add");
  const [firstTeamLength, setFirstTeamLength] = useState(0);
  const [secondTeamLength, setSecondteamLength] = useState(0);
  const handleAreaChange = async (areaId) => {
    const response = await api.get("/rivelary/api/v1/getAreaRivelaries", {
      params: { _id: areaId },
    });

    // console.log(response)
    setCompetitions(response.data);
  };
  const handleTeamChange = async (e, team) => {
    const teamId = e.target.value;
    const teamPlayers = await api.post(
      "/auth/api/v1/getTeamPlayers",
      {
        _id: teamId,
      },
      { headers: { token: cookies.token } }
    );
    if (team === "firstTeam") setFirstTeamLength(teamPlayers.data.length);
    if (team === "secondTeam") setSecondteamLength(teamPlayers.data.length);
  };
  const handleCompetitionChange = async (rivelaryId) => {
    const response = await api.get("/rivelary/api/v1/getRivelaryDivisonsAll", {
      params: { _id: rivelaryId },
    });
    console.log(response.data);
    setDivisions(response.data);
  };
  const handleDivisionChange = async (divisionId) => {
    const response = await api.post("/games/api/v1/getDivisionTeams", {
      division: divisionId,
    });
    setTeams(response.data);

    let selectedDivision = divisions.filter(
      (division) => division._id == divisionId
    );
    // let myArray = new Array(selectedDivision[0].subDivisionNo).fill(0);
    // myArray.forEach((item, index) => (myArray[index] = index + 1));
    // setSubDivs(myArray);
    const responce2 = await api.get("/rivelary/api/v1/getDivisionSubsActive", {
      params: { _id: divisionId },
    });
    setGameType(selectedDivision[0]?.type);
    setSubDivs(responce2.data);
  };

  const handleVenueChange = async (venueId) => {
    const response = await api.post("/crud/api/v1/getVenueFields", {
      _id: venueId,
      gameDate:formik.values.gameDate
    });
    setFields(response.data);
  };

  const handleFieldChange = async (fieldId) => {
    let fieldFilter = fields.filter((field) => field._id == fieldId);
    setTimeSlots(fieldFilter[0].timeSlots);
  };
  return (
    <>
      {/* <AddDivision
        show={addDivison}
        setShow={setAddDivision}
        divisions={divisions}
        setDivisions={setDivisions}
        selectedDiv={selectedDiv}
        type={type}
        selectedRivalry={selectedRivalry}
        getDivisions={getDivisions}
      /> */}
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {type ? "Edit Game" : "Add Game"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="area"
                        >
                          Area
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="area"
                          name="area"
                          onChange={(e) => {
                            handleAreaChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.area}
                        >
                          <option value={""} hidden>
                            Please select area
                          </option>
                          {areas?.map((area) => {
                            return (
                              <option value={area._id}>{area.areaName}</option>
                            );
                          })}
                        </select>
                        {formik.touched.area && formik.errors.area ? (
                          <div className="text-red-500">
                            {formik.errors.area}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="area"
                        >
                          Competition
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="rivelary"
                          name="rivelary"
                          onChange={(e) => {
                            handleCompetitionChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.rivelary}
                        >
                          <option value={""} hidden>
                            Please select competition
                          </option>
                          {competitions?.map((competition) => {
                            return (
                              <option value={competition._id}>
                                {competition.rivelaryName}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.rivelary && formik.errors.rivelary ? (
                          <div className="text-red-500">
                            {formik.errors.rivelary}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="startDate"
                        >
                          Divison
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="division"
                          name="division"
                          onChange={(e) => {
                            handleDivisionChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.division}
                        >
                          <option value={""} hidden>
                            Please select division
                          </option>
                          {divisions?.map((division) => {
                            return (
                              <option value={division._id}>
                                {division.divisionName} / {division.night} /{" "}
                                {division.type}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.division && formik.errors.division ? (
                          <div className="text-red-500">
                            {formik.errors.division}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="subDiv"
                        >
                          Sub division
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="subDiv"
                          name="subDiv"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.subDiv}
                        >
                          <option value={""} hidden>
                            Please select sub division
                          </option>
                          {subDivs?.map((division) => {
                            return (
                              <option value={division._id}>
                                {division.subDivName}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.subDiv && formik.errors.subDiv ? (
                          <div className="text-red-500">
                            {formik.errors.subDiv}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstTeam"
                        >
                          First Team
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstTeam"
                          name="firstTeam"
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleTeamChange(e, "firstTeam");
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstTeam}
                        >
                          <option value={""} hidden>
                            Please select first team
                          </option>
                          {teams?.map((team) => {
                            return (
                              <option value={team._id}>{team.teamName}</option>
                            );
                          })}
                        </select>

                        {formik.touched.firstTeam && formik.errors.firstTeam ? (
                          <div className="text-red-500">
                            {formik.errors.firstTeam}
                          </div>
                        ) : null}
                        {formik.touched.firstTeam && (
                          <span className="text-green-500">
                            {" "}
                            First team has {firstTeamLength} players{" "}
                          </span>
                        )}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstTeamScore"
                        >
                          First team score
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstTeamScore"
                          name="firstTeamScore"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstTeamScore}
                        />
                        {formik.touched.firstTeamScore &&
                        formik.errors.firstTeamScore ? (
                          <div className="text-red-500">
                            {formik.errors.firstTeamScore}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="secondTeam"
                        >
                          Second team
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="secondTeam"
                          name="secondTeam"
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleTeamChange(e, "secondTeam");
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondTeam}
                        >
                          <option value={""} hidden>
                            Please select second team
                          </option>
                          {teams?.map((team) => {
                            return (
                              <option value={team._id}>{team.teamName}</option>
                            );
                          })}
                        </select>
                        {formik.touched.secondTeam &&
                        formik.errors.secondTeam ? (
                          <div className="text-red-500">
                            {formik.errors.secondTeam}
                          </div>
                        ) : null}
                        {formik.touched.secondTeam && (
                          <span className="text-green-500">
                            {" "}
                            Second team has {secondTeamLength} players{" "}
                          </span>
                        )}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="secondTeamScore"
                        >
                          Second team score
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="secondTeamScore"
                          name="secondTeamScore"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondTeamScore}
                        />
                        {formik.touched.secondTeamScore &&
                        formik.errors.secondTeamScore ? (
                          <div className="text-red-500">
                            {formik.errors.secondTeamScore}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="startDate"
                        >
                          Status
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="finished"
                          name="finished"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.finished}
                        >
                          <option value={""} hidden>
                            Please select status
                          </option>
                          <option value={false}>Not Finished</option>
                          <option value={true}>Finished</option>
                        </select>
                        {formik.touched.finished && formik.errors.finished ? (
                          <div className="text-red-500">
                            {formik.errors.finished}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="gameDate"
                        >
                          Competition date
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="gameDate"
                          name="gameDate"
                          type="date"
                          onChange={(e) => {
                            formik.setFieldValue("venue","")
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.gameDate}
                        />
                        {formik.touched.gameDate && formik.errors.gameDate ? (
                          <div className="text-red-500">
                            {formik.errors.gameDate}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="startDate"
                        >
                          Venue
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="venue"
                          name="venue"
                          onChange={(e) => {
                            handleVenueChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.venue}
                        >
                          <option value={""} hidden>
                            Please select venue
                          </option>
                          {venues?.map((venue) => {
                            return (
                              <option value={venue._id}>
                                {venue.venueName}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.venue && formik.errors.venue ? (
                          <div className="text-red-500">
                            {formik.errors.venue}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="startDate"
                        >
                          Field
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="field"
                          name="field"
                          onChange={(e) => {
                            handleFieldChange(e.target.value);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.field}
                        >
                          <option value={""} hidden>
                            Please select field
                          </option>
                          {fields?.map((field) => {
                            return (
                              <option value={field._id}>
                                {field.fieldName}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.field && formik.errors.field ? (
                          <div className="text-red-500">
                            {formik.errors.field}
                          </div>
                        ) : null}
                      </div>
                      
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="gameTime"
                        >
                          Competition time
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="gameTime"
                          name="gameTime"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.gameTime}
                        >
                          <option value={""} hidden>
                            Please select time
                          </option>
                          {timeSlots?.map((slot) => {
                            return <option value={slot}>{slot}</option>;
                          })}
                          {/* <option value={1}>6:00</option>
                          <option value={2}>6:45</option>
                          <option value={3}>7:30</option>
                          <option value={4}>8:15</option> */}
                        </select>
                        {formik.touched.gameTime && formik.errors.gameTime ? (
                          <div className="text-red-500">
                            {formik.errors.gameTime}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex items-center">

                      <span className="text-red-500">Only avaialble fields and timeslots will be displayed based on your choice.</span>
                      </div>

                      <div className="w-full flex flex-col col-span-2 items-center mt-5">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          {loading ? (
                            <ClipLoader
                              color="white"
                              loading={loading}
                              size={30}
                              speedMultiplier={1}
                            />
                          ) : type ? (
                            "Edit Game"
                          ) : (
                            "Add Game"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AdminAddNewGame;
