import React from "react";

export default function Playerscodeofconduct() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">Players Code of Conduct</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Abide by the rules. Never argue with the referee. Respect
            decisions made by referees and encourage others to do the same.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Never argue with an official. If you disagree have your captain,
            coach or manager approach the official during a break or after the
            competition
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Control your temper. Verbal or physical abuse towards referees,
            officials or other players, are not acceptable or permitted in
            Oztag. Do not yell at, sledge, harass or ridicule others. Do not use
            foul, threatening or insulting language. Never make racist threats
            or remarks.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Be a good sport. Applaud all good plays whether they are made by
            your team or the opposition.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Treat all participants in your sport as you like to be treated. Do
            not bully or take unfair advantage of another competitor.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Show compassion and consideration at all times, to injured and ill
            players and other persons. Do everything you can to help and support
            them.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Cooperate with your coach, teammates and opponents. Without them
            there would be no competition.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Participate for your own enjoyment and benefit, not just to please
            parents and coaches. Win or lose with respect and dignity.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            • Respect the rights, dignity and worth of all participants
            regardless of their gender, ability, cultural background or
            religion.
          </li>
        </ul>
      </div>
    </div>
  );
}
