import React from "react";
import "./Home.scss";
import CarouselHome from "../components/CarouselHome";
import Categories from "../components/Categories";
import FeaturedProducts from "../components/FeaturedProducts";
import ComingGames from "../components/ComingGames";
import LatestScores from "../components/LatestScores";
import Tables from "../components/Tables";
import DownloadApp from "../components/DownloadApp";
import SponsorSlider from "../components/SponsorSlider";
import CommonSlider from "../components/CommonSlider";
function Home() {
  return (
    <div>
      {/* <CarouselHome /> */}
      <CommonSlider query={"position=home"} allowRegister={true} />
      <div className="mainDiv">
        <Categories />
        <FeaturedProducts />
        <ComingGames />
        <LatestScores />
        <Tables />
        <SponsorSlider />
        <DownloadApp />
      </div>
    </div>
  );
}

export default Home;
