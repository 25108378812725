import { Select } from "flowbite-react";
import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { BsEye, BsPerson, BsPhone } from "react-icons/bs";
import { CgCalendar, CgLock } from "react-icons/cg";
import { CiEdit, CiMedal } from "react-icons/ci";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { TbSoccerField } from "react-icons/tb";
import { IoMedalOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import api from "../api";
import EditPlayerModal from "../components/EditPlayerDataModal";
import ClipLoader from "react-spinners/ClipLoader";
import { useFormik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import * as Yup from "yup";
import { successNotification } from "../components/Notifications";
import ChangePasswordModal from "../components/auth/ChangePasswordModal";

function OrderDetailsModal({ show, setShow, orderData, getAllOrders }) {
  // console.log(orderData, "========<<<");
  const [initialValues, setInitialValues] = useState({
    status: "",
    shippingNumber: "",
    address: "",
    phone: "",
    totalPrice: "",
  });
  const [cookies, setCookie] = useCookies(["token"]);
  let [isOpen, setIsOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    setInitialValues({ ...initialValues, ...orderData });
  }, [orderData]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      try {
        const order = await api.patch("/ecommerce/api/v1/orders", values, {
          headers: { token: cookies.token },
          params: { _id: orderData._id },
        });
        successNotification("Order Data Updated Successfully");
        getAllOrders();
        setShow(false);
        // console.log(newUser)
      } catch (error) {
        console.log("error");
      }
    },
  });

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto scale-75">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-100"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-100"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h2>Edit Order Details</h2>
                  {/* body start */}
                  <div className="mt-2">
                    <div className="flex flex-col gap-3 my-10">
                      <div className="flex gap-2">
                        <span>User Name</span>
                        <span className="font-medium text-base">
                          {orderData?.user?.firstName}{" "}
                          {orderData?.user?.lastName}
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span>User ID</span>
                        <span className="font-medium text-base">
                          {orderData?.user?._id}
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span>Order ID</span>
                        <span className="font-medium text-base">
                          {orderData?._id}
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span>Order Payment ID</span>
                        <span className="font-medium text-base">
                          {orderData?.paymentLogId}
                        </span>
                      </div>
                    </div>
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Addrss
                        </label>
                        <input
                          className="py-2 px-2 border-0 bg-slate-200 rounded-md"
                          id="address"
                          name="address"
                          type="text"
                          disabled
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {/* {formik.touched.address &&
                        formik.errors.address ? (
                          <div className="text-red-500">
                            {formik.errors.address}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phone"
                        >
                          Phone Number
                        </label>
                        <input
                          className="py-2 px-2 border-0 bg-slate-200 rounded-md"
                          id="phone"
                          name="phone"
                          type="text"
                          disabled
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {/* {formik.touched.phone &&
                        formik.errors.phone ? (
                          <div className="text-red-500">
                            {formik.errors.phone}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="totalPrice"
                        >
                          Total Price
                        </label>
                        <input
                          className="py-2 px-2 border-0 bg-slate-200 rounded-md"
                          id="totalPrice"
                          name="totalPrice"
                          type="text"
                          disabled
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.totalPrice}
                        />
                        {/* {formik.touched.totalPrice &&
                        formik.errors.totalPrice ? (
                          <div className="text-red-500">
                            {formik.errors.totalPrice}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="shippingNumber"
                        >
                          Shipping Number
                        </label>
                        <input
                          className="py-2 px-2 border-0 bg-slate-200 rounded-md"
                          id="shippingNumber"
                          name="shippingNumber"
                          type="text"
                          disabled
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.shippingNumber}
                        />
                        {/* {formik.touched.shippingNumber &&
                        formik.errors.shippingNumber ? (
                          <div className="text-red-500">
                            {formik.errors.shippingNumber}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="status"
                        >
                          Order Status
                        </label>
                        <select
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="status"
                          name="status"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.status}
                        >
                          <option value={"received"}>received</option>
                          <option value={"processing"}>processing</option>
                          <option value={"rejected"}>rejected</option>
                          <option value={"shipped"}>shipped</option>
                        </select>
                        {formik.touched.status && formik.errors.status ? (
                          <div className="text-red-500">
                            {formik.errors.status}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          Apply Changes
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* body end */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default OrderDetailsModal;
