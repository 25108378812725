import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import * as Yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import { failNotification, successNotification } from "../Notifications";
import RegisterToTeam from "./players/RegisterToTeam";
import { TiDeleteOutline } from "react-icons/ti";
import Loader from "../loader";
import { PhoneRegExp } from "../TeamRegForm3";

function AdminEditPlayerData({
  playerId,
  type = "add",
  show,
  setShow,
  getAllPlayers,
  selectedPlayer,
}) {
  //   console.log(selectedPlayer);
  const [playerTeams, setPlayerTeams] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    dateOfBirth: "",
    email: "",
  });
  // const [codeVisible, setCodeVisible] = useState(false);
  const [image, setImage] = useState(null);
  const [cookies] = useCookies(["token"]);
  const [adminType, setAdminType] = useState(3);
  const [loading, setLoading] = useState(false);
  function closeModal() {
    formik.resetForm();
    setShow(false);
  }
  const updateData = async (user) => {};
  const handlePaymentStatus = async (subscription) => {
    const body = {
      playerId: selectedPlayer._id,
      subscription: {
        teamId: subscription.team._id,
        paidStatus: !subscription.paidStatus,
      },
    };
    // console.log(body);
    try {
      await api.post(`auth/api/v1/handlePaymentStatus`, body);
      // console.log(updatedResponse, "====<< updatedResponse");
      setPlayerTeams((prev) => {
        prev = prev.map((element) => {
          if (element.team._id === body.subscription.teamId) {
            element.paidStatus = body.subscription.paidStatus;
            return element;
          }
          return element;
        });
        return prev;
      });
    } catch (err) {
      // console.log(err);
    }
  };

  //   const navigate = useNavigate();
  //   const getPlayerInfo = async () => {
  //     try {
  //       //   const user = await api.get("/auth/api/v1/getPlayerData", {
  //       //     headers: { token: cookies.token },
  //       //   });
  //       //   setUserData(user.data);
  //       // console.log(user)
  //     } catch {
  //       console.log("error");
  //       //   navigate("/");
  //     }
  //   };
  //   useEffect(() => {
  //     getPlayerInfo();
  //   }, []);

  useEffect(() => {
    const handleProfile = async () => {
      const newUser = await api.get("/auth/api/v1/validateToken", {
        headers: { token: cookies.token },
      });
      setAdminType(newUser.data.roleId);
    };
    handleProfile();
    if (selectedPlayer) {
      setPlayerTeams(selectedPlayer?.subscriptions);
      setInitialValues({
        ...initialValues,
        ...selectedPlayer,
        paid: selectedPlayer?.paid ? "true" : "false",
        dateOfBirth: selectedPlayer.dateOfBirth
          ? new Date(selectedPlayer.dateOfBirth).toISOString().slice(0, 10)
          : "",
      });
      setImage(selectedPlayer?.image);
    } else {
      setPlayerTeams([]);
      setInitialValues({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        address: "",
        dateOfBirth: "",
        email: "",
        paid: false,
      });
      setImage(null);

      formik.resetForm();
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [selectedPlayer]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      setLoading(true);
      // console.log(values)
      try {
        if (type == "add") {
          const formData = new FormData();

          Object.entries(values).forEach((item) => {
            let [key, value] = item;
            if (key !== "paid") {
              formData.append(key, value);
            }
          });
          formData.append("regPlatform", "Web");

          // let paidValue = values.paid === "true" ? true : false;
          // formData.append("paid", paidValue);
          const newUser = await api.post("/auth/api/v1/addPlayer", formData, {
            headers: { token: cookies.token },
          });
          setLoading(false);
          successNotification("Player Add Successfully");
          getAllPlayers();
          setShow(false);
          formik.resetForm();
        } else {
          const formData = new FormData();

          Object.entries(values).forEach((item) => {
            let [key, value] = item;
            if (key !== "paid" && key !== "teamId" && key !== "favTeams") {
              formData.append(key, value);
            }
          });
          let paidValue = values.paid === "true" ? true : false;
          formData.append("paid", paidValue);
          const updatedUser = await api.patch(
            "/auth/api/v1/updatePlayerDataFromTeam",
            formData,
            {
              headers: { token: cookies.token },
            }
          );
          setLoading(false);
          successNotification("Player Edited Successfully");
          getAllPlayers();
          setShow(false);
          formik.resetForm();
        }
        // getPlayerInfo();
        // console.log(newUser)
      } catch (error) {
        // console.log(error.response.data.message);
        setLoading(false);
        failNotification(error.response.data.message);
      }
    },
  });
  const [addTeam, setAddTeam] = useState(false);

  const handleRegisterTeam = () => {
    setAddTeam(true);
  };

  const handleRemove = async (teamId) => {
    try {
      const team = await api.put(
        "/auth/api/v1/removeTeamFromPlayerAdmin",
        { teamId: teamId, player: selectedPlayer },
        {
          headers: { token: cookies.token },
        }
      );
      successNotification("team removed successfully");
      // const newArray = playerTeams?.filter((team) => team?._id !== teamId);
      setPlayerTeams((prev) => {
        prev = prev.filter((element) => element.team._id !== teamId);

        return prev;
      });
      getAllPlayers();
    } catch {
      failNotification("problem happened");
    }
  };
  // console.log(adminType);

  return (
    <>
      <RegisterToTeam
        show={addTeam}
        setShow={setAddTeam}
        player={selectedPlayer}
        resetFunc={getAllPlayers}
        setPlayerTeams={setPlayerTeams}
        playerTeams={playerTeams}
        onUpdateData={updateData}
      />
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {type == "add" ? "Add Player" : "Edit Player"}
                  </Dialog.Title>

                  <div className="mt-2">
                    {
                      <div className="flex w-full justify-center my-3">
                        {image ? (
                          <img
                            loading="lazy"
                            className="w-20 h-20 rounded-full"
                            src={`${process.env.REACT_APP_SERVER_URL}${image}`}
                            alt="Rounded avatar"
                          ></img>
                        ) : (
                          <div className="relative w-32 h-32 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                            <svg
                              className="absolute w-36 h-36 text-gray-400 -left-1"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    }
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstName"
                          name="firstName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-500">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="lastName"
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-500">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="email"
                        >
                          E-mail
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="email"
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Address
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="address"
                          name="address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-red-500">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="dateOfBirth"
                        >
                          Date Of Birth
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfBirth}
                        />
                        {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth ? (
                          <div className="text-red-500">
                            {formik.errors.dateOfBirth}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="text-red-500">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="confirmPassword"
                        >
                          Image
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="image"
                          name="image"
                          type="file"
                          onChange={(e) => {
                            formik.setFieldValue("image", e.target.files[0]);
                            setImage(URL.createObjectURL(e.target.files[0]));
                          }}
                          onBlur={formik.handleBlur}
                          value={undefined}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className="text-red-500">
                            {formik.errors.image}
                          </div>
                        ) : null}
                      </div>
                      {/* {adminType === 4 ? (
                        <div className="w-full flex flex-col">
                          <label
                            className="font-medium text-base mt-3"
                            htmlFor="paid"
                          >
                            Payment status
                          </label> */}
                      {/* <select
                            className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                            id="paid"
                            name="paid"
                            value={formik.values.paid}
                            onChange={(e) => {
                              // handleTeamChange(e.target.value);
                              formik.handleChange(e);
                            }}
                          >
                            <option value={"false"}>not paid</option>
                            <option value={"true"}>paid</option>
                          </select> */}
                      {/* {formik.touched.paid && formik.errors.paid ? (
                            <div className="text-red-500">
                              {formik.errors.paid}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        ""
                      )} */}

                      {/* <div className="w-full flex flex-col col-span-2">
                      <div className="flex flex-row my-5 items-center">
                        <input
                          checked={readTerms}
                          onChange={() => setReadTerms(!readTerms)}
                          type="checkbox"
                          className="w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60"
                        />
                        <label className="font-medium text-base ml-3 ">
                          This player has read & agreed to the insurance
                          Condititions of Play & the Competition Regulations
                        </label>
                      </div>
                    </div> */}
                      <div className="w-full flex flex-col col-span-2">
                        {type === "edit" && (
                          <div
                            className="normalButton w-fit py-2 cursor-pointer"
                            onClick={() => handleRegisterTeam()}
                          >
                            Register Team
                          </div>
                        )}
                      </div>
                      {type === "edit" && (
                        <div className="w-full flex flex-col col-span-2">
                          <table>
                            <thead>
                              <th>Team</th>
                              <th>Payment Status</th>
                              <th>Team Status</th>
                              <th>Remove</th>
                            </thead>
                            <tbody>
                              {playerTeams?.map((subscription) => {
                                // console.log(subscription);
                                return (
                                  <tr>
                                    <td>{subscription?.team?.teamName}</td>
                                    <td className="flex justify-between">
                                      {subscription?.paidStatus === false
                                        ? "pending"
                                        : "Active"}
                                      {adminType === 4 && (
                                        <span
                                          className=" cursor-pointer mx-4 "
                                          onClick={() =>
                                            handlePaymentStatus(subscription)
                                          }
                                        >
                                          {" "}
                                          {subscription.paidStatus === false ? (
                                            <span className="text-green-500">
                                              Activate
                                            </span>
                                          ) : (
                                            <span className="text-red-500">
                                              Hold
                                            </span>
                                          )}{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td className="font-bold">
                                      <span
                                        className={`pl-4 ${
                                          subscription?.team?.status ===
                                          "active"
                                            ? "text-green-500"
                                            : "text-red-500"
                                        }`}
                                      >
                                        {subscription?.team?.status}
                                      </span>
                                    </td>
                                    <td>
                                      <TiDeleteOutline
                                        size={30}
                                        className="cursor-pointer hover:text-red-500 text-xl"
                                        onClick={() =>
                                          handleRemove(subscription?.team._id)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                          disabled={loading}
                        >
                          {loading ? (
                            <Loader />
                          ) : type == "add" ? (
                            "Add Player"
                          ) : (
                            "Edit Player"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AdminEditPlayerData;
