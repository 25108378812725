import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import {HiArrowRight} from 'react-icons/hi'

function EditPlayerModal({ show, setShow }) {
    // console.log(show)
    // console.log('testsss')
    const cancelButtonRef = useRef(null)



    return (
        <Transition.Root show={show} as={Fragment}>


            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShow}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
                            enterTo="opacity-100 translate-y-0 sm:scale-75"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-75"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-70"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-1/2 scale-75">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    {/* body start */}
                                    <div className="flex justify-center w-full">
                                        <div className='flex flex-col justify-center   py-10 w-full y-fit gap-3'>
                                            <h2 className='font-semibold text-3xl text-center'>Profile</h2>
                                            <div className='flex justify-center items-center gap-2'>

                                                {/* <span className='text-base font-medium text-[#828D9E]'>#Man of the match </span>
                                                <HiArrowRight className='text-[#FFB000]'/>
                                                <span className='text-base font-medium text-[#1E354E]'>4 times </span> */}

                                            </div>
                                            <h2 className='font-semibold text-2xl'>Personal Information</h2>


                                            <div className='grid grid-cols-2 gap-x-5 gap-y-5 w-full'>
                                                <label htmlFor="firstName" className='font-semibold text-base'>First Name</label>
                                                <label htmlFor="lastName" className='font-semibold text-base'>Last Name</label>
                                                <input type="text" className='input' placeholder='First Name' id='firstName' />
                                                <input type="text" className='input' placeholder='Last Name' id='lastName' />
                                                <label htmlFor="email" className='font-semibold text-base'>Email</label>
                                                <label htmlFor="phone" className='font-semibold text-base'>Phone Number</label>
                                                <input type="email" className='input' placeholder='Email' id='email' />
                                                <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='input' placeholder='Phone Number' id='phone' />
                                                <label htmlFor="DOF" className='font-semibold text-base'>Date Of Birth</label>
                                                <label htmlFor="address" className='font-semibold text-base'>Address</label>
                                                <input type="date" className='input' placeholder='Date Of Birth' id='DOF' />
                                                <input type="text" className='input' placeholder='Address' id='address' />

                                            </div>
                                            <div className='flex justify-center my-3'>
                                                <button className='px-10 py-3 bg-[#00B5FF] text-white rounded-lg'>Apply Changes</button>
                                            </div>

                                        </div>
                                    </div>

                                    {/* body end */}
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div >
            </Dialog >
        </Transition.Root >

    )
}

export default EditPlayerModal