import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useCookies } from "react-cookie";
import api from "../../api";
import { failNotification, successNotification } from "../Notifications";

function ConfirmRemovePlayer({ confirmRemove, setConfirmRemove, playerId ,getAllPlayers}) {
  const [cookies] = useCookies(["token"]);

  const handleTeamRemove = async () => {
    try {
      const response = await api.put(
        "auth/api/v1/deactivatePlayer",
        { playerId: playerId },
        { headers: { token: cookies.token } }
      );
      successNotification("Player removed successfully");
      getAllPlayers();
      setConfirmRemove(false);
    } catch (error) {
      failNotification("something went wrong");
      setConfirmRemove(false);
    }
  };

  return (
    <Transition appear show={confirmRemove} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setConfirmRemove(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  This player will be deactivated, please confirm.
                </Dialog.Title>
                <div className="mt-5 flex flex-col gap-3">
                  <button
                    className="text-white bg-[#00B5FF]  rounded-lg w-full py-2"
                    onClick={() => handleTeamRemove()}
                  >
                    Confirm
                  </button>
                  <button
                    className="text-[#00B5FF] border-[#00B5FF] border-2 rounded-lg w-full py-2"
                    onClick={() => setConfirmRemove(false)}
                  >
                    Back
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ConfirmRemovePlayer;
