import React from 'react'
import { RiTeamLine } from 'react-icons/ri'
// import {TbMap2} from 'react-icons/gr'
import {TbSoccerField,TbMap2} from 'react-icons/tb'
import {HiOutlineUsers} from 'react-icons/hi'

function TeamInfoCard({team}) {
  return (
    <div className='w-full border rounded-lg p-4 gap-2 flex flex-col'>
        <div className='flex justify-between  items-center'>
            <div className='flex gap-2 text-[#828D9E]'>
                <RiTeamLine className='w-5 h-5'/>
                <span>Team Name</span>
            </div>
            <span className='font-semibold text-xs'>{team?.teamName}</span>
        </div>
        <div className='flex justify-between  items-center'>
            <div className='flex gap-2 text-[#828D9E]'>
                <TbMap2 className='w-5 h-5'/>
                <span>Area</span>
            </div>
            <span className='font-semibold text-xs'>{team?.area?.areaName}</span>
        </div>
        <div className='flex justify-between items-center'>
            <div className='flex gap-2 text-[#828D9E]'>
                <HiOutlineUsers className='w-5 h-5'/>
                <span>Competition</span>
            </div>
            <span className='font-semibold text-xs '>{team?.rivelary?.rivelaryName}</span>
        </div>
        <div className='flex justify-between  items-center'>
            <div className='flex gap-2 text-[#828D9E]'>
                <TbSoccerField className='w-5 h-5'/>
                <span>Division</span>
            </div>
            <span className='font-semibold text-xs'>{team?.division?.divisionName}</span>
        </div>
        
    </div>
  )
}

export default TeamInfoCard