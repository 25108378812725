import React from "react";

export default function SMPolicies() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center py-8">Social Media Policy</h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3>Purpose</h3>
        <p>
          This policy aims to provide guidelines to follow when using social
          media. This policy does not apply to the personal use of social media
          platforms by Oztag players, referees or officials that makes no
          reference to the game of Oztag or related issues.
        </p>
        <p>
          It is important that the reputation of the Australian Oztag (AO), its
          affiliated associations, all competitions and the game generally is
          not tarnished by anyone using social media tools inappropriately,
          particularly in relation to any content that refers to Oztag.
        </p>
        <p>
          When an Oztag representative (association, convenor, referee, coach,
          representative players or domestic players) discusses their
          involvement in the game of Oztag on social media, they are expected to
          behave and express themselves appropriately and in ways that are
          consistent with AO’s values and policies. The web is not anonymous and
          Oztag representatives should assume that everything they write can be
          traced back to them.
        </p>
        <h3>Scope</h3>
        <p>
          This policy applies to AO staff, managers / convenors of affiliated
          associations, hall of fame members, referees, coaches, representative
          players & recreational players.
        </p>
        <p>
          This policy covers all forms of social media including, but not
          limited to:
        </p>
        <p>
          Managing a profile page on social or business networking sites (i.e.
          facebook, Twitter, MySpace etc)
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Sharing photo or video content (ie. Instagram, flicker, YouTube
            etc).
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Commenting on blogs for business or personal reasons.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Leaving product or service reviews on retailer / customer review
            sites.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Taking part in conversations on public and private web forums or
            editing a Wikipedia page.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Publishing negative or derogatory comments involving Oztag on your
            associations website.
          </li>
        </ul>
        <p>
          The intent of this policy is to include anything posted online where
          information is shared that might affect players, staff, sponsors or AO
          as an organisation and the reputation of the game in general. When
          using social media all representatives must respect the brands of AO
          and follow the guidelines in place to ensure that Oztag’s intellectual
          property or its relationship with sponsors is not compromised or the
          organisation is brought into disrepute.
        </p>
        <h3>Usage</h3>
        <p>
          For AO representatives, affiliated association managers and staff
          using social media, such use:
        </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Must not contain, or link to, defamatory or harassing content –
            this also applies to the use of illustrations or nicknames;
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Must not comment on, or publish confidential information;
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Must not bring the Australian Oztag or the game of Oztag into
            disrepute in any way; or must not be in breach of any of AO’s Codes
            of Conduct in any way.
          </li>
        </ul>
        <p>
          For AO staff using social media, such use must not interfere with work
          commitments
        </p>
        <h3>Official Oztag websites, social pages and online forums</h3>
        <p>
          When affiliated association convenors / managers create a new website,
          social networking page or forum care should be taken to ensure the
          appropriate person has given permission to create the page or forum.
        </p>
        <p>
          Login details should be kept private and not given out to the general
          public to reduce the risk of sites being hacked or tampered with.
        </p>
        <p>
          Permission must be obtained for the use of logos or images. Images of
          children may not be replicated on any site without the written
          permission of the child’s parent / guardian.
        </p>
        <p>For official Oztag social pages, forums or blogs: </p>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ Posts must not contain, nor link to pornographic or indecent
            content;
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ All materials published or used must respect the copyright of
            third parties.
          </li>
          <li style={{ all: "unset", fontWeight: "bold" }}>
            ⦁ AO staff and Oztag convenors must not use official Oztag websites
            or social pages to promote personal projects.
          </li>
        </ul>
        <h3>Consideration for others when using social media platforms</h3>
        <p>
          AO staff and Oztag convenors need to acknowledge that it may not
          always be appropriate to share photos, videos and comments on social
          media sites as they will be shared with hundreds of other users.
          Associations should be considerate to others and not post information
          when they have been asked not to or consent has not been asked nor
          given. They must also remove information about another person should
          they be asked to do so.
        </p>
        <p>
          Under no circumstances should offensive or negative comments be made
          about AO, affiliated associations, referees, coaches or players
          online.
        </p>
        <h3>Breach of policy</h3>
        <p>
          AO and affiliated Associations reserve the right to take appropriate
          action, as their board see’s fit, against any representative who
          breaches these guidelines including, but not limited to, disciplinary
          action, fines or taking legal action for damages.
        </p>
        <p>
          AO will continually monitor online activity in relation to the
          Association and the game. Any breach of this policy by an Oztag
          representative should be reported to AO.
        </p>
        <p>
          If reported and guilty, a breach of this policy may result in
          disciplinary action, the AO or the affiliated association’s have the
          ability to take action as they deem necessary based on the severity of
          the breach
        </p>
        <h3>A basic guide is provided below but not limited to:</h3>
        <p>AO Staff – Warning or Termination of employment</p>
        <p>
          Affiliated Associations – 1st offence Verbal or written warning 2nd
          offence possible termination of licence
        </p>
        <p>
          Representative Coach - 1st offence written warning and termination of
          that appointment 2nd offence a suspension term from coaching any rep
          team
        </p>
        <p>
          Oztag players - 1st offence verbal or written warning and/or
          suspension from playing.
        </p>
        <p>
          2nd offence suspension term from representative teams and or
          competitions
        </p>
        <p>
          Referee - 1st offence written warning and/or suspension from
          refereeing 2nd offence a suspension term
        </p>
        <h3>Definitions</h3>
        <p>
          Affiliated associations – Any Oztag competition run under the auspices
          of the Australian Oztag Sports Association
        </p>
        <p>
          Association convenor – means the person who is the public officer or
          the president of the association.
        </p>
        <p>
          Oztag Representative – is any association, convenor, referee, coach,
          or Oztag player.
        </p>
      </div>
    </div>
  );
}
