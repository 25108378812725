import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import ReactLoading from "react-loading";
import AddField from "../AddField";
import ConfirmRemoveGeneral from "../ConfirmRemoveGeneral";
import api from "../../../api";
import AddDivision from "../AddDivisionModal";

function AdminDivisions() {
  const [DivisonData, setDivisonData] = useState([]);
  const [modalType, setModalType] = useState("add");
  const [cookies] = useCookies(["token"]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removeApi, setRemoveApi] = useState("");
  const [DivisionModal, setDivisionModal] = useState(false);
  const [selectedDivision, setselectedDivision] = useState();
  const [loading, setLoading] = useState(false);

  const getAllDivisions = async () => {
    setLoading(true);
    const response = await api.get("crud/api/v1/division", {
      headers: { token: cookies.token },
    });
    setLoading(false);
    // console.log(response.data)
    setDivisonData(response.data);
  };

  useEffect(() => {
    getAllDivisions();
  }, []);

  const handleAddNew = () => {
    setModalType("add");
    setDivisionModal(true);
  };

  const handleEdit = (data) => {
    setselectedDivision(data);
    setModalType("edit");
    setDivisionModal(true);
  };
  const handleRemove = (data) => {
    setselectedDivision(data);
    setRemoveApi("crud/api/v1/removeDivision");
    setConfirmRemove(true);
  };

  const { SearchBar } = Search;

  let columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "56px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            {rowIndex}
          </div>
        );
      },
    },
    {
      dataField: "divisionName",
      text: "Division Name",
      sort: true,
    },
    {
      dataField: "night",
      text: "Night",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "96px", textAlign: "center" };
      },
    },
    {
      dataField: "competition.rivelaryName",
      text: "Rivalry",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "64px", textAlign: "center" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "64px", textAlign: "center" };
      },
    },

    {
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center gap-5 items-start">
            <FiEdit
              size={20}
              className="cursor-pointer hover:text-green-500 text-xl mt-1"
              onClick={() => handleEdit(row)}
            />
            <TiDeleteOutline
              size={30}
              className="cursor-pointer hover:text-red-500 text-xl"
              onClick={() => handleRemove(row)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmRemoveGeneral
        confirmRemove={confirmRemove}
        setConfirmRemove={setConfirmRemove}
        apiUrl={removeApi}
        selected={selectedDivision}
        resetFc={getAllDivisions}
      />

      <AddDivision
        show={DivisionModal}
        setShow={setDivisionModal}
        getAllDivisions={getAllDivisions}
        type={modalType}
        selectedDivision={selectedDivision}
      />
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      {DivisonData.length > 0 ? (
        <div className="h-fit flex flex-col w-4/5 gap-5 scale-90 -mx-20 min-h-screen">
          <ToolkitProvider
            keyField="_id"
            data={DivisonData}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="flex w-full justify-between">
                  <SearchBar {...props.searchProps} className="input " />
                  <button
                    className="normalButton py-2 text-sm"
                    onClick={() => handleAddNew()}
                  >
                    Add Division
                  </button>
                </div>
                <hr />

                <BootstrapTable
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  condensed
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </>
  );
}

export default AdminDivisions;
