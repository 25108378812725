import React from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import CartItem from "../components/CartItem";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactLoading from "react-loading";

function ShoppingCart() {
  const [cartProducts, setCartProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    api
      .get("ecommerce/api/v1/cartList", { headers: { token: cookies.token } })
      .then((res) => {
        setCartProducts(res.data.cart);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const calculatSum = (cartProducts) => {
    const sumArray = [];
    cartProducts.forEach((item) => {
      if (item?.item) {
        sumArray.push(item.quantity * item?.item?.price);
      }
    });
    return sumArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };

  const handleCheckout = () => {
    const productsIds = [];
    cartProducts?.forEach((item) => {
      productsIds.push(item._id);
    });
    navigate("/checkout");
  };
  return (
    <div className="flex flex-col container scale-90">
      <div className="my-3 ">
        <Link
          to="/"
          className="no-underline font-medium text-base text-[#828D9E] mx-2"
        >
          Main
        </Link>
        /
        <Link
          to="/shop"
          className="no-underline font-medium text-base text-[#828D9E] mx-2"
        >
          Shopping Cart
        </Link>
      </div>
      <h2 className="h2Semibold">Shopping Cart</h2>
      {!isLoading && cartProducts.length > 0 && (
        <>
          <table className="cartTable">
            <thead>
              <tr className="border-b">
                <th className="py-3" width={"40%"}>
                  Item
                </th>
                <th width={"20%"} className={"pl-16"}>
                  Size
                </th>
                <th width={"20%"} className={"pl-16"}>
                  Qty
                </th>
                <th width={"10%"}>Subtotal</th>
                <th width={"10%"}></th>
              </tr>
            </thead>
            <tbody>
              {cartProducts?.map((item) => {
                return (
                  <CartItem
                    key={item._id}
                    itemName={item?.item?.name}
                    price={item?.item?.price}
                    id={item?.item?._id}
                    itemId={item._id}
                    quantity={item.quantity}
                    setProducts={setCartProducts}
                    image={
                      `${process.env.REACT_APP_SERVER_URL}` + item?.item?.image
                    }
                    size={item?.size}
                  />
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-col justify-between my-3">
            <div className="w-10/12 flex justify-between mt-5">
              <h5 className="h4Semibold">Total</h5>
              <h5 className="h4Semibold">${calculatSum(cartProducts)}</h5>
            </div>
            <div className="flex justify-between mt-5">
              <label className="text-lg">
                <Link
                  to={"/shop"}
                  className="input h-fit no-underline text-black"
                >
                  Continue Shopping
                </Link>
              </label>
              <button
                className="input bg-[#00B5FF] text-white text-center"
                onClick={handleCheckout}
                disabled={cartProducts.length === 0}
              >
                Checkout
              </button>
            </div>
          </div>
        </>
      )}
      {!isLoading && cartProducts.length === 0 && (
        <div className="h-[350px] flex justify-center items-center ">
          <label className="text-lg">
            No Item In Cart {"  "}
            <Link to={"/shop"} className=" text-black font-semibold">
              Continue Shopping
            </Link>
          </label>
        </div>
      )}
      {isLoading && (
        <div className="h-[350px] flex justify-center items-center ">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </div>
  );
}

export default ShoppingCart;
