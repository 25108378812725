import React from 'react'
import { useLocation } from 'react-router-dom'
import DownloadApp from '../components/DownloadApp'
import TournmentTable from '../components/TournmentTable'

function TournmentInner() {
    const location = useLocation()
    // console.log(props.location)
    // const { title } = location.state
    // console.log(title)
    return (
        <div className='flex flex-col justify-center items-center scale-90'>
            <h1 className='h1Semibold'>
                {location.state.title&&location.state.title}
            </h1>
            {/* tounment tables */}
            <TournmentTable group='Group A' division='Sydney City Mens Division Tables' />
            <TournmentTable group='Group B' division='Sydney City Womens Division Tables' />
            <TournmentTable group='Group C' division='Sydney City Mixed Division Tables' />

            <DownloadApp />
        </div>
    )
}

export default TournmentInner