import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BsEye, BsPerson, BsPhone } from "react-icons/bs";
import { CgCalendar, CgLock } from "react-icons/cg";
import { HiArrowRight } from "react-icons/hi";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import api from "../api";
import * as Yup from "yup";
import { successNotification } from "../components/Notifications";
import ChangePasswordModal from "../components/auth/ChangePasswordModal";
import CartItem from "../components/CartItem";
import OrderItem from "../components/orderItem";
import AddNewTeamPopup from "../components/playerProfile/AddNewTeamPopup";
import DeletePlayerProfile from "./DeletePlayerProfile";
import { PhoneRegExp } from "../components/TeamRegForm3";
function PlayerProfile() {
  const [userOrders, setOrderSummery] = useState();
  const [changePassword, setChangePassword] = useState(false);
  const [showAddNewTeam, setShowAddNewTeam] = useState(false);

  const [userData, setUserData] = useState();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    dateOfBirth: "",
    email: "",
  });
  const [codeVisible, setCodeVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  let [isOpen, setIsOpen] = useState(false);
  let [isOpen2, setIsOpen2] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }
  const navigate = useNavigate();
  const getPlayerInfo = async () => {
    try {
      const user = await api.get("/auth/api/v1/getPlayerData", {
        headers: { token: cookies.token },
      });
      setUserData(user.data);
      // console.log(userData);
    } catch {
      // console.log("error");
      navigate("/");
    }
  };

  const getOrderSummery = () => {
    api
      .get("/ecommerce/api/v1/userOrders", {
        headers: { token: cookies.token },
      })
      .then((res) => {
        setOrderSummery(res.data.orders);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getPlayerInfo();
    getOrderSummery();
  }, []);

  useEffect(() => {
    getPlayerInfo();
  }, []);
  console.log(userData, "=====<");

  useEffect(() => {
    if (userData) {
      // console.log(userData.dateOfBirth);
      setInitialValues({
        ...initialValues,
        ...userData,
        dateOfBirth: userData.dateOfBirth
          ? new Date(userData?.dateOfBirth)?.toISOString().slice(0, 10)
          : null,
      });
    }
    // console.log(new Date(userData.dateOfBirth).toISOString().slice(0,10))
  }, [userData]);
  // console.log(initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      dateOfBirth: Yup.date()
        .required("Required")
        .max("2024-01-31", "DOB field must be earlier than 2024-01-31")
        .min("1980-12-31", "DOB field must be later than 1980-12-31"),
      address: Yup.string()
        .min(6, "Please insert a valid address")
        .required("Required"),
      phoneNumber: Yup.string()
        // .length(10, "please enter a valid phone number")
        .matches(PhoneRegExp, "please enter a valid phone number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);

      const formData = new FormData();

      Object.entries(values).forEach((item) => {
        let [key, value] = item;
        if (
          key === "firstName" ||
          key === "lastName" ||
          key === "email" ||
          key === "address" ||
          key === "dateOfBirth" ||
          key === "phoneNumber" ||
          key === "image"
        ) {
          formData.append(key, value);
        }
      });
      try {
        const newUser = await api.post(
          "/auth/api/v1/updatePlayerDataFromProfile",
          formData,
          { headers: { token: cookies.token } }
        );
        getPlayerInfo();
        successNotification("Player Data Updated Successfully");
        closeModal();
        // console.log(newUser)
      } catch (error) {
        // console.log("error");
      }
    },
  });
  return (
    <>
      <ChangePasswordModal
        changePassword={changePassword}
        setChangePassword={setChangePassword}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Player
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      className="grid grid-cols-2 gap-3 my-10"
                      onSubmit={formik.handleSubmit}
                    >
                      {/* <form className='flex flex-col' onSubmit={formik.handleSubmit}> */}
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="firstName"
                          name="firstName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-500">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="lastName"
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-500">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="email"
                        >
                          E-mail
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="email"
                          name="email"
                          type="email"
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          value={formik.values.email}
                          disabled={true}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="address"
                        >
                          Address
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="address"
                          name="address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-red-500">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="dateOfBirth"
                        >
                          Date Of Birth
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfBirth}
                        />
                        {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth ? (
                          <div className="text-red-500">
                            {formik.errors.dateOfBirth}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="text-red-500">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex flex-col">
                        <label
                          className="font-medium text-base mt-3"
                          htmlFor="confirmPassword"
                        >
                          Image
                        </label>
                        <input
                          className="border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	"
                          id="image"
                          name="image"
                          type="file"
                          onChange={(e) => {
                            formik.setFieldValue("image", e.target.files[0]);
                          }}
                          onBlur={formik.handleBlur}
                          value={undefined}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className="text-red-500">
                            {formik.errors.image}
                          </div>
                        ) : null}
                      </div>

                      {/* <div className='w-full flex flex-col col-span-2'>
                                                <div className='flex flex-row my-5 items-center'>
                                                    <input checked={readTerms} onChange={() => setReadTerms(!readTerms)} type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                                                    <label className='font-medium text-base ml-3 '>This player has read & agreed to the insurance Condititions of Play & the Competition Regulations</label>
                                                </div>

                                            </div> */}

                      <div className="w-full flex flex-col col-span-2 items-center">
                        <button
                          type="submit"
                          className="normalButton w-fit py-2"
                        >
                          Apply Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Order Summery
                  </Dialog.Title>
                  <div className="mt-2 h-[400px] overflow-y-scroll">
                    {userOrders?.map((item, index) => {
                      return (
                        <>
                          {item?.products?.map((item, index) => {
                            return (
                              <Fragment key={item._id}>
                                <OrderItem
                                  itemName={item.name}
                                  price={item.price}
                                  image={item.image}
                                  product_Gender={item.product_Gender}
                                />
                              </Fragment>
                            );
                          })}
                        </>
                      );
                    })}

                    {/* <OrderItem />
                    <OrderItem />
                    <OrderItem />
                    <OrderItem />
                    <OrderItem /> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <AddNewTeamPopup
        show={showAddNewTeam}
        setShow={setShowAddNewTeam}
        playerInfo={userData}
        resetData={getPlayerInfo}
      />
      <div className="container scale-75  flex flex-col gap-5 justify-center items-center">
        <h1 className="font-semibold text-3xl text-center">Profile</h1>

        <div className="flex w-full justify-center my-3">
          {userData?.image ? (
            <img
              loading="lazy"
              className="w-20 h-20 rounded-full"
              src={`${process.env.REACT_APP_SERVER_URL}` + userData?.image}
              alt="Rounded avatar"
            ></img>
          ) : (
            <div className="relative w-32 h-32 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg
                className="absolute w-36 h-36 text-gray-400 -left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center gap-2">
          {/* <span className="text-base font-medium text-[#828D9E]">
            #Man of the match{" "}
          </span>
          <HiArrowRight className="text-[#FFB000]" />
          <span className="text-base font-medium text-[#1E354E]">4 times </span> */}
        </div>
        <div className=" w-4/6 flex items-center flex-col">
          <div className="w-full flex justify-between my-3">
            <button
              className="input w-fit py-2 bg-[#00B5FF] text-white rounded-lg"
              onClick={() => setShowAddNewTeam(true)}
            >
              Register new team
            </button>
            <button
              className="px-10 py-3 bg-[#00B5FF] text-white rounded-lg"
              onClick={openModal}
            >
              Edit Profile
            </button>
          </div>
          <div className="grid grid-cols-2 w-full gap-3">
            <div className="flex gap-2 items-center">
              <BsPerson className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Full Name</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>
                {userData?.firstName} {userData?.lastName}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <MdEmail className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Email</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.email}</span>
            </div>
            <div className="flex gap-2 items-center">
              <BsPhone className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Phone Number</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.phoneNumber}</span>
            </div>
            <div className="flex gap-2 items-center">
              <MdLocationPin className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Address</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.address}</span>
            </div>
            <div className="flex gap-2 items-center">
              <CgCalendar className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Date of Birth</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>
                {new Date(userData?.dateOfBirth).toLocaleDateString()}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <CgLock className="text-[#828D9E]" />
              <span className="text-[#828D9E]">Player Code</span>
              <div className="flex"></div>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <BsEye
                onClick={() => setCodeVisible(!codeVisible)}
                className="cursor-pointer"
              />
              <span>{codeVisible ? userData?.playerCode : "**********"}</span>
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-[#828D9E]">Player Area</span>
            </div>
            <div className="flex gap-2 flex-row-reverse">
              <span>{userData?.area?.areaName}</span>
            </div>
            <button
              className=" text-center input w-fit py-2"
              onClick={() => setChangePassword(true)}
            >
              changePassword
            </button>
          </div>

          <div className="w-full flex flex-col justify-center  items-center  my-5 gap-3">
            <div className="flex gap-2 items-center mb-2">
              <RiTeamLine className="text-[#828D9E]" />
              <span className="text-[#828D9E]">My teams</span>
            </div>

            <div className="w-full flex-col justify-between gap-y-4 ">
              <table className=" table">
                <thead className="text-lg tracking-wider">
                  <th className="text-center">Team Name</th>
                  <th className="text-center">Team Status</th>
                  <th className="text-center"> Team Code</th>
                  <th className="text-center"> Player Payment Status</th>
                </thead>
                <tbody>
                  {userData?.subscriptions?.map((subscription) => (
                    <tr key={subscription._id}>
                      <td className="text-base text-center font-semibold text-[#828D9E] ">
                        {subscription.team.teamName}
                      </td>
                      <td
                        className={` ${
                          subscription.team.status === "active"
                            ? "text-green-500"
                            : "text-red-500"
                        } text-base text-center font-semibold text-[#828D9E]`}
                      >
                        {subscription.team.status}
                      </td>
                      <td className="text-base text-center font-semibold text-[#828D9E]">
                        {subscription.team.teamCode}
                      </td>
                      <td className=" text-center text-base font-semibold text-[#828D9E]">
                        {subscription.paidStatus ? "Paid" : "Not Paid"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full flex justify-between">
            <DeletePlayerProfile playerId={userData?._id} />
            <button className="input w-fit py-2" onClick={openModal2}>
              Order summery
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerProfile;
