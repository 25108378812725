import React from 'react'

function NewPlayerForm({playerNum}) {
  return (
    <div>
        <h2 className='font-semibold text-2xl my-3'>Player {playerNum}</h2>
            <div className='grid grid-cols-2 gap-3 my-10'>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>First Name</p>
                    <input type="text" placeholder='First Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Last Name</p>
                    <input type="text" placeholder='Last Name' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>E-mail</p>
                    <input type="email" placeholder='E-mail' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Phone Number</p>
                    <input type="number" placeholder='Phone Number' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Date Of Birth</p>
                    <input type="date" placeholder='Date Of Birth' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-base mt-3 '>Address</p>
                    <input type="text" placeholder='Address' className='border rounded-lg border-[#E2E5EA] py-2 px-2 focus:border-[#E2E5EA] border-solid	' />
                </div>
            </div>
            <div className='flex flex-row my-5 items-center'>
                <input type="checkbox" className='w-4 h-4 text-[#00B5FF] bg-[#00B5FF] rounded border-[#00B5FF] focus:ring-[#00B5FF] dark:focus:ring-[#00B5FF] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60' />
                <label className='font-medium text-base ml-3 '>This player has read & agreed to the insurance Condititions of Play & the Competition Regulations</label>
            </div>
    </div>
  )
}

export default NewPlayerForm