import React from "react";

export default function CodeOfConduct() {
  return (
    <div className="container  flex flex-col items-center">
      <h1 className="text-center pt-8">Code of Conduct </h1>
      <h1 className="text-center pb-8">(Players, Parents & Spectators) </h1>
      <div className="flex flex-col  justify-center w-3/4">
        <h3>Players Code of Conduct</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Abide by the rules. Never argue with the referee. Respect
            decisions made by referees and encourage others to do the same.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Never argue with an official. If you disagree, have your captain,
            coach or manager approach the official during a break or after the
            competition.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Control your temper. Verbal or physical abuse towards referees,
            officials or other players, are not acceptable or permitted in
            Oztag. Do not yell at, sledge, harass or ridicule others. Do not use
            foul, threatening or insulting language. Never make racist threats
            or remarks.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Be a good sport. Applaud all good plays whether they are made by
            your team or the opposition.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Treat all participants in your sport as you like to be treated. Do
            not bully or take unfair advantage of another competitor.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Show compassion and consideration at all times, to injured and ill
            players and other persons. Do everything you can to help and support
            them.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Cooperate with your coach, teammates and opponents. Without them
            there would be no competition.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Participate for your own enjoyment and benefit, not just to please
            parents and coaches. Win or lose with respect and dignity.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Respect the rights, dignity and worth of all participants
            regardless of their gender, ability, cultural background or
            religion.
          </li>
        </ul>
        <h3>Parents Code of Conduct</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Remember that children participate in sport for their enjoyment
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Encourage children to participate, do not force them.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Applaud good performance and efforts from all individuals and
            teams. Congratulate all participants on their performance regardless
            of the game's result.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Encourage children to always play according to the rules and
            settle disagreements without resorting to hostility or violence.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Focus on the child's efforts and performance rather than winning
            or losing.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Never ridicule or scold a player for making a mistake. Respect
            officials’ decisions and teach children to do likewise.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Do not use foul language, sledge or harass players, coaches or
            officials
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Show appreciation for volunteer coaches, officials and
            administrators. Without them, your child would not participate.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Respect the rights, dignity and worth of all participants
            regardless of their gender, ability, cultural background or
            religion.
          </li>
        </ul>
        <h3>Spectators Code of Conduct</h3>
        <ul className="flex flex-col gap-2">
          <li style={{ all: "unset" }}>
            ⦁ Respect the decisions of the referee and teach children to do the
            same.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Never ridicule or unduly scold a player for making a mistake.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Do not use violence in any form against any player, official or
            other spectators.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Do not engage in discrimination, harassment or abuse in any form,
            including the use of obscene or offensive language or gestures, the
            incitement of hatred or violence or partaking in indecent or racist
            sledging of players or other spectators
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Spectators are not permitted to enter the playing area while a
            game is on unless instructed by an Oztag official or referee.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Never involve themselves in an altercation / fight should one
            occur.
          </li>
          <li style={{ all: "unset" }}>
            ⦁ Respect the rights, dignity and worth of all participants
            regardless of their gender, ability, cultural background or
            religion.
          </li>
        </ul>
      </div>
    </div>
  );
}
